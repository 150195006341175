export const getSearchedProducts = (state, props) => {
  const filteredProducts = state.get('resources').get('filteredProducts');
  const searchedProducts = filteredProducts.filter((product) => {
    if (product.name.toUpperCase().includes(props.searchedTerm.toUpperCase())
      || product.description && product.description.toUpperCase().includes(props.searchedTerm.toUpperCase())
    ) {
      return product;
    }
  });
  return searchedProducts;
};
