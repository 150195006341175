import React, { useState, Fragment } from 'react';
import {
  Icon, Paper, Typography, Popover,
} from '@mui/material';

const styles = {
  helpOutlineIcon: {
    fontSize: '18px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  helpPopoverText: {
    whiteSpace: 'pre-line',
  },
  helpPopover: {
    maxWidth: '350px',
    padding: '10px',
  },
};

const HelpIcon = ({ infoText }) => {
  const [anchor, setAnchor] = useState(null);

  return (
    <Fragment>
      <Icon
        sx={styles.helpOutlineIcon}
        onClick={(event) => {
          event.stopPropagation();
          setAnchor(event.target);
        }}
      >
        help_outline
      </Icon>
      <Popover
        open={!!anchor}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
        onClick={event => event.stopPropagation()}
      >
        <Paper
          sx={styles.helpPopover}
        >
          <Typography sx={styles.helpPopoverText}>{infoText}</Typography>
        </Paper>
      </Popover>
    </Fragment>
  );
};

export default HelpIcon;
