import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {
  Typography,
  FormControl,
  TextField,
  Paper,
  Popover,
  FormLabel,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { commonResourceStyle } from '../../css/style';

class TextInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchor: null,
    };
  }

  render() {
    const {
      value,
      title,
      isRequired,
      classes,
      onChange,
      onPaste = () => {},
      multiline,
      rows, // number of rows to display
      helpMessage,
      maxLength,
      disabled,
      placeholder,
      theme,
    } = this.props;
    const { anchor } = this.state;
    const displayError = isRequired && isEmpty(value);

    const stylesWithTheme = commonResourceStyle(theme);

    return (
      <FormControl fullWidth sx={stylesWithTheme.formControl}>
        <div style={stylesWithTheme.formControlLabelContainer}>
          <FormLabel sx={stylesWithTheme.formControlLabel}>
            {title}
            {helpMessage && (
              <HelpOutlineIcon
                sx={stylesWithTheme.helpOutlineIcon}
                onClick={e => this.setState({
                  anchor: e.target,
                  helpMessage,
                })}
              />
            )}
          </FormLabel>
          {displayError && (
            <FormLabel sx={stylesWithTheme.formControlLabelWarnText}>This field is required</FormLabel>
          )}
        </div>
        <TextField
          value={value}
          onChange={e => onChange(e.target.value)}
          onPaste={e => onPaste()}
          fullWidth
          disabled={disabled}
          multiline={multiline}
          rows={rows}
          variant="outlined"
          InputProps={{ classes: { notchedOutline: displayError ? classes.invalidInput : null } }}
          inputProps={{ maxLength }}
          placeholder={placeholder}
        />
        <Popover
          open={!!anchor}
          anchorEl={anchor}
          onClose={e => this.setState({ anchor: null })}
        >
          <Paper sx={stylesWithTheme.helpPopover}>
            <Typography sx={stylesWithTheme.helpPopoverText}>{helpMessage}</Typography>
          </Paper>
        </Popover>
      </FormControl>
    );
  }
}

TextInput.propTypes = {
  value: PropTypes.string,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
};

TextInput.defaultProps = {
  isRequired: false,
};

export default withStyles(commonResourceStyle, { withTheme: true })(TextInput);
