import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import {
  Button,
  Paper,
  Typography,
  Tooltip,
  Icon,
  Alert,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import useMediaQuery from '@mui/material/useMediaQuery';

import DisplayResources from '../crud/DisplayResources';
import {
  resourceFields,
  matchesPaginationCount,
  VET_TYPES,
  BASE_URLS,
  SCHOOL_LOGOS_STORAGE_LINK,
  IMG_STORAGE_LINK,
} from '../../data/Constants';
import {
  renderPaginationImage,
  renderPageNumber,
  renderAddIcon,
  getMoodIcon,
} from '../core/renderHelper';
import {
  utils, mapConnections, getBackgroundColor, scrollToItem, formatPostTitle,
} from '../../utils/Functions';
import { commonResourceStyle } from '../../css/style';
import LoadingBar from '../subcomponents/LoadingBar';
import types from '../../actions/ActionTypes';
import DateFormat from '../subcomponents/DateFormat';
import ConnectionCard from '../subcomponents/ConnectionCard';
import SearchableSelect from '../subcomponents/SearchableSelect';
import PracticeAutocomplete from '../subcomponents/PracticeAutocomplete';
import SchoolAutocomplete from '../subcomponents/SchoolAutocomplete';

const styles = commonResourceStyle();

class OtherChannelChats extends Component {
  constructor(props) {
    super(props);
    const { admin } = props;
    const { isExternshipAdmin, isShiftAdmin } = admin;
    const connectionFilters = (props.filters && props.filters['connections/other/externchannel']) ? props.filters['connections/other/externchannel'] : {};
    this.state = {
      selectedPractices: connectionFilters.selectedPractices || [],
      selectedJobPostings: connectionFilters.selectedJobPostings || [],
      selectedTalentTypes: connectionFilters.selectedTalentTypes || [],
      selectedSchools: connectionFilters.selectedSchools || [],
      sort: null,
      order: false,
      fetchConnections: null,
      otherChannel: isShiftAdmin && !isExternshipAdmin ? 'externchannel' : null,
      vetSchools: [],
    };
    this.mounted = false;
  }

  componentDidMount = async () => {
    const {
      actions, apiToken, width, pagination, history, admin, adminPractices, location,
    } = this.props;
    const { otherChannel } = this.state;
    if (!otherChannel) return;
    this.mounted = true;
    let queryParams = `connections/other/${otherChannel}?start=0&count=10&practices=&jobpostings=&talentTypes=`;
    let filtersObj = {};
    if (location.search) {
      filtersObj = utils.queryParamsToObject(location.search);
      queryParams = `connections/other/${otherChannel}${location.search}`;
      this.setQueryParams(queryParams);
      await Promise.all([
        actions.getAllResources(apiToken, 'schools'),
        actions.getAllResources(apiToken, queryParams),
      ]).then(([schoolList, resp]) => {
        this.setState({ vetSchools: schoolList.response });

        if (!resp.error && this.mounted) {
          this.setState({
            fetchConnections: resp.response.list,
          });
        }
      });
    } else {
      this.setState({
        selectedPractices: [],
        selectedJobPostings: [],
        selectedTalentTypes: [],
        selectedSchools: [],
        otherChannel,
      });
      const start = 0;
      queryParams = this.getQuery(start, matchesPaginationCount, this.getFilterParams());
      this.setQueryParams(queryParams);
      await Promise.all([
        actions.getAllResources(apiToken, 'schools'),
        actions.getAllResources(apiToken, queryParams),
      ]).then(([schoolList, resp]) => {
        this.setState({ vetSchools: schoolList.response });
        if (!resp.error && this.mounted) {
          this.setState({
            fetchConnections: resp.response.list,
          });
        }
      });
    }

    if (this.mounted) {
      if (adminPractices) {
        filtersObj.practices && this.resetFilter(filtersObj.practices, adminPractices, 'id', 'selectedPractices');
      } else {
        actions.getAllResources(apiToken, BASE_URLS.getPractices).then((resp) => {
          filtersObj.practices && resp.response && this.resetFilter(filtersObj.practices, resp.response, 'id', 'selectedPractices');
        });
      }

      if (filtersObj.jobpostings) {
        actions.getAllResources(apiToken, 'jobpostings/titles').then((resp) => {
          resp.response.list && this.resetFilter(filtersObj.jobpostings, resp.response.list, 'id', 'selectedJobPostings');
        });
      } else {
        actions.getAllResources(apiToken, 'jobpostings/titles');
      }

      if (filtersObj.talentTypes) {
        this.resetFilter(filtersObj.talentTypes, VET_TYPES, 'key', 'selectedTalentTypes');
      }

      if (filtersObj.talentSchools) {
        this.resetFilter(filtersObj.talentSchools, this.state.vetSchools, 'id', 'selectedSchools');
      }
    }

    const otherFilter = document.getElementById('otherFilter');
    otherFilter && otherFilter.addEventListener('keydown', e => this.handleKeyDown(e));
  };

  resetFilter = (filterField, datalist, key, stateFilterName) => {
    utils.setPrevFilter(filterField, datalist, key, (selectedItems) => {
      this.mounted && this.setState({ [stateFilterName]: selectedItems });
    });
  };

  componentDidUpdate = (prevProps) => {
    const { location } = this.props;
    if (location.search && prevProps.location.search && prevProps.location.search !== location.search) {
      this.fetchDataOnQueryChange();
    }
    scrollToItem(prevProps, this.props, 'otherApplVerticalScroll');
  };

  fetchDataOnQueryChange = () => {
    const {
      actions,
      apiToken,
      location,
    } = this.props;
    const { otherChannel } = this.state;

    if (!this.mounted) return;
    actions.updateLoadingState(types.VIEW_MORE_RESOURCE_REQUESTED);
    const queryParams = `connections/other/${otherChannel}${location.search}`;
    actions.getAllResources(apiToken, queryParams).then((resp) => {
      if (!resp.error && this.mounted) {
        this.setState({
          fetchConnections: resp.response.list,
        });
      }
    });
    actions.updateLoadingState(types.VIEW_MORE_RESOURCE_SUCCESS);
  };

  componentWillUnmount = () => {
    this.mounted = false;
    const otherFilter = document.getElementById('otherFilter');
    otherFilter && otherFilter.removeEventListener('keydown', e => this.handleKeyDown(e));
  };

  getFilterParams = () => {
    const {
      selectedPractices,
      selectedJobPostings,
      selectedTalentTypes,
      selectedSchools,
    } = this.state;

    return `&practices=${
      selectedPractices.map(item => item.id).join(',')
    }&jobpostings=${
      selectedJobPostings.map(item => item.id).join(',')
    }&talentTypes=${
      selectedTalentTypes.map(item => item.key).join(',')
    }&talentSchools=${
      selectedSchools.map(item => item.id).join(',')
    }`;
  };


  setQueryParams(queryParams) {
    const { history } = this.props;
    const { otherChannel } = this.state;

    const query = queryParams.replace(`connections/other/${otherChannel}`, '');
    history.push(query);
  }

  getQuery = (start, count, filterParams) => {
    const { otherChannel } = this.state;
    return `connections/other/${otherChannel}?start=${start}&count=${count}${filterParams}`;
  };

  applyFilters = () => {
    const {
      actions,
      loading,
    } = this.props;
    const {
      selectedPractices,
      selectedJobPostings,
      selectedTalentTypes,
      selectedSchools,
      otherChannel,
    } = this.state;

    if (!loading) {
      const start = 0;
      const queryParams = this.getQuery(start, matchesPaginationCount, this.getFilterParams());
      this.setQueryParams(queryParams);
    }
    actions.addResourceFilter(`connections/other/${otherChannel}`, {
      selectedPractices,
      selectedJobPostings,
      selectedTalentTypes,
      selectedSchools,
    });
  };

  clearFilters = () => {
    const {
      selectedPractices,
      selectedJobPostings,
      selectedTalentTypes,
      selectedSchools,
    } = this.state;

    if (selectedPractices.length || selectedJobPostings.length || selectedTalentTypes.length || selectedSchools.length) {
      this.setState({
        selectedPractices: [],
        selectedJobPostings: [],
        selectedTalentTypes: [],
        selectedSchools: [],
      }, () => this.applyFilters());
    }
  };

  onClickViewMore = async (pageNo, sortField) => {
    const {
      pagination,
      loading,
      admin,
      isDesktop,
    } = this.props;
    const { sort, order, otherChannel } = this.state;

    const newSort = sortField ? sortField : sort;
    let newOrder = (sortField && sort === sortField) ? !order : (sortField && sort !== sortField) ? false : order;
    this.setState({
      sort: newSort,
      order: newOrder,
    });

    if (!loading) {
      let queryParams;
      if (!pageNo) {
        queryParams = `connections/other/${otherChannel}?start=${pagination.start + pagination.count}&count=${matchesPaginationCount}${this.getFilterParams()}`;
      } else {
        queryParams = `connections/other/${otherChannel}?start=${(pageNo - 1) * pagination.count}&count=${matchesPaginationCount}${this.getFilterParams()}`;
      }
      if (newSort) {
        if (newSort === 'connect_date') {
          newOrder = !newOrder;
        }
        queryParams = `${queryParams}&sort=${newSort}&order=${newOrder ? 'desc' : 'asc'}`;
      }
      this.setQueryParams(queryParams);
    }

    if (!isDesktop) {
      sessionStorage.removeItem('otherApplVerticalScroll');
    }
  };

  getContactedAt = (data) => {
    const { classes } = this.props;
    const { lastTalentMsgDate, firstEmployerResDate } = data;
    // lastTalentMsgDate will not be null (if no talent msg, it will be connectedDate), firstEmployerResDate might be null
    const lastTalentMsgDateTS = new Date(lastTalentMsgDate).getTime();
    const firstEmployerResDateTS = firstEmployerResDate ? new Date(data.firstEmployerResDate).getTime() : Date.now();
    const faceInterval = firstEmployerResDateTS - lastTalentMsgDateTS;

    return (
      <div className={classes.contactedAtContainer}>
        <DateFormat
          str={lastTalentMsgDateTS}
          moment
        />
        {getMoodIcon(faceInterval, classes)}
      </div>
    );
  };

  getFullNameColumn = data => (
    <div style={styles.overflowEllipsis}>
      {`${data.talentPII.firstName} ${data.talentPII.lastName}`}
    </div>
  );

  getTalentTypeIcon = (data, isCard) => {
    let image;
    let style;
    let employeeTitle = '';

    const { vetSchools } = this.state;

    // Set school logo image for each student application
    if (data && data.talent && data.talent.isStudent) {
      let schoolId = 404;
      const talentSchoolObj = data.talentProfileItems.find(item => item.criteria.name === 'school-id');
      if (talentSchoolObj) {
        const school = vetSchools && vetSchools.find(vetSchool => vetSchool.id == talentSchoolObj.numValue);
        if (school) {
          schoolId = school.id;
          employeeTitle = school.name ? utils.toTitleCase(school.name) : '';
        }
      }
      image = `${SCHOOL_LOGOS_STORAGE_LINK}${schoolId}.png`;
    } else if (data && data.jobPosting && data.jobPosting.talentType) {
      image = `${IMG_STORAGE_LINK}talent-types/${data.jobPosting.talentType}.png`;
      employeeTitle = utils.toTitleCase(data.jobPosting.talentType);
    }
    const isNew = !data.adminViewDate;

    if (isCard && isNew) {
      style = styles.newTalentTypeIconCard;
    } else if (isCard) {
      style = styles.talentTypeIconCard;
    } else {
      style = styles.fullNameColumn;
    }

    return (
      <Tooltip title={employeeTitle}>
        <div style={style}>
          {isNew && (
            <div style={isCard ? styles.cardNewItem : styles.newItem}>
              <Icon style={styles.newItemIcon}>new_releases</Icon>
              new
            </div>
          )}
          {image && (
            <img
              style={isCard ? styles.talentTypeIconImage : styles.fullNameColumnImage}
              src={image}
              alt="Selectable Icon"
            />
          )}
        </div>
      </Tooltip>
    );
  };

  getJobTitle = data => (
    <Tooltip title={formatPostTitle(data.jobPosting.title)}>
      <div style={styles.overflowEllipsis}>
        {formatPostTitle(data.jobPosting.title)}
      </div>
    </Tooltip>
  );

  getStatus = (data) => {
    const isArchived = data && data.jobPosting && data.jobPosting.deletedAt && !data.jobPosting.isDeleted;
    const isDeleted = data && data.jobPosting && data.jobPosting.deletedAt && data.jobPosting.isDeleted;

    if (isArchived) {
      return (
        <Alert style={styles.jobStatus} icon={<ArchiveIcon />} severity="warning">
          Posting Archived
        </Alert>
      );
    }

    if (isDeleted) {
      return (
        <Alert style={styles.jobStatus} icon={<DeleteIcon />} severity="error">
          Posting Deleted
        </Alert>
      );
    }

    return <div>{' '}</div>;
  };

  getFilter = (items, type, title) => {
    const { isDesktop } = this.props;
    const value = this.state[type];
    if (type === 'selectedPractices') {
      return (
        <PracticeAutocomplete
          handleStateChange={(val) => {
            if (val) {
              return this.setState({ [type]: val });
            }
          }}
          title={title}
          items={items}
          value={value}
          labelCount={3}
        />
      );
    } else if (type === 'selectedSchools') {
      return (
        <SchoolAutocomplete
          handleStateChange={(val) => {
            if (val) {
              return this.setState({ selectedSchools: val });
            }
          }}
          title={title}
          schoolList={items}
          selectedSchools={this.state.selectedSchools}
          labelCount={isDesktop ? 3 : 2}
        />
      );
    } else {
      return (
        <SearchableSelect
          value={value}
          title={title}
          items={items}
          onChange={val => this.setState({ [type]: val })}
          filter
        />
      );
    }
  };

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.applyFilters();
    }
  };

  getFilters = () => {
    const {
      adminPractices,
      jobpostings,
      classes,
      admin,
      theme,
    } = this.props;
    const {
      selectedJobPostings,
      selectedPractices,
      selectedTalentTypes,
      selectedSchools,
      vetSchools,
    } = this.state;
    const { isExternshipAdmin, isShiftAdmin } = admin;

    const formattedJobPostings = jobpostings && jobpostings.map(j => ({ ...j, title: formatPostTitle(j.title) }));

    const stylesWithTheme = commonResourceStyle(theme);

    return (
      <div className={classes.filterFrontContainer}>
        <Paper sx={stylesWithTheme.paperFilters}>
          {adminPractices && this.getFilter(adminPractices, 'selectedPractices', 'All Practices')}
          {this.getFilter(VET_TYPES, 'selectedTalentTypes', 'All Employee Types')}
          {isExternshipAdmin && formattedJobPostings && this.getFilter(formattedJobPostings, 'selectedJobPostings', 'All Open Positions')}
          {isShiftAdmin && vetSchools && this.getFilter(vetSchools, 'selectedSchools', 'All Schools')}
          <Button
            color="primary"
            variant="contained"
            sx={stylesWithTheme.searchButton}
            onClick={e => this.applyFilters()}
            disableRipple
          >
            APPLY FILTER
          </Button>
        </Paper>
        {
          (selectedJobPostings.length || selectedPractices.length || selectedTalentTypes.length || selectedSchools.length)
            ? (
              <Button
                sx={stylesWithTheme.clearFilterText}
                onClick={() => this.clearFilters()}
                disableRipple
                variant='text'
              >
                clear filters
              </Button>
            )
            : <div />
        }
      </div>
    );
  };

  render() {
    const {
      classes, otherChannelCons, actions, loading, apiToken,
      viewMoreLoading, pagination, adminPractices, history,
      isDesktop, theme,
    } = this.props;
    const { fetchConnections = [], otherChannel } = this.state;
    const stylesWithTheme = commonResourceStyle(theme);

    if (!otherChannel) return null;

    const customRender = {
      talentTypeIcon: this.getTalentTypeIcon,
      fullName: this.getFullNameColumn,
      contactedAt: this.getContactedAt,
      jobTitle: this.getJobTitle,
      status: this.getStatus,
    };
    let mutableConnections = fetchConnections
      ? cloneDeep(fetchConnections)
      : cloneDeep(otherChannelCons ? otherChannelCons.filter(connection => connection.id !== 'viewMore') : otherChannelCons);

    mutableConnections && mutableConnections.map((connection) => {
      const practiceId = connection.jobPosting.practice_id;
      const practice = adminPractices && adminPractices.find(practice => practice.id === practiceId);
      const practiceName = practice ? practice.name : '-';
      Object.assign(connection, { practiceName });
    });

    return (
      (loading === true && viewMoreLoading === 0)
        ? <LoadingBar />
        : (
          <div style={stylesWithTheme.container} id='otherFilter'>
            <img className={classes.searchImage} src={`${IMG_STORAGE_LINK}search-dog-s-2.png`} />
            <div className={classes.resourceHeader}>
              <div style={stylesWithTheme.resourceNameLarge}>
                <Typography sx={stylesWithTheme.resourceNameLarge}>
                  <NotificationsActiveIcon sx={stylesWithTheme.resourceLogoApp} />
                    EXTERN CHATS
                </Typography>
              </div>
            </div>
            {this.getFilters()}
            {
              isDesktop
                ? (
                  <Paper sx={stylesWithTheme.paperApplications}>
                    <DisplayResources
                      data={mutableConnections}
                      actions={actions}
                      fieldNames={resourceFields.applications}
                      customRender={customRender}
                      resourceName="otherchannelchats"
                      apiToken={apiToken}
                      viewOnly
                      onClickViewMore={this.onClickViewMore}
                      totalResource={pagination && pagination.totalSize}
                      pagination={pagination}
                      viewMoreLoading={viewMoreLoading}
                    />
                  </Paper>
                ) : (
                  mutableConnections
                    ? (
                      <div style={stylesWithTheme.applicationMobileCardsContainer}>
                        {mapConnections(mutableConnections, this.getTalentTypeIcon).map((connection, i) => (
                          <ConnectionCard
                            key={connection.key}
                            data={connection}
                            backgroundColor={getBackgroundColor(i)}
                            history={this.props.history}
                            onClick={() => {
                              sessionStorage.setItem('otherApplVerticalScroll', window.pageYOffset);
                              history.push(`/connections/${connection.key}/view`);
                            }}
                          />
                        ))
                        }
                        {this.renderPaginationTableBodyRow()}
                      </div>
                    )
                    : <Typography sx={stylesWithTheme.text}>No recent applications</Typography>
                )
            }
          </div>
        )
    );
  }

  renderPaginationTableBodyRow = () => {
    const {
      viewMoreLoading,
      pagination,
      onClickButton,
      buttonTitle,
    } = this.props;

    if (!pagination) return null;

    const totalPage = Math.floor((pagination.totalSize + pagination.count - 1) / pagination.count);
    const currentPage = pagination.start / pagination.count;

    return (
      <div>
        {viewMoreLoading !== 0 ? (
          'Loading...'
        ) : (
          <div style={styles.paginationContainer} id="paginationContainer">
            {renderPaginationImage({
              isPrevious: true,
              currentPage,
              onClickViewMore: this.onClickViewMore,
            })}
            {[...Array(totalPage)].map((x, index) => (
              renderPageNumber({
                currentPage,
                totalPage,
                index,
                onClickViewMore: this.onClickViewMore,
              })
            ))}
            {renderPaginationImage({
              totalPage,
              currentPage,
              pagination,
              onClickViewMore: this.onClickViewMore,
            })}
            {renderAddIcon({
              buttonTitle,
              styleObj: styles.paginationButton,
              onClickButton,
            })}
          </div>
        )}
      </div>
    );
  };
}

OtherChannelChats.propTypes = {
  loading: PropTypes.bool.isRequired,
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  apiToken: PropTypes.string.isRequired,
};

OtherChannelChats.defaultProps = {};

function OtherChannelChatsWrapper({ theme, ...rest }) {
  const isDesktop = theme ? useMediaQuery(theme.breakpoints.up('md')) : true;
  return <OtherChannelChats {...rest} theme={theme} isDesktop={isDesktop} />;
}

export default withStyles(commonResourceStyle, { withTheme: true })(withRouter(OtherChannelChatsWrapper));
