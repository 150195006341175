/* eslint-disable quote-props */
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { isEmpty, sortBy } from 'lodash';
import {
  Button,
  Paper,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
  Hidden,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import AddBoxIcon from '@mui/icons-material/AddBox';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { DataGrid } from '@mui/x-data-grid';

import { PracticeReportsStyles } from './styles/PracticeReportsStyles';
import { green } from '../../css/style';
import { badgeColor } from '../../utils/Functions';
import AddFastpassStaffDialog from '../subcomponents/AddFastpassStaffDialog';
import { EXPERIENCE_CATEGORY_SPECIAL_FIELD_CONFIG, TALENT_ROLES_CONFIG } from '../../data/Constants';

const fastpassColumns = [
  {
    field: 'name',
    headerName: 'Name',
    width: 300,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 300,
  },
  {
    field: 'displayRoles',
    headerName: 'Unleashed Worker Role',
    flex: 1,
  },
  {
    field: 'jfDescriptor',
    headerName: 'Workday Worker Type',
    flex: 0.5,
  },
];

const pageSizeOptions = [5, 10, 25, 50, 100];

class FastpassStaff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      practice: null,
      selectedDate: {},
      fastpassData: [],
      staff: [],
      badgedUsers: [],
      loaded: false,
      deletedRowIds: [],
      badges: [],
    };
  }

  componentDidMount = async () => {
    await this.retrieveData();
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { resources, match } = nextProps;
    const { resourceId } = match.params;
    const practice = resources[`practices/${resourceId}`];
    if (!prevState.practice) {
      return { practice };
    }
    return null;
  }

  handleActionClick = ({
    action,
    id,
    userId,
    fastpassBadgeId,
  }) => {
    const { actions, apiToken, match } = this.props;
    const { resourceId } = match.params;
    const { deletedRowIds } = this.state;

    if (action === 'remove') {
      if (!id) return;
      actions.deleteResource(apiToken, 'fastpass', id).then((resp) => {
        this.setState({ deletedRowIds: [...deletedRowIds, id] }, () => this.retrieveFastpassData());
      });
    } else if (action === 'add') {
      if (!userId || !resourceId) return;
      actions.addResource(apiToken, {
        practiceId: Number(resourceId),
        userId,
        fastpassBadgeId,
      }, 'fastpass').then((resp) => {
        this.retrieveFastpassData();
      });
    }
  };

  retrieveData = async () => {
    const {
      actions,
      apiToken,
      match,
      badges,
    } = this.props;
    const { resourceId } = match.params;

    actions.getOneResource(apiToken, `practices/${resourceId}`);

    const staffRes = await actions.getAllResources(apiToken, 'privatetalents', 'enabled');
    let staffList = staffRes?.response?.map(it => ({
      id: it.id,
      name: `${it.name} (${it.email})`,
      email: it.email,
    }));

    const badgedUsersRes = await actions.getAllResources(apiToken, 'userbadges/fastpass');
    let badgedUserList = badgedUsersRes?.response?.map(it => ({
      id: it.id,
      name: `${it.name} (${it.email})`,
      email: it.email,
      fpBadgeId: it?.badges?.find(b => b.config?.allowFastpass)?.id,
    }));

    if (isEmpty(badges)) {
      actions.getAllResources(apiToken, 'badges', 'all').then((resp) => {
        this.setState({ badges: resp?.response });
      });
    } else {
      this.setState({ badges });
    }

    this.setState({ staff: staffList, badgedUsers: badgedUserList }, () => this.retrieveFastpassData());
  };

  retrieveFastpassData = async () => {
    const { actions, apiToken, match } = this.props;
    const { resourceId } = match.params;
    const { badgedUsers, deletedRowIds } = this.state;

    const fastpassRes = await actions.getAllResources(apiToken, `fastpass/practice/${resourceId}`);
    const fastpasslist = fastpassRes?.response?.list;

    if (fastpasslist) {
      let resultList = fastpasslist.filter(r => !r.deleted_at || (r.deleted_at && deletedRowIds.includes(r.id)));
      resultList = sortBy(resultList, 'created_at').reverse();
      resultList.forEach((talentData) => {
        let displayRoles = '';
        !isEmpty(talentData.roles) && talentData.roles.forEach((roleItem) => {
          let subRole = '';
          let subRoleTitle = '';
          if (!roleItem.talentType) {
            displayRoles = '';
          } else {
            const typeObj = EXPERIENCE_CATEGORY_SPECIAL_FIELD_CONFIG[roleItem.talentType] || {};
            if (isEmpty(roleItem?.profileItems)) {
              displayRoles = TALENT_ROLES_CONFIG[roleItem.talentType]?.title;
            } else {
              const subTypeProfileItem = roleItem?.profileItems?.find(i => i.criteria === typeObj.key) || {};
              if (typeObj.items) {
                if (subTypeProfileItem.criteria === 'speciality') {
                  subRole = subTypeProfileItem.str2Value;
                  subRoleTitle = `Specialist (${(typeObj.items.find(i => i.key === subRole) || {}).title})`;
                } else {
                  subRole = subTypeProfileItem.strValue;
                  subRoleTitle = (typeObj.items.find(i => i.key === subRole) || {}).title;
                }
              }
            }
            displayRoles = displayRoles && subRoleTitle ? `${displayRoles} - ${subRoleTitle}` : displayRoles || subRoleTitle;
          }
        });
        talentData.displayRoles = displayRoles;
      });

      this.setState({
        fastpassData: resultList,
        practiceName: fastpassRes?.response?.name,
      });
      // Filter out the staff already assigned to fastpass
      this.setState({ badgedUsers });
    }
    this.setState({ loaded: true });
  };

  renderHeader = () => {
    const {
      classes,
      actions,
      apiToken,
      match,
      theme,
    } = this.props;
    const { practice } = this.state;
    const { resourceId } = match.params;
    const fastpassAllStaff = practice?.fastpassAllStaff || false;
    const activePractice = practice && practice.active === true && !practice.deletedAt;
    const stylesWithTheme = PracticeReportsStyles(theme);

    return (
      <div className={classes.titleContainer}>
        <Button
          variant="contained"
          disableRipple
          onClick={e => this.setState({ openAddStaffDialog: true, allStaff: true })}
          sx={stylesWithTheme.reportButton}
          startIcon={<AddToPhotosIcon />}
          disabled={!activePractice}
        >
          Add &nbsp;
          <strong style={{ fontSize: '14px', display: 'contents' }}>
            * all *
          </strong>
          &nbsp; local staff
        </Button>
        <FormControlLabel
          style={{
            marginLeft: '3px',
          }}
          control={(
            <Checkbox
              style={{ paddingLeft: 0 }}
              checked={fastpassAllStaff}
              onChange={(e) => {
                this.setState({ practice: { ...practice, fastpassAllStaff: e.target.checked } },
                  () => actions.updateResource(apiToken, { ...practice, fastpassAllStaff: e.target.checked }, 'practices', resourceId));
              }}
              disabled={!activePractice}
            />
          )}
          label={(
            <p>
              Fastpass all newly enabled staff of this location.
            </p>
          )}
        />

        <div className={classes.reportButtonGroup}>
          <Button
            variant="contained"
            disableRipple
            onClick={e => this.setState({ openAddStaffDialog: true, allStaff: false })}
            sx={stylesWithTheme.reportButton}
            startIcon={(<AddBoxIcon />)}
            disabled={!activePractice}
          >
            Add fastpass user
          </Button>
        </div>
      </div>
    );
  };

  render() {
    const {
      classes,
      match,
      history,
      actions,
      apiToken,
      theme,
    } = this.props;
    const {
      fastpassData,
      openAddStaffDialog,
      staff,
      allStaff,
      loaded,
      practice,
      deletedRowIds,
      badgedUsers,
      badges,
    } = this.state;
    const { resourceId } = match.params;
    const stylesWithTheme = PracticeReportsStyles(theme);

    return (
      <div className={classes.mainContainer}>
        <div className={classes.resourceHeader}>
          <div className={classes.resourceNameLarge}>
            <Typography sx={stylesWithTheme.resourceNameLarge}>
              { practice ? `Fastpass Staff at ${practice.name}` : 'Fastpass Staff'}
            </Typography>
            <Hidden smDown>
              <Button
                sx={stylesWithTheme.headerButton}
                onClick={() => history.push(`/practices/${match?.params?.resourceId}/view`)}
              >
                BACK
              </Button>
            </Hidden>
          </div>
        </div>

        <Paper sx={stylesWithTheme.paper} style={{ paddingTop: 20 }}>
          <div className={classes.reportContainer}>
            {this.renderHeader()}
          </div>
          <Grid container sx={stylesWithTheme.gridContainer} spacing={2}>
            <Grid item xs={12}>
              <div className={classes.dataGridContainer}>
                <DataGrid
                  autoHeight
                  rows={fastpassData}
                  columns={[...fastpassColumns,
                    {
                      field: 'user_type',
                      headerName: 'User Type',
                      flex: 0.5,
                      headerAlign: 'center',
                      align: 'center',
                      renderCell: (params) => {
                        const badgeId = params?.row?.fastpass_badge_id;
                        if (badgeId) {
                          const badge = badges?.find(b => b.id === badgeId);
                          return (
                            <Button
                              sx={stylesWithTheme.badgeFilter}
                              key={`badge_${badgeId}`}
                              style={{
                                backgroundColor: badgeColor(badge.id || badge.title),
                                margin: '-5px 4px',
                              }}
                            >
                              {badge.title}
                            </Button>
                          );
                        }
                        return 'STAFF';
                      },
                    },
                    {
                      field: 'action',
                      headerName: 'Action',
                      width: 120,
                      type: 'actions',
                      renderCell: params => (
                        <strong>
                          {params?.row?.deleted_at
                            ? (
                              <Button
                                title='Restore the row'
                                onClick={() => this.handleActionClick({
                                  action: 'add',
                                  id: params?.row?.id,
                                  userId: params?.row?.user_id,
                                  fastpassBadgeId: params?.row?.fastpass_badge_id,
                                })}
                              >
                                <AddCircleIcon
                                  style={{ color: green }}
                                  size="small"
                                />
                              </Button>
                            )
                            : (
                              <Button
                                title='Delete the row'
                                onClick={() => this.handleActionClick({ action: 'remove', id: params?.row?.id })}
                              >
                                <RemoveCircleIcon
                                  style={{ color: 'red' }}
                                  size="small"
                                />
                              </Button>
                            )
                          }

                        </strong>
                      ),
                    },
                  ]}
                  initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                  }}
                  pageSizeOptions={pageSizeOptions}
                  getRowClassName={params => (params?.row?.deleted_at ? classes.disabledRow : '')}
                  disableColumnMenu
                  isRowSelectable={() => false}
                  loading={!loaded}
                />
              </div>
            </Grid>
          </Grid>
        </Paper>
        <AddFastpassStaffDialog
          open={openAddStaffDialog}
          apiToken={apiToken}
          handleOnClose={(refreshData) => {
            this.setState({ openAddStaffDialog: false });
            if (refreshData) {
              if (allStaff) {
                this.setState({ practice: { ...practice, fastpassAllStaff: allStaff } }, () => {
                  actions.updateResource(apiToken, { ...practice, fastpassAllStaff: allStaff }, 'practices', resourceId);
                  this.retrieveFastpassData();
                });
              } else {
                this.retrieveFastpassData();
              }
            }
          }}
          actions={actions}
          staff={staff}
          badgedUsers={badgedUsers}
          practiceId={resourceId}
          allStaff={allStaff}
          fastpassData={fastpassData}
        />
      </div>
    );
  }
}

export default withStyles(PracticeReportsStyles, { withTheme: true })(withRouter(FastpassStaff));
