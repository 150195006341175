import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { configureStore } from './store/configureStore';
import App from './components/App.jsx';

import {
  ThemeProvider,
  createTheme,
} from '@mui/material/styles';
import { createGenerateClassName } from '@mui/styles';

import { customizedThemes } from './css/style';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

/**
 * In the production version, the material-ui class names are binded into shorter format.
 * eg. change from 'MuiTypography-root-1' to 'jss1'
 * but some classes are given the same name. eg. multiple classes have the same name such as 'jss1' , 'jss2' , 'jss3' ...
 * this causes wrong styling is matched for some material-ui components
 * comment that gives idea for solving this issue:
 * https://github.com/mui-org/material-ui/issues/11397#issuecomment-390675123
 * link for solving issue, using JssProvider:
 * https://material-ui.com/customization/css-in-js/#jssprovider
 */


import { StylesProvider } from '@mui/styles';

const generateClassName = createGenerateClassName({
  productionPrefix: 'c',
});
const theme = createTheme(customizedThemes);
const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      {/* Inject Emotion before JSS */}
      <StylesProvider generateClassName={generateClassName}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <App />
        </LocalizationProvider>
      </StylesProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById('app'),
);
