/* eslint-disable quote-props */
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import {
  isEmpty,
  uniq,
  sortBy,
  isNil,
} from 'lodash';
import {
  Button,
  Paper,
  Typography,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Hidden,
  List,
  ListItem,
  Chip,
  Badge,
  Link,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import { RegionalNeedsStyles } from './styles/RegionalNeedsStyles';
import DateUtilities from '../Datepicker/utils';
import LoadingBar from '../subcomponents/LoadingBar';
import Pagination from '../subcomponents/Pagination';

const typeLabel = {
  VET_TECH: 'Vet Tech',
  VET_ASSISTANT: 'Vet Assistant',
  VET_TECH_OR_ASSISTANT: 'Vet Tech/VA',
  DMV_GP: 'GP DVM',
  DMV_ER: 'ER DVM',
  DMV_GP_OR_ER: 'GP/ER DVM',
  CSR: 'CSR',
};

const distance = 75; // miles
const timeRange = 30; // within the next 30 days

const regionalNeedsPaginationCount = 6;

class RegionalNeeds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      regionalData: [],
    };
  }

  componentDidMount = async () => {
    const {
      actions,
      apiToken,
      match,
      resources,
    } = this.props;
    const { resourceId } = match.params;

    if (!resourceId) return;
    const pagination = resources?.pagination ? resources.pagination[`practices/${resourceId}/distance/${distance}`] : null;

    const res = await actions.getAllResources(apiToken, `practices/${resourceId}/distance/${distance}?start=${pagination?.start || 0}&count=${regionalNeedsPaginationCount}`);
    if (res && res.response && !isEmpty(res.response.list)) {
      let sortedRegionalData = sortBy(res.response.list, ['distanceInMiles']);

      // Remove empty types
      sortedRegionalData = sortedRegionalData.map((item) => {
        let avaiObj = item.available;
        Object.keys(avaiObj).forEach(key => isEmpty(avaiObj[key]) && delete avaiObj[key]);
        return item;
      });

      this.setState({ regionalData: sortedRegionalData });
    } else {
      const practiceRes = await actions.getOneResource(apiToken, `practices/${resourceId}`);
      this.setState({
        practice: practiceRes && practiceRes.response ? practiceRes.response : {},
        noPracticesMgs: !res || !res.response || isEmpty(res.response.list) ? 'There are not any active nearby practices.' : null,
      });
    }
  };

  renderChip = (practiceId, type, dateStr, index) => {
    const { theme } = this.props;
    const { chipRootFull, chipRootLabel } = RegionalNeedsStyles(theme);

    return (
      <Chip
        key={`${practiceId}_${type}_${dateStr}_${index}`}
        label={`${moment(dateStr).format('MMM.DD')} `}
        color="primary"
        size="medium"
        sx={{
          ...chipRootFull,
          '& .MuiChip-label': chipRootLabel,
        }}
      />
    );
  };

  onClickViewMore = (pageNo) => {
    const {
      actions,
      apiToken,
      resources,
      loading,
      match,
    } = this.props;
    const { resourceId } = match.params;
    const pagination = resources?.pagination ? resources.pagination[`practices/${resourceId}/distance/${distance}`] : null;

    if (!loading) {
      const start = pagination?.start || 0;
      let query = `practices/${resourceId}/distance/${distance}?`;

      query += !pageNo
        ? `&start=${start + regionalNeedsPaginationCount}&count=${regionalNeedsPaginationCount}`
        : `&start=${(pageNo - 1) * regionalNeedsPaginationCount}&count=${regionalNeedsPaginationCount}`;
      actions.getAllResources(apiToken, query).then((res) => {
        if (!isEmpty(res?.response?.list)) {
          let sortedRegionalData = sortBy(res.response.list, ['distanceInMiles']);

          // Remove empty types
          sortedRegionalData = sortedRegionalData.map((item) => {
            let avaiObj = item.available;
            Object.keys(avaiObj).forEach(key => isEmpty(avaiObj[key]) && delete avaiObj[key]);
            return item;
          });

          this.setState({ regionalData: sortedRegionalData });
        }
      });
    }
  };

  getJobCategory(dataType) {
    switch (dataType) {
      case typeLabel.VET_TECH:
      case typeLabel.VET_ASSISTANT:
      case typeLabel.VET_TECH_OR_ASSISTANT:
        return 'vet-tech-or-assistant';
      case typeLabel.DMV_GP:
      case typeLabel.DMV_ER:
      case typeLabel.DMV_GP_OR_ER:
        return 'dvm-doctor-of-veterinary-medicine';
      default:
        return 'non-clinical';
    }
  }

  render() {
    const {
      classes,
      match,
      history,
      admin,
      loading,
      viewMoreLoading,
      theme,
      resources,
    } = this.props;
    const { regionalData, practice, noPracticesMgs } = this.state;
    const { resourceId } = match.params;

    const stylesWithTheme = RegionalNeedsStyles(theme);

    const pagination = resources?.pagination ? resources.pagination[`practices/${resourceId}/distance/${distance}`] : null;

    if (admin && !admin.isShiftAdmin) {
      const returnLink = match.params && match.params.resourceId ? `/practices/${match.params.resourceId}/view` : '/practices';
      history.push(returnLink);
    }

    return (
      (loading === true && viewMoreLoading === 0)
        ? <LoadingBar />
        : (
          <div className={classes.mainContainer}>
            <div className={classes.resourceHeader}>
              <Typography sx={stylesWithTheme.resourceName}>
                {`Regional Needs close to ${!isEmpty(regionalData) ? regionalData[0].enquiringPracticeName : practice ? practice.name : ''}`}
              </Typography>
              <Typography sx={stylesWithTheme.subtitle}>
                {`Geographical distance: ${distance} miles - Time range: the next ${timeRange} days`}
              </Typography>
              <Hidden smDown>
                <Button
                  sx={stylesWithTheme.headerButton}
                  onClick={() => history.push(`/practices/${match.params.resourceId}/view`)}
                >
                  BACK
                </Button>
              </Hidden>
            </div>

            <Paper sx={stylesWithTheme.paper}>
              <Grid container spacing={2}>
                { !isEmpty(regionalData) ? regionalData.map(dataItem => (
                  <Grid item xs={12} md={6} key={`grid_${dataItem.id}`}>
                    <Card sx={stylesWithTheme.card} key={`card_${dataItem.id}`}>
                      <CardHeader
                        title={(
                          <Link href={`/practices/${dataItem.id}/view`}>
                            <Typography
                              color="secondary"
                              sx={stylesWithTheme.practiceName}
                              key={`cardTitle_${dataItem.id}`}
                            >
                              {dataItem.practiceName}
                            </Typography>
                          </Link>
                        )}
                        subheader={!isNil(dataItem.distanceInMiles) ? `Distance: ${Number(dataItem.distanceInMiles).toFixed(2)} miles` : null}
                        key={`cardHeader_${dataItem.id}`}
                        sx={stylesWithTheme.cardHeader}
                      />
                      <CardContent sx={stylesWithTheme.cardContent} style={isEmpty(dataItem.available) ? { justifyContent: 'center' } : {}} key={`cardContent_${dataItem.id}`}>
                        <List component="nav" key={`list_${dataItem.id}_list`}>
                          { !isEmpty(dataItem.available) ? Object.keys(dataItem.available).map((type) => {
                            if (isEmpty(dataItem.available[type]) || !typeLabel[type]) return null;

                            let sortedDates = type ? dataItem.available[type].sort((date1, date2) => (DateUtilities.midDayDate(date1) - DateUtilities.midDayDate(date2))) : [];
                            let multipleDates = [];
                            sortedDates.forEach((date, index) => {
                              const duplicated = DateUtilities.isSameDay(DateUtilities.midDayDate(date), DateUtilities.midDayDate(sortedDates[index - 1]));
                              if (duplicated && multipleDates.indexOf(date) < 0) {
                                multipleDates.push(date);
                              }
                            });
                            sortedDates = uniq(sortedDates);

                            return (
                              <ListItem key={`list_item_${dataItem.id}_${type}`}>
                                <Grid container sx={stylesWithTheme.gridContainer} spacing={2} key={`${dataItem.id}_${type}_container`}>
                                  <Grid item xs={12} md={5} lg={3} sx={stylesWithTheme.gridItem} key={`${dataItem.id}_${type}_item`}>
                                    <div
                                      className={classes.bullet}
                                      key={`${dataItem.id}_${type}_bullet`}
                                    />
                                    &nbsp;&nbsp;
                                    <Link href={`/jobpostings?practices=${dataItem.id}&talentTypes=${this.getJobCategory(typeLabel[type])}&isArchived=false`}>
                                      <Typography key={`${dataItem.id}_${type}_label`}>
                                        {typeLabel[type]}
                                      </Typography>
                                    </Link>
                                  </Grid>
                                  <Grid item xs={12} md={7} lg={9} sx={stylesWithTheme.gridItem} key={`${dataItem.id}_${type}_dates`}>
                                    { !isEmpty(sortedDates) && sortedDates.map((dateStr, index) => {
                                      if (multipleDates.indexOf(dateStr) >= 0) {
                                        return (
                                          <Badge
                                            key={`${dataItem.id}_${type}_${dateStr}_${index}_badge`}
                                            overlap="circular"
                                            badgeContent='+'
                                            color='error'
                                            anchorOrigin={{
                                              vertical: 'top',
                                              horizontal: 'right',
                                            }}
                                          >
                                            {this.renderChip(dataItem.id, type, dateStr, index)}
                                          </Badge>
                                        );
                                      }
                                      return this.renderChip(dataItem.id, type, dateStr, index);
                                    })
                                    }
                                  </Grid>
                                </Grid>
                              </ListItem>
                            );
                          })
                            : (
                              <Typography
                                style={{ textAlign: 'center' }}
                                key={`cardContent_${dataItem.id}_text`}
                              >
                                No relief shifts - fully staffed!
                              </Typography>
                            )}
                        </List>
                      </CardContent>
                    </Card>
                  </Grid>
                ))
                  : noPracticesMgs && (
                    <Typography
                      style={{ textAlign: 'center', width: '100%' }}
                      key='cardContent_text'
                    >
                      {noPracticesMgs}
                    </Typography>
                  )
                }
              </Grid>
              {
              pagination
                && (
                  <Pagination
                    pagination={pagination}
                    paginationCount={6}
                    onClickViewMore={this.onClickViewMore}
                  />
                )
              }
            </Paper>
          </div>
        )
    );
  }
}

export default withStyles(RegionalNeedsStyles, { withTheme: true })(withRouter(RegionalNeeds));
