import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { FormLabel, Grid } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Slider from '@mui/material/Slider';
import { withStyles } from '@mui/styles';

import { commonResourceStyle, orange } from '../../css/style';
import MultiSelect from './MultiSelect';
import { customLabelConfig } from '../../data/Constants';

const styles = commonResourceStyle();

const generalSliderStyles = theme => ({
  root: {
    color: '#ffffff',
    height: 8,
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus,&:hover': {
      boxShadow: '0 2px 4px 0 rgba(140, 140, 140, 0.3), 0 -2px 4px 0 rgba(140, 140, 140, 0.2)',
    },
    boxShadow: '0 2px 4px 0 rgba(140, 140, 140, 0.3), 0 -2px 4px 0 rgba(140, 140, 140, 0.2)',
  },
  track: {
    height: 8,
    borderRadius: 4,
    backgroundColor: `${orange}`,
  },
  rail: {
    height: 8,
    backgroundColor: '#bababa',
  },
  markLabel: {
    transform: 'translateX(-100%)',
    fontSize: '0.7rem',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  markLabelActive: {
    transform: 'translateX(0%)',
  },
  valueLabel: {
    top: -15,
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
});

const PrettoSlider = withStyles(generalSliderStyles)(Slider);

class TalentExperienceItem extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      type, config, state, setState, customWidth, disabled, isRequired,
    } = this.props;
    const min = config.min ? config.min : 0;
    const displayError = isRequired && isEmpty(state[type]);

    return (
      <Grid item md={customWidth}>
        <FormControl fullWidth style={styles.formControl}>
          <div style={styles.formControlLabelContainer}>
            <FormLabel sx={styles.formControlLabel}>
              {config.inputTitle || config.title}
            </FormLabel>
            {displayError && (
              <FormLabel style={{ color: 'red' }}>This field is required</FormLabel>
            )}
          </div>
          {config.items
          && (
            <MultiSelect
              disabled={disabled}
              value={state[type] || []}
              title={config.unselected}
              items={config.items}
              onChange={value => setState({ [type]: value })}
              form
              type={type}
              customLabelConfig={customLabelConfig}
              unSelctableTitle
              isRequired={isRequired}
              error={displayError}
            />
          )}
          {config.max && (
            <div style={styles.marginTop}>
              <PrettoSlider
                aria-label="pretto slider"
                value={state[type] || 0}
                min={min}
                max={config.max}
                step={1}
                valueLabelDisplay="on"
                marks={[{ value: min || 0, label: min }, { value: config.max, label: `${config.max}+` }]}
                onChange={(e, value) => setState({ [type]: value, ['customExperienceLevel']: true })}
              />
            </div>
          )}
        </FormControl>
      </Grid>
    );
  }
}

TalentExperienceItem.propTypes = {
  customWidth: PropTypes.number,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
};

TalentExperienceItem.defaultProps = {
  customWidth: 6,
  disabled: false,
  isRequired: false,
};

export default withStyles(commonResourceStyle)(TalentExperienceItem);
