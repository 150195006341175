import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  VET_TYPES_TITLES,
  BASE_URLS,
  IMG_STORAGE_LINK,
  EXTERNSHIP,
  HQ_ADMIN_KEY,
} from '../../data/Constants';
import {
  Paper, Typography, Icon, Button, IconButton, Card, CardContent, Grid,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import DomainIcon from '@mui/icons-material/Domain';
import PlaceIcon from '@mui/icons-material/Place';
import { commonResourceStyle, orange } from '../../css/style';
import { commonCardStyles } from '../../css/cardStyles';
import ExperienceDetailCard from '../subcomponents/ExperienceDetailCard';
import ContactInfo from '../subcomponents/ContactInfo';
import DateFormat from '../subcomponents/DateFormat';
import { badgeColor, formatPostTitle } from '../../utils/Functions';

const cardStyles = commonCardStyles();

class ConnectionsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      badges: [],
      unreadMessagesCount: 0,
      isHoverMsgBtn: false,
      vetSchools: [],
    };
  }

  componentDidMount = async () => {
    const {
      match, apiToken, actions, resources,
    } = this.props;
    const { resourceId } = match.params;
    const { adminPractices } = resources;
    actions.updateResource(apiToken, { adminViewDate: new Date() }, 'connections', resourceId);
    await actions.getAllResources(apiToken, 'schools').then((schoolList) => {
      this.setState({ vetSchools: schoolList.response });
    });
    actions.getOneResource(apiToken, `connections/${resourceId}`).then((res) => {
      if (!res.error) {
        const connection = res.response;
        this.setState({
          unreadMessagesCount: connection.unreadMessagesCount,
          talent: connection.talent,
          talentPII: connection.talentPII,
          email: connection.email,
          savedSearch: connection.savedSearch,
          jobPosting: connection.jobPosting,
          talentProfileItems: connection.talentProfileItems,
          badges: connection.badges,
          connectDate: connection.connectDate,
        });
      }
    });
    if (!adminPractices || !adminPractices.length) {
      actions.getAllResources(apiToken, BASE_URLS.getPractices);
    }
    try {
      window.scrollTo(0, 0);
    } catch (e) {
      console.log(e);
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { resources, match } = nextProps;
    const { resourceId } = match.params;
    const connection = resources[`connections/${resourceId}`];
    return connection || null;
  }

  getConnectionHeader() {
    const {
      history, match, classes, resources, theme,
    } = this.props;
    const { resourceId } = match.params;
    const {
      talent, talentPII, email, savedSearch, unreadMessagesCount, isHoverMsgBtn,
    } = this.state;
    const { adminPractices } = resources;
    if (!talent || !talentPII) {
      return '';
    }
    const homePractice = adminPractices && adminPractices.find(practice => practice.id === talent.practice_id);
    const practiceName = homePractice ? homePractice.name : null;

    let image = null;

    if (talent.type) {
      image = `${IMG_STORAGE_LINK}talent-types/${talent.type}.png`;
    }

    const stylesWithTheme = commonResourceStyle(theme);

    return (
      <div className={classes.connectionHeader}>
        <div className={classes.connectionsHeaderImageContainer}>
          {image && <img style={stylesWithTheme.connectionsHeaderImage} src={image} />}
        </div>
        <div className={classes.connectionHeaderText}>
          <Typography sx={stylesWithTheme.connectionHeaderName}>{`${talentPII.firstName} ${talentPII.lastName}`}</Typography>
          <Typography sx={stylesWithTheme.connectionHeaderTalentType}>{VET_TYPES_TITLES[talent.type]}</Typography>
        </div>
        <div className={classes.contactInfoContainer}>
          <ContactInfo
            phone={talentPII.mobile}
            email={email}
            address={savedSearch.address}
            homePractice={practiceName}
            color='#ffffff'
          />
          <IconButton
            sx={stylesWithTheme.connectionMessageButton}
            style={{ backgroundColor: isHoverMsgBtn ? `${orange}` : '#ffffff' }}
            onClick={() => history.push(`/messages/${resourceId}`)}
            onMouseOver={() => this.setState({ isHoverMsgBtn: true })}
            onMouseLeave={() => this.setState({ isHoverMsgBtn: false })}
          >
            <div style={stylesWithTheme.msgBtnContentContainer}>
              <div className={classes.chatIconContainer}>
                {unreadMessagesCount > 0
                && (
                  <Fragment>
                    <Typography sx={stylesWithTheme.unreadMsgCount}>
                      {unreadMessagesCount}
                    </Typography>
                    <Icon sx={stylesWithTheme.unreadMsgIcon}>
                      lens
                    </Icon>
                  </Fragment>
                )}
                <Icon
                  sx={stylesWithTheme.chatIcon}
                  style={{ color: isHoverMsgBtn ? '#ffffff' : `${orange}` }}
                >
                  chat
                </Icon>
              </div>
              {
                unreadMessagesCount > 0
                && (
                  <div style={stylesWithTheme.newMsgTextContainer}>
                    <Typography sx={stylesWithTheme.newMsgText} style={{ color: isHoverMsgBtn ? '#ffffff' : `${orange}` }}>{`${unreadMessagesCount} new`}</Typography>
                    <Typography sx={stylesWithTheme.newMsgText} style={{ color: isHoverMsgBtn ? '#ffffff' : `${orange}` }}>messages!</Typography>
                  </div>
                )
              }
            </div>

          </IconButton>
        </div>
      </div>
    );
  }

  getBadge(title, id) {
    const { theme } = this.props;
    const { badgeFilter } = commonResourceStyle(theme);

    return (
      <Button
        key={title}
        style={{
          ...badgeFilter,
          backgroundColor: title === 'Thrive' ? badgeColor(title) : badgeColor(id || title),
        }}
      >
        {title}
      </Button>
    );
  }

  getConnectionDetails() {
    const {
      classes, resources, history, actions, admin, theme,
    } = this.props;
    const {
      talent, talentPII, email, savedSearch, badges, jobPosting,
    } = this.state;

    const { isExternshipAdmin, isShiftAdmin } = admin;
    const isHQAdmin = admin && admin.adminRole === HQ_ADMIN_KEY;

    if (!talent || !talentPII) {
      return '';
    }
    const { adminPractices } = resources;
    const practiceItem = adminPractices && adminPractices.find(x => x.id === this.state.jobPosting.practice_id);
    const practiceName = practiceItem ? practiceItem.name : '';
    const practiceLocation = practiceItem ? practiceItem.location : '';

    const stylesWithTheme = commonResourceStyle(theme);
    const rotationTypes = jobPosting.rotationTypes && JSON.parse(jobPosting.rotationTypes);

    return (
      <div className={classes.newConnectionDetailsLeft}>
        <div style={stylesWithTheme.connectionDetailsHeader}>
          {`About ${talentPII.firstName} ${talentPII.lastName}`}
        </div>
        <Typography sx={stylesWithTheme.connectionDetailsText}>{talent.about || '-'}</Typography>

        <div style={stylesWithTheme.connectionDetailsHeader}>
          Work Experience
        </div>
        <Typography sx={stylesWithTheme.connectionDetailsText}>{talent.brief || '-'}</Typography>

        <div style={stylesWithTheme.connectionDetailsHeader}>
          Badges
        </div>
        <div className={classes.badgesContainerView}>
          {talent.private_aggregator_id && talent.private_aggregator_id !== 6 && this.getBadge(talent.private_aggregator_id ? 'private' : 'public')}
          {badges.map(badge => this.getBadge(badge.title, badge.id))}
        </div>
        <div style={stylesWithTheme.connectionDetailsHeader}>
          JOB POSITION
        </div>
        <div
          className={classes.jobPositionCardContainer}
          onClick={() => {
            if (jobPosting.typeOfEmployment === EXTERNSHIP && !isHQAdmin && isShiftAdmin && !isExternshipAdmin) {
              return;
            }
            jobPosting.deletedAt ? actions.displayErrorMessage('This post has been archived')
              : history.push(`/editposition/${jobPosting.parent_job_posting_id || jobPosting.id}`);
          }}
        >
          <Card>
            <CardContent>
              <Typography color='secondary' sx={cardStyles.jobTitle}>
                {formatPostTitle(jobPosting.title)}
              </Typography>
              <Grid container sx={cardStyles.matchItemInfo}>
                <Grid item xs={12} sx={cardStyles.matchIteminfoItem}>
                  <AccessTimeIcon sx={cardStyles.matchIteminfoIcon} />
                  <Typography component='span'>{jobPosting.typeOfEmployment}</Typography>
                </Grid>
                {jobPosting.typeOfEmployment === EXTERNSHIP && (
                  <Grid item xs={12} sx={cardStyles.matchIteminfoItem}>
                    <BookmarksIcon sx={cardStyles.matchIteminfoIcon} />
                    <Typography component='span'>{rotationTypes?.join(', ')}</Typography>
                  </Grid>
                )}
                <Grid item xs={12} sx={cardStyles.matchIteminfoItem}>
                  <DomainIcon sx={cardStyles.matchIteminfoIcon} />
                  <Typography sx={cardStyles.overflowTextEllipsis}>{practiceName}</Typography>
                </Grid>
                <Grid item xs={12} sx={cardStyles.matchIteminfoItem}>
                  <PlaceIcon sx={cardStyles.matchIteminfoIcon} />
                  <Typography>{practiceLocation}</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }

  makeUnreadApplication() {
    const { actions, apiToken, match } = this.props;
    const { resourceId } = match.params;
    actions.updateResource(apiToken, { makeUnread: true }, 'connections', resourceId).then((res) => {
      if (!res.error) {
        this.setState({ makeUnread: true });
      }
    });
  }

  render() {
    const { history, classes, theme } = this.props;
    const {
      connectDate, talentProfileItems, talent, makeUnread, vetSchools,
    } = this.state;
    const stylesWithTheme = commonResourceStyle(theme);

    return (
      <div className={classes.container}>
        <Paper sx={{ ...stylesWithTheme.hideBoxShadows, ...stylesWithTheme.paper }}>
          {this.getConnectionHeader()}
          <div className={classes.connectionDetailsActions}>
            <div style={stylesWithTheme.appActionContainer}>
              <Button color="secondary" variant="outlined" onClick={e => window.print()} sx={stylesWithTheme.printButton}>
                <Icon>print</Icon>
                <Typography sx={stylesWithTheme.printButtonText}>print</Typography>
              </Button>
              <Button
                variant="contained"
                onClick={e => this.makeUnreadApplication()}
                sx={{ ...stylesWithTheme.saveButton, marginLeft: '5px' }}
                style={makeUnread ? { display: 'none' } : {}}
              >
                <img src={`${IMG_STORAGE_LINK}email_icon.svg`} alt="Email" style={stylesWithTheme.unreadImg} />
                <Typography sx={stylesWithTheme.printButtonText}>Mark as Unread</Typography>
              </Button>
            </div>
            <div className={classes.connectionDetailsActionsItem}>
              <Typography sx={stylesWithTheme.connectedOn}>
                <DateFormat str={connectDate} prepend="Connected on" />
              </Typography>
              <Button variant="contained" sx={stylesWithTheme.saveButton} onClick={e => history.goBack()}>Back</Button>
            </div>
          </div>
          <Paper sx={stylesWithTheme.connectionDetails}>
            {this.getConnectionDetails()}
            <ExperienceDetailCard
              classes={classes}
              talent={talent}
              talentProfileItems={talentProfileItems}
              vetSchools={this.state.vetSchools}
            />
          </Paper>
        </Paper>
      </div>
    );
  }
}

ConnectionsView.propTypes = {
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  apiToken: PropTypes.string.isRequired,
};

ConnectionsView.defaultProps = {};

export default withStyles(commonResourceStyle, { withTheme: true })(withRouter(ConnectionsView));
