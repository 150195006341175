import { Map } from 'immutable';
import types from '../actions/ActionTypes';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';

// action.resourcePath --> resource, resourceId, subResource, subResourceId,...
export default function ResourcesReducer(state = Map(), action) {
  if (action.type === types.SIGNOUT_USER) return Map();
  if (action.type === types.GET_DOWNLOAD_RESOURCES_SUCCESS) return state;
  if (action.type === types.UPLOAD_RESOURCE_SUCCESS) return state;
  if (action.type === types.FILTER_PRODUCTS) {
    if (action.category === null) {
      return state.set('filteredProducts', state.get('products'));
    }
    return state.set('filteredProducts', state.get('products').filter(item => action.category.includes(item.category.name)));
  }
  if (action.type === types.GET_RECENT_ORDERS_SUCCESS) {
    return state.set('recentOrders', action.recentOrders.data);
  }
  if (action.type === types.ADD_RESOURCES_FILTER) {
    return state.setIn(['filters', action.resource], action.filter);
  }
  if (!action.resourcePath || (action.resourcePath.includes('stripe-payment')
    && (action.resourcePath.includes('sessions') || action.resourcePath.includes('cancel')))) {
    return state;
  }

  return createNewState(state, action.resourcePath, action);
}

const createNewState = (state, path, action) => {
  let resource = path[0];
  let resourceId = path[1];
  let queryParam = null;

  if (resource === 'practices/admin') {
    resource = 'adminPractices';
  }

  // Check if there is a query parameter
  if (path.length === 1 && resource.includes('?')) {
    const queryParams = resource.split('?');
    resource = queryParams[0];
    queryParam = queryParams[1];
  }

  if (path.includes('archived')) {
    path.splice(2, 1);
  }

  // For paths like 'practice/practiceimages/1
  if (path.length > 2 && !Number(resourceId)) {
    resourceId = path[2];
    path.splice(1, 1);
  }

  if (path.length > 2) {
    const subState = state.get(resource).filter(resource => resource.id == resourceId)[0];
    const subPath = path.slice(2);
    const updatedSubState = createNewState(Map(subState), subPath, action);

    return state.update(resource, resourceList => resourceList.map((resourceItem) => {
      if (resourceItem.id == resourceId) {
        return Map(resourceItem).merge(updatedSubState).toJS();
      }

      return resourceItem;
    }));
  }
  let updatedState = update(state, action, resource, resourceId, queryParam);
  // the product table in the menu page uses the state 'filteredProducts'
  // if the 'products' state updated, the 'filteredProducts' also need to be updated
  if (resource === 'products') {
    updatedState = updatedState.set('filteredProducts', updatedState.get('products'));
  }
  return updatedState;
};

const getStateWithPagination = (resource, action, state) => {
  const paginationInfo = {
    [resource]: {},
  };
  let list;
  Object.keys(action.response).forEach((key) => {
    if (key === 'start' || key === 'count' || key === 'totalSize' || key === 'search'
      || key === 'filter') {
      // Add the start, count, and totalSize to the pagination object
      paginationInfo[resource][key] = action.response[key];
    } else {
      // Initialize list as the list of paginated responses
      list = action.response[key];
    }
  });
  if (!state.has('pagination')) {
    // if the first time request paginated resource
    const resourceList = action.response !== undefined ? list : [];
    return state.set(resource, resourceList).merge({ pagination: paginationInfo });
  } else if (paginationInfo[resource].start === 0) {
    // if request another paginated resource or request fetched paginated resource again
    // e.g each time enter a resource page will trigger a fetch to make sure the resource is up to date
    const newPaginationState = state.get('pagination').merge(paginationInfo);
    return state.set(resource, list).merge({ pagination: newPaginationState });
  } else {
    // if update paginated resource. e.g request next 10 items
    const cocatCond = (state.has(resource) && state.get(resource) && (paginationInfo[resource].start == state.get(resource).length));
    const newResourceList = cocatCond ? state.get(resource).concat(list) : list;
    const newPaginationState = state.get('pagination').merge(paginationInfo);
    return state.set(resource, newResourceList).merge({ pagination: newPaginationState });
  }
};

const update = (state, action, resource, resourceId, queryParam) => {
  switch (action.type) {
    case types.DELETE_RESOURCE_SUCCESS:
      const updatedState = state.update(resource, (resources) => {
        if (resources !== undefined && resources !== null) {
          return resources.filter(res => res.id != resourceId);
        }
        return resources;
      });
      // Decrement pagination totalSize if the resource is paginated
      const totalSize = state.getIn(['pagination', resource, 'totalSize'], null);
      if (totalSize) {
        return updatedState.setIn(['pagination', resource, 'totalSize'], totalSize - 1);
      }
      return updatedState;
    case types.ADD_RESOURCE_SUCCESS:
      return state.update(resource, (resources) => {
        if (resources !== undefined && resources !== null) {
          return resources.concat(action.response);
        }
        return resources;
      });
    case types.UPDATE_RESOURCE_SUCCESS:
      return state.update(resource, (resources) => {
        if (resources !== undefined && resources !== null) {
          if (resourceId) {
            return resources.map((res) => {
              if (res.id.toString() === resourceId.toString()) {
                return action.response;
              }
              return res;
            });
          } else if (resource === 'companies') {
            resources[0] = action.response;
          }
        }
        return resources;
      });
    case types.GET_ONE_RESOURCE_SUCCESS:
      if (resource === 'connections' || resource === 'privatetalents') {
        return state.set(resource, action.response !== undefined ? [action.response] : []);
      }
      return state.set(resource, action.response !== undefined ? action.response : {});
    case types.GET_ALL_RESOURCES_SUCCESS:
      if (queryParam || resource === 'jobpostings') {
        return getStateWithPagination(resource, action, state);
      }

      if (resource === 'companies') {
        return state.set(resource, action.response !== undefined
          ? [action.response]
          : []);
      }

      return state.set(resource, action.response !== undefined
        ? action.response
        : []);
    default:
      return state;
  }
};
