import {
  headerButtonStyle,
  hoverEffectOne,
  navy,
  tiffany,
  orange,
  gray,
} from '../../../css/style';

export const practicesPageStyles = theme => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    overflow: 'hidden',
  },
  resourceImage: {
    position: 'absolute',
    zIndex: '0',
    right: '266px',
    top: '4px',
  },
  resourceHeader: {
    display: 'flex',
    flexDirection: 'column',
    width: '94%',
    alignItems: 'left',
    justifyContent: 'space-between',
    margin: '3% 3% 0 3%',
    position: 'relative',
    [theme && theme.breakpoints.down('sm')]: {
      paddingTop: '30px',
    },
  },
  headerButton: {
    ...headerButtonStyle,
    color: '#ffffff',
    backgroundColor: `${orange}`,
    right: '0',
    cursor: 'pointer',
    ...hoverEffectOne,
  },
  buttonIcon: {
    verticalAlign: 'sub',
    width: '16px',
    marginRight: '5px',
  },
  dashboard: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    color: `${navy}`,
    marginRight: '5px',
    marginLeft: '5px',
    fontSize: 18,
  },
  archivedIcon: {
    color: `${gray}`,
    marginRight: '5px',
    marginLeft: '5px',
    fontSize: 18,
  },
  resourceName: {
    fontSize: '30px',
    fontWeight: 'bold',
    color: 'white',
    textTransform: 'uppercase',
    fontFamily: 'circe-rounded',
    marginBottom: '5px',
  },
  resourceLogo: {
    verticalAlign: 'sub',
    marginRight: '10px',
  },
  paperContainer: {
    padding: '10px',
  },
  emptyPaper: {
    margin: '1% 2% 1% 2%',
    width: '94%',
    maxHeight: '95%',
    overflowX: 'hidden',
    overflowY: 'hidden',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  paper: {
    margin: '1% 2% 1% 2%',
    width: '94%',
    //maxHeight: '95%',
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  searchComponentContainer: {
    width: '94%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    zIndex: '2',
  },
  searchComponentPaper: {
    width: '100%',
    maxHeight: '95%',
    minHeight: '87px',
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  clearFilterDiv: {
    display: 'inline-block',
    width: '95%',
  },
  clearFilterText: {
    color: `${navy}`,
    textDecoration: 'underline',
    float: 'right',
    fontStyle: 'italic',
    textTransform: 'lowercase',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'unset',
    },
  },
  positions: {
    display: 'flex',
    flexDireaction: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  divider: {
    borderRight: '1px solid grey',
    width: '20px',
    height: '15px',
    marginRight: '10px',
  },
  numbers: {
    color: `${tiffany}`,
    fontWeight: 'bold',
    fontSize: '14px',
  },
  fabButton: {
    position: 'fixed',
    width: 63,
    height: 63,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    fontFamily: 'Asap',
    fontSize: '9px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    cursor: 'pointer',
    justifyContent: 'center',
    alignItems: 'center',
    right: 20,
    bottom: 100,
    backgroundColor: `${orange}`,
    color: '#fff',
    borderRadius: '50%',
  },
  customRenderText: {
    fontSize: '14px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  positionCustomRenderText: {
    fontSize: '14px',
    color: `${tiffany}`,
    fontWeight: 'bold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  iconButton: {
    float: 'right',
    backgroundColor: 'transparent',
    height: '20px',
    padding: '0',
    marginRight: '15px',
  },
});
