import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Paper, Typography, Button, IconButton, Popover, Icon,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';

import {
  practicesPaginationCount, resourceFields, BADGE_INFO_TEXT, IMG_STORAGE_LINK,
} from '../../data/Constants';
import types from '../../actions/ActionTypes';
import { badgeColor, utils } from '../../utils/Functions';
import LoadingBar from '../subcomponents/LoadingBar';
import DisplayResources from '../crud/DisplayResources';
import SearchComponent from '../subcomponents/SearchComponent';
import CreateBadgeDialog from '../subcomponents/CreateBadgeDialog';
import EditBadgeDialog from '../subcomponents/EditBadgeDialog';
import InviteBadgeDialog from '../subcomponents/InviteBadgeDialog';
import DeleteUserBadgeDialog from '../subcomponents/DeleteUserBadgeDialog';
import { badgesPageStyles } from './styles/BadgesStyles';

const styles = badgesPageStyles();

class Badges extends Component {
  constructor(props) {
    super(props);
    let params = {};
    if (location.search) {
      params = utils.queryParamsToObject(location.search);
    }

    this.state = {
      badge: null,
      openCreateDialog: false,
      openEditDialog: false,
      openInviteDialog: false,
      openUserDeleteDialog: false,
      fetchedUserBadges: [],
      search: params.name || '',
      filter: params.badgeIds || '',
      helpIconAnchor: null,
      staff: [],
      editUser: {
        badges: [],
      },
    };
  }

  componentDidMount = () => {
    const {
      actions, apiToken, pagination, userbadges, badges,
    } = this.props;
    const { filter, search } = this.state;
    const start = pagination?.start;

    let queryParams = `userbadges?start=${start || 0}&count=${practicesPaginationCount}&badgeIds=${filter}&name=${search || ''}&orderBy=id`;

    this.setState({ filter: Number(filter) || 'all-badges', search: search || '' });

    Promise.all([
      actions.getAllResources(apiToken, queryParams),
      isEmpty(badges) && actions.getAllResources(apiToken, 'badges', 'all'),
      actions.getAllResources(apiToken, 'privatetalents', 'all'),
    ]).then(([userbadgesResponse, allBadgesResponse, staffRes]) => {
      if (!userbadgesResponse.error) {
        const respData = userbadgesResponse.response || {};
        this.setState({
          searchValue: respData.search || '',
          selectValue: respData.filter || 'all',
          fetchedUserBadges: respData.list || [],
        });
        if (staffRes.response) {
          const staffList = staffRes.response.map(it => ({
            id: it.id,
            name: `${it.name} (${it.email})`,
            email: it.email,
          }));
          this.setState({ staff: staffList });
        }
      }
    });

    const badgeFilter = document.getElementById('badgeFilter');
    badgeFilter && badgeFilter.addEventListener('keydown', e => this.handleKeyDown(e));
  };

  componentWillUnmount() {
    const badgeFilter = document.getElementById('badgeFilter');
    badgeFilter && badgeFilter.removeEventListener('keydown', e => this.handleKeyDown(e));
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.handleSearch();
    }
  };

  getBadgesList(badges, isFilter) {
    const { theme } = this.props;
    const stylesWithTheme = badgesPageStyles(theme);

    return badges.map(badge => (
      <Button
        onClick={() => isFilter && this.handleSearch(badge.id)}
        sx={stylesWithTheme.badgeFilter}
        key={badge.id}
        style={{
          backgroundColor: badgeColor(badge.id || badge.title),
          margin: '-5px 4px',
        }}
      >
        {badge.title}
      </Button>
    ));
  }

  getBadges = data => this.getBadgesList(data.badges);

  getActions = data => (
    <div>
      <IconButton
        style={styles.iconButton}
        onClick={event => this.setState({
          editUser: data,
        }, () => this.setState({
          openUserDeleteDialog: true,
        }))}
      >
        <Icon style={styles.icon}>delete</Icon>
      </IconButton>
    </div>
  );

  setQueryParams(queryParams) {
    const query = queryParams.replace('userbadges', '');
    history.pushState(null, '', query);
  }

  clearFilters = () => {
    this.setState({
      filter: 'all-badges',
      search: '',
    }, () => this.handleSearch(''));
  };

  handleSearch = (e) => {
    const { actions, apiToken } = this.props;
    const { search, filter } = this.state;
    const badgeIdParam = (!filter || filter === 'all-badges') ? '' : filter;
    let queryParams = `userbadges?start=0&count=${practicesPaginationCount}&badgeIds=${badgeIdParam}&name=${search || ''}&orderBy=id`;
    this.setQueryParams(queryParams);
    actions.getAllResources(apiToken, queryParams).then((resp) => {
      if (!resp.errors) {
        this.setState({ fetchedUserBadges: resp.response.list });
      }
    });
    this.setState({ filter: filter || '' });
  };

  onClickViewMore = async (pageNo) => {
    const {
      actions,
      apiToken,
      pagination,
      loading,
      badges,
    } = this.props;
    const { filter } = this.state;
    if (!loading) {
      const searchParam = pagination.search ? `&search=${pagination.search}` : '';
      const filterParam = pagination.filter ? `&filter=${pagination.filter}` : '';
      const badgeIdParam = `&badgeIds=${(!filter || filter === 'all-badges') ? '' : filter}`;
      let queryParams;
      if (!pageNo) {
        queryParams = `userbadges?start=${pagination.start + pagination.count}&count=${practicesPaginationCount}${searchParam}${filterParam}&orderBy=id`;
      } else {
        queryParams = `userbadges?start=${(pageNo - 1) * pagination.count}&count=${practicesPaginationCount}${searchParam}${filterParam}${badgeIdParam}&orderBy=id`;
      }
      actions.updateLoadingState(types.VIEW_MORE_RESOURCE_REQUESTED);
      actions.getAllResources(apiToken, queryParams).then((resp) => {
        if (!resp.errors) {
          this.setState({
            fetchedUserBadges: resp.response.list,
          });
        }
      });
      actions.updateLoadingState(types.VIEW_MORE_RESOURCE_SUCCESS);
    }
  };

  handleClickArchive = (id) => {
    const {
      actions, practices, apiToken, pagination,
    } = this.props;
    const { orderBy } = this.state;
    let practice = practices.find(practice => practice.id === id);

    if (!practice.deletedAt) {
      this.setState({
        open: true,
        archiveId: id,
        archiveName: practice.name,
      });
    } else {
      const searchParam = pagination.search ? `&search=${pagination.search}` : '';
      const filterParam = pagination.filter ? `&filter=${pagination.filter}` : '';
      let queryParams = `practices?start=0&count=${practicesPaginationCount}&orderby=${orderBy}${searchParam}${filterParam}`;

      practice.deletedAt = null;
      actions.updateResource(apiToken, practice, 'practices', id)
        .then((response) => {
          if (!response.error) {
            return actions.getAllResources(apiToken, queryParams);
          }
        })
        .then((response) => {
          this.setState({
            searchValue: response.response.search || '',
            selectValue: response.response.filter || 'all',
          });
        });
    }
  };

  checkEnterPressed = (e) => {
    if (e.keyCode === 13) {
      this.handleSearch();
    }
  };

  handleClickDialog = (flag) => {
    this.setState({
      openCreateDialog: false,
      openEditDialog: false,
      openInviteDialog: false,
      openUserDeleteDialog: false,
    });
    let moving = flag || 0;
    const { pagination } = this.props;
    let currentPage = pagination.start / pagination.count;
    if (pagination.totalSize === pagination.start - moving && currentPage + moving > -1) {
      currentPage = currentPage + moving;
    }
    this.onClickViewMore(currentPage + 1);
  };

  handleChange = (e, key) => {
    this.setState(key === 'searchValue' ? { search: e.target.value } : { filter: e.target.value });
  };

  render() {
    const {
      loading,
      badges,
      userbadges,
      actions,
      apiToken,
      pagination,
      viewMoreLoading,
      history,
      classes,
      admin,
      theme,
    } = this.props;
    const {
      openCreateDialog,
      openEditDialog,
      openInviteDialog,
      openUserDeleteDialog,
      editUser,
      search,
      fetchedUserBadges,
      filter,
      helpIconAnchor,
      staff,
    } = this.state;
    let mutableItems = fetchedUserBadges;
    const customRender = {
      badges: this.getBadges,
      actions: this.getActions,
    };
    const badgesList = this.getBadgesList(badges);
    badgesList.unshift(<div key="all-badges">All Badges</div>);

    const isHQAdmin = admin && admin.adminRole === 'HQ_ADMIN';
    const stylesWithTheme = badgesPageStyles(theme);


    return (
      (loading === true && viewMoreLoading === 0)
        ? <LoadingBar />
        : (
          <div style={styles.container} onKeyDown={this.handleKeyDown}>
            <div className={classes.resourceHeader}>
              <div style={styles.resourceName}>
                <Typography style={stylesWithTheme.resourceName}>
                  <img style={styles.resourceLogo} src={`${IMG_STORAGE_LINK}badge.png`} />
                  BADGES
                  <Icon
                    className={classes.helpOutlineIcon}
                    onClick={event => this.setState({ helpIconAnchor: event.target })}
                  >
                    help_outline
                  </Icon>
                  <Popover
                    open={!!helpIconAnchor}
                    anchorEl={helpIconAnchor}
                    onClose={() => this.setState({ helpIconAnchor: null })}
                  >
                    <Paper className={classes.helpPopover}>
                      <Typography sx={stylesWithTheme.helpPopoverText}>{BADGE_INFO_TEXT}</Typography>
                    </Paper>
                  </Popover>
                </Typography>
                <div className={classes.headerButtonGroup}>
                  <Button
                    variant="contained"
                    disableRipple
                    onClick={e => this.setState({ openCreateDialog: true })}
                    sx={stylesWithTheme.headerButton}
                  >
                    create badge
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    disableRipple
                    onClick={e => this.setState({ openEditDialog: true })}
                    sx={stylesWithTheme.headerSecondButton}
                  >
                    edit badges
                  </Button>
                  { isHQAdmin
                    && (
                      <Button
                        variant="contained"
                        disableRipple
                        disabled={isEmpty(staff)}
                        onClick={e => this.setState({ openInviteDialog: true })}
                        sx={stylesWithTheme.headerButton}
                      >
                        <Icon className={classes.inviteUserBtnIcon}>add_circle_outline</Icon>
                        INVITE USER
                      </Button>
                    )}
                </div>
              </div>

            </div>
            <div className={classes.searchComponentContainer} style={window.innerWidth < 1100 ? { margin: '3% 3% 0 3%' } : { margin: '1% 3% 0 3%' }}>
              <Paper style={styles.searchComponentPaper} id='badgeFilter'>
                <SearchComponent
                  selectValues={badgesList}
                  selected={filter}
                  search={search}
                  searchFieldText="Search by Name"
                  applyButtonText="APPLY FILTER"
                  handleChange={this.handleChange}
                  onHandleSearch={this.handleSearch}
                  filter
                  customized
                  customWidth={{ textSearch: '43%', singleSelect: '43%' }}
                />
              </Paper>
              {
                badges && badges.length > 0
                && (
                  <Button
                    sx={stylesWithTheme.clearFilterText}
                    onClick={() => this.clearFilters()}
                  >
                  clear search
                </Button>
                )
              }
            </div>
            <Paper style={(mutableItems && mutableItems.length === 0) ? styles.emptyPaper : styles.paper}>
              <DisplayResources
                data={mutableItems}
                actions={actions}
                fieldNames={resourceFields.badges}
                customRender={customRender}
                resourceName="badges"
                apiToken={apiToken}
                hideCreate
                admin={admin}
                onClickViewMore={this.onClickViewMore}
                totalResource={pagination && pagination.totalSize}
                customAction
                viewMoreLoading={viewMoreLoading}
                handleClickArchive={id => this.handleClickArchive(id)}
                handleClickNew={() => history.push('/newpractice')}
                pagination={pagination}
                viewOnly
              />
              <div style={{
                display: 'flex',
                padding: '10px 0px',
              }}
              />
            </Paper>

            <CreateBadgeDialog
              open={openCreateDialog}
              apiToken={apiToken}
              handleOnClick={() => this.handleClickDialog()}
              actions={actions}
            />
            {openEditDialog && (
              <EditBadgeDialog
                open={openEditDialog}
                apiToken={apiToken}
                handleOnClick={() => this.handleClickDialog()}
                actions={actions}
                badges={badges}
              />
            )}
            <InviteBadgeDialog
              open={openInviteDialog}
              apiToken={apiToken}
              handleOnClick={() => this.handleClickDialog()}
              actions={actions}
              badges={badges}
              staff={staff}
            />
            <DeleteUserBadgeDialog
              open={openUserDeleteDialog}
              apiToken={apiToken}
              handleOnClick={() => this.handleClickDialog(-1)}
              actions={actions}
              badges={badges}
              user={editUser}
            />
          </div>
        )
    );
  }
}

Badges.propTypes = {
  loading: PropTypes.bool.isRequired,
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  apiToken: PropTypes.string.isRequired,
  badges: PropTypes.array,
  userbadges: PropTypes.array,
};

Badges.defaultProps = {
  badges: [],
  userbadges: [],
};

export default withStyles(badgesPageStyles, { withTheme: true })(withRouter(Badges));
