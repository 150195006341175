import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import {
  Card,
  CardContent,
  Typography,
  Tooltip,
  Icon, Menu, MenuItem,
  Grid, Button,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import DomainIcon from '@mui/icons-material/Domain';
import PlaceIcon from '@mui/icons-material/Place';
import { darkOrange, tiffany } from '../../css/style';
import { commonCardStyles } from '../../css/cardStyles';
import {
  EXTERNSHIP,
  HQ_ADMIN_KEY,
  VET_TYPES_TITLES,
  IMG_STORAGE_LINK,
} from '../../data/Constants';
import { badgeColor, formatPostTitle } from '../../utils/Functions';
import { isEmpty } from 'lodash';

const styles = commonCardStyles();

class JobPostingCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  edit = () => {
    const { history, item } = this.props;
    history.push(`/editposition/${item.id}`);
  };

  connections = async () => {
    const {
      history,
      item,
      resources,
      actions,
      apiToken,
    } = this.props;
    const { connections } = resources;

    if (item.lifecycle_badge_id) {
      const childConnection = connections?.filter(c => c?.jobPosting?.parent_job_posting_id === item.id);
      let childPostId = childConnection?.length && childConnection[0].job_posting_id;
      if (!childPostId) {
        const childResp = await actions.getOneResource(apiToken, `jobpostings/${item.id}/child`);
        childPostId = childResp?.response?.id;
      }
      history.push(`/applications?jobpostings=${item.id},${childPostId}`);
    } else {
      history.push(`/applications?jobpostings=${item.id}`);
    }
  };

  duplicate = () => {
    const { history, item } = this.props;

    history.push(`/newposition/${item.id}`);
  };

  boost = () => {
    const { history, item } = this.props;
    history.push(`/editposition/${item.id}?boost=true`);
  };

  archive = () => {
    const {
      history, item, apiToken, actions,
    } = this.props;
    const itm = { deletedAt: item.deletedAt ? null : new Date() };
    actions.updateResource(apiToken, itm, 'jobpostings', item.id);
    if (item?.lifecycle_badge_id && !item?.parent_job_posting_id) {
      actions.getOneResource(apiToken, `jobpostings/${item.id}/child`).then((childResp) => {
        if (childResp?.response?.id) {
          const childItm = { deletedAt: childResp?.response?.deletedAt ? null : new Date() };
          actions.updateResource(apiToken, childItm, 'jobpostings', childResp?.response?.id);
        }
      });
    }
    this.setState({ menuEl: null });
  };

  delete = () => {
    const { actions, apiToken, item } = this.props;
    actions.deleteResource(apiToken, 'jobpostings', item.id, 'archived');

    if (item?.lifecycle_badge_id && !item?.parent_job_posting_id) {
      actions.getOneResource(apiToken, `jobpostings/${item.id}/child`).then((childResp) => {
        if (childResp?.response?.id) {
          actions.deleteResource(apiToken, 'jobpostings', childResp?.response?.id, 'archived');
        }
      });
    }
    this.setState({ menuEl: null });
  };


  toTitleCase(str) {
    str = str.replace(new RegExp('-', 'g'), ' ');
    return str.replace(
      /\w\S*/g,
      txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
    );
  }

  getBadge(title, id) {
    const { classes } = this.props;
    return (
      <Button
        key={title}
        style={{
          ...styles.badgeFilter,
          backgroundColor: title === 'Thrive' ? badgeColor(title) : badgeColor(id || title),
        }}
        ref={title}
      >
        {title}
      </Button>
    );
  }

  getBadges() {
    const { item, badges } = this.props;
    // Filter badges that are no longer shared
    const filteredBadges = Object.keys(item.badges).filter(badgeId => badges[badgeId]);
    return (
      <div style={{
        height: 30,
        overflow: 'hidden',
        paddingRight: '50px',
        position: 'relative',
      }}
      >
        {item.isPublic && this.getBadge('public')}
        {item.isPrivate && item.practice && item.practice.aggregator_id !== 6 && this.getBadge('private-staff')}
        {item.customPrivateUsers && item.customPrivateUsers.length > 0 && this.getBadge('Superhero')}
        {filteredBadges.map(id => this.getBadge(badges[id], id))}
        {this.moreBadges()}
      </div>
    );
  }

  moreBadges() {
    const { item, badges } = this.props;
    let lineTops = [];
    const publicRef = this.refs.public;
    const privateRef = this.refs['private-staff'];
    let firstLineTop = publicRef ? publicRef.offsetTop : null;
    if (!firstLineTop) {
      firstLineTop = privateRef ? privateRef.offsetTop : null;
    }
    if (firstLineTop && privateRef && firstLineTop !== privateRef.offsetTop) {
      lineTops.push(privateRef.offsetTop);
    }
    Object.keys(item.badges).forEach((id) => {
      let ref = this.refs[badges[id]];
      if (ref) {
        if (!firstLineTop) {
          firstLineTop = ref.offsetTop;
        }
        if (ref.offsetTop != firstLineTop) {
          lineTops.push(ref.offsetTop);
        }
      }
    });
    if (lineTops && lineTops.length > 0) {
      return (
        <span style={{
          position: 'absolute',
          top: 10,
          right: 0,
          fontSize: '14px',
          fontFamily: 'Asap',
        }}
        >
          +
          {lineTops.length}
          {' '}
          more
        </span>
      );
    } else {
      return null;
    }
  }

  render() {
    const { menuEl } = this.state;
    const {
      classes,
      item,
      isEnterprise,
      admin,
    } = this.props;
    const isArchived = !!item.deletedAt;
    const isHQAdmin = admin && admin.adminRole === HQ_ADMIN_KEY;
    const showFullMenu = isHQAdmin || (!isHQAdmin && item.typeOfEmployment !== EXTERNSHIP);
    const archivedStyle = { color: isArchived && '#8c8c8c' };
    const rotationTypes = item.rotationTypes && JSON.parse(item.rotationTypes);
    let image = null;

    if (item.talentType) {
      image = `${IMG_STORAGE_LINK}talent-types/${item.talentType}.png`;
    }

    return (
      <div style={{ position: 'relative' }}>
        {
          item.isBoostedByDates
          && (
            <div className={classes.boostedBtnContainer}>
              <div className={classes.boostedBtnWhiteBorder} />
              <Button style={styles.boostedBtn}>
                BOOSTED
              </Button>
              <div className={classes.boostedBtnWhiteBorder} />
            </div>
          )
        }
        <Card className={classes.card}>
          <CardContent style={{ mixBlendMode: isArchived ? 'luminosity' : '', border: item.isBoostedByDates ? '4px solid red' : '4px solid transparent' }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={10}>
                <Tooltip title={formatPostTitle(item.title)}>
                  <Typography color='secondary' style={{ ...styles.jobTitle, ...archivedStyle }}>
                    {formatPostTitle(item.title)}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item xs={2} classes={{ item: styles.talentTypeIconContainer }}>
                {image && (
                  <Tooltip title={item.talentType ? VET_TYPES_TITLES[item.talentType] : ''}>
                    <img
                      className={classes.talentTypeIcon}
                      src={image}
                      alt="Selectable Icon"
                    />
                  </Tooltip>
                )}
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between" alignItems="center" className={classes.statistics}>
              <Grid item className={classes.statisticsItem}>
                <Icon>touch_app</Icon>
                {`${item.clickCount || 0} Clicks`}
              </Grid>
              <Grid item className={classes.divider} />
              <Grid item className={classes.statisticsItem}>
                <Icon>track_changes</Icon>
                {`${item.matchesCount || 0} Matches`}
              </Grid>
              <Grid item className={classes.divider} />
              <Grid
                item
                className={classes.clickableStatisticsItem}
                onClick={this.connections}
                style={{ color: item.connectionsCount > 0 ? darkOrange : tiffany }}
              >
                <Icon>insert_emoticon</Icon>
                {`${item.connectionsCount || 0} Applications`}
              </Grid>
            </Grid>
            <Grid container className={classes.matchItemInfo}>
              <Grid item xs={12} className={classes.matchIteminfoItem}>
                <AccessTimeIcon className={classes.matchIteminfoIcon} />
                <Typography component='span' style={archivedStyle}>{item.typeOfEmployment}</Typography>
              </Grid>
              {item.typeOfEmployment === EXTERNSHIP && rotationTypes && rotationTypes.length > 0 && (
                <Grid item xs={12} className={classes.matchIteminfoItem}>
                  <BookmarksIcon className={classes.matchIteminfoIcon} />
                  <Tooltip title={`Externship Rotation: ${rotationTypes?.join(', ')}`}>
                    <Typography component='span' style={styles.overflowTextEllipsis}>
                      <span>{rotationTypes?.join(', ')}</span>
                    </Typography>
                  </Tooltip>
                </Grid>
              )}
              <Grid item xs={12} className={classes.matchIteminfoItem}>
                <DomainIcon className={classes.matchIteminfoIcon} />
                <Typography
                  style={{ ...styles.overflowTextEllipsis, ...archivedStyle }}
                >
                  {!item.practice ? 'N/A' : item.practice.name}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.matchIteminfoItem}>
                <PlaceIcon className={classes.matchIteminfoIcon} />
                <Typography style={archivedStyle}>{!item.practice ? 'N/A' : item.practice.location}</Typography>
              </Grid>
            </Grid>
            {this.getBadges()}
          </CardContent>
        </Card>
        <div className={classes.newIconContainer} onClick={e => this.setState({ menuEl: e.currentTarget })}>
          <Icon className={classes.newIcon}>more_vert</Icon>
        </div>
        <Menu
          anchorEl={menuEl}
          keepMounted
          open={Boolean(menuEl)}
          onClose={e => this.setState({ menuEl: null })}
        >
          <MenuItem onClick={this.edit}>
            <Icon>create</Icon>
            View / Edit
          </MenuItem>
          {showFullMenu && (
            <MenuItem onClick={this.connections}>
              <Icon>insert_emoticon</Icon>
              Applications
            </MenuItem>
          )}
          {showFullMenu && (
            <MenuItem onClick={this.duplicate}>
              <Icon>content_copy</Icon>
              Duplicate
            </MenuItem>
          )}
          {/* {
            showFullMenu && isEnterprise && !item.isBoostedByDates
            && (
              <MenuItem onClick={this.boost}>
                <Icon>attach_money</Icon>
                Boost
              </MenuItem>
            )
          } */}
          {showFullMenu && !isArchived && (
            <MenuItem onClick={this.archive}>
              <Icon>move_to_inbox</Icon>
              Archive
            </MenuItem>
          )}
          {showFullMenu && isArchived && (
            <MenuItem onClick={this.archive}>
              <Icon>play_circle_outline</Icon>
              Activate
            </MenuItem>
          )}
          {showFullMenu && isArchived && (
            <MenuItem onClick={this.delete}>
              <Icon>delete</Icon>
              Delete
            </MenuItem>
          )}
        </Menu>
        {showFullMenu && isArchived
        && (
          <div className={classes.archived}>
            <Icon>move_to_inbox</Icon>
            Archived
          </div>
        )}
      </div>
    );
  }
}

JobPostingCard.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any),
  actions: PropTypes.objectOf(PropTypes.any),
  confirmConnectToMatch: PropTypes.func,
};

JobPostingCard.defaultProps = {
  user: null,
  actions: null,
  confirmConnectToMatch: () => {
  },
};

export default withStyles(commonCardStyles)(withRouter(JobPostingCard));
