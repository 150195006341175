import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from '@mui/material';
import { withStyles } from '@mui/styles';

import { tiffany, navy } from '../../css/style';

const photoGalleryStyles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: '5px',
    flexWrap: 'wrap',
  },
  childContainer: {
    position: 'relative',
    marginRight: '15px',
    marginTop: '15px',
  },
  closeButtonContainer: {
    position: 'absolute',
    top: '-10px',
    right: '-10px',
    zIndex: '100',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeButton: {
    backgroundColor: `${tiffany}`,
    color: '#fff',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  logo: {
    maxWidth: '150px',
    maxHeight: '100px',
  },
  uploadButton: {
    height: '120px',
    width: '170px',
  },
  addButton: {
    color: `${navy}`,
    marginTop: '15px',
    height: '120px',
    width: '170px',
  },
  addIcon: {
    color: `${navy}`,
    marginRight: '5px',
  },
});

const styles = photoGalleryStyles();

class PhotoGallery extends Component {
  setRef = (ref, id) => {
    this[id] = ref;
  };

  click = (id) => {
    this[id].click();
  };

  render() {
    const {
      classes, practiceImages, handleFileChange, handleOnChange,
    } = this.props;

    return (
      <div className={classes.container}>
        {
          practiceImages.map((image, index) => (
            <div key={image.id || `key${index}`} className={classes.childContainer}>
              <div className={classes.closeButtonContainer}>
                <Icon
                  fontSize="small"
                  sx={styles.closeButton}
                  onClick={e => handleOnChange(e, image.id ? 'practiceImages' : 'newPracticeImages',
                    image.id || index)}
                >
                  close
                </Icon>
              </div>
              <div>
                <Button
                  sx={styles.uploadButton}
                  fullWidth
                  variant="outlined"
                  onClick={() => this.click(image.id || `key${index}`)}
                >
                  <img src={image.image} alt="logo" className={classes.logo} />
                  <input
                    id={image.id || `key${index}`}
                    type="file"
                    style={{ display: 'none' }}
                    accept="image/*"
                    ref={ref => this.setRef(ref, image.id || `key${index}`)}
                    onChange={e => handleFileChange(e, image.id ? 'practiceImages' : 'newPracticeImages',
                      image.id || index)
                    }
                  />
                </Button>
              </div>
            </div>
          ))
        }
        <Button
          sx={styles.addButton}
          fullWidth
          variant="outlined"
          onClick={() => this.upload.click()}
        >
          <Icon
            fontSize="small"
            sx={styles.addIcon}
          >
            add_circle_outline
          </Icon>
          ADD PHOTO
          <input
            id="fileInput"
            type="file"
            style={{ display: 'none' }}
            accept="image/*"
            ref={ref => this.upload = ref}
            onClick={(e) => {
              e.target.value = '';
            }}
            onChange={e => handleFileChange(e, 'newPracticeImages')}
          />
        </Button>
      </div>
    );
  }
}

PhotoGallery.propTypes = {
  practiceImages: PropTypes.array.isRequired,
  handleFileChange: PropTypes.func.isRequired,
  handleOnChange: PropTypes.func.isRequired,
};

export default withStyles(photoGalleryStyles)(PhotoGallery);
