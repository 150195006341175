import React from 'react';
import { withStyles } from '@mui/styles';
import { Icon } from '@mui/material';

const styles = {
  connectionHeaderContact: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 20,
    margin: '25px',
  },
  connectionHeaderContactItem: {
    fontFamily: 'ASAP',
    marginLeft: '10px',
    wordWrap: 'break-word',
    wordBreak: 'break-all',
    hyphens: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflowWrap: 'break-word',
  },
  emailhyperLink: {
    fontFamily: 'ASAP',
  },
};

const ContactInfo = (props) => {
  const {
    classes, phone, email, address, homePractice, color,
  } = props;

  const homePracticeStr = homePractice || '-';
  const addressStr = address || '-';

  return (
    <div style={{ color }}>
      <div className={classes.connectionHeaderContact}>
        <Icon>call</Icon>
        <div className={classes.connectionHeaderContactItem}>{phone || '-'}</div>
      </div>
      <div className={classes.connectionHeaderContact}>
        <Icon>mail</Icon>
        <div className={classes.connectionHeaderContactItem}>
          {email ? <a className={classes.emailhyperLink} style={{ color }} href={`mailto:${email}`}>{email}</a> : '-'}
        </div>
      </div>
      <div className={classes.connectionHeaderContact}>
        <Icon>{homePractice ? 'domain' : 'place'}</Icon>
        <div className={classes.connectionHeaderContactItem}>{homePractice ? homePracticeStr : addressStr}</div>
      </div>
    </div>
  );
};

export default withStyles(styles)(ContactInfo);
