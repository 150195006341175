import React, { useEffect, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopTimePicker } from '@mui/x-date-pickers';
import { withStyles } from '@mui/styles';
import { TextField } from '@mui/material';
import { tiffany } from '../../css/style';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';

const timePickerStyle = theme => ({
  container: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  labelContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: 'ASAP',
    color: 'red',
  },
  label: {
    marginBottom: 10,
    fontFamily: 'ASAP',
    color: 'rgba(0,0,0,0.5)',
  },
  containerRange: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 4,
  },
});

const popper = {
  sx: {
    '& .MuiList-root': {
      backgroundColor: '#f0f0f0',
    },
    '& .MuiMenuItem-root': {
      '&:hover': {
        backgroundColor: `${tiffany}`,
      },
      '&.Mui-selected': {
        backgroundColor: `${tiffany}`,
      },
    },
    '& .MuiDialogActions-root button': {
      backgroundColor: `${tiffany}`,
    },
  },
};

const styles = timePickerStyle();

const TimePicker = ({
  onChange,
  isRequired,
  defaultValue,
  label,
}) => {
  const [value, setValue] = useState(dayjs(defaultValue, 'h:mm A'));
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (!isNaN(dayjs(value)) && typeof dayjs(value) === 'object') {
      setMessage('');
    } else if (isRequired && isEmpty(value)) {
      setMessage('This field is required');
    }
  }, [value, isRequired]);

  const slotProps = {
    popper,
    textField: {
      sx: {
        width: '100%',
        flex: 1,
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: isRequired && !value ? 'red' : 'unset',
          },
          '&:hover fieldset': {
            borderColor: isRequired && !value ? 'red' : 'unset',
          },
          '&.Mui-focused fieldset': {
            border: '1px solid',
            borderColor: isRequired && !value ? 'red' : 'unset',
          },
        },
      },
    },
  };

  const onTimePickerChange = (newValue) => {
    setValue(newValue);
    onChange(isNaN(dayjs(newValue)) ? '' : dayjs(newValue).format('h:mm(A)'));
  };

  return (
    <div style={styles.container}>
      <div style={styles.labelContainer}>
        <label style={styles.label}>{label}</label>
        {message && (
          <span>{message}</span>
        )}
      </div>
      <div style={styles.containerRange}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopTimePicker
            sx={{ width: '100%', flex: 1 }}
            renderInput={params => <TextField {...params} />}
            ampm
            value={value}
            onChange={newValue => onTimePickerChange(newValue)}
            slotProps={slotProps}
            timeSteps={{ hours: 1, minutes: 1 }}
            onError={(error) => {
              setMessage('This field is required');
            }}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default withStyles(timePickerStyle)(TimePicker);
