import { hoverEffectOne, orange } from './style';
import { IMG_STORAGE_LINK } from '../data/Constants';

export const loginPageStyles = theme => ({
  signUpTextField: {
    width: '75%',
    color: '#000',
    marginTop: '20px',
    [theme && theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  signin: {
    '&:hover': {
      cursor: 'pointer',
    },
    display: 'flex',
    flexDirection: 'row',
    marginTop: '10px',
    fontWeight: 'bold',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
    [theme && theme.breakpoints.down('sm')]: {
      marginTop: '50px',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    [theme && theme.breakpoints.down('sm')]: {
      height: '100vh',
    },
  },
  leftContainer: {
    width: '40%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  leftContainerImage: {
    width: '40%',
    height: '100%',
    position: 'fixed',
    backgroundImage: `url(${IMG_STORAGE_LINK}Jobs_background.png)`,
    backgroundSize: 'cover',
  },
  rightContainer: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme && theme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'flex-start',
    },
  },
  textBoxContainer: {
    position: 'absolute',
  },
  welcomeTextBox: {
    fontFamily: 'circe-rounded',
    fontWeight: 'normal',
    color: '#243060',
    fontSize: '20px',
    marginTop: '50px',
  },
  underConstructionTitle: {
    fontFamily: 'circe-rounded',
    fontWeight: 'bold',
    display: 'inline-block',
    color: '#243060',
    fontSize: '30px',
    marginBottom: '50px',
  },
  underConsTextField: {
    width: '50%',
    color: '#000',
    marginTop: '20px',
    [theme && theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  sendEmailBtnContainer: {
    width: '50%',
    display: 'flex',
    flexDirection: 'row',
    marginTop: '4%',
    fontWeight: 'bold',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
    [theme && theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  sendEmailBtn: {
    backgroundColor: `${orange}`,
    width: '100%',
    height: '45px',
    fontFamily: 'Asap',
    fontSize: '14px',
    boxShadow: 'none',
    ...hoverEffectOne,
  },
  backgroundImg: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  logo: {
    marginBottom: '10%',
  },
  underConstructionLogo: {
    marginTop: '20px',
    marginBottom: '10%',
  },
  loginTitleContainer: {
    textAlign: 'left',
    marginLeft: 'auto',
    [theme && theme.breakpoints.down('sm')]: {
      marginLeft: '5%',
    },
  },
  loginTitle: {
    fontFamily: 'circe-rounded',
    fontWeight: 'normal',
    color: '#243060',
    fontSize: '20px',
  },
  inputSection: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  inputSectionLogin: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme && theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  inputRow: {
    width: '80%',
    display: 'flex',
    alignItems: 'center',
    [theme && theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  signup: {
    width: '60%',
    display: 'flex',
    flexDirection: 'row',
    marginTop: '4%',
    fontWeight: 'bold',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
    [theme && theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  signUpHere: {
    width: '170px',
  },
  sigupText: {
    fontStyle: 'italic',
    fontWeight: 'bold',
    marginRight: '10px',
  },
  sigupTextDesc: {
    fontWeight: 'bold',
    paddingLeft: '5px',
    textAlign: 'center',
  },
  passwordLabel: {
    color: '#ababab',
    marginTop: '40px',
    width: '75%',
    [theme && theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  textField: {
    width: '100%',
    color: '#000',
    marginTop: '5%',
    [theme && theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  loginErrorContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexBasis: '50%',
    marginTop: '2%',
    padding: '0 10px',
    [theme && theme.breakpoints.down('sm')]: {
      marginTop: 0,
      flexBasis: '100%',
      justifyContent: 'center',
    },
  },
  loginError: {
    color: `${orange}`,
    [theme && theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  loginBtn: {
    fontFamily: 'Asap',
    fontSize: '20px',
    fontWeight: 'bold',
    width: '50%',
    backgroundColor: `${orange}`,
    marginTop: '6%',
    [theme && theme.breakpoints.down('sm')]: {
      width: '90%',
    },
    ...hoverEffectOne,
  },
  signUpErrors: {
    color: 'red',
    fontFamily: 'Asap',
    marginTop: '20px',
  },
  rotate: {
    transform: 'rotate(180deg)',
  },
  signupBtn: {
    backgroundColor: `${orange}`,
    width: '50%',
  },
  nextBtn: {
    backgroundColor: `${orange}`,
    width: '40%',
    height: '45px',
    fontFamily: 'Asap',
    fontSize: '14px',
    boxShadow: 'none',
    [theme && theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: '-150px',
    },
    ...hoverEffectOne,
  },
  forgetPassword: {
    width: '50%',
    display: 'flex',
    fontStyle: 'italic',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    [theme && theme.breakpoints.down('sm')]: {
      marginTop: '20px',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  rememberAdminContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rememberAdminCheckBox: {
    padding: '0 5px 0 0',
  },
  rememberAdminText: {
    fontFamily: 'Asap',
  },
  forgotPasswordText: {
    textDecoration: 'underline',
    fontFamily: 'Asap',
  },
  lookingForJobText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '16px',
    marginTop: '50px',
    marginBottom: '10px',
    [theme && theme.breakpoints.down('sm')]: {
      marginTop: '30px',
      marginBottom: '0',
    },
  },
  lookingForJobLink: {
    color: `${orange}`,
  },
  signUpActionBtnsContainer: {
    display: 'flex',
    width: '75%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '70px',
    [theme && theme.breakpoints.down('sm')]: {
      width: '90%',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  signUpLeftArrow: {
    transform: 'rotate(180deg)',
    color: '#243060',
  },
  signUpRightArrow: {
    paddingLeft: '5px',
  },
  signUpBtn: {
    ...hoverEffectOne,
  },
});
