import isString from 'lodash/isString';
import Immutable from 'seamless-immutable';

import types from '../actions/ActionTypes';

const sanitizeMsg = (actionMsg) => {
  // Sanitize action message to make sure a string is added to this reducer's state
  if (actionMsg) {
    const message = isString(actionMsg) ? actionMsg : isString(actionMsg.message) ? actionMsg.message : '';
    return message.replace(/validation error:/gi, '');
  }
  return '';
};

export default function MessageReducer(state = Immutable({ errors: '', success: '', serverDown: false }), action) {
  let serverIsDown = false;
  if (action.error && sanitizeMsg(action.error) === 'Server is not responding') {
    serverIsDown = true;
  }
  switch (action.type) {
    case types.ERROR:
    case types.LOGIN_USER_FAILURE:
    case types.RESET_PASSWORD_FAILURE:
    case types.VERIFY_USER_FAILURE:
    case types.UPDATE_RESOURCE_NO_LOADING_FAILURE:
    case types.UPDATE_RESOURCE_FAILURE:
    case types.VERIFY_USER_FAILURE:
    case types.VERIFY_ACCOUNT_EMAIL_FAILURE:
      return Immutable.merge(state, { errors: sanitizeMsg(action.error), success: '', serverDown: serverIsDown });
    case types.VERIFY_USER_SUCCESS:
    case types.RESET_PASSWORD_SUCCESS:
    case types.UPDATE_RESOURCE_SUCCESS:
    case types.UPDATE_USER_SUCCESS:
    case types.VERIFY_USER_SUCCESS:
    case types.VERIFY_ACCOUNT_EMAIL_SUCCESS:
      return Immutable.merge(state, { errors: '', success: sanitizeMsg(action.successMsg), serverDown: false });
    case types.CLEAR_MESSAGE:
      return Immutable.merge(state, { errors: '', success: '' });
    default:
      return Immutable.merge(state, { errors: sanitizeMsg(action.error) });
  }
}
