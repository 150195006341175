import { companyHeader } from '../data/Constants';

const base_url = window.api_host;

export default class ResourcesAPI {
  static loginAdmin = (admin) => {
    const path = `${base_url}users/login`;
    const request = (
      new Request(path, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(admin),
      })
    );
    return handleResponse(request);
  };

  static signUpUser = (userData) => {
    const path = `${base_url}users/signup`;
    const request = (
      new Request(path, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userData),
      })
    );
    return handleResponse(request);
  };

  static sendSignUpRequest = (adminInfo) => {
    const path = `${base_url}formsubmission/contactus`;
    const request = (
      new Request(path, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(adminInfo),
      })
    );
    return handleResponse(request);
  };

  static addResource = (apiToken, resource, resourcePath, isPlain) => {
    const path = base_url + generatePath(resourcePath);
    const request = (
      new Request(path, {
        method: 'POST',
        headers: generateHeader(apiToken),
        body: JSON.stringify(resource),
      })
    );
    return handleResponse(request, isPlain);
  };

  static duplicateResource = (apiToken, resourcePath, isPlain) => {
    const path = base_url + generatePath(resourcePath);
    const request = (
      new Request(path, {
        method: 'put',
        headers: generateHeader(apiToken),
      })
    );
    return handleResponse(request, isPlain);
  };

  static deleteResource = (apiToken, resourcePath) => {
    const path = base_url + generatePath(resourcePath);
    const request = (
      new Request(path, {
        method: 'DELETE',
        headers: generateHeader(apiToken),
      })
    );
    return handleResponse(request, true);
  };

  static deleteFastpass = (apiToken, resourcePath) => {
    const path = base_url + generatePath(resourcePath);
    const request = (
      new Request(path, {
        method: 'DELETE',
        headers: generateHeader(apiToken),
      })
    );
    return handleResponse(request);
  };

  static updateResource = (apiToken, resource, resourcePath) => {
    const path = base_url + generatePath(resourcePath);
    const request = (
      new Request(path, {
        method: 'PUT',
        headers: generateHeader(apiToken),
        body: JSON.stringify(resource),
      })
    );
    return handleResponse(request);
  };

  static getAllResources = (apiToken, resourcePath) => {
    const path = base_url + generatePath(resourcePath);
    const request = (
      new Request(path, {
        method: 'GET',
        headers: generateHeader(apiToken),
      })
    );
    return handleResponse(request);
  };

  static getResource = (apiToken, resourcePath) => {
    const path = base_url + generatePath(resourcePath);
    const request = (
      new Request(path, {
        method: 'GET',
        headers: generateHeader(apiToken),
      })
    );
    return handleResponse(request);
  };

  static downloadResource = (apiToken, resourcePath) => {
    const path = base_url + generatePath(resourcePath);
    const request = (
      new Request(path, {
        method: 'GET',
        headers: generateHeader(apiToken),
      })
    );
    return handleDownloadResponse(request);
  };

  static uploadResource = (apiToken, resource, resourcePath) => {
    const path = base_url + generatePath(resourcePath);
    const request = (
      new Request(path, {
        method: 'POST',
        headers: generateHeader(apiToken),
        body: JSON.stringify(resource),
      })
    );
    return handleResponse(request);
  };
}

export const handleResponse = (request, isPlain) => fetch(request)
  .then((response) => {
    if (response.status === 401) {
      throw new Error('Token expired.');
    }
    return (!isPlain ? response.json() : response.text()).then(data => ({
      data,
      response,
    }));
  })
  .then(({ data, response }) => {
    if (!response.ok) {
      return Promise.reject(!isPlain ? data.message : data);
    }

    const body = {
      data,
    };
    return Promise.resolve(body);
  }).catch((error) => {
    console.error(error);
    if (error == 'TypeError: Failed to fetch') {
      error = 'Server is not responding';
    } else if (error.message === 'Token expired.') {
      error = error.message;
    }
    return Promise.reject(error);
  });

export const handleDownloadResponse = (request, isPlain) => fetch(request).then(response => (response.blob()).then(data => ({
  data,
  response,
})))
  .then(({ data, response }) => {
    if (!response.ok) {
      return Promise.reject(!isPlain ? data.message : data);
    }
    const headerContentDisposition = response.headers.get('Content-Disposition');
    const fileName = headerContentDisposition ? headerContentDisposition.split('filename="')[1].replace('"', '') : 'file';
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    link.click();
    return Promise.resolve({});
  }).catch((error) => {
    console.log(error);
    if (error == 'TypeError: Failed to fetch') {
      error = 'Server is not responding';
    }
    return Promise.reject(error);
  });


export function generatePath(resourcePath) {
  return resourcePath.join('/');
}

export function generateHeader(token) {
  const apiHeader = {};
  apiHeader.Authorization = `${token}`;
  apiHeader['Content-Type'] = 'application/json';
  return apiHeader;
}
