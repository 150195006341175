import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { withRouter } from 'react-router';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    textAlign: 'center',
    marginBottom: '8px',
    [theme && theme.breakpoints && theme.breakpoints.up('md')]: {
      '& > *': {
        width: 36,
        margin: '0 8px !important',
      },
      padding: '0px 25px 0px 25px',
    },
    [theme && theme.breakpoints && theme.breakpoints.down('md')]: {
      padding: '0px 5px 0px 5px',
    },
    fontFamily: 'Asap',
    fontSize: '11px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#686868',

  },
}));

const Week = props => <Typography {...props} />;

const WeekHeader = () => {
  const classes = useStyles();
  moment.updateLocale('en', {
    week: {
      dow: 1,
    },
  });
  const weekdayNames = moment.weekdaysShort(true);

  return (
    <div className={classes.root}>
      {
        weekdayNames.map(name => (
            <Week key={name}>{name}</Week>
        ))
      }
    </div>
  );
};

export default withRouter(WeekHeader);
