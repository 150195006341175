import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Button, Dialog, DialogActions, DialogContent, Grid, Paper, Typography,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { isEqual } from 'lodash';
import parse from 'html-react-parser';
import DisplayImages from '../subcomponents/DisplayImages';
import OrderDetailBox from '../subcomponents/OrderDetailBox';
import * as utilFunctions from '../../utils/Functions';
import { resourceFields, sevenDaysInAWeek } from '../../data/Constants';
import { commonResourceStyle } from '../../css/style';
import { resourceFormStyles } from '../../css/resourceFormStyles';


const styles = resourceFormStyles();
const commonStyles = commonResourceStyle();

class ViewResource extends Component {
  constructor(props) {
    super(props);
    this.state = this.createState();
  }

  componentDidMount = async () => {
    const {
      apiToken, match, actions, loading, resources,
    } = this.props;
    const {
      resource, subResource, subResourceId, resourceId,
    } = match.params;

    if (loading === false && !resources[resource]) {
      await actions.getAllResources(apiToken, resource);
      if (resource === 'orders') {
        await actions.getAllResources(apiToken, 'orders', resourceId, 'items');
      }
    }
    const resourceType = subResource || resource;
    const resourceToLoad = resourceFields[resourceType].filter(field => field.loadSeperately);
    if (resourceToLoad.length > 0) {
      const resourcePath = subResource ? [resource, resourceId, subResource, subResourceId] : [resource, resourceId];
      resourceToLoad.map((resource) => {
        actions.getAllResources(apiToken, ...resourcePath, resource.key);
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    const { resources } = this.props;
    if (!isEqual(resources, nextProps.resources)) {
      const state = this.createState(nextProps.resources);
      this.setState(state);
    }
  }

  createState = (newResources) => {
    const { resources, match } = this.props;
    const {
      resource, resourceId, subResource, subResourceId,
    } = match.params;
    const resourceType = subResource || resource;
    const fields = resourceFields[resourceType];
    const state = {};
    const itemToView = subResource ? utilFunctions.findSubItemByID(resources, match, newResources) : utilFunctions.findItemByID(resources, match, newResources);
    state.id = subResourceId || resourceId;
    state.openDialog = false;
    state.dialogContents = null;
    fields.forEach((field) => {
      state[field.key] = itemToView ? itemToView[field.key] : null;
    });
    return state;
  };

  onClickEdit = (data) => {
    const { actions, match, history } = this.props;
    actions.clearErrors();
    if (match.params.subResourceId) {
      history.push(`/${match.params.resource}/${match.params.resourceId}/${match.params.subResource}/${data.id}/edit`);
    } else {
      history.push(`/${match.params.resource}/${data.id}/edit`);
    }
  };

  onClickDelete = async (data) => {
    const {
      actions,
      apiToken,
      history,
      match,
    } = this.props;
    const {
      resource, subResource, subResourceId, resourceId,
    } = match.params;
    const primaryResource = (
      resource === 'categories'
      || resource === 'products'
      || resource === 'taxes'
      || resource === 'customizations'
    )
      ? 'menu'
      : resource;
    const nextRoute = (subResource) ? (`/${resource}/${resourceId}/${subResource}`) : (`/${primaryResource}`);

    if (subResource) {
      actions.deleteResource(apiToken, resource, resourceId, subResource, subResourceId);
    } else if (resource === 'categories' && data.timeLimits && data.timeLimits.length > 0) {
      await actions.deleteResource(apiToken, resource, data.id, 'time-limits', data.timeLimits[0].id);
      actions.deleteResource(apiToken, resource, data.id);
    } else {
      actions.deleteResource(apiToken, resource, resourceId);
    }
    history.push(nextRoute);
  };

  getTime = (hours) => {
    const timeArray = hours.split(':');
    let hour = timeArray[0];
    const amOrPm = (hour >= 12) ? ' PM' : ' AM';
    hour = (hour > 12) ? (hour - 12) : (hour === 0) ? 12 : hour;
    let minutes = timeArray[1];
    minutes = (minutes < 10) ? `:0${minutes}` : `:${minutes}`;

    return hour + minutes + amOrPm;
  };

  showDialog = (field, fieldData) => {
    this.setState({
      openDialog: true,
      dialogContents: parse(fieldData),
    });
  };

  handleCloseDialog = () => {
    this.setState({
      openDialog: false,
      dialogContents: null,
    });
  };

  // no longer in use?
  renderProducts = (field, fieldData) => {
    let displayProuducts = '';
    fieldData.map(dataItem => (
      displayProuducts += `${dataItem.name}, `
    ));
    return displayProuducts;
  };

  renderImages = (field, fieldData) => {
    let result = null;
    if (!fieldData || fieldData.length === 0) {
      result = (
        <Typography>There are no images</Typography>
      );
    } else {
      result = (
        <DisplayImages
          key={field.key}
          allowMultiple={field.allowMultiple}
          data={fieldData}
          defaultValue={fieldData}
          style={styles.fieldTypeStyle}
          type="view"
          onChange={(event) => {
            this.onChangeInput(event, field);
          }}
        />
      );
    }
    return result;
  };

  renderHours = (field, fieldData) => fieldData && (
    fieldData.map((day) => {
      if (!(day.open === null && day.close === null)) {
        const openTime = this.getTime(day.open);
        const closeTime = this.getTime(day.close);
        const hours = (day.open === day.close) ? 'Closed' : `${openTime} - ${closeTime}`;
        return (
            <div key={day.id}>
              <Typography variant="h6">{sevenDaysInAWeek[day.day]}</Typography>
              <Typography>{hours}</Typography>
            </div>
        );
      }
    })
  );

  renderList = (field, fieldData) => {
    let displayList = '';
    fieldData && fieldData.map(data => (
      displayList += `${data.name}, `
    ));
    return displayList;
  };

  renderAddress = fieldData => ((fieldData !== undefined)
    ? fieldData
    : null);

  renderTimeLimits = (fieldData) => {
    let displayTimeLimit = '';
    displayTimeLimit += utilFunctions.listArrayToString(fieldData[0].daysOfWeek);
    displayTimeLimit += ` From ${fieldData[0].start} to ${fieldData[0].end}`;
    return displayTimeLimit;
  };

  renderResource = (field, fieldData) => {
    const { resource } = this.props.match.params;
    let displayData = fieldData;
    if (fieldData === 'N/A') {
      displayData = fieldData;
    } else if (!fieldData || fieldData.length === 0) {
      displayData = `There are no ${field.key}`;
    } else if (field.isHtmlFormat) {
      displayData = <InfoIcon onClick={() => this.showDialog(field, fieldData)} />;
    } else if (field.viewOnlyName) {
      displayData = this.renderList(field, fieldData);
    } else if (field.type === 'boolean') {
      displayData = fieldData.toString();
    }

    return (
      !(field.type === 'image' || field.type === 'hours' || field.key === 'items')
      && (
      <Grid item key={field.key} xs={field.gridWidth ? parseInt(field.gridWidth) : 'auto'} style={styles.gridItem}>
        <Typography style={styles.itemTitle}>{`${field.title}:`}</Typography>
        <div
          style={{
            ...styles.fieldTypeStyle,
            fontSize: '15px',
            padding: '10px 10px 0px 10px',
            height: field.type === 'longText' ? '100px' : '35px',
            margin: '16px 0 8px 0',
            overflowY: 'auto',
          }}
        >
          {displayData}
        </div>
      </Grid>
      )
    );
  };

  renderIsolatedField = (field, fieldData) => {
    const { resource } = this.props.match.params;
    if (field.type === 'image') {
      return this.renderImages(field, fieldData);
    } else if (field.type === 'hours') {
      return this.renderHours(field, fieldData);
    } else if (field.key === 'items' && resource === 'orders') {
      return (
        <OrderDetailBox
          fieldData={fieldData}
          tax={this.state.tax}
          tips={this.state.tipAmount}
          totalPrice={this.state.totalPrice}
        />
      );
    }
  };

  render() {
    const { resources, match } = this.props;
    const { orders } = resources;
    const {
      resource, subResource, resourceId, subResourceId,
    } = match.params;
    const dialogActions = [
      <Button key="cancel" onClick={this.handleCloseDialog}>Cancel</Button>,
    ];

    const resourceType = (subResource) || (resource);
    const imageField = resourceFields[resourceType].filter(field => field.type === 'image');
    const hourField = resourceFields[resourceType].filter(field => field.type === 'hours');
    const orderDetailField = resourceFields[resourceType].filter(field => field.key === 'items' && resource === 'orders');
    const isolateField = imageField[0] || hourField[0] || orderDetailField[0];
    const gridContainerWidth = isolateField ? '50%' : '100%';
    const resourceTitle = utilFunctions.depluralizeResourceName(resource).charAt(0).toUpperCase() + utilFunctions.depluralizeResourceName(resource).slice(1);

    /**
     * This method needs to be changed once ProductItems and Products are
     * located on the same page
     * Home -> Menu -> ProductName -> ProductItemName
     */
    return (
      <div style={styles.container}>
        <div style={commonStyles.resourceHeader}>
          <div style={commonStyles.resourceName}>
            <Typography style={commonStyles.resourceName}>{resourceTitle}</Typography>
          </div>
          <div style={commonStyles.breadcrumbContainer} />
        </div>
        <Paper style={commonStyles.paper}>
          <form noValidate autoComplete="off" style={styles.formContainer}>
            <Grid container spacing={3} style={{ ...styles.gridContainer, width: gridContainerWidth }}>
              {
                  resourceFields[resourceType].map((field) => {
                    if (field.hideInView) return;
                    let fieldData;
                    fieldData = utilFunctions.formatFieldData(field, this.state[field.key]);
                    if (resource === 'orders') {
                      if (field.key === 'user') {
                        fieldData = this.state.user && this.state.user.name;
                      } else if (field.key === 'telephone') {
                        fieldData = this.state.user && this.state.user.telephone;
                      }
                    }
                    return this.renderResource(field, fieldData);
                  })
                }
            </Grid>
            {
              isolateField
                ? (
                  <div style={styles.isolatedField}>
                    {
                      isolateField.title !== 'Image'
                      && <Typography style={styles.itemTitle}>{`${isolateField.title}:`}</Typography>}
                    {this.renderIsolatedField(isolateField, this.state[isolateField.key])}
                  </div>
                )
                : <div />
            }
          </form>
          <Dialog
            modal={false}
            open={this.state.openDialog}
            onClose={this.handleCloseDialog}
          >
            <DialogContent>
              {this.state.dialogContents}
            </DialogContent>
            <DialogActions>
              {dialogActions}
            </DialogActions>
          </Dialog>
          <div style={styles.viewResourceBtnWrapper}>
            {(resourceType !== 'users' && resourceType !== 'addresses')
              && (
                <Button
                  style={styles.viewResourceButtons}
                  variant="contained"
                  onClick={() => this.onClickEdit(this.state)}
                >
                  Edit
                </Button>
              )
            }
            {(resourceType !== 'users' && resourceType !== 'addresses' && resourceType !== 'orders')
              && (
                <Button
                  style={styles.viewResourceButtons}
                  variant="contained"
                  onClick={() => this.onClickDelete(this.state)}
                >
                  Delete
                </Button>
              )
            }
          </div>
        </Paper>
      </div>
    );
  }
}

ViewResource.propTypes = {
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  resources: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
  apiToken: PropTypes.string.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(ViewResource);
