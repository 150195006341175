import React, { Component } from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

import MenuBar from './MenuBar';
import ErrorBar from './ErrorBar';
import Login from './Login';
import UnderConstruction from './UnderConstruction';
import ListResources from './crud/ListResources';
import ViewResource from './crud/ViewResource';
import * as Actions from '../actions/Actions';
import { appPageStyles } from '../css/style';
import JobPostingsForm from './resources/JobPostingsForm';
import UsersForm from './resources/UsersForm';
import PracticesForm from './resources/PracticesForm';
import ConnectionsView from './resources/ConnectionsView';
import PracticesView from './resources/PracticesView';
import PracticeReports from './resources/PracticeReports';
import RegionalNeeds from './resources/RegionalNeeds';
import FastpassStaff from './resources/FastpassStaff';
import Settings from './resources/Settings';
import UsersView from './resources/UsersView';
import PrivateUsersForm from './resources/PrivateUsersForm';
import BadgeUser from './resources/BadgeUser';
import ResetPasswordDialog from './ResetPasswordDialog';

import MessageSnackbar from './core/components/MessageSnackbar';
import ErrorBoundary from './ErrorBoundary';
import {
  checkQueryParam,
  isStripeEnabled,
  updateAdminAccessList,
  filterBadges,
} from '../utils/Functions';
import queryString from 'query-string';

const styles = appPageStyles();

/* eslint-disable react/prefer-stateless-function */
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      closeDialogBox: false,
      localAdmin: null,
    };
  }

  componentDidMount = async () => {
    const { admin, actions } = this.props;
    let localAdmin = null;

    if (!admin && localStorage.admin) {
      localAdmin = JSON.parse(localStorage.getItem('admin'));
      await actions.setAdmin(localAdmin);
      const userAccess = UsersForm.getUserAccess(localAdmin, 'aggregator');
      if (isStripeEnabled(userAccess)) {
        actions.setAdminStripeEnabled(true);
      }
    }

    if (localAdmin && checkQueryParam('session_id')) {
      const sessionId = queryString.parse(window.location.href).session_id;
      const aggregator = UsersForm.getUserAccess(localAdmin, 'aggregator')[0];
      actions.createStripe(localAdmin.token, {}, 'stripe-payment', 'sessions', sessionId, 'aggregators', aggregator.id)
        .then((res) => {
          if (!res.error) {
            if (res.response.subscriptionStatus) {
              updateAdminAccessList(localAdmin, actions, res.response);
              actions.setAdminStripeEnabled(true);
            }
          }
        });
    }

    this.setState({
      localAdmin,
    });
  };

  componentDidUpdate(prevProps) {
    const { admin, errors, actions } = this.props;

    // Log user out if the token is expired
    if (!isEqual(errors, prevProps.errors) && errors.errors === 'Token expired.') {
      localStorage.clear();
      sessionStorage.clear();
      this.setState({
        localAdmin: null,
      });
      actions.signoutUser();
      // Display message when rendering login page
      actions.dataRetrievalFailure('Token expired.');
    } else if (!isEqual(prevProps.admin, admin)) {
      if (admin) {
        const userAccess = UsersForm.getUserAccess(admin, 'aggregator');
        if (isStripeEnabled(userAccess)
          && window.location.pathname !== '/signup'
          && window.location.pathname !== '/subscription'
          && window.location.pathname !== '/under-construction'
        ) {
          actions.setAdminStripeEnabled(true);
        }
      }
      this.setState({
        localAdmin: admin,
      });
    }
  }

  handlePasswordReset = (type, data, localAdmin) => {
    const { actions } = this.props;
    const { id, token } = localAdmin;
    const { password } = data;
    actions.updateAdmin(token, {
      password,
      resetPassword: false,
    }, 'users', `${id}`)
      .then(() => {
        this.setState({ closeDialogBox: true });
      });
  };

  renderResetPasswordDialog = (localAdmin) => {
    const { closeDialogBox } = this.state;
    const resetPassword = localAdmin && localAdmin.resetPassword;
    return (
      <ResetPasswordDialog
        type='changePassword'
        open={!closeDialogBox && resetPassword || false}
        requireVerification={false}
        title='Change Password'
        description='Please create a password'
        handleCloseDialog={() => {
        }}
        disableClose
        handleOnClick={(type, data) => this.handlePasswordReset(type, data, localAdmin)}
        disableEscapeKeyDown
      />
    );
  };

  isGradientStyle() {
    let path = window.location.href.split('/')[3];
    if (path && path.toString().indexOf('?') > 0) {
      path = path.toString().split('?')[0];
    }
    const pageWithGradientBanner = ['settings', 'messages', 'practices', 'newpractice', 'editpractice', 'users', 'badges', 'applications', 'otherchannelchats', 'regionalneeds', 'fastpassstaff'];
    return pageWithGradientBanner.includes(path.toString().toLowerCase());
  }

  render() {
    const {
      actions, loading, resources, errors, success, admin, viewMoreLoading, stripeEnabled,
    } = this.props;
    const { localAdmin } = this.state;

    return (
      <BrowserRouter>
        {
          !stripeEnabled || localAdmin === null || window.location.pathname === '/subscription'
            ? (
              <Switch>
                <Route path="/newpractice" exact name="newpractices" render={() => <PracticesForm loading={loading} viewMoreLoading={viewMoreLoading} resources={resources} actions={actions} apiToken='' admin={localAdmin} />} />
                <Route path="/verify/:verificationUuid" exact render={() => (<Login actions={actions} />)} />
                <Route path="/under-construction" exact render={() => (<UnderConstruction actions={actions} />)} />
                <Route
                  render={() => (
                    <React.Fragment>
                      <Login actions={actions} admin={localAdmin} resources={resources} />
                      <MessageSnackbar openMessageSnackbar={!!(errors.errors)} errorMsg={errors.errors} actions={actions} />
                    </React.Fragment>
                  )}
                />
              </Switch>
            ) : (
              <div className="App" style={styles.container}>
                <MenuBar actions={actions} admin={localAdmin} apiToken={localAdmin.token} resources={resources} />
                <ErrorBoundary>
                  <div style={this.isGradientStyle() ? styles.gradientTabs : styles.tabs}>
                    <Switch>
                      <Route path="/" exact name="home" render={() => <Redirect to='/connections' />} />
                      <Route path="/verify/:verificationUuid" exact render={() => <Redirect to="/settings" />} />
                      <Route path="/newposition" exact name="newpositions" render={() => <JobPostingsForm loading={loading} viewMoreLoading={viewMoreLoading} resources={resources} actions={actions} apiToken={localAdmin.token} admin={localAdmin} />} />
                      <Route path="/newposition/:jobpostingId" exact name="duplicatepositions" render={() => <JobPostingsForm loading={loading} viewMoreLoading={viewMoreLoading} resources={resources} actions={actions} apiToken={localAdmin.token} admin={localAdmin} />} />
                      <Route path="/editposition/:jobpostingId" exact name="newpositions" render={() => <JobPostingsForm loading={loading} viewMoreLoading={viewMoreLoading} resources={resources} actions={actions} apiToken={localAdmin.token} admin={localAdmin} edit />} />
                      <Route path="/newpractice" exact name="newpractices" render={() => <PracticesForm loading={loading} viewMoreLoading={viewMoreLoading} resources={resources} actions={actions} apiToken={localAdmin.token} admin={localAdmin} />} />
                      <Route path="/editpractice/:practiceId" exact name="newpractices" render={() => <PracticesForm loading={loading} viewMoreLoading={viewMoreLoading} resources={resources} actions={actions} apiToken={localAdmin.token} admin={localAdmin} edit />} />
                      <Route path="/practices/:resourceId/view" exact name="viewPractice" render={() => <PracticesView admin={admin} loading={loading} resources={resources} actions={actions} apiToken={localAdmin.token} />} />
                      <Route path="/practices/:resourceId/reports" exact name="practiceReports" render={() => <PracticeReports loading={loading} resources={resources} actions={actions} apiToken={localAdmin.token} />} />
                      <Route path="/regionalneeds/:resourceId" exact name="regionalNeeds" render={() => <RegionalNeeds loading={loading} viewMoreLoading={viewMoreLoading} resources={resources} actions={actions} apiToken={localAdmin.token} admin={admin} />} />
                      <Route path="/fastpassstaff/:resourceId" exact name="fastpassStaff" render={() => <FastpassStaff loading={loading} viewMoreLoading={viewMoreLoading} resources={resources} actions={actions} apiToken={localAdmin.token} admin={admin} badges={filterBadges(localAdmin, resources.badges)} />} />
                      <Route path="/users/private/create" exact name="createPrivateUser" render={() => <PrivateUsersForm loading={loading} viewMoreLoading={viewMoreLoading} resources={resources} actions={actions} apiToken={localAdmin.token} admin={localAdmin} />} />
                      <Route path="/users/create" exact name="createUser" render={() => <UsersForm loading={loading} viewMoreLoading={viewMoreLoading} resources={resources} actions={actions} apiToken={localAdmin.token} admin={localAdmin} />} />
                      <Route path="/users/:userId/edit" exact name="editUser" render={() => <UsersForm loading={loading} viewMoreLoading={viewMoreLoading} resources={resources} actions={actions} apiToken={localAdmin.token} admin={localAdmin} edit />} />
                      <Route path="/users/private/:userId/edit" exact name="editPrivateUser" render={() => <PrivateUsersForm loading={loading} viewMoreLoading={viewMoreLoading} resources={resources} actions={actions} apiToken={localAdmin.token} admin={localAdmin} edit />} />
                      <Route path="/privateusers/:userId/view" exact name="viewUser" render={() => <UsersView loading={loading} resources={resources} actions={actions} apiToken={localAdmin.token} admin={localAdmin} />} />
                      <Route path="/badgeuser/:userId" exact name="editUserBadge" render={() => <BadgeUser loading={loading} resources={resources} actions={actions} apiToken={localAdmin.token} admin={localAdmin} badges={filterBadges(localAdmin, resources.badges)} />} />
                      <Route path="/connections/:resourceId/view" exact name="viewConnection" render={() => <ConnectionsView loading={loading} resources={resources} actions={actions} apiToken={localAdmin.token} admin={localAdmin} />} />
                      <Route path="/:resource/" exact name="resources" render={() => <ListResources loading={loading} viewMoreLoading={viewMoreLoading} resources={resources} actions={actions} apiToken={localAdmin.token} admin={localAdmin} />} />
                      <Route path="/:resource/privatetalents" exact name="resources" render={() => <ListResources loading={loading} viewMoreLoading={viewMoreLoading} resources={resources} actions={actions} apiToken={localAdmin.token} admin={localAdmin} getPrivate />} />
                      <Route path="/:resource/:resourceId" exact name="resources" render={() => <ListResources loading={loading} viewMoreLoading={viewMoreLoading} resources={resources} actions={actions} apiToken={localAdmin.token} admin={localAdmin} />} />
                      <Route path="/:resource/:resourceId/view" exact name="viewResource" render={() => <ViewResource loading={loading} resources={resources} actions={actions} apiToken={localAdmin.token} />} />
                      <Route path="/:resource/:resourceId/:subResource" exact name="subResources" render={() => <ListResources loading={loading} resources={resources} actions={actions} apiToken={localAdmin.token} />} />
                      <Route path="/:resource/:resourceId/:subResource/:subResourceId/view" exact name="viewSubResource" render={() => <ViewResource loading={loading} resources={resources} actions={actions} apiToken={localAdmin.token} />} />
                    </Switch>
                  </div>
                </ErrorBoundary>
                <ErrorBar errors={errors.errors} actions={actions} />
                {this.renderResetPasswordDialog(localAdmin)}
              </div>
            )
        }
      </BrowserRouter>
    );
  }
}

App.propTypes = {
  loading: PropTypes.bool.isRequired,
  resources: PropTypes.objectOf(PropTypes.any),
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  admin: PropTypes.objectOf(PropTypes.any),
  errors: PropTypes.objectOf(PropTypes.any),
  viewMoreLoading: PropTypes.number.isRequired,
  stripeEnabled: PropTypes.bool.isRequired,
};

App.defaultProps = {
  resources: {},
  admin: null,
  errors: null,
};

export default connect(
  state => ({
    loading: state.get('loading').toJS().loading,
    viewMoreLoading: state.get('loading').toJS().viewMoreLoading,
    resources: state.get('resources').toJS(),
    errors: state.get('errors').toJS(),
    admin: state.get('admin').toJS().admin,
    stripeEnabled: state.get('admin').toJS().stripeEnabled,
  }),
  dispatch => ({
    actions: bindActionCreators(Actions, dispatch),
  }),
)(App);
