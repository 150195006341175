import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Radio, RadioGroup, FormControlLabel, FormControl, FormLabel,
} from '@mui/material';


const customRadioStyles = theme => ({
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    width: '150px',
    paddingTop: '15px',
  },
  noPaddingRadioGroup: {
    display: 'flex',
    flexDirection: 'row',
    width: '150px',
    paddingTop: '5px',
  },
});

const styles = customRadioStyles();

export default class CustomRadio extends Component {
    static propTypes = {
      onChange: PropTypes.func.isRequired,
    }

    constructor(props) {
      super(props);
      this.state = {
        selectedBoolean: props.data || 'false',
      };
    }

    componentDidMount() {
      this.props.onChange(this.state.selectedBoolean);
    }

    onChangeBoolean = (event) => {
      this.setState({ selectedBoolean: event });
      this.props.onChange(event);
    }

    render() {
      const { disabled, hidepadding } = this.props;
      const { selectedBoolean } = this.state;
      const defaultValue = selectedBoolean.toString();
      return (
        <FormControl component="fieldset">
          <FormLabel component="legend">{this.props.label}</FormLabel>
          <RadioGroup
            name="default_setting"
            value={defaultValue}
            onChange={(event) => { this.onChangeBoolean(event.target.value); }}
            style={hidepadding ? styles.noPaddingRadioGroup : styles.radioGroup}
          >
            <FormControlLabel value="true" control={<Radio color="primary" />} label="Yes" disabled={disabled} />
            <FormControlLabel value="false" control={<Radio color="primary" />} label="No" disabled={disabled} />
          </RadioGroup>
        </FormControl>
      );
    }
}
