import moment from 'moment';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEqual, uniqBy, isEmpty } from 'lodash';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Paper,
  Typography,
  Grid,
  IconButton, Icon,
  MenuList, MenuItem,
  Drawer, Divider,
  Popper, Box, Tooltip,
  Snackbar, Alert,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import { withStyles } from '@mui/styles';
import DateFormat from '../subcomponents/DateFormat';
import {
  connectionsPaginationCount,
  BASE_URLS,
  IMG_STORAGE_LINK,
  IMG_STORAGE_TALENT_LINK,
} from '../../data/Constants';
import {
  commonResourceStyle,
  navy,
  orangeAccent,
  bgGray,
  green,
  orange,
  tiffany,
  errorRed,
  lightBlue,
} from '../../css/style';
import types from '../../actions/ActionTypes';
import LoadingBar from '../subcomponents/LoadingBar';
import { utils, formatPostTitle, getTimeCategory } from '../../utils/Functions';
import PracticeCalendar from '../subcomponents/PracticeCalendar';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const styles = commonResourceStyle();
const sxStyles = {
  statisticsRoot: {
    '& .MuiTypography-root': {
      color: navy,
      fontSize: '10px',
      fontWeight: '600',
      fontFamily: 'Asap',
      textTransform: 'capitalize',
    },
    '& .MuiButtonBase-root': {
      padding: 0,
      display: 'block',

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  statNumber: {
    color: orangeAccent,
    fontSize: '10px',
    fontWeight: '600',
    fontFamily: 'Asap',
  },
  icon: {
    color: navy,
    width: '24px',
    height: '24px',
  },

  lensIcon: {
    color: orangeAccent,
    fontSize: '10px',
    padding: '0',
    position: 'absolute',
    bottom: '22px',
    left: '18px',
  },
  msgLensIcon: {
    color: orangeAccent,
    fontSize: '10px',
    padding: '0',
    position: 'absolute',
    bottom: '17px',
    left: '20px',
  },
  toolBarIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  caption: {
    height: '12px',
    fontSize: '10px',
    fontStyle: 'italic',
    color: '#fff',
  },
  mainCalendarOuter: {
    display: 'flex',
    width: '94%',
    height: '85vh',
    backgroundColor: 'transparent',
    marginTop: '24px',
    borderRadius: '8px',
  },
  requestDiv: {
    background: 'linear-gradient(270deg, #8CD0E0 0%, #4EBDAE 100%)',
    border: `1px solid ${tiffany}`,
    borderRadius: '20px',
    padding: '12px 20px',
  },
  eventDiv: {
    padding: '12px 20px',
    marginBottom: '16px',
  },
  shiftInfo: {
    fontSize: 10,
    color: 'white',
  },
  requestText: {
    maxWidth: '150px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};

const defaultTalentType = [{
  key: 'dvm-doctor-of-veterinary-medicine',
  title: 'DVM - Doctor of Veterinary Medicine',
}];

const countComparingStatusRows = (date, jobPostingId, status, shiftCalendarItems) => {
  let count = 0;
  const shiftCalendarItem = shiftCalendarItems.find(item => item.id === jobPostingId);
  if (shiftCalendarItem) {
    shiftCalendarItem?.jobPostingCalendar.forEach((item) => {
      if (item.status === status && item.date === date) {
        count += 1;
      }
    });
  }
  return count;
};

class HomeCalendar extends Component {
  constructor(props) {
    super(props);
    const filterTalentType = localStorage.getItem('filterTalentType');
    const filterPractice = !isEmpty(localStorage.getItem('filterPractice')) ? JSON.parse(localStorage.getItem('filterPractice')) : null;

    this.state = {
      totalUnreadMessagesCount: 0,
      startPosition: 0,
      calendarPractice: filterPractice || {},
      calendarTalentType: filterTalentType || defaultTalentType[0].key,
      selectedDate: moment().format('YYYY-MM-DD'),
      openSnackbar: false,
    };
  }

  componentWillUnmount() {
    const { calendarPractice, calendarTalentType } = this.state;
    localStorage.setItem('filterPractice', JSON.stringify(calendarPractice));
    localStorage.setItem('filterTalentType', calendarTalentType);
  }

  componentDidMount() {
    const {
      actions, apiToken, adminPractices,
    } = this.props;

    if (isEmpty(adminPractices)) {
      actions.getAllResources(apiToken, BASE_URLS.getPractices).then((resp) => {
        const activeSortedAdminPractices = resp.response && resp.response.filter(a => a.active)?.sort((a, b) => (a.activePostingsCount > b.activePostingsCount ? -1 : 1));
        this.setState({ activeSortedAdminPractices }, () => this.loadCalendarData());
      });
    } else {
      const activeSortedAdminPractices = adminPractices && adminPractices.filter(a => a.active)?.sort((a, b) => (a.activePostingsCount > b.activePostingsCount ? -1 : 1));
      this.setState({ activeSortedAdminPractices }, () => this.loadCalendarData());
    }
    let queryParams = `connections?start=0&count=${connectionsPaginationCount}`;
    actions.getAllResources(apiToken, queryParams).then((res) => {
      if (!res.error) {
        const connections = res.response.list;
        const totalUnreadMessagesCount = connections.map(connect => connect.unreadMessagesCount).reduce((a, b) => a + b, 0);
        this.setState({ totalUnreadMessagesCount });
      }
    });
    actions.getAllResources(apiToken, 'connections/statistics');
  }

  // load data for calendar view
  loadCalendarData = () => {
    const {
      actions, apiToken,
    } = this.props;
    const { calendarPractice, calendarTalentType, activeSortedAdminPractices } = this.state;

    const practice = !isEmpty(calendarPractice)
      ? { ...calendarPractice } : !isEmpty(activeSortedAdminPractices)
        ? activeSortedAdminPractices[0] : {};
    const talentType = calendarTalentType || '';

    if (!isEmpty(practice) && talentType) {
      const resourceName = `practices/calendar?practiceId=${practice.id}&talentType=${talentType}`;
      actions.getAllResources(apiToken, resourceName).then((resp) => {
        this.setState({ shiftCalendarItems: resp.response.list, calendarPractice: practice, calendarTalentType: talentType });
        localStorage.setItem('filterPractice', '');
        localStorage.setItem('filterTalentType', '');
      });
      this.loadDateTrackingItems(practice.id);
    }
  };

  loadDateTrackingItems = (practiceId) => {
    const {
      actions, apiToken,
    } = this.props;

    if (practiceId) {
      actions.getAllResources(apiToken, `date-request-trackings/practice/${practiceId}`).then((resp) => {
        const dateTrackingItems = resp?.response || [];
        const requestedDates = !isEmpty(dateTrackingItems)
          ? dateTrackingItems.reduce((acc, item) => {
            if (item.status === 'requested') {
              const key = item.job_posting_id;
              acc[key] = acc[key] || [];
              acc[key].push(item.date);
            }
            return acc;
          }, {})
          : {};
        this.setState({ dateTrackingItems, requestedDates });
      });
    }
  };

  getStatsInfo = () => {
    const { statistics, history } = this.props;
    const { totalUnreadMessagesCount, startPosition } = this.state;

    if (statistics) {
      const {
        newConnections, firstNewConnectionDate, totalConnections, firstConnectionDate, totalMatches, firstMatchDate, positionClicks, firstClickDate, activePositions, archivedPositions,
      } = this.props.statistics;

      return (
        <Grid container sx={sxStyles.statisticsRoot}>
          <Grid item xs={4} sx={sxStyles.toolBarIconContainer}>
            <IconButton
              onClick={() => history.push('/applications')}
              disableRipple
            >
              <EmojiEmotionsIcon sx={sxStyles.icon} />
              <Typography>
                <span style={sxStyles.statNumber}>
                  {totalConnections}
                  &nbsp;
                </span>
                Applications
              </Typography>
              <Typography style={sxStyles.caption}>
                <DateFormat str={firstConnectionDate} format="MMMM, yyyy" prepend="since" />
              </Typography>
            </IconButton>
          </Grid>
          <Grid item xs={4} sx={sxStyles.toolBarIconContainer}>
            <IconButton
              onClick={() => history.push('/applications')}
              disableRipple
            >
              <Icon sx={sxStyles.icon}>new_releases</Icon>
              <Typography>
                <span style={sxStyles.statNumber}>
                  {newConnections}
                  &nbsp;
                </span>
                New Applications
                </Typography>
              <Typography style={sxStyles.caption}>
                <DateFormat str={firstNewConnectionDate} prepend="since" />
              </Typography>
            </IconButton>
          </Grid>
          <Grid item xs={4} sx={sxStyles.toolBarIconContainer}>
            <IconButton
              onClick={() => history.push('/applications')}
              disableRipple
            >
              {
                totalUnreadMessagesCount > 0 && (
                  <>
                    <Icon sx={sxStyles.lensIcon}>
                      lens
                    </Icon>
                  </>
                )
              }
              <Icon sx={sxStyles.icon}>chat_bubble</Icon>
            </IconButton>
            <Typography>
              {/* <span style={sxStyles.statNumber}>
                {totalUnreadMessagesCount}
                &nbsp;
              </span> */}
              New Messages
            </Typography>
            <Typography style={sxStyles.caption}>
                &nbsp;
              </Typography>
          </Grid>
        </Grid>
      );
    }
  };

  renderItem = (item, dateItem, index, dateIndex, isRequested, requestedCountOnDate) => {
    const {
      classes, history,
    } = this.props;
    const {
      selectedDate,
      selectedJobPostingId,
      calendarPractice,
      calendarTalentType,
      requestedDates,
    } = this.state;

    const jobPostingId = item.parentJobPostingId || item.id;

    return (
      <>
      <div
        key={`shift${index}`}
        onClick={(event) => {
          this.setState({ [`menuAnchor${dateIndex}`]: event.target, selectedJobPostingId: jobPostingId });
        }}
        style={{
          ...sxStyles.eventDiv,
          backgroundColor: isRequested ? orangeAccent : dateItem.status === 'available' ? green : navy,
          border: isRequested ? `1px solid ${orangeAccent}` : dateItem.status === 'available' ? `1px solid ${green}` : `1px solid ${navy}`,
          borderRadius: dateItem.status === 'available' ? '20px' : '0px',
          cursor: 'pointer',
          color: '#fff',
          display: 'flex',
          flexDirection: 'column',
          marginBottom: !isRequested && dateItem.status === 'taken' && dateItem.date === selectedDate ? '0px' : '16px',
        }}
      >
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          <span className={classes.shiftInfo} key={`${index}_date`}>
            {selectedDate ? moment(selectedDate, 'YYYY-MM-DD').format('ddd, MMM D') : ''}
          </span>
          {dateItem.status === 'available' && item.avaiCount > 1 && (
            <span className={classes.shiftInfo} key={`${index}_count`}>
              {isRequested ? `(${requestedCountOnDate}/${item.avaiCount})` : `(${item.avaiCount})`}
            </span>
          )}
          {item.shiftCat && (
            <img
              key={`${index}_icon`}
              src={item.shiftCat === 'MORNING'
                ? `${IMG_STORAGE_TALENT_LINK}shift_morning.svg`
                : item.shiftCat === 'AFTERNOON' ? `${IMG_STORAGE_TALENT_LINK}shift_afternoon.svg` : `${IMG_STORAGE_TALENT_LINK}shift_evening.svg`}
              style={{ width: '20px', height: '20px' }}
            />
          )}
        </div>
        <Typography key={`${index}_title`}>{formatPostTitle(item.title)}</Typography>
      </div>
      <Popper
        key={`${dateIndex}_popper`}
        open={!!this.state[`menuAnchor${dateIndex}`]}
        anchorEl={this.state[`menuAnchor${dateIndex}`]}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        style={{ zIndex: '2000' }}
      >
        <ClickAwayListener onClickAway={() => this.setState({ [`menuAnchor${dateIndex}`]: null })}>
          <Paper>
          <MenuList
            key={`${index}_popover_menu`}
            aria-labelledby="composition-button"
          >
            <MenuItem
              key={`${index}_app_link`}
              onClick={() => {
                let link = '/applications';
                if (dateItem.status === 'available') {
                  const practiceId = calendarPractice?.id || '';
                  const talentType = calendarTalentType || '';
                  link += `?practices=${practiceId}&talentTypes=${talentType}`;
                }
                if (dateItem.status === 'taken') {
                  link += `?jobpostings=${item.id}`;
                }
                history.push(link);
              }}
            >
              View Applications
            </MenuItem>
            <MenuItem key={`${index}_post_link`} onClick={() => history.push(`/editposition/${selectedJobPostingId}`)}>
              View/Edit Job Post
            </MenuItem>
          </MenuList>
          </Paper>
        </ClickAwayListener>
      </Popper>
      {!isRequested && dateItem.date === selectedDate && dateItem.status === 'taken' && (
        <Box
          sx={{
            margin: '0px 0px 10px 0px',
            backgroundColor: '#fff',
            display: 'flex',
            alignItems: 'center',
            padding: '12px 20px',
            border: `1px solid ${navy}`,
            color: navy,
            justifyContent: 'space-between',
            '& .MuiButtonBase-root': { padding: '0 4px' },
          }}
          key={`${index}_req_div`}
        >
          <Tooltip title="Message" placement='top'>
          <IconButton
            onClick={() => history.push(`/messages/${dateItem.match_id}`)}
            key={`req-chat-icon-${index}`}
            disableRipple
          >
            {item.unreadMessagesCount > 0 && (<><Icon sx={sxStyles.msgLensIcon}>lens</Icon></>)}
            <Icon sx={sxStyles.icon}>chat_bubble</Icon>
          </IconButton>
          </Tooltip>
          <Typography key={`${index}_name_confirmed`}>{dateItem.name}</Typography>
        </Box>
      )}
      </>
    );
  };

  handleDateMsgConfirm = async (editedRow, action, item) => {
    const { actions, apiToken, admin } = this.props;
    const {
      dateTrackingItems = [],
      selectedDate,
      calendarPractice,
      calendarTalentType,
      shiftCalendarItems,
    } = this.state;

    if (isEmpty(editedRow)) return;

    if (action === 'approved') { // Approve the shift
      const requestedShiftsRows = dateTrackingItems.filter(row => row.job_posting_id === editedRow.job_posting_id
        && row.status === 'requested'
        && row.date === editedRow.date);
      const totalDeclinedCount = requestedShiftsRows?.length - item?.avaiCount;

      // Check if the date is still available --> assign shift successfully, then we save change into database
      if (item?.avaiCount > 0) {
        // Save job posting calendar, request date tracking
        let takenShift = 0;
        const shiftCalendarItem = shiftCalendarItems.find(r => r.id === editedRow.job_posting_id);
        const jobPostingCalendar = shiftCalendarItem.jobPostingCalendar || [];
        // Save shift
        const calendarData = [...jobPostingCalendar].map((row) => {
          if (takenShift === 0 && row.date === editedRow.date
              && row.job_posting_id === editedRow.job_posting_id
              && row.status === 'available') {
            takenShift += 1;
            return {
              ...row,
              status: 'taken',
              talent_user_id: editedRow.user_id,
              confirmation_category: 'known_taken',
              confirmation: `${editedRow.name} (${editedRow.email})`,
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              confirmation_date: new Date(),
            };
          }
          return row;
        });
        let dataRequestTrackingDates = [];

        // If we multiple avaiable shifts --> do not decline
        if (item?.avaiCount > 1) {
          dataRequestTrackingDates = [...dateTrackingItems].map((row) => {
            if (row.id === editedRow.id) {
              return {
                ...row,
                status: 'approved',
                isSentMessage: true,
              };
            }
            return row;
          });
        } else {
          // If we run out of shifts --> decline other requests
          let declineCount = 0;
          dataRequestTrackingDates = [...dateTrackingItems].map((row) => {
            if (row.id === editedRow.id) {
              return {
                ...row,
                status: 'approved',
                isSentMessage: true,
              };
            } else if (totalDeclinedCount > 0 && declineCount < totalDeclinedCount && row.status === 'requested'
              && row.date === editedRow.date && row.job_posting_id === editedRow.job_posting_id) {
              declineCount += 1;
              return {
                ...row,
                status: 'declined',
                isSentMessage: false,
              };
            }
            return row;
          });
        }

        actions.addResource(apiToken, dataRequestTrackingDates, 'date-request-trackings', 'update-trackings').then((resp) => {
          const dateTrackingItems = resp?.response || [];
          const requestedDates = !isEmpty(dateTrackingItems)
            ? dateTrackingItems.reduce((acc, item) => {
              if (item.status === 'requested') {
                const key = item.job_posting_id;
                acc[key] = acc[key] || [];
                acc[key].push(item.date);
              }
              return acc;
            }, {})
            : {};
          actions.updateResource(apiToken, { jobPostingCalendar: calendarData, isMessagePage: true }, 'jobpostings', editedRow.job_posting_id).then(() => {
            actions.addResource(apiToken, { action: 'admin_calendar' }, 'users', 'log-event');
            // Save message
            const date = moment(editedRow.date).format('MM/DD');
            this.saveNewMessage(editedRow, `${admin.name} has confirmed you for ${date}. A summary email will follow at the end of the day.`);

            setTimeout(() => {
              // refresh shift calendar
              const resourceName = `practices/calendar?practiceId=${calendarPractice?.id}&talentType=${calendarTalentType}`;
              actions.getAllResources(apiToken, resourceName).then((resp) => {
                this.setState({ shiftCalendarItems: resp?.response?.list || [] });
              });
            }, 2000);
          });

          if (!resp?.response) {
            this.setState({
              errorMessage: resp?.error?.message || 'Something went wrong. Please try again.',
              sucessMessage: '',
              openSnackbar: true,
            });
          } else {
            let sucessMessage = `${editedRow.name} was approved for ${formatPostTitle(item?.title)}.`;
            if (item?.avaiCount === 1 && totalDeclinedCount > 0) {
              sucessMessage = sucessMessage.concat(' All other applicants were declined.');
            }

            this.setState({
              dateTrackingItems: [...dataRequestTrackingDates],
              requestedDates,
              openSnackbar: true,
              sucessMessage,
              errorMessage: '',
            });
          }
        });
      }
    }

    if (action === 'declined' && editedRow.status === 'requested') {
      const data = [...dateTrackingItems].map((row) => {
        if (row.id === editedRow.id) {
          return {
            ...row,
            status: 'declined',
            isSentMessage: true,
          };
        }
        return row;
      });

      // Call API to decline the requested date
      actions.addResource(apiToken, data, 'date-request-trackings', 'update-trackings').then((resp) => {
        const dateTrackingItems = resp?.response || [];
        const requestedDates = !isEmpty(dateTrackingItems)
          ? dateTrackingItems.reduce((acc, item) => {
            if (item.status === 'requested') {
              const key = item.job_posting_id;
              acc[key] = acc[key] || [];
              acc[key].push(item.date);
            }
            return acc;
          }, {})
          : {};

        // Save message
        const date = moment(editedRow.date).format('MM/DD');
        this.saveNewMessage(editedRow, `We have decided to pursue another worker for ${date}.`);

        // refresh shift calendar
        const resourceName = `practices/calendar?practiceId=${calendarPractice?.id}&talentType=${calendarTalentType}`;
        actions.getAllResources(apiToken, resourceName).then((resp) => {
          this.setState({ shiftCalendarItems: resp?.response?.list || [], dateTrackingItems: [...data], requestedDates });
        });
      });
    }
  };

  saveNewMessage = (editedRow, newMessage) => {
    const { actions, apiToken, admin } = this.props;

    const payload = {
      body: newMessage,
      match_id: parseInt(editedRow.match_id),
      isUnread: true,
    };
    actions.addResource(apiToken, payload, 'messages').then((res) => {
      actions.addResource(apiToken, { action: 'admin_message' }, 'users', 'log-event');
    });
  };

  handleFilterChange = (fieldName, val) => {
    const { actions, apiToken } = this.props;

    if (!isEmpty(val) && !isEqual(this.state[fieldName], val)) {
      this.setState({ [fieldName]: val }, () => {
        const resourceName = fieldName === 'calendarPractice'
          ? `practices/calendar?practiceId=${val.id}&talentType=${this.state.calendarTalentType}`
          : `practices/calendar?practiceId=${this.state.calendarPractice.id}&talentType=${val}`;
        actions.getAllResources(apiToken, resourceName).then((resp) => {
          const shiftCalendarItems = resp?.response?.list || [];
          this.setState({ shiftCalendarItems });
        });
      });
      if (fieldName === 'calendarPractice') {
        this.loadDateTrackingItems(val.id);
      }
    }
  };

  render() {
    const {
      classes, actions, loading, apiToken, viewMoreLoading, history, resources, admin, adminPractices, theme, isDesktop,
    } = this.props;
    const { badges } = resources;
    const {
      jobpostings,
      selectedDate,
      shiftCalendarItems,
      sideDrawerOpen,
      selectedJobPostingId,
      calendarPractice,
      calendarTalentType,
      requestedDates,
      dateTrackingItems,
    } = this.state;

    let badgeTitles = {};
    if (badges) {
      badges.forEach(x => badgeTitles[x.id] = x.title);
    }
    let jobPostingList = jobpostings ? [...jobpostings].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) : [];
    jobPostingList = uniqBy(jobPostingList, 'id');

    const activeSortedAdminPractices = adminPractices && adminPractices.filter(a => a.active)?.sort((a, b) => (a.activePostingsCount > b.activePostingsCount ? -1 : 1));
    const stylesWithTheme = commonResourceStyle(theme);

    // Filter the shiftCalendarItems by selectedDate
    let filteredShiftItemsByDate = selectedDate && shiftCalendarItems ? shiftCalendarItems.filter(item => item.jobPostingCalendar && item.jobPostingCalendar.find(x => x.date === selectedDate)) : [];
    filteredShiftItemsByDate = filteredShiftItemsByDate.map((item) => {
      const copiedItem = { ...item };
      const avaiCount = copiedItem.jobPostingCalendar && countComparingStatusRows(selectedDate, item.id, 'available', shiftCalendarItems);
      copiedItem.avaiCount = avaiCount;
      const shiftCat = selectedDate && copiedItem.startTime ? getTimeCategory(moment(moment(`${selectedDate} ${copiedItem.startTime}`, 'YYYY-MM-DD h:mm(A)'))) : null;
      copiedItem.shiftCat = shiftCat;
      copiedItem.jobPostingCalendar = copiedItem.jobPostingCalendar.map((jobPostingCalendarRow) => {
        let copiedRow = { ...jobPostingCalendarRow };
        if (copiedRow.status === 'available') {
          copiedRow.uniqueKey = `${copiedRow.job_posting_id}_${copiedRow.date}_${copiedRow.status}`;
        } else {
          copiedRow.uniqueKey = copiedRow.id;
        }
        return copiedRow;
      });
      return copiedItem;
    });
    filteredShiftItemsByDate.forEach((item) => {
      item.jobPostingCalendar = uniqBy(item.jobPostingCalendar, 'uniqueKey');
    });

    const dateText = selectedDate ? moment(selectedDate, 'YYYY-MM-DD').format('ddd, MMM D') : moment().format('ddd, MMM D');


    return (
      (loading === true && viewMoreLoading === 0)
        ? <LoadingBar />
        : (
          <div style={styles.greenContainer} id='postFilter'>
            <Grid container className={classes.calendarHomeHeader}>
              <Grid item xs={12} md={4} className={classes.resourceName}>
                <Typography sx={styles.homeResourceName} style={{ paddingLeft: '12px' }}>
                  <Icon style={{ marginRight: '5px' }}>home</Icon>
                  {`Welcome, ${admin.name}`}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                {this.getStatsInfo()}
              </Grid>
              <Grid item xs={12} md={4}>&nbsp;</Grid>
            </Grid>
            <div style={sxStyles.mainCalendarOuter}>
              <div
                id='mainDivCalendar'
                style={
                  sideDrawerOpen ? {
                    ...stylesWithTheme.calendarDiv,
                    ...stylesWithTheme.contentShiftRight,
                  } : stylesWithTheme.calendarDiv}
              >
                <div style={{ width: '100%', display: 'flex' }}>
                  <PracticeCalendar
                    handleEventSelect={event => this.setState({ selectedEvent: event, openDetailsDialog: true })}
                    shiftCalendarPractice={this.state.selectedPractices?.length ? this.state.selectedPractices[0] : {}}
                    shiftCalendarItems={this.state.shiftCalendarItems || []}
                    isMobile={!isDesktop}
                    onPracticeChange={(val) => {
                      this.handleFilterChange('calendarPractice', val);
                    }}
                    dateClickCallback={date => this.setState({ sideDrawerOpen: true, selectedDate: date })}
                    sideDrawerOpen={sideDrawerOpen}
                    setSideDrawerOpen={() => {
                      this.setState({ sideDrawerOpen: !sideDrawerOpen });
                      filteredShiftItemsByDate?.forEach((item) => {
                        const dateItems = item.jobPostingCalendar.filter(x => x.date === selectedDate);
                        dateItems.forEach((dateItem, dateIndex) => {
                          this.setState({ [`menuAnchor${dateIndex}`]: null });
                        });
                      });
                    }}
                    activeSortedAdminPractices={activeSortedAdminPractices}
                    calendarPractice={this.state.calendarPractice}
                    calendarTalentType={this.state.calendarTalentType}
                    onTalentTypeChange={(val) => {
                      this.handleFilterChange('calendarTalentType', val);
                    }}
                    requestedDates={this.state.requestedDates || {}}
                    selectedDate={selectedDate}
                  />
                </div>
              </div>
              {sideDrawerOpen && (
                <>
                <img
                  src={`${IMG_STORAGE_LINK}image-dog.png`}
                  alt="Dog"
                  style={{
                    height: '189px',
                    zIndex: 9999,
                    position: 'absolute',
                    top: '0px',
                    right: '4%',
                  }}
                />
                <Drawer
                  className={classes.drawer}
                  variant="persistent"
                  anchor="right"
                  open={sideDrawerOpen}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                >
                {sideDrawerOpen
                && (
                  <Box className={classes.rightDrawerCloseBtn}>
                    <IconButton onClick={() => this.setState({ sideDrawerOpen: false })} size='small'>
                      <ChevronRightIcon style={{ fontSize: '16px' }} />
                    </IconButton>
                  </Box>
                )
                }
                  <div
                    className={classes.drawerHeader}
                    style={{
                      justifyContent: 'space-between',
                      padding: '16px 10px',
                      backgroundColor: bgGray,
                      display: 'flex',
                    }}
                  >
                    <Typography color="secondary" sx={{ width: '30%' }}>
                      {dateText}
                    </Typography>
                    <Divider sx={{ width: '70%' }} />
                  </div>
                    <div className={classes.drawerContainer}>
                      {filteredShiftItemsByDate?.map((item, index) => {
                        const dateItems = item.jobPostingCalendar.filter(x => x.date === selectedDate);
                        const filterRequestDates = dateTrackingItems ? dateTrackingItems.filter(i => i.job_posting_id === item.id
                          && i.status === 'requested' && i.date === selectedDate) : [];

                        return dateItems.map((dateItem, dateIndex) => {
                          const isRequested = dateItem.status === 'available' && filterRequestDates?.find(i => i.date === dateItem.date);
                          const requestedCountOnDate = filterRequestDates?.filter(i => i.date === dateItem.date)?.length;
                          return isRequested
                            ? (
                            <div style={sxStyles.requestDiv} key={`${index}_${dateIndex}_div`}>
                              {this.renderItem(item, dateItem, index, dateIndex, isRequested, requestedCountOnDate)}
                              {filterRequestDates?.map((requestItem, requestIndex) => (
                              <Grid
                                container
                                sx={{
                                  margin: '10px 0',
                                  flexDirection: isDesktop ? 'row' : 'column',
                                  backgroundColor: '#fff',
                                  display: 'flex',
                                  alignItems: 'center',
                                  borderRadius: '20px',
                                  padding: '12px 20px',
                                  flexWrap: 'nowrap',
                                  justifyContent: 'space-between',
                                  '& .MuiButtonBase-root': { padding: '0 4px' },
                                }}
                                key={`${requestIndex}_req_box`}
                              >
                                <Grid item xs={2} md={2} sx={{ display: 'flex', alignItems: 'center' }}>
                                  <Tooltip title='Message' placement='top'>
                                    <IconButton
                                      onClick={() => history.push(`/messages/${requestItem.match_id}`)}
                                      key={`req-chat-icon-${requestIndex}`}
                                      disableRipple
                                    >
                                      {requestItem.unreadMessagesCount > 0 && (<><Icon sx={sxStyles.msgLensIcon}>lens</Icon></>)}
                                      <Icon sx={sxStyles.icon}>chat_bubble</Icon>
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                                <Grid item xs={10} md={8} key={`${requestIndex}_req`}>
                                  <Typography sx={sxStyles.requestText}>{requestItem.name}</Typography>
                                </Grid>
                                <Grid item xs={12} md={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                  <Tooltip title='Approve' placement='top'>
                                    <IconButton
                                      key={`approve-btn-${requestIndex}`}
                                      onClick={() => {
                                        this.handleDateMsgConfirm(requestItem, 'approved', item);
                                      }}
                                    >
                                      <CheckCircleIcon
                                        key={`approve-icon-${requestIndex}`}
                                        sx={{
                                          width: '24px',
                                          height: '24px',
                                          cursor: 'pointer',
                                          color: green,
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title='Decline' placement='top'>
                                    <IconButton
                                      key={`decline-btn-${requestIndex}`}
                                      onClick={() => {
                                        this.handleDateMsgConfirm(requestItem, 'declined', item);
                                      }}
                                    >
                                        <CancelIcon
                                          key={`decline-icon-${requestIndex}`}
                                          sx={{
                                            width: '24px',
                                            height: '24px',
                                            cursor: 'pointer',
                                            color: errorRed,
                                          }}
                                        />
                                      </IconButton>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                              ))}
                            </div>
                            )
                            : this.renderItem(item, dateItem, index, dateIndex, isRequested);
                        });
                      })}
                    </div>
                </Drawer>
                  <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={this.state.openSnackbar}
                    onClose={() => this.setState({ openSnackbar: false })}
                    key='snackbar'
                    autoHideDuration={6000}
                  >
                    <Alert
                      variant="outlined"
                      severity={this.state.sucessMessage ? 'success' : 'error'}
                      style={{ backgroundColor: '#fff' }}
                    >
                      {this.state.sucessMessage || this.state.errorMessage}
                    </Alert>
                  </Snackbar>
                </>
              )}
            </div>
          </div>
        )
    );
  }
}

HomeCalendar.propTypes = {
  loading: PropTypes.bool.isRequired,
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  apiToken: PropTypes.string.isRequired,
};

HomeCalendar.defaultProps = {};

function HomeCalendarWrapper({ theme, ...rest }) {
  const isDesktop = theme ? useMediaQuery(theme.breakpoints.up('md')) : true;
  return <HomeCalendar {...rest} theme={theme} isDesktop={isDesktop} />;
}

export default withStyles(commonResourceStyle, { withTheme: true })(withRouter(HomeCalendarWrapper));
