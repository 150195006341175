import React, { useEffect, Fragment, useState } from 'react';
import {
  TextField,
  Chip,
  Checkbox,
  InputAdornment,
  Badge,
  Popover,
  FormLabel,
  Typography, ListItem,
} from '@mui/material';
import { isEmpty, uniq, uniqBy } from 'lodash';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { makeStyles } from '@mui/styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { commonResourceStyle, autocompleteOptionStyle } from '../../css/style';

const styles = commonResourceStyle();

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    fontSize: '14px',
    backgroundColor: '#ffffff',
    margin: '10px 0',
    position: 'relative',
    marginTop: 5,

    [theme && theme.breakpoints.up('md')]: {
      margin: props => (props.form ? '0' : '20px 0px 20px 20px'),
    },

    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: '6px 30px 7px 5px !important',
    },

    '& .MuiAutocomplete-input': {
      '&::placeholder': {
        color: 'rgba(0, 0, 0, 0.87)',
        opacity: 1,
        '&:focus': {
          opacity: 0.5,
        },
      },
    },

    '& .Mui-focused': {
      '& .MuiAutocomplete-input': {
        '&::placeholder': {
          color: 'rgba(0, 0, 0, 0.87)',
          opacity: 0.5,
        },
      },
    },

    '& .MuiChip-deleteIcon': {
      color: 'rgba(255, 255, 255, 0.7)',
    },

    '& .MuiChip-root': {
      color: '#fff',
      backgroundColor: '#243060',
      maxWidth: '100px',
      marginLeft: '5px',
      marginBottom: '1px',
    },

    '& .MuiChip-label': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
    },

    '& .MuiInputAdornment-positionEnd': {
      margin: '3px 12px',
    },

    '& .MuiBadge-badge': {
      height: '24px',
    },
  },
}));

const filterOptions = createFilterOptions({
  limit: 5,
  stringify: option => option.name,
});

export default function PracticeAutocomplete(props) {
  const {
    title,
    labelCount = 2,
    lettersTypedToShowOptions = 2,
    label,
    helpMessage,
    form,
    noOptionsText,
    disableImportedItem,
    disabled = false,
    fastpassData = [],
    autoFocus,
    isSingle = false,
    fixedPractices,
  } = props;
  const classes = useStyles({ form });
  const fixedOptions = fixedPractices ? fixedPractices : props.items && props.items.filter(x => x.disabledEdit);
  const [inputValue, setInputValue] = React.useState('');
  const [value, setValue] = React.useState(() => (props.value || []));
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [help, setHelp] = React.useState(null);
  const [disableClearable, setDisableClearable] = useState(false);

  useEffect(() => {
    if (disableImportedItem) {
      const filterValueImported = (props.value || []).filter(x => x.isImported);
      if (filterValueImported.length > 0) setDisableClearable(true);
    }
  }, [disableImportedItem]);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    const { handleStateChange } = props;
    handleStateChange(value);
  }, [value]);

  useEffect(() => {
    const { items } = props;
    if (!inputValue || inputValue.length <= lettersTypedToShowOptions) {
      setOptions([]);
      setOpen(false);
      return;
    }
    const filteredPractices = items.filter(c => c.name.toLowerCase().includes(inputValue.toLowerCase()));
    setOptions(filteredPractices || []);
    if (!isSingle) setOpen(true);
  }, [inputValue]);

  const handleClickHelp = (event) => {
    setHelp(event.currentTarget);
  };

  const renderChips = (value, getTagProps) => {
    let prunedValue = value;
    if (value.length > labelCount) {
      prunedValue = value.slice(0, labelCount);
    }
    return (
      <Fragment>
        {prunedValue.map((option, index) => {
          const iProps = getTagProps({ index });
          if (option.isImported || option.disabledEdit) delete iProps.onDelete;
          return (
            <Chip
              variant="outlined"
              label={option.name}
              size="small"
              sx={{
                ...styles.chipRootFull,
                '& .MuiChip-label': styles.chipRootLabel,
                '& .MuiChip-deleteIcon': { color: 'rgba(255, 255, 255, 0.7)' },
              }}
              {...iProps}
            />
          );
        })}
        {
          (value.length > labelCount)
          && (
            <InputAdornment position="end" className={classes.practiceSelectEndAdornment}>
              <Badge badgeContent={`+${value.length - labelCount}`} color="primary" />
            </InputAdornment>
          )
        }
      </Fragment>
    );
  };

  return (
    <React.Fragment>
      {label && (
        <FormLabel style={styles.formControlLabel}>
          {label}
          {helpMessage && (
            <React.Fragment>
              <HelpOutlineIcon
                style={styles.helpOutlineIcon}
                onClick={handleClickHelp}
              />
              <Popover
                open={Boolean(help)}
                anchorEl={help}
                onClick={() => { setHelp(null); }}
              >
                <Typography style={styles.helpContents} sx={{ p: 2 }}>{helpMessage}</Typography>
              </Popover>
            </React.Fragment>
          )}
        </FormLabel>
      )}
      <Autocomplete
        id="practices-autocomplete"
        classes={classes}
        sx={{
          '& .MuiAutocomplete-tag': {
            maxWidth: '100px',
          },
        }}
        open={open}
        onOpen={() => {
          setOpen(inputValue.length > lettersTypedToShowOptions);
        }}
        multiple={!isSingle}
        onClose={() => {
          setOpen(false);
        }}
        getOptionLabel={option => (option.name ? option.name : '')}
        filterOptions={filterOptions}
        inputValue={inputValue}
        value={value}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onChange={(event, newValue, reason, details) => {
          if (disableImportedItem && details.option && details.option.isImported) {
            if (reason !== 'remove-option') {
              let result = newValue;
              if (!isEmpty(fixedOptions)) {
                result = !isEmpty(newValue) ? uniqBy([...fixedOptions, ...newValue], 'id') : uniqBy([...fixedOptions], 'id');
              }
              setValue(result);
            }
          } else {
            let result = newValue;
            if (!isEmpty(fixedOptions) && !isEmpty(newValue)) {
              result = uniqBy([...fixedOptions, ...newValue], 'id');
            }
            setValue(result);
          }
        }}
        noOptionsText={noOptionsText ? noOptionsText : 'All Practices'}
        options={options}
        popupIcon={null}
        renderInput={params => (
          <TextField
            {...params}
            id="select-multiple-checkbox"
            variant="outlined"
            placeholder={isEmpty(value) ? title : ''}
            onFocus={e => e.target.placeholder = 'Type letters'}
            onBlur={e => e.target.placeholder = isEmpty(value) ? title : ''}
            autoFocus={autoFocus}
          />
        )}
        renderOption={(props, option, { selected }) => {
          const isSeleted = fastpassData.find(x => x.practice_id === option.id);
          return (
            <ListItem {...props} key={option.id} sx={autocompleteOptionStyle}>
              <Checkbox checked={!!isSeleted || selected || ((option.key || option.name) == value)} disabled={isSeleted} key={`${option.id}_checkbox`} />
                {' '}
                {option.key || option.name || ''}
            </ListItem>
          );
        }}
        renderTags={(value, getTagProps) => (isSingle ? (<Typography>{value}</Typography>) : renderChips(value, getTagProps))}
        getOptionDisabled={(option) => {
          const isSeleted = fastpassData.find(x => x.practice_id === option.id);
          return !!isSeleted || (disableImportedItem && option.isImported);
        }}
        disableClearable={disableImportedItem && disableClearable}
        ListboxProps={{ style: { maxHeight: '500px' } }}
        disabled={disabled}
      />
    </React.Fragment>
  );
}
