import React, { Component } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';

class DateFormat extends Component {
  render() {
    const {
      str, date, format, prepend, moment,
    } = this.props;
    if (!date && !str) {
      return '';
    }
    const dateUtils = new DateFnsUtils();
    const prep = prepend ? `${prepend} ` : '';
    const dateFormat = format || 'MMM, do, yyyy';
    const actualDate = date || dateUtils.date(str);
    if (moment) {
      const momentUtils = new MomentUtils();
      return `${prep}${momentUtils.moment(actualDate).fromNow()}`;
    }
    return `${prep}${dateUtils.format(actualDate, dateFormat)}`;
  }
}

export default DateFormat;
