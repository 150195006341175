import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Paper, Grid, Typography, Button, Card, CardContent,
  FormControlLabel, Checkbox,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddBoxIcon from '@mui/icons-material/AddBox';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

import { DataGrid } from '@mui/x-data-grid';

import { isEmpty, sortBy } from 'lodash';

import { green } from '../../css/style';
import { UserViewStyles } from './styles/UserViewStyles';
import {
  BASE_URLS,
  TALENT_ROLES_CONFIG,
  TALENT_PROFILE_ROLE_MAPPING,
} from '../../data/Constants';
import { badgeColor } from '../../utils/Functions';
import PrivateUsersForm from './PrivateUsersForm';
import AddFastpassPracticeDialog from '../subcomponents/AddFastpassPracticeDialog';
import RemoveFastpassUserBadgeDialog from '../subcomponents/RemoveFastpassUserBadgeDialog';

export class BadgeUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePractices: [],
      fastpassData: [],
      loaded: false,
      openAddPracticeDialog: false,
      deletedRowIds: [],
      user: {},
      selectedBadges: {},
      openWarningPopUp: false,
      showBadgeWarning: false,
      userBadges: [],
    };
  }

  componentDidMount = async () => {
    const {
      actions,
      match,
      apiToken,
      resources,
      badges,
    } = this.props;
    const { adminPractices } = resources;
    const { userId } = match && match.params;

    actions.getAllResources(apiToken, `badges/user/${userId}/badges`).then((resp) => {
      const user = resp?.response || {};
      this.setState({
        user,
        selectedBadges: user?.badges?.reduce((o, i) => {
          o[i.id] = true;
          return o;
        }, {}),
      });
    });
    actions.getAllResources(apiToken, `userbadges/user/${userId}`).then((resp) => {
      this.setState({ userBadges: resp?.response || [] });
    });

    isEmpty(badges) && actions.getAllResources(apiToken, 'badges', 'all'),
    actions.getAllResources(apiToken, `talents/user/${userId}/talents`);
    actions.getAllResources(apiToken, `talentprofileitems/user/${userId}/talentprofileitems`);
    actions.getAllResources(apiToken, 'criterias');
    if (!adminPractices) {
      actions.getAllResources(apiToken, BASE_URLS.getPractices).then((resp) => {
        this.setState({
          activePractices: (resp?.response || []).filter(p => !p.deletedAt && p.active),
        }, () => this.retrieveFastpassData());
      });
    } else {
      this.setState({
        activePractices: adminPractices.filter(p => !p.deletedAt && p.active),
      }, () => this.retrieveFastpassData());
    }
  };

  /**
   * This is used to traverse the resources object
   * A user's related assocations are stored retrieved differently
   */
  getResourceValue = (key, appendString) => {
    const {
      match,
      resources,
    } = this.props;
    const { userId } = match.params;
    if (appendString) {
      return resources && resources[`${key}/${appendString}/${userId}/${key}`];
    } else {
      return resources && resources[`${key}/${userId}`];
    }
  };

  badgeSelected(badge) {
    const { id, config } = badge;
    const allowFastpass = config?.allowFastpass;
    const { selectedBadges } = this.state;

    let newSelectedBadges = { ...selectedBadges };
    newSelectedBadges[id] = !newSelectedBadges[id];
    const newBadgeIds = Object.keys(newSelectedBadges).filter(x => newSelectedBadges[x]);
    this.setState({ showBadgeWarning: newBadgeIds.length < 1 });
    if (newBadgeIds.length < 1) return;

    // Unchecked a fastpass badge
    if (allowFastpass && selectedBadges[id]) {
      this.setState({ openWarningPopUp: true, fastpassBadge: badge });
    } else {
      this.saveBadgeSelected(id);
    }
  }

  saveBadgeSelected(id, unselectFastpassBadge) {
    const { actions, apiToken } = this.props;
    const { selectedBadges, user, fastpassData } = this.state;

    selectedBadges[id] = !selectedBadges[id];
    this.setState({ selectedBadges }, () => {
      const badgeIds = selectedBadges ? Object.keys(selectedBadges).filter(x => selectedBadges[x]) : [];
      actions.updateResource(apiToken, { badges: badgeIds }, 'userbadges', user.id).then((resp) => {
        // Reload userbadges to get the latest createdAt value
        actions.getAllResources(apiToken, `userbadges/user/${user.id}`).then((getResp) => {
          this.setState({ userBadges: getResp?.response || [] });
        });

        // Remove all related fastpass
        if (unselectFastpassBadge && !isEmpty(fastpassData)) {
          Promise.all(fastpassData.map(fastpass => fastpass.fastpass_badge_id === id && actions.deleteFastpass(apiToken, 'fastpass', fastpass.id))).then(() => {
            this.setState({ fastpassData: fastpassData.filter(fp => fp.fastpass_badge_id !== id) });
          });
        }
      });
    });
  }

  getBadge(badge) {
    const { title, id } = badge;
    const allowFastpass = badge.config?.allowFastpass;
    const { theme } = this.props;
    const { badgeFilter } = UserViewStyles(theme);

    return (
      <Button
        sx={badgeFilter}
        onClick={() => this.badgeSelected(badge)}
        key={title}
        style={{
          backgroundColor: badgeColor(id || title),
          marginTop: 0,
        }}
      >
        {allowFastpass ? `${title} *` : title}
      </Button>
    );
  }

  getWorkerProfile() {
    const { classes, resources, theme } = this.props;
    const { user } = this.state;
    const { userInfoTitle } = UserViewStyles(theme);

    const talentProfileItems = this.getResourceValue('talentprofileitems', 'user');
    const talent = this.getResourceValue('talents', 'user');
    let criteriaList = resources && resources.criterias;

    if (!user || !talentProfileItems || !talent || !criteriaList) {
      return '';
    }

    const items = talentProfileItems?.reduce((g, i) => {
      const criteriaName = (criteriaList?.find(criteria => criteria.id === i.criteria_id) || {}).name;
      if (Object.keys(TALENT_PROFILE_ROLE_MAPPING).includes(criteriaName) || criteriaName === 'extra-roles') {
        g[criteriaName] = g[criteriaName] || [];
        let val = i.strValue;
        if (i.str2Value && i.str2Value !== i.strValue) {
          val = `${i.str2Value} (${i.strValue})`;
        }
        if (criteriaName === 'extra-roles') {
          val = PrivateUsersForm.setExtraRoles(i);
        }
        g[criteriaName].push(val);
      }
      return g;
    }, {});

    const subTypeKey = TALENT_ROLES_CONFIG[talent.type]?.dataSubKey;
    const subType = items[subTypeKey] && items[subTypeKey][0];
    const subRole = TALENT_PROFILE_ROLE_MAPPING[subTypeKey]?.items?.find(i => i.key === subType || subType?.includes(i.key));

    let talentExperience = [{
      key: 'Name',
      value: user.name,
    },
    {
      key: 'Email',
      value: user.email || 'No Email Provided',
    }, {
      key: 'Role',
      value: subTypeKey === 'speciality' ? `Specialist (${subRole?.title})` : subRole?.title,
    }];
    if (!isEmpty(items['extra-roles'])) {
      const extraRoleItems = items['extra-roles'][0]?.map((item) => {
        const subTypeObj = TALENT_ROLES_CONFIG[item.talentType];
        if (subTypeObj) {
          const subRole = TALENT_PROFILE_ROLE_MAPPING[subTypeObj.dataSubKey]?.items?.find(i => (i.key === item[subTypeObj.subKey] || item[subTypeObj.subKey]?.includes(i.key)));
          return item.talentType === 'specialist-veterinarian' ? `Specialist (${subRole?.title})` : subRole?.title;
        }
      });
      talentExperience = talentExperience.concat([{
        key: 'Secondary role(s)',
        value: !isEmpty(extraRoleItems) ? extraRoleItems.join(', ') : '-',
      }]);
    }

    return (
      <div style={{ marginBottom: '24px' }}>
        <Typography color="secondary" sx={userInfoTitle}>
          Worker Profile
        </Typography>
        <Card className={classes.card}>
          <CardContent style={{ border: '4px solid transparent', backgroundColor: '#f9f9f9' }}>
            {talentExperience?.map(exp => (
              <Grid container className={classes.badgeWorkerItem}>
                <Grid item xs={3} className={classes.badgeWorkerItemTitle} key={`${exp.key}_label`}>
                  {`${exp.key}: `}
                </Grid>
                <Grid item xs={9} key={`${exp.key}_value`}>
                  {exp.value}
                </Grid>
              </Grid>
            ))}
          </CardContent>
        </Card>
      </div>
    );
  }

  getBadges() {
    const { classes, badges, theme } = this.props;
    const {
      user,
      selectedBadges,
      showBadgeWarning,
      userBadges,
    } = this.state;
    const sortedUserBadges = sortBy(userBadges, 'createdAt');
    const {
      userInfoTitle,
      checkBox,
      badgeWarning,
      badgeInfo,
    } = UserViewStyles(theme);

    return (
      <div style={{ marginTop: '24px' }}>
        <Typography color="secondary" sx={userInfoTitle}>
          Badges
        </Typography>
        {!isEmpty(badges)
          && badges.map(badge => (
            <FormControlLabel
              key={badge.id}
              control={(
                <Checkbox
                  checked={selectedBadges[badge.id] || false}
                  onChange={() => this.badgeSelected(badge)}
                  sx={checkBox}
                  value={badge.title}
                />
              )}
              label={this.getBadge(badge)}
            />
          ))}

        <Grid
          container
          justifyContent="space-between"
          style={{ margin: '20px 0' }}
        >
          {showBadgeWarning && (
            <Grid item xs={12} className={classes.badgeInfoItem}>
              <Typography sx={badgeWarning}>
                At least one badge should be selected.
              </Typography>
            </Grid>
          )}
          {!isEmpty(sortedUserBadges) && (
            <Grid item xs={12} className={classes.badgeInfoItem}>
              <Typography
                sx={badgeInfo}
              >
                {`First badged on ${moment(sortedUserBadges[0].createdAt).format('MM/DD/YYYY')}`}
              </Typography>
            </Grid>
          )}
          {!isEmpty(sortedUserBadges) && (
            <Grid item xs={12} className={classes.badgeInfoItem}>
              <Typography
                sx={badgeInfo}
              >
                {`Last badged on ${moment(sortedUserBadges[sortedUserBadges.length - 1].createdAt).format('MM/DD/YYYY')}`}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} className={classes.badgeInfoItem}>
            {badges.find(b => b.config?.allowFastpass) && (
              <Typography
                sx={badgeInfo}
              >
                (*): Allow Fastpass
              </Typography>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }

  retrieveFastpassData = async () => {
    const {
      actions,
      apiToken,
      match,
    } = this.props;
    const { userId } = match && match.params;
    const { activePractices, deletedRowIds } = this.state;

    const fastpassRes = await actions.getAllResources(apiToken, `fastpass/user/${userId}`);
    const fastpassData = fastpassRes?.response;
    if (fastpassData) {
      let resultList = fastpassData.filter(r => !r.deletedAt || (r.deletedAt && deletedRowIds.includes(r.id)));
      resultList = sortBy(resultList, 'createdAt').reverse();

      this.setState({
        fastpassData: resultList,
      });
      // Filter out the practices already assigned to fastpass
      // const fpPracticeIds = resultList?.map(fp => fp.practice_id);
      this.setState({ activePractices });
    }
    this.setState({ loaded: true });
  };

  renderFastpassPractices() {
    const {
      classes,
      actions,
      apiToken,
      badges,
      theme,
    } = this.props;
    const {
      fastpassData,
      loaded,
      openAddPracticeDialog,
      activePractices,
      deletedRowIds,
      selectedBadges,
    } = this.state;
    const talent = this.getResourceValue('talents', 'user');
    const user = this.getResourceValue('users');
    const selectedBadgeIds = selectedBadges ? Object.keys(selectedBadges).filter(x => selectedBadges[x]) : [];
    const fastpassBadge = badges?.find(b => b.config?.allowFastpass);
    const fastpassBadgeId = selectedBadgeIds?.find(id => Number(id) === Number(fastpassBadge?.id));
    const {
      userInfoTitle,
      reportButton,
    } = UserViewStyles(theme);

    return (
      <div>
        <div className={classes.titleContainer}>
          <Typography color="secondary" sx={userInfoTitle}>Fastpass Practices</Typography>
          <div className={classes.reportButtonGroup}>
            <Button
              variant="contained"
              disableRipple
              onClick={e => this.setState({ openAddPracticeDialog: true })}
              sx={reportButton}
              startIcon={(<AddBoxIcon />)}
              disabled={(user ? !user.enabled : false) || !fastpassBadgeId}
            >
              Add practice
            </Button>
          </div>
        </div>
        <div className={classes.dataGridContainer}>
          <DataGrid
            autoHeight
            rows={fastpassData || []}
            columns={[
              {
                field: 'practiceName',
                headerName: 'Practice Name',
                flex: 1,
              }, {
                field: 'action',
                headerName: 'Action',
                flex: 0.25,
                type: 'actions',
                renderCell: params => (
                  <strong>
                    {params.row.deletedAt
                      ? (
                        <Button
                          onClick={() => this.handleActionClick({ action: 'add', id: params.row.id, practiceId: params.row.practice_id })}
                          title='Restore the row'
                        >
                          <AddCircleIcon
                            style={{ color: green }}
                            size="small"
                          />
                        </Button>
                      )
                      : (
                        <Button
                          title='Delete the row'
                          onClick={() => {
                            this.setState({ deletedRowIds: [...deletedRowIds, params.row.id] },
                              () => this.handleActionClick({ action: 'remove', id: params.row.id }));
                          }}
                        >
                          <RemoveCircleIcon
                            style={{ color: 'red' }}
                            size="small"
                          />
                        </Button>
                      )
                    }

                  </strong>
                ),
              },
            ]}
            initialState={{
              pagination: { paginationModel: { pageSize: 5 } },
            }}
            getRowClassName={params => (params.row.deletedAt ? classes.disabledRow : '')}
            disableColumnMenu
            isRowSelectable={() => false}
            loading={!loaded}
          />
        </div>
        <AddFastpassPracticeDialog
          open={openAddPracticeDialog}
          apiToken={apiToken}
          handleOnClose={(refreshData) => {
            this.setState({ openAddPracticeDialog: false });
            if (refreshData) {
              this.retrieveFastpassData();
            }
          }}
          actions={actions}
          practices={activePractices}
          talentId={talent?.id}
          fastpassBadgeId={fastpassBadgeId}
          fastpassData={fastpassData}
        />
      </div>
    );
  }

  handleActionClick = ({ action, id, practiceId }) => {
    const { actions, apiToken, match } = this.props;
    const { userId } = match.params;
    const { deletedRowIds, activePractices } = this.state;

    if (action === 'remove') {
      if (!id) return;
      actions.deleteFastpass(apiToken, 'fastpass', id).then((deleteResp) => {
        if (deleteResp?.data) {
          let resultList = deleteResp?.data?.filter(r => !r.deletedAt || (r.deletedAt && deletedRowIds.includes(r.id)));
          resultList = sortBy(resultList, 'createdAt').reverse();
          this.setState({ fastpassData: resultList });
        }
      });
    } else if (action === 'add') {
      if (!userId || !practiceId) return;
      actions.addResource(apiToken, {
        practiceId: Number(practiceId),
        userId,
      }, 'fastpass').then((resp) => {
        if (resp && resp.response) {
          let resultList = resp?.response?.filter(r => !r.deletedAt || (r.deletedAt && deletedRowIds.includes(r.id)));
          resultList = sortBy(resultList, 'createdAt').reverse();
          this.setState({ fastpassData: resultList });
        }
      });
    }
  };

  render() {
    const {
      classes,
      history,
      match,
      apiToken,
      actions,
      theme,
    } = this.props;
    const { userId } = match && match.params;
    const {
      badgedUserTitle,
      formBtn,
      backButton,
    } = UserViewStyles(theme);

    return (
      <div className={classes.usersViewContainer}>
        <Paper className={classes.paper} style={{ minHeight: '808px' }}>
          <div className={classes.badgedUserBanner}>
            <Grid container justifyContent="space-between">
              <Grid item xs={9} container alignItems="center">
                <VerifiedUserIcon className={classes.icon} />
                <Typography sx={badgedUserTitle}>
                  BADGED WORKER PROFILE
                </Typography>
              </Grid>
              <Grid item xs={3} container justifyContent="flex-end">
                <Button
                  variant="contained"
                  sx={{ ...formBtn, ...backButton }}
                  onClick={() => history.goBack()}
                >
                  <span style={{ verticalAlign: 'super' }}>BACK</span>
                </Button>
              </Grid>
            </Grid>
          </div>
          <Grid container className={classes.body}>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12} md={8}>
                <Grid item xs={12} className={classes.bodyItem}>
                  <div className={classes.newConnectionDetailsLeft}>
                    {this.getWorkerProfile()}
                    {this.getBadges()}
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4} className={classes.bodyItem}>
                {this.renderFastpassPractices()}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <RemoveFastpassUserBadgeDialog
          open={this.state.openWarningPopUp}
          handleOnClick={(isClosed) => {
            this.setState({ openWarningPopUp: false });
            if (!isClosed) {
              this.saveBadgeSelected(this.state.fastpassBadge?.id, true);
            }
          }}
          badge={this.state.fastpassBadge || {}}
        />
      </div>
    );
  }
}

BadgeUser.propTypes = {
  loading: PropTypes.bool.isRequired,
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  apiToken: PropTypes.string.isRequired,
};

export default withStyles(UserViewStyles, { withTheme: true })(withRouter(BadgeUser));
