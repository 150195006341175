import React, { Component, Fragment } from 'react';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';

const orderDetailBoxStyles = theme => ({
  boxContainer: {
    padding: '20px',
    margin: '20px 20px 20px 0',
    border: '2px solid black',
    height: '90%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'auto',
  },
  itemTitleAndPriceContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  customizationText: {
    color: '#8b8b8b',
    fontStyle: 'italic',
  },
  subtitleText: {
    color: '#8b8b8b',
  },
});

const OrderDetailBox = (props) => {
  const {
    fieldData, tax, tips, totalPrice, classes, theme,
  } = props;
  const stylesWithTheme = orderDetailBoxStyles(theme);

  return (
    <div className={classes.boxContainer}>
      <div>
        {
          fieldData && fieldData.map(item => (
            <Fragment key={item.id}>
              <div className={classes.itemTitleAndPriceContainer}>
                <Typography variant="h6">{`${item.quantity} x ${item.productItem.name}`}</Typography>
                <Typography variant="h6">{`$${item.quantity * item.price}`}</Typography>
              </div>
              {
                item.options.length > 0
                  && item.options.map(option => (
                      <Typography key={option.id} variant="h6" sx={stylesWithTheme.customizationText}>
                        {
                          option.name.toLowerCase().startsWith('no')
                            ? `${option.name}`
                            : `${option.quantity} x ${option.name}`
                        }
                      </Typography>
                  ))
              }
            </Fragment>
          ))
        }
      </div>
      <div>
        {
          <div className={classes.itemTitleAndPriceContainer}>
            <Typography variant="h6" sx={stylesWithTheme.subtitleText}>Tax</Typography>
            <Typography variant="h6" sx={stylesWithTheme.subtitleText}>{`$${tax}`}</Typography>
          </div>
        }
        {
          <div className={classes.itemTitleAndPriceContainer}>
            <Typography variant="h6" sx={stylesWithTheme.subtitleText}>Tips</Typography>
            <Typography variant="h6" sx={stylesWithTheme.subtitleText}>{`$${tips}`}</Typography>
          </div>
        }
        {
          <div className={classes.itemTitleAndPriceContainer}>
            <Typography variant="h5">Total</Typography>
            <Typography variant="h5">{`$${totalPrice}`}</Typography>
          </div>
        }
      </div>
    </div>
  );
};

export default withStyles(orderDetailBoxStyles, { withTheme: true })(OrderDetailBox);
