import React from 'react';
import {
  Button, Typography, TextField, Icon, Dialog, DialogContent, IconButton,
} from '@mui/material';
import PropTypes from 'prop-types';
import { IMG_STORAGE_LINK } from '../data/Constants';
import { passwordDialogStyles } from '../css/dialogStyles';

const styles = passwordDialogStyles();

const textFieldInputs = [
  { id: 'recoveryToken1', autoFocus: true, position: 1 },
  { id: 'recoveryToken2', autoFocus: false, position: 2 },
  { id: 'recoveryToken3', autoFocus: false, position: 3 },
  { id: 'recoveryToken4', autoFocus: false, position: 4 },
  { id: 'recoveryToken5', autoFocus: false, position: 5 },
];

const RecoveryTokenDialog = (props) => {
  const {
    dialogState,
    onClose,
    onClickNext,
    enteredEmail,
    onTextFieldChange,
    tokenInputRef,
    errorText,
  } = props;
  return (
    <Dialog
      maxWidth="xl"
      open={dialogState}
      onClose={() => onClose()}
    >
      <DialogContent style={{ ...styles.dialogContainer, height: '520px' }}>
        <IconButton
          style={styles.closeButtonStyle}
          onClick={onClose}
        >
          <Icon>close</Icon>
        </IconButton>
        <img src={`${IMG_STORAGE_LINK}password.svg`} alt="password" style={styles.topImage} />
        <Typography style={styles.dialogTitle}>
          5-DIGIT CODE
        </Typography>
        <Typography style={styles.dialogDescription}>
            A verification code was sent to
          <span style={{ color: 'red' }}>{` ${enteredEmail}`}</span>
            . To reset your password enter it below. If you didn’t receive an email,
            please check your Spam folder.
        </Typography>
        <div style={styles.tokenBoxContainer}>
          {
            textFieldInputs.map(fieldInput => (
              <TextField
                key={fieldInput.id}
                autoFocus={fieldInput.autoFocus}
                style={styles.tokenBox}
                inputProps={{
                  maxLength: 1,
                  style: { margin: '5px 5px 5px 20px' },
                }}
                InputProps={{ disableUnderline: true }}
                inputRef={input => tokenInputRef(fieldInput, input)}
                id={fieldInput.id}
                value={props[fieldInput.id]}
                onChange={e => onTextFieldChange(e, fieldInput.id)}
              />
            ))
          }
        </div>
        <Button
          style={styles.bottomButton}
          onClick={() => onClickNext()}
        >
          NEXT
        </Button>
        <Typography style={styles.errorTextStyle}>{errorText}</Typography>
      </DialogContent>
    </Dialog>
  );
};

RecoveryTokenDialog.propTypes = {
  dialogState: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onClickNext: PropTypes.func.isRequired,
  enteredEmail: PropTypes.string.isRequired,
  onTextFieldChange: PropTypes.func.isRequired,
  tokenInputRef: PropTypes.func.isRequired,
};

export default RecoveryTokenDialog;
