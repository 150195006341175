import React from 'react';
import PropTypes from 'prop-types';
import {
  IconButton, Snackbar,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


class ErrorBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { errors: this.props.errors };
  }

  componentDidMount() {
    this.setState({ errors: this.props.errors });
  }

  render() {
    const { errors, actions } = this.props;
    return (
      !errors
        ? <div />
        : (
          <Snackbar
            autoHideDuration={4000}
            open={errors !== null}
            variant="warning"
            onClose={() => actions.clearErrors()}
            message={<span>{errors.toString()}</span>}
            action={[
                  <IconButton
                    key="close"
                    aria-label="Close"
                    color='inherit'
                    onClick={() => actions.clearErrors()}
                  >
                    <CloseIcon />
                  </IconButton>,
            ]}
          />
        )
    );
  }
}

ErrorBar.propTypes = {
  errors: PropTypes.string,
};

ErrorBar.defaultProps = {
  errors: null,
};

export default ErrorBar;
