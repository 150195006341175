import {
  navy,
  tiffany,
  orange,
  hoverEffectOne,
  hoverEffectTwo,
} from '../../../css/style';

export const messagePageStyle = theme => ({
  helpOutlineIcon: {
    marginTop: '10px',
    height: '18px',
    color: 'white',
  },
  helpPopoverText: {
    whiteSpace: 'pre-line',
  },
  helpPopover: {
    maxWidth: '400px',
    padding: '10px',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '30px 1.5% 0 40px',
    [theme && theme.breakpoints.down('sm')]: {
      margin: '55px 20px 0 25px',
    },
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme && theme.breakpoints.down('sm')]: {
      marginTop: '-25px',
    },
  },
  pageTitle: {
    color: '#ffffff',
    fontSize: '30px',
    fontWeight: 'bold',
    [theme && theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  messagesContainer: {
    width: '65%',
    margin: '20px 20px 20px 40px',
    display: 'flex',
    flexDirection: 'column',
    [theme && theme.breakpoints.down('md')]: {
      width: '90%',
      margin: '16px auto',
      height: '75vh',
    },
  },
  pingButton: {
    cursor: 'pointer',
    width: '186px',
    height: '40px',
    borderRadius: '5px',
    backgroundColor: `${orange}`,
    border: 'unset',
    fontFamily: 'Asap',
    fontSize: '12px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#ffffff',
    ...hoverEffectOne,
    [theme && theme.breakpoints.down('sm')]: {
      width: '100px',
    },
    '&:disabled': {
      color: 'gray',
      backgroundColor: '#f0f0f0',
    },
  },
  twoPapersContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '88vh',
    [theme && theme.breakpoints.down('md')]: {
      height: 'auto',
    },
  },
  connectionPreviewContainer: {
    width: '30%',
    margin: '20px 20px 20px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  userIconContainer: {
    height: 135,
    width: 135,
    minHeight: 135,
    minWidth: 135,
    backgroundColor: `${tiffany}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    marginTop: '20px',
  },
  userName: {
    fontSize: 30,
    fontWeight: 700,
    color: `${navy}`,
    margin: '0 25px',
    textAlign: 'center',
    wordWrap: 'break-word',
    wordBreak: 'break-all',
    hyphens: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflowWrap: 'break-word',
  },
  subtitle: {
    color: `${navy}`,
    fontSize: 20,
    fontStyle: 'italic',
    textAlign: 'center',
  },
  divider: {
    width: '90%',
  },
  fullDetailContainer: {
    display: 'flex',
    width: '85%',
    justifyContent: 'flex-end',
    '&:hover': {
      cursor: 'pointer',
    },
    [theme && theme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'center',
    },
  },
  fullDetailText: {
    fontSize: '16px',
    color: `${orange}`,
    fontWeight: 'bold',
  },
  fullDetailIcon: {
    color: `${orange}`,
    marginLeft: '5px',
  },
  newExperienceDetails: {
    width: '80%',
    backgroundColor: '#f7f7f7',
    borderRadius: 5,
    padding: '10px',
    paddingTop: '30px',
    overflow: 'hidden',
  },
  experienceItem: {
    display: 'flex',
    marginBottom: '20px',
    fontFamily: 'Asap',
  },
  newExperienceItemTitle: {
    width: '40%',
    textAlign: 'right',
    fontWeight: 'bold',
    fontStyle: 'italic',
    color: '#878787',
    paddingRight: '10px',
  },
  newExperienceItemValue: {
    width: '60%',
    textTransform: 'capitalize',
  },
  userDialog: {
    width: '300px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    padding: '20px 30px 20px 30px',
  },
  dialogClose: {
    width: '100%',
    textAlign: 'right',
  },
  userDialogTitle: {
    fontSize: 20,
    textAlign: 'center',
    color: `${tiffany}`,
    fontWeight: 'bold',
  },
  deleteIcon: {
    fontSize: '18px',
    color: `${tiffany}`,
    border: `3px solid ${tiffany}`,
    borderRadius: '50%',
    display: 'flex',
    width: 50,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '10px',
  },
  deleteUserBtn: {
    fontSize: '12px',
    fontWeight: 'bold',
    margin: '20px',
    height: '40px',
    backgroundColor: `${navy}`,
    ...hoverEffectTwo,
  },
});
