import React, {
  useRef, useCallback, useState, useEffect,
} from 'react';
import { withRouter } from 'react-router';
import {
  omit,
  isEmpty,
  orderBy,
  uniqBy,
} from 'lodash';
import moment from 'moment';
import {
  FormControlLabel,
  Switch,
  Badge,
  Button,
  ButtonBase,
  Typography,
  FormLabel,
  TextField,
  Checkbox,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { navy } from '../../css/style';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import WeekHeader from './WeekHeader';
import Month from './Month';
import {
  addMonths,
  addDays,
  formatISODateOnly,
  defaultUtils as utils,
} from './dateUtils';
import CalendarToolbar from './CalendarToolbar';
import CalendarButtons from './CalendarButtons';
import MultipleNeedsDialog from '../subcomponents/MultipleNeedsDialog';
import GreenSwitch from '../subcomponents/GreenSwitch';
import DateUtilities from './utils';
import { IMG_STORAGE_LINK } from '../../data/Constants';

const useStyles = makeStyles(theme => ({
  root: {
    flex: '1',
    display: 'flex',
    maxHeight: '100%',
    overflow: 'hidden',
  },
  selectorContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    [theme && theme?.breakpoints?.up('md')]: {
      minWidth: '430px',
    },
  },
  calendarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: '0 16px',
    [theme && theme?.breakpoints?.only('xs')]: {
      padding: '0 8px',
      width: '90%',
    },
  },
  formControlLabelRoot: {
    margin: '10px 25px',
  },
  attributeContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 16px',

    '& .MuiOutlinedInput-root': {
      height: '28px',
    },

    [theme && theme?.breakpoints?.only('xs')]: {
      padding: '0 8px',
      width: '90%',
    },
  },
  boostLink: {
    color: navy,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '19.2px',
    display: 'flex',
    alignItems: 'center',
  },
  // from dialog
  dialogContent: {
    padding: '8px 25px',
  },
  publicBadgeLabel: {
    textAlign: 'right',
    display: 'block',
    paddingRight: '60px',
  },
  formRow: {
    marginTop: '10px',
    display: 'flex',
  },
  boostContainer: {
    maxWidth: '404px',
    margin: '16px',
    display: 'flex',
    flexWrap: 'wrap',

    '& .MuiTypography-root': {
      fontSize: '14px',
    },
  },
  boostItem: {
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 8px',

    '& .MuiButtonBase-root': {
      padding: '0 8px',
    },
  },
  error: {
    color: 'red',
    paddingLeft: '16px',
    display: 'flex',
    alignItems: 'center',
    fontStyle: 'italic',
    fontSize: '12px',
  },
}));

const Calendar = ({
  initialDate,
  maxDate,
  minDate,
  selectedDates,
  notAvailableDates,
  pendingDates,
  initPendingDates,
  multipleNeeds,
  onSelect,
  selectAllDates,
  onCancel,
  onOk,
  readOnly,
  showAllButton,
  onRemoveAtIndex,
  onRemoveAll,
  cancelButtonText,
  submitButtonText,
  allButtonText,
  resetButtonText,
  selectedDatesTitle,
  disabledRange,
  coronadoErDayColor,
  lifeCycleBadgeTitle,
  referenceOnly,
  outerBoostData = [],
  isReliefPost,
  jobPostingParentId,
}) => {
  const calendar = useRef(null);
  const classes = useStyles();
  let initialDate_ = new Date();
  let disabledToDate;

  if (disabledRange) {
    disabledToDate = new Date((initialDate_).setDate(initialDate_.getDate() + disabledRange)); // today + 60 days
    initialDate_ = disabledToDate;
  }

  initialDate_.setHours(12, 0, 0, 0);
  const [displayDate, setDisplayDate] = useState(() => utils.getFirstDayOfMonth(initialDate_));
  const [enabledNeedsClick, setEnabledNeedsClick] = useState(false);
  const [openMultipleNeedsDialog, setOpenMultipleNeedsDialog] = useState(false);
  const [multiNeedsData, setMultiNeedsData] = useState(() => (multipleNeeds));
  const [needsOnSelectedDay, setNeedsOnSelectedDay] = useState({});
  const [boostData, setBoostData] = useState([...outerBoostData] || []);
  const [surgeryNeededDates, setSurgeryNeededDates] = useState(() => (outerBoostData ? outerBoostData.filter(r => r.surgeryNeeded).map(r => r.dateText) : []));
  const [boostDates, setBoostDates] = useState(() => (outerBoostData ? outerBoostData.filter(r => r.isBoosted).map(r => r.dateText) : []));
  const [boostDetailsInvalid, setBoostDetailsInvalid] = useState(false);

  const onSelectAll = () => {
    const lastDate = addMonths(displayDate, 1);
    const dates = [];
    let d = displayDate;
    while (d < lastDate) {
      dates.push(d);
      d = addDays(d, 1);
    }
    selectAllDates(dates);
  };
  const handleMonthChange = useCallback(
    (months) => {
      setDisplayDate(displayDate => utils.addMonths(displayDate, months));
    },
    [setDisplayDate],
  );

  const onMultiNeedsClick = useCallback(
    (data) => {
      setOpenMultipleNeedsDialog(true);
      setNeedsOnSelectedDay(data);
    },
    [setOpenMultipleNeedsDialog, setMultiNeedsData],
  );

  const handleBoostChange = (item, value, field) => {
    const copiedBoostData = [...boostData];
    let editedRow = copiedBoostData.find(r => (r.id && r.id === item.id) || (!r.id && r.dateText === item.dateText));
    if (editedRow) {
      editedRow[field] = value;
      setBoostData(copiedBoostData);

      if (field === 'surgeryNeeded' || field === 'isBoosted') {
        const dateIndex = field === 'surgeryNeeded' ? surgeryNeededDates.indexOf(editedRow.dateText) : boostDates.indexOf(editedRow.dateText);
        if (dateIndex > -1 && !editedRow[field]) { // remove the date from the array
          if (field === 'surgeryNeeded') {
            setSurgeryNeededDates(surgeryNeededDates.filter((_, index) => index !== dateIndex));
          } else if (field === 'isBoosted') {
            setBoostDates(boostDates.filter((_, index) => index !== dateIndex));
          }
        }
        if (dateIndex === -1 && editedRow[field]) {
          if (field === 'surgeryNeeded') {
            setSurgeryNeededDates(surgeryNeededDates.concat(editedRow.dateText));
          } else if (field === 'isBoosted') {
            setBoostDates(boostDates.concat(editedRow.dateText));
          }
        }
      }
    }
  };

  const handleCallback = (needsObj) => {
    setOpenMultipleNeedsDialog(false);
    setNeedsOnSelectedDay(needsObj);
    let dataObj = { ...multiNeedsData };
    if (needsObj.available + needsObj.taken + needsObj.pending > 1) {
      dataObj[needsObj.dayKey] = {
        available: needsObj.available,
        taken: needsObj.taken,
        pending: needsObj.pending,
        date: needsObj.selectedDate,
        pendingToAvai: needsObj.pendingToAvai,
        pendingToTaken: needsObj.pendingToTaken,
      };
    } else {
      dataObj = omit(dataObj, [needsObj.dayKey]);
    }
    setMultiNeedsData(dataObj);
    onSelect(needsObj.selectedDate, needsObj);
  };

  maxDate = maxDate || utils.addYears(new Date(), 100);
  minDate = minDate || utils.addYears(new Date(), -100);

  const toolbarInteractions = {
    prevMonth: utils.monthDiff(displayDate, minDate) > 0,
    nextMonth: utils.monthDiff(displayDate, maxDate) < 0,
  };

  useEffect(
    () => {
      setMultiNeedsData(multipleNeeds);
    },
    [multipleNeeds],
  );

  useEffect(
    () => {
      let copiedBoostData = [...boostData];
      const boostDataDates = !isEmpty(copiedBoostData) ? copiedBoostData.map(r => r.date) : [];

      let singleBoostData = [...boostData];
      if (!isEmpty(selectedDates)) {
        selectedDates.forEach((date) => {
          if (!DateUtilities.dateIn(boostDataDates, date)) {
            singleBoostData.push({
              date,
              dateText: formatISODateOnly(date),
              status: 'available',
              isBoosted: false,
              boostAmount: 0,
              surgeryNeeded: false,
            });
          }
        });
      }

      let multiBoostData = [...boostData];
      !isEmpty(multiNeedsData) && Object.keys(multiNeedsData).forEach((dateKey) => {
        if (multiNeedsData[dateKey]?.available > 0) {
          if (!DateUtilities.dateIn(boostDataDates, DateUtilities.midDayDate(dateKey))) {
            multiBoostData.push({
              date: DateUtilities.midDayDate(dateKey),
              dateText: dateKey,
              status: 'available',
              isBoosted: false,
              boostAmount: 0,
              surgeryNeeded: false,
            });
          }
        }
      });

      copiedBoostData = uniqBy([...singleBoostData, ...multiBoostData], 'dateText');

      // remove boostData dates that are not in selectedDates &&  multiNeedsData
      if (!isEmpty(copiedBoostData)) {
        const multiAvaiKeys = Object.keys(multiNeedsData) ? Object.keys(multiNeedsData).filter(k => multiNeedsData[k].available > 0) : [];
        const multiAvaiDates = multiAvaiKeys ? multiAvaiKeys.map(dateKey => DateUtilities.midDayDate(dateKey)) : [];
        const avaiDates = [...selectedDates, ...multiAvaiDates];
        copiedBoostData = avaiDates ? [...copiedBoostData].filter(r => DateUtilities.dateIn(avaiDates, r.date)) : [];
      }
      copiedBoostData = orderBy(copiedBoostData, ['date'], ['asc']);
      setBoostData(copiedBoostData);
      setBoostDates(copiedBoostData ? copiedBoostData.filter(r => r.isBoosted).map(r => r.dateText) : []);
      setSurgeryNeededDates(copiedBoostData ? copiedBoostData.filter(r => r.surgeryNeeded).map(r => r.dateText) : []);
    },
    [multiNeedsData, selectedDates],
  );

  const setBoostInvalid = () => {
    const allBoostedValid = !isEmpty(boostData)
      ? boostData.map(r => (r.isBoosted && Number(r.boostAmount) > 0) || !r.isBoosted).reduce((a, b) => a && b, true)
      : true;
    setBoostDetailsInvalid(!allBoostedValid);
  };

  const renderBoostDetails = () => {
    const singleDatesInMonth = !isEmpty(boostData) && displayDate ? boostData.filter(r => DateUtilities.isSameMonth(r.date, displayDate)) : [];
    return !isEmpty(singleDatesInMonth) && (
      <Grid container className={classes.boostContainer}>
        <Grid item xs={3} className={classes.boostItem}>
          <Typography>Dates</Typography>
        </Grid>
        <Grid item xs={2} className={classes.boostItem}>
          <Typography>Boost</Typography>
        </Grid>
        <Grid item xs={3} className={classes.boostItem}>
          <Typography>Amount ($)</Typography>
        </Grid>
        <Grid item xs={4} className={classes.boostItem}>
          <Typography>Surgery Needed</Typography>
        </Grid>
        {!isEmpty(singleDatesInMonth) && singleDatesInMonth.map((item, index) => (
          <>
          <Grid item xs={3} key={`boost-${index}_item`} className={classes.boostItem}>
            <Typography key={`boost-date-${index}`}>{moment(item.date).format('MMM DD')}</Typography>
          </Grid>

          <Grid item xs={2} key={`boost-checkbox-${index}_item`} className={classes.boostItem}>
            <Checkbox
              onChange={(e) => {
                handleBoostChange(item, e?.target?.checked, 'isBoosted');
                setBoostInvalid();
              }}
              checked={item.isBoosted}
              key={`boost-checkbox-${index}`}
            />
          </Grid>
          <Grid item xs={3} key={`boost-amt-${index}_item`} className={classes.boostItem}>
            <TextField
              onChange={(e) => {
                handleBoostChange(item, e?.target?.value, 'boostAmount');
                setBoostInvalid();
              }}
              variant="outlined"
              inputProps={{
                type: 'number',
                min: 0,
                step: 1,
                style: { height: '28px', padding: '0 8px', width: '80px' },
              }}
              autoComplete="off"
              key={`boost-amt-${index}`}
              value={item.boostAmount}
              disabled={!item.isBoosted}
            />
          </Grid>
          <Grid item xs={4} key={`boost-surgery-${index}_item`} className={classes.boostItem}>
            <Checkbox
              onChange={(e) => {
                handleBoostChange(item, e?.target?.checked, 'surgeryNeeded');
              }}
              checked={item.surgeryNeeded}
              key={`surgery-${index}`}
              sx={{
                '& .MuiButtonBase-root': {
                  '& .MuiCheckbox-root': {
                    padding: 0,
                  },
                },
              }}
            />
          </Grid>
          </>
        ))}
      </Grid>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.selectorContainer}>
        <div className={classes.calendarContainer}>
        { coronadoErDayColor && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={() => {}}
              key={lifeCycleBadgeTitle}
              sx={{
                backgroundColor: coronadoErDayColor,
                color: '#fff',
                marginTop: '16px',
                height: '28px',
                fontSize: '14px',
                padding: '0px 8px',
                width: '120px',
                '&:hover': {
                  backgroundColor: coronadoErDayColor,
                },
              }}
            >
              {lifeCycleBadgeTitle}
            </Button>
          </div>
        )}
          <CalendarToolbar
            displayDate={displayDate}
            onMonthChange={handleMonthChange}
            prevMonth={toolbarInteractions.prevMonth}
            nextMonth={toolbarInteractions.nextMonth}
          />
          <WeekHeader />
          <Month
            displayDate={displayDate}
            key={displayDate.toDateString()}
            selectedDates={selectedDates}
            notAvailableDates={notAvailableDates}
            pendingDates={pendingDates}
            initPendingDates={initPendingDates}
            multiNeedsData={multiNeedsData}
            minDate={minDate}
            maxDate={maxDate}
            onSelect={onSelect}
            readOnly={readOnly}
            ref={calendar}
            onMultiNeedsClick={onMultiNeedsClick}
            enabledNeedsClick={enabledNeedsClick}
            disabledToDate={disabledToDate}
            coronadoErDayColor={coronadoErDayColor}
            referenceOnly={referenceOnly}
            surgeryNeededDates={surgeryNeededDates}
            boostDates={boostDates}
          />
        </div>
        <div className={classes.attributeContainer}>
          {!referenceOnly && (
          <FormControlLabel
            control={(
              <GreenSwitch
                color="primary"
                checked={enabledNeedsClick}
                disableRipple
                onChange={e => setEnabledNeedsClick(e.target.checked)}
                sx={{
                  '&.MuiSwitch-root .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
                    backgroundImage: `linear-gradient(to bottom, ${coronadoErDayColor}, ${coronadoErDayColor} 98%)`,
                  },
                }}
              />
            )}
            label='Multiple needs'
            className={classes.formControlLabelRoot}
          />
          )}
        </div>
        { isReliefPost && (!coronadoErDayColor && !jobPostingParentId) && !referenceOnly
          && !isEmpty(displayDate && boostData?.length && boostData.filter(r => DateUtilities.isSameMonth(r.date, displayDate))) && (
          <Accordion sx={{ maxHeight: '350px', overflowY: 'auto' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
            sx={{
              '& .MuiAccordionSummary-content': {
                alignItems: 'center',
              },
            }}
          >
            <>
              <img
                src={`${IMG_STORAGE_LINK}dollar-navy.png`}
                style={{ width: 16, height: 16 }}
                alt='Boost'
                title='Boost'
              />
              <Typography className={classes.boostLink}>
                Boost / Sx Details
              </Typography>
              {boostDetailsInvalid
                && (
                  <div className={classes.error}>
                    <ErrorOutlineIcon fontSize='small' />
                    <span style={{ marginLeft: '5px' }}>Please enter amount for a boosted date.</span>
                  </div>
                )
              }
            </>
          </AccordionSummary>
          <AccordionDetails>
            {renderBoostDetails()}
          </AccordionDetails>
          </Accordion>
        )}
        <CalendarButtons
          readOnly={readOnly}
          showAllButton={showAllButton}
          onCancel={(e) => {
            setBoostData([]);
            onCancel(e);
          }}
          onOk={e => onOk(e, [...boostData])}
          selectedDates={selectedDates}
          initPendingDates={initPendingDates}
          onRemoveAll={() => {
            setBoostData([]);
            setBoostDates([]);
            setSurgeryNeededDates([]);
            setMultiNeedsData({});
            onRemoveAll();
          }}
          onSelectAll={onSelectAll}
          cancelButtonText={cancelButtonText}
          submitButtonText={submitButtonText}
          allButtonText={allButtonText}
          resetButtonText={resetButtonText}
          coronadoErDayColor={coronadoErDayColor}
          referenceOnly={referenceOnly}
          boostDetailsInvalid={boostDetailsInvalid}
        />
      </div>
      <MultipleNeedsDialog
        open={openMultipleNeedsDialog}
        handleCallback={needsObj => handleCallback(needsObj)}
        needsOnSelectedDay={needsOnSelectedDay}
        coronadoErDayColor={coronadoErDayColor}
      />
    </div>
  );
};

export default withRouter(Calendar);
