import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  IconButton,
  Icon,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Close } from '@mui/icons-material';

import { badgeDialogStyles } from '../../css/dialogStyles';


class DeleteUserBadgeDialog extends Component {
  constructor() {
    super();
    this.state = {};
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.open) return null;
    const badges = {};
    nextProps.badges.forEach((x) => {
      badges[x.id] = false;
    });
    nextProps.user && nextProps.user.badges.forEach((x) => {
      badges[x.id] = true;
    });
    return {
      badges,
    };
  }

  handleSave() {
    const {
      handleOnClick, actions, apiToken, user,
    } = this.props;
    const { badges } = this.state;
    const badgIds = Object.keys(badges).filter(x => badges[x]);
    actions.updateResource(apiToken, {
      badges: [],
    }, 'userbadges', user.id).then(() => {
      handleOnClick();
    });
  }

  badgeSelected(id, value) {
    const { badges } = this.state;
    badges[id] = !badges[id];
    this.setState({ badges });
  }

  render() {
    const {
      classes, handleOnClick, open, badges, user, theme,
    } = this.props;
    const badgIds = Object.keys(this.state.badges).filter(x => this.state.badges[x]);
    const showDeleteWarning = !badgIds.length;
    const {
      title, closeButton, button, dialogDescription,
      dialogDescriptionBold, dialogActions, icon, dialogContent,
    } = badgeDialogStyles(theme);

    return (
      <Dialog
        maxWidth="md"
        PaperProps={{
          style: {
            padding: '24px 4px',
            minWidth: '310px',
          },
        }}
        open={open}
        onClose={() => handleOnClick('close')}
      >
        <DialogTitle sx={dialogContent}>
          <div className={classes.titleContainer}>
            <Icon sx={icon}>delete</Icon>
            <Typography color="primary" sx={title}>
              Remove User
            </Typography>
          </div>
          <IconButton
            aria-label="close"
            sx={closeButton}
            onClick={() => handleOnClick('close')}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={dialogContent}>
          <Typography sx={dialogDescription}>
            Are you sure you want to remove the user below?
          </Typography>
          <Typography sx={dialogDescriptionBold}>
            {user && user.name}
          </Typography>
        </DialogContent>
        <DialogActions sx={dialogActions}>
          <Button
            sx={button}
            onClick={() => this.handleSave()}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}


DeleteUserBadgeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  actions: PropTypes.any.isRequired,
  apiToken: PropTypes.string.isRequired,
  badges: PropTypes.array.isRequired,
  user: PropTypes.any.isRequired,
};

export default withStyles(badgeDialogStyles, { withTheme: true })(DeleteUserBadgeDialog);
