import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Typography, Box } from '@mui/material';
import LeftIcon from '@mui/icons-material/ArrowLeft';
import RightIcon from '@mui/icons-material/ArrowRight';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import moment from 'moment';
import { withRouter } from 'react-router';

import { capitalizeFirstLetter } from './utils';

const changeMonth = {
  position: 'fixed',
  border: '1px solid #fff',
  borderRadius: '50%',
  cursor: 'pointer!important',
  zIndex: '999',
  backgroundColor: 'white',
  boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.12)',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#243060',
};

class CalendarToolbar extends Component {
  static propTypes = {
    displayDate: PropTypes.object.isRequired,
    nextMonth: PropTypes.bool,
    onMonthChange: PropTypes.func,
    prevMonth: PropTypes.bool,
  };

  static defaultProps = {
    nextMonth: true,
    prevMonth: true,
  };

  handleTouchTapPrevMonth = (e) => {
    e.preventDefault();
    if (this.props.onMonthChange) {
      this.props.onMonthChange(-1);
    }
  };

  handleTouchTapNextMonth = (e) => {
    e.preventDefault();
    if (this.props.onMonthChange) {
      this.props.onMonthChange(1);
    }
  };

  render() {
    const { displayDate } = this.props;
    const dateTimeFormatted = moment(displayDate).format('MMMM YYYY');

    return (
      <Box
        sx={[
          { textAlign: 'center', color: '#243060' },
          theme => ({
            margin: `${theme.spacing(3)} 0`,
            [theme && theme.breakpoints.down('sm')]: {
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
              justifyContent: 'space-between',
            },
          }),
        ]}
      >
        <IconButton sx={{ display: { sm: 'none' } }} disabled={!this.props.prevMonth} onClick={this.handleTouchTapPrevMonth}>
          <LeftIcon />
        </IconButton>
        <Box
          sx={[{
            ...changeMonth,
            top: '50%',
            margin: '-30px',
            marginLeft: '-38px',
            display: { xs: 'none', sm: 'flex' },
          },
          theme => ({
            [theme && theme.breakpoints.up('sm')]: {
              width: '50px',
              height: '50px',
            },
            [theme && theme.breakpoints.only('sm')]: {
              width: '40px',
              height: '40px',
            },
          }),
          ]}
          onClick={this.handleTouchTapPrevMonth}
        >
          <IconButton disabled={!this.props.prevMonth}>
            <ArrowBackIos style={{ color: '#243060' }} />
          </IconButton>
        </Box>
        <Typography
          variant='subtitle1'
          sx={{
            fontSize: '16px',
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
          }}
        >
          {capitalizeFirstLetter(dateTimeFormatted)}
        </Typography>
        <Box
          sx={[{
            ...changeMonth,
            top: '50%',
            left: 'calc(50% + 240px)',
            margin: '-30px',
            display: { xs: 'none', sm: 'flex' },
          },
          theme => ({
            [theme && theme.breakpoints.up('sm')]: {
              width: '50px',
              height: '50px',
            },
            [theme && theme.breakpoints.only('sm')]: {
              width: '40px',
              height: '40px',
              left: 'calc(50% + 190px)',
            },
          }),
          ]}
          onClick={this.handleTouchTapNextMonth}
        >
          <IconButton disabled={!this.props.nextMonth}>
            <ArrowForwardIos style={{ color: '#243060' }} />
          </IconButton>
        </Box>
        <IconButton sx={{ display: { sm: 'none' } }} disabled={!this.props.nextMonth} onClick={this.handleTouchTapNextMonth}>
          <RightIcon />
        </IconButton>
      </Box>

    );
  }
}

export default withRouter(CalendarToolbar);
