import React, { Fragment, useState, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import { Typography, IconButton, Icon } from '@mui/material';

import { formatISODateOnly } from '../Datepicker/dateUtils';
import DateUtilities, { setBoostSurgeryDetails } from '../Datepicker/utils';
import MultipleDatePicker from '../Datepicker';
import { orange } from '../../css/style';

const styles = theme => ({
  headerContainer: {
    width: '100%',
    minHeight: '100px',
    backgroundColor: '#243060',
    display: 'flex',
    alignItems: 'center',
    [theme && theme?.breakpoints?.down('sm')]: {
      minHeight: '70px',
    },
  },
  talentInfoContainer: {
    paddingLeft: '5px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    [theme && theme?.breakpoints?.down('sm')]: {
      width: '45%',
    },
    [theme && theme?.breakpoints?.up('sm')]: {
      width: 'calc(100% - 185px)',
    },
  },
  backButton: {
    color: 'white',
  },
  backButtonDisabled: {
    color: 'white',
    cursor: 'not-allowed!important',
  },
  openButtonContainer: {
    backgroundColor: `${orange}`,
    borderRadius: '50%',
    width: '44px',
    [theme && theme?.breakpoints?.up('xs')]: {
      marginLeft: '30px',
    },
    [theme && theme?.breakpoints?.down('xs')]: {
      marginLeft: '5px',
    },
  },
  openButtonContainerDisabled: {
    backgroundColor: '#ebebeb',
    borderRadius: '50%',
    width: '44px',
    [theme && theme?.breakpoints?.up('xs')]: {
      marginLeft: '30px',
    },
    [theme && theme?.breakpoints?.down('xs')]: {
      marginLeft: '5px',
    },
  },
  openContainer: {
    [theme && theme?.breakpoints?.up('sm')]: {
      marginLeft: 'auto',
      width: '120px',
    },
    display: 'flex',
    flexDirection: 'column',
  },
  openTxt: {
    opacity: '0.5',
    fontSize: '11px',
    color: '#ffffff',
    marginTop: '5px',
    textAlign: 'center',
    [theme && theme?.breakpoints?.down('xs')]: {
      display: 'none',
    },

  },
  talentName: {
    color: '#ffffff',
    fontFamily: 'Asap',
    fontSize: '25px',
    fontWeight: 'bold',
    [theme && theme?.breakpoints?.down('sm')]: {
      fontSize: '14px',
      maxWidth: '300px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  talentType: {
    color: '#ffffff',
    fontFamily: 'Asap',
    fontSize: '18px',
    fontStyle: 'italic',
    [theme && theme?.breakpoints?.down('sm')]: {
      fontSize: '12px',
      maxWidth: '250px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
});

const MessagesHeader = (props) => {
  const {
    name,
    talentType,
    jobTitle,
    classes,
    history,
    jobPostingId,
    jobPostingParentId,
    hideJobPostingLink,
    jobPostingCalendar,
    refreshCalendarData,
    readOnlyCalendar,
    address,
    theme,
    jobPostingType,
    actions,
    apiToken,
  } = props;
  const [datePicker, setDatePicker] = useState(() => false);
  const [availableDates, setAvailableDates] = useState([]);
  const [takenDates, setTakenDates] = useState([]);
  const [initPendingDates, setInitPendingDates] = useState([]);
  const [pendingDates, setPendingDates] = useState([]);
  const [multipleNeeds, setMultipleNeeds] = useState({});
  const [confirmedTakenDates, setConfirmedTakenDates] = useState([]);
  const [confirmedMultipleNeeds, setConfirmedMultipleNeeds] = useState({});
  const [initMultipleNeeds, setInitMultipleNeeds] = useState({});
  const [initBoostSurgeryData, setInitBoostSurgeryData] = useState([]);
  const [boostDetails, setBoostDetails] = useState([]);

  useEffect(() => {
    const {
      availableDates,
      takenDates,
      pendingDates,
      initPendingDates,
      multipleNeeds,
      initBoostSurgeryData,
    } = _setCalendarData(jobPostingCalendar);
    setAvailableDates(availableDates);
    setTakenDates(takenDates);
    setConfirmedTakenDates([...takenDates]);
    setPendingDates([...pendingDates]);
    setInitPendingDates([...initPendingDates]);
    setMultipleNeeds(multipleNeeds);
    setConfirmedMultipleNeeds({ ...multipleNeeds });
    setInitMultipleNeeds({ ...multipleNeeds });
    setInitBoostSurgeryData([...initBoostSurgeryData]);
  }, [jobPostingCalendar]);


  const countRows = (jobPostingCalendar, dateObj, status) => (jobPostingCalendar.filter(c => DateUtilities.isSameDay(DateUtilities.midDayDate(c.date), dateObj) && c.status === status).length);

  const _setStateValues = function (jobPostingCalendar) {
    const {
      availableDates,
      takenDates,
      pendingDates,
      initPendingDates,
      multipleNeeds,
      initBoostSurgeryData,
    } = _setCalendarData(jobPostingCalendar);
    setAvailableDates(availableDates);
    setTakenDates(takenDates);
    setConfirmedTakenDates([...takenDates]);
    setPendingDates([...pendingDates]);
    setInitPendingDates([...initPendingDates]);
    setMultipleNeeds(multipleNeeds);
    setConfirmedMultipleNeeds({ ...multipleNeeds });
    setInitMultipleNeeds({ ...multipleNeeds });
    setInitBoostSurgeryData([...initBoostSurgeryData]);
  };

  const _setCalendarData = function (jobPostingCalendar) {
    const multipleNeeds = {};
    let availableDates = [];
    let takenDates = [];
    let initPendingDates = [];
    let pendingDates = [];
    let multipleNeedsDates = [];
    let initBoostSurgeryData = [];

    if (jobPostingCalendar) {
      jobPostingCalendar.forEach((item) => {
        const dateRow = item || {};
        const dateObj = DateUtilities.midDayDate(dateRow.date);
        const avaiRows = countRows(jobPostingCalendar, dateObj, 'available');
        const takenRows = countRows(jobPostingCalendar, dateObj, 'taken');
        const pendingRows = countRows(jobPostingCalendar, dateObj, 'pending');
        if (dateRow.status === 'pending') {
          initPendingDates.push(dateObj);
        }

        if (avaiRows + takenRows + (pendingRows || 0) > 1) {
          multipleNeeds[formatISODateOnly(dateObj)] = {
            available: avaiRows,
            taken: takenRows,
            pending: pendingRows,
            date: dateObj,
          };
          if (!multipleNeedsDates.find(d => DateUtilities.isSameDay(d, dateObj))) {
            multipleNeedsDates.push(dateObj);
          }
          if (avaiRows > 0 && dateRow.status === 'available' && !initBoostSurgeryData.find(r => r.date === dateRow.date)) {
            initBoostSurgeryData.push(dateRow);
          }
        } else {
          if (dateRow.status === 'available') {
            availableDates.push(dateObj);
            initBoostSurgeryData.push(dateRow);
          }
          if (dateRow.status === 'taken') {
            takenDates.push(dateObj);
          }
          if (dateRow.status === 'pending') {
            pendingDates.push(dateObj);
          }
        }
      });
    }
    return {
      availableDates,
      takenDates,
      pendingDates,
      initPendingDates,
      multipleNeeds,
      initBoostSurgeryData,
    };
  };

  const datesSelected = function (availableDates, takenDates, pendingDates, multipleNeeds, boostData) {
    const {
      setDates,
    } = props;
    setDates(availableDates, takenDates, pendingDates, multipleNeeds, confirmedTakenDates, confirmedMultipleNeeds, initMultipleNeeds, boostData);
    setBoostDetails(boostData);
    setDatePicker(false);
  };

  const stylesWithTheme = styles(theme);

  return (
    <div className={classes.headerContainer}>
      <IconButton
        sx={stylesWithTheme.backButton}
        onClick={() => history.goBack()}
      >
        <Icon fontSize="large">chevron_left</Icon>
      </IconButton>
      <div className={classes.talentInfoContainer}>
        <Typography variant="h5" sx={stylesWithTheme.talentName}>{name || ''}</Typography>
        <Typography variant="h5" sx={stylesWithTheme.talentType}>{talentType || ''}</Typography>
        <Typography variant="h5" sx={stylesWithTheme.talentType}>
          <strong>{jobTitle || ''}</strong>
          {' '}
          {address ? `(${address})` : ''}
        </Typography>
      </div>
      {
        jobPostingCalendar
        && (
          <div className={classes.openContainer}>
            <div className={classes.openButtonContainer}>
              <IconButton
                sx={{ ...stylesWithTheme.backButton, padding: '12px' }}
                onClick={async () => {
                  const calendarResponse = await actions.getAllResources(apiToken, `jobpostings/${jobPostingId}/calendar`);
                  if (calendarResponse && calendarResponse.response) {
                    const newJobPostingCalendar = calendarResponse.response;
                    const {
                      availableDates,
                      takenDates,
                      pendingDates,
                      initPendingDates,
                      multipleNeeds,
                      initBoostSurgeryData,
                    } = _setCalendarData(newJobPostingCalendar);

                    setAvailableDates(availableDates);
                    setTakenDates(takenDates);
                    setConfirmedTakenDates([...takenDates]);
                    setPendingDates([...pendingDates]);
                    setInitPendingDates([...initPendingDates]);
                    setMultipleNeeds(multipleNeeds);
                    setConfirmedMultipleNeeds({ ...multipleNeeds });
                    setInitMultipleNeeds({ ...multipleNeeds });
                    setInitBoostSurgeryData([...initBoostSurgeryData]);

                    const boostDataArr = setBoostSurgeryDetails({
                      availableDates,
                      initBoostSurgeryData,
                      multipleNeeds,
                      boostDetails,
                    });
                    setBoostDetails(boostDataArr);
                    setDatePicker(true);
                  }
                }}
              >
                <Icon fontSize="small">event</Icon>
              </IconButton>
            </div>
            <div className={classes.openTxt}>
              JOB DATES
            </div>
          </div>
        )
      }
      <div className={classes.openContainer}>
        <div className={hideJobPostingLink ? classes.openButtonContainerDisabled : classes.openButtonContainer}>
          <IconButton
            sx={hideJobPostingLink ? { ...stylesWithTheme.backButtonDisabled, padding: '12px' } : { ...stylesWithTheme.backButton, padding: '12px' }}
            onClick={() => {
              if (!hideJobPostingLink && jobPostingId) {
                history.push(`/editposition/${jobPostingParentId || jobPostingId}`);
              }
            }}
          >
            <Icon fontSize="small">open_in_new</Icon>
          </IconButton>
        </div>
        <div className={classes.openTxt}>
          POST
        </div>
      </div>
      <MultipleDatePicker
        open={datePicker}
        selectedDates={availableDates}
        notAvailableDates={takenDates}
        initPendingDates={initPendingDates}
        pendingDates={pendingDates}
        multipleNeeds={multipleNeeds}
        readOnly={readOnlyCalendar}
        onBackdropClick={() => {
          setBoostDetails([]);
          setDatePicker(false);
        }}
        onCancel={(e) => {
          setBoostDetails([]);
        }}
        onSubmit={(
          availableDates,
          takenDates,
          pendingDates,
          multipleNeeds,
          pendingToAvai,
          pendingToTaken,
          boostData,
        ) => datesSelected(
          availableDates,
          takenDates,
          pendingDates,
          multipleNeeds,
          pendingToAvai,
          pendingToTaken,
          boostData,
        )}
        jobPostingCalendar={jobPostingCalendar}
        outerBoostData={boostDetails}
        isReliefPost={jobPostingType === 'Relief'}
        jobPostingParentId={jobPostingParentId}
      />
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(MessagesHeader);
