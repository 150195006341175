import React, { Fragment } from 'react';
import { Typography, IconButton, Icon } from '@mui/material';
import { navy, orange } from '../../css/style';

const messageNotificationBubbleStyles = theme => ({
  unreadMessagesCount: {
    fontSize: '10px',
    position: 'absolute',
    bottom: '9px',
    left: '13px',
    zIndex: '5',
    color: 'white',
  },
  unreadMessageRedDot: {
    color: `${orange}`,
    fontSize: '14px',
    padding: '0',
    position: 'absolute',
    bottom: '18px',
    left: '18px',
  },
  smallUnreadMessageRedDot: {
    color: `${orange}`,
    fontSize: '12px',
    padding: '0',
    position: 'absolute',
    bottom: '12px',
    left: '15px',
  },
  icon: {
    color: `${navy}`,
    marginRight: '12px',
    marginLeft: '5px',
    fontSize: '30px',
  },
  iconButton: {
    float: 'right',
    backgroundColor: 'transparent',
    height: '30px',
    padding: '0',
    marginRight: '15px',
  },
});

const styles = messageNotificationBubbleStyles();

const MessageNotificationBubble = (props) => {
  const { unreadMessagesCount, pingeeStatus } = props;
  return (
    <IconButton
      style={props.smallSize ? { ...styles.iconButton, height: '20px' } : styles.iconButton}
    >
      {(unreadMessagesCount > 0 || pingeeStatus)
      && (
        <Fragment>
          {unreadMessagesCount > 0
            && (<Typography style={styles.unreadMessagesCount}>{unreadMessagesCount}</Typography>)}
          <Icon style={props.smallSize ? styles.smallUnreadMessageRedDot : styles.unreadMessageRedDot}>
            lens
          </Icon>
        </Fragment>
      )}
      <Icon sx={{ ...styles.icon, fontSize: props.smallSize && '20px' }}>chat_bubble</Icon>
    </IconButton>
  );
};

export default MessageNotificationBubble;
