import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Select, MenuItem, InputLabel, FormControl, InputBase,
} from '@mui/material';

const dropdownStyles = theme => ({
  formControlStyle: {
    width: '100%',
  },
  dropDownItem: {
    fontFamily: 'Asap',
  },
  disabledDropDownItem: {
    backgroundColor: 'black',
    color: 'white',
  },
});

const styles = dropdownStyles();

export default class Dropdown extends Component {
  static propTypes = {
    data: PropTypes.any,
    actions: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    allowMultiple: PropTypes.bool,
    dropdownOptions: PropTypes.array,
    dropdownType: PropTypes.string,
  }

  constructor(props) {
    super(props);
    const { data, allowMultiple, allowNone } = props;
    let selectedOptions = [];
    if (!data || data.length === 0) {
      selectedOptions = ['helperText'];
    } else if (data != null && allowMultiple) {
      selectedOptions = data.map(option => option.id);
    } else if (data != null && !allowMultiple) {
      selectedOptions = data.id;
    }
    this.state = {
      selectedOptions,
      isNoneAllowed: allowNone,
    };
  }

  componentDidMount = () => {
    const {
      dropdownOptions, dropdownType, actions, apiToken,
    } = this.props;

    if (!dropdownOptions) {
      actions.getAllResources(apiToken, dropdownType);
    }
  }

  componentWillReceiveProps = (nextProps) => {
    const {
      data, allowMultiple, dropdownType, allowNone,
    } = nextProps;
    let selectedOptions = [];
    if (!data && allowNone) {
      selectedOptions = ['none'];
    } else if (!data) {
      selectedOptions = ['helperText'];
    } else if (data != null && allowMultiple) {
      selectedOptions = data.map(option => option.id);
    } else if (data != null && !allowMultiple) {
      selectedOptions = data.id;
    }
    this.setState({ selectedOptions });
  }

  onChangeSelect = (event) => {
    const { dropdownOptions, allowMultiple, allowNone } = this.props;
    const selectedId = event.target.value;
    let selectedOptions = dropdownOptions.filter(option => (allowMultiple ? selectedId.includes(option.id) : option.id === selectedId));
    selectedOptions = allowMultiple ? selectedOptions : selectedOptions[0];
    this.setState({ selectedOptions: selectedId });
    this.props.onChange(selectedOptions);
  }

  renderOptions = () => this.props.dropdownOptions.map(option => (
    <MenuItem key={option.id} value={option.id} style={option.isDisabled ? styles.disabledDropDownItem : styles.dropDownItem} disabled={option.isDisabled}>
      {option.name}
    </MenuItem>
  ))

  render() {
    const {
      loading, dropdownOptions, allowMultiple, label, style,
    } = this.props;
    const selectedOptions = this.state.selectedOptions || [];
    return (
      (loading)
        ? <div>Loading...</div>
        : (!dropdownOptions)
          ? <div />
          : (
            <FormControl style={styles.formControlStyle} margin="normal">
              <InputLabel htmlFor="select-multiple">{label}</InputLabel>
              <Select
                multiple={allowMultiple}
                value={selectedOptions}
                onChange={this.onChangeSelect}
                style={style}
                input={<InputBase />}
              >
                <MenuItem value="helperText" disabled style={{ fontFamily: 'Asap' }}>Please Select</MenuItem>
                {
                  this.state.isNoneAllowed && <MenuItem value="none" style={{ fontFamily: 'Asap' }}>None</MenuItem>
                }
                {this.renderOptions()}
              </Select>
            </FormControl>
          )

    );
  }
}
