import React from 'react';
import { withStyles } from '@mui/styles';
import MessageBubble from '../subcomponents/MessageBubble';

const styles = theme => ({
  chatBoxContainer: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    scrollBehavior: 'smooth',
    [theme && theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      height: '100%',
    },
  },
  emptyDiv: {
    height: '20px',
  },
});

const MessagesChatBox = (props) => {
  const {
    messages, loggedInUser, classes, onClickSetUnreadMessage,
    requestTrackingDates = [],
    handleDateMsgConfirm = () => {},
  } = props;
  const isSenderMsgs = messages.map((msg, index) => (msg.is_sender ? index : -1));
  let maxSenderIndex = 0;
  if (isSenderMsgs.length) maxSenderIndex = Math.max(...isSenderMsgs);

  return (
    <div id="messagesContainer" className={classes.chatBoxContainer}>
      <div className={classes.emptyDiv} />
      {
        messages.length != 0 && messages.map((msg, index) => (
          <MessageBubble
            key={index}
            content={msg.body}
            info={msg.info}
            senderName={msg.creatorName}
            type={msg.is_sender ? 'sender' : 'receiver'}
            date={msg.createdAt}
            isUnread={index > maxSenderIndex && !msg.is_sender}
            messageId={msg.id}
            onClickSetUnreadMessage={onClickSetUnreadMessage}
            requestTrackingDates={requestTrackingDates}
            handleDateMsgConfirm={handleDateMsgConfirm}
          />
        ))
      }
      <div className={classes.emptyDiv} />
    </div>
  );
};

export default withStyles(styles)(MessagesChatBox);
