import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Checkbox,
  Icon,
  Tooltip,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { KeyboardArrowDown, MoreVert, NavigateNext } from '@mui/icons-material';
import { getMonthName, depluralizeResourceName, formatPostTitle } from '../../utils/Functions';
import { sendKmlFile } from '../../api/ImportTool';
import SearchComponent from '../subcomponents/SearchComponent';
import { getSearchedProducts } from '../../selectors';
import {
  commonResourceStyle,
  headerButtonStyle,
  navy,
  tiffany,
  orange,
  gray,
} from '../../css/style';
import HelpIcon from '../subcomponents/HelpIcon';
import DeleteConfirmDialog from '../subcomponents/DeleteConfirmDialog';
import LoadingBar from '../subcomponents/LoadingBar';
import MessageNotificationBubble from '../subcomponents/MessageNotificationBubble';
import { renderPaginationImage, renderPageNumber, renderAddIcon } from '../core/renderHelper';

const displayResourceStyles = theme => ({
  tableRootContainer: {
    overflow: 'auto',
  },
  tableHeadCell: {
    padding: '13px',
    fontSize: '13px',
    color: '#fff',
    borderBottom: 'none',
    fontFamily: 'Asap',
    textAlign: 'center',
    position: navigator.vendor.startsWith('Apple') ? '-webkit-sticky' : 'sticky',
    top: '0',
    zIndex: '1',
    background: 'rgb(75, 189, 173)',
  },
  tableHeadRow: {
    background: `${tiffany}`,
  },
  mainTableCell: {
    fontFamily: 'Asap',
    fontSize: '14px',
    textAlign: 'center',
    color: 'inherit',
    maxWidth: 100,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    borderBottom: '2px solid #f2f2f2',
  },
  buttonTableCell: {
    padding: '0',
    position: 'relative',
    borderBottom: '2px solid #f2f2f2',
    width: '10px',
  },
  iconButton: {
    float: 'right',
    backgroundColor: 'transparent',
    height: '20px',
    padding: '0',
    marginRight: '15px',
  },
  icon: {
    color: `${navy}`,
    marginRight: '12px',
    marginLeft: '5px',
    fontSize: '19px',
  },
  iconGray: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginRight: '12px',
    marginLeft: '5px',
    fontSize: '21px',
  },
  archivedIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginRight: '100%',
    marginLeft: '5px',
    fontSize: 21,
  },
  swapIcon: {
    color: '#ffffff',
    fontSize: 16,
    marginLeft: '2px',
  },
  iconHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  clickableHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  toolbarContainer: {
    height: '10%',
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: '1%',
    padding: '10px 0',
  },
  searchTextField: {
    border: '1px solid',
    borderRadius: '3px',
  },
  addNewResButton: {
    backgroundColor: '#e0592a',
    color: '#fff',
    padding: '0',
    fontSize: '11px',
    height: '40px',
    width: '170px',
    fontFamily: 'Asap',
    boxShadow: 'none',
    marginRight: '20px',
    marginLeft: 'auto',
  },
  marginRight: {
    marginRight: '5px',
  },
  fabButton: {
    position: 'fixed',
    width: '63px',
    height: '63px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    fontFamily: 'Asap',
    fontSize: '9px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    cursor: 'pointer',
    justifyContent: 'center',
    alignItems: 'center',
    right: '20px',
    bottom: '100px',
    backgroundColor: `${orange}`,
    color: '#fff',
    borderRadius: '50%',
  },
  exportButton: {
    backgroundColor: '#777777',
    color: '#fff',
    padding: '0',
    fontSize: '11px',
    height: '40px',
    width: '140px',
    fontFamily: 'Asap',
    borderRadius: '0px',
    boxShadow: 'none',
    marginRight: '20px',
  },
  importButton: {
    backgroundColor: '#777777',
    color: '#fff',
    padding: '0',
    verticalAlign: 'middle',
    textAlign: 'center',
    display: 'inline-block',
    fontSize: '11px',
    height: '40px',
    width: '140px',
    fontFamily: 'Asap',
    borderRadius: '0px',
    boxShadow: 'none',
    marginRight: '20px',
    fontWeight: 500,
    letterSpacing: '0.02857em',
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
  importButtonSpan: {
    marginTop: '13px',
    display: 'block',
  },
  viewMoreRow: {
    height: '35px',
  },
  viewMoreTableCell: {
    textAlign: 'center',
    padding: '5px 20px',
    height: '35px',
    borderBottom: 'none',
    fontFamily: 'Asap',
    fontWeight: 'bold',
    width: '100%',
  },
  keyDownIcon: {
    width: '15px',
  },
  navigateNextIcon: {
    width: '17px',
  },
  addButton: {
    color: 'white',
    fontSize: '11px',
    backgroundColor: `${orange}`,
    margin: '10px 0',
  },
  addButtonCustom: {
    color: 'white',
    fontSize: '11px',
    backgroundColor: `${orange}`,
    marginTop: '10px',
    marginBottom: '30px',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '85px',
    position: 'relative',
  },
  paginationButton: {
    ...headerButtonStyle,
    color: '#fff',
    backgroundColor: `${orange}`,
    cursor: 'pointer',
    right: '40px',
    top: '20px',
  },
  pageNumberSpan: {
    fontSize: '12px',
    width: '32px',
    height: '32px',
    display: 'inlineBlock',
    lineHeight: '32px',
    marginRight: '4px',
    cursor: 'pointer',
  },
  pageDotSpan: {
    fontSize: '18px',
    width: '32px',
    height: '40px',
    display: 'inlineBlock',
    lineHeight: '32px',
    marginRight: '4px',
  },
  applicationsButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  viewMoreApplicationsButton: {
    fontSize: '11px',
    fontFamily: 'Asap',
    fontWeight: 'bold',
    color: `${orange}`,
  },
  viewMoreButton: {
    fontSize: '11px',
    fontFamily: 'Asap',
    fontWeight: 'bold',
  },
  checkBox: {
    padding: '0 0 0 2px',
    color: '#e0592a',
  },
  tableHead: {
    width: '100%',
  },
  tableBody: {
    height: '90%',
    width: '100%',
    cursor: 'pointer',
  },
  emptyTableCell: {
    borderBottom: 'none',
    padding: '15px',
  },
  sendPushNotificationButton: {
    width: '150px',
    position: 'absolute',
    right: '15px',
    margin: '0',
    top: '10px',
  },
  headerContentContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerContentIcon: {
    fontSize: '16px',
  },
});

const styles = displayResourceStyles();

class DisplayResources extends Component {
  constructor(props) {
    super(props);
    const { data } = props;
    this.state = {
      anchorEl: null,
      isMenuOpen: data ? new Array(data.length) : [],
      isChecked: [],
      isDeleteConfirmDialogOpen: false,
      dataInDeleteConfirmDialog: '',
    };
  }

  componentDidMount = () => {
    this.updateCategoryCheckboxState();
  };

  updateCategoryCheckboxState = () => {
    const { data, resourceName, products } = this.props;
    if (this.props.data && resourceName === 'categories') {
      const array = new Array(data.length);
      data.map((category, index) => {
        products.map((product) => {
          if (category.name === product.category.name) {
            array[index] = true;
          }
        });
      });
      this.setState({ isChecked: array });
    }
  };

  onClickEdit = (data) => {
    const {
      actions, match, history, resourceName,
    } = this.props;
    const { resource, resourceId } = match.params;
    actions.clearErrors();
    if (resourceId) {
      history.push(`/${resource}/${resourceId}/${resourceName}/${data.id}/edit`);
    } else {
      history.push(`/${resourceName}/${data.id}/edit`);
    }
  };

  onClickDelete = async (data, index) => {
    this.setState({
      isDeleteConfirmDialogOpen: true,
      dataInDeleteConfirmDialog: data,
    });
    this.handleMenuClose(index);
  };

  /**
   * On click, make a duplicate of the selected resource
   */
  onClickDuplicate = (data, index) => {
    const {
      actions, match, resourceName, apiToken,
    } = this.props;
    const { resource, resourceId, subResource } = match.params;
    const { id, image, ...dataCopy } = data;
    if (resource === 'products') {
      let resourcePath;
      if (resourceId) {
        resourcePath = [resource, resourceId, resourceName, id, 'duplicate'];
      } else {
        resourcePath = [resourceName, id, 'duplicate'];
      }
      actions.duplicateResource(apiToken, resourcePath).then((res) => {
        this.handleMenuClose(index);
      });
    } else {
      // todo add api methods for other resources
      let subResourceName;
      let subResource;
      if (resourceName === 'products') {
        subResourceName = 'items';
        subResource = data.items;
      } else if (resourceName === 'customizations') {
        subResourceName = 'options';
        subResource = data.options;
      }
      let resourcePath = [resourceName];
      if (resourceId) {
        resourcePath = [resource, resourceId, resourceName];
      }
      dataCopy.name = `Copy of ${dataCopy.name}`;
      // Add copy of resource
      actions.addResource(apiToken, dataCopy, ...resourcePath).then((res) => {
        if (subResource) {
          subResource.map(async (item) => {
            const { id, ...itemCopy } = item;
            const resourceId = res.response.id;
            // Add subresource
            await actions.addResource(apiToken, itemCopy, resourceName, resourceId, subResourceName);
            // TODO: fetch customizations & customization options
          });
        }
        this.handleMenuClose(index);
      });
    }
  };

  onClickRowSelection = (rowId, column) => {
    // user needs to click first column to view resource
    const {
      resourceName, match, history, customAction, viewPrivate,
    } = this.props;
    const { resource, resourceId, subResource } = match.params;

    if (resourceName === 'jobpostings') {
      history.push(`/editposition/${rowId}`);
    } else if (['applications', 'otherchannelchats'].includes(resourceName)) {
      history.push(`/connections/${rowId}/view`);
    } else if (resourceName === 'users') {
      history.push(`/users/${rowId}/edit`);
    } else if (viewPrivate) {
      // navigate to ViewResource component
      history.push(`/${resourceName}/${rowId}/view`);
    } else if (resourceName === 'badges') {
      history.push(`/badgeuser/${rowId}`);
    } else if (customAction) {
      // TODO: implement this (Not sure what this is left blank for - clarify with Roohi)
    } else if (resourceId) {
      history.push(`/${resource}/${resourceId}/${subResource}/${rowId}/view`);
    } else {
      history.push(`/${resourceName}/${rowId}/view`);
    }
  };

  onClickCustomButton = (rowId, btnType) => {
    const { resourceName, history } = this.props;
    history.push(`/${resourceName}/${rowId}/${btnType}/`);
  };

  getFieldDataFromType = (fieldData, field) => {
    const stringKeys = [
      'component', 'customizationView',
      'posIntegration', 'paymentIntegration',
      'reportFrequency', 'actionType',
      'type',
    ];
    if (fieldData === undefined || fieldData === null || field.key === 'orderStatus') return fieldData;
    switch (field.type) {
      case 'boolean':
        return fieldData ? 'YES' : 'NO';
      case 'decimal':
        return parseFloat(fieldData).toFixed(2);
      case 'dropdown':
        if (stringKeys.includes(field.key)) {
          // This runs if the dropdown value is a STRING - converts "STRING" to "String"
          return fieldData.charAt(0).concat(fieldData.slice(1).toLowerCase());
        }
        return fieldData.name; // Runs if the dropdown value is an OBJECT
      default:
        return fieldData;
    }
  };

  /**
   * If fieldData is null, undefined, NaN, empty string (""), 0,
   * or false => show as N/A. fieldData for Birth Day and Birth
   * Month have default vals of 0 => show as N/A.
   */
  getFieldDataValues = (data, fieldKey, rowData) => {
    if (!data) {
      return 'N/A';
    }
    if (fieldKey === 'birthMonth') {
      return getMonthName(data);
    }
    if (fieldKey === 'isSingleSelectDefault') {
      // Display true/false when it's a single-select customization
      // true: default value (either included option or first option in list) selected
      // false: no values selected by default
      if (rowData.minOptions === 1 && rowData.maxOptions === 1) {
        return data;
      }
      return 'N/A'; // N/A when it's a multi-select customization
    }
    return data;
  };

  onCheckBoxChange = (category, index) => {
    const { actions } = this.props;
    const { isChecked } = this.state;
    // set specific checkbox status and keep others unchanged
    this.setState({
      isChecked: [
        ...isChecked.slice(0, index),
        !isChecked[index],
        ...isChecked.slice(index + 1),
      ],
    }, () => {
      // update the products page, first get all checked categories, then call the action
      const { data } = this.props;
      const checkedCategories = [];
      data.map((item, ind) => {
        if (this.state.isChecked[ind] === true) checkedCategories.push(item.name);
      });
      actions.filterProducts(checkedCategories);
    });
  };

  /**
   * @description
   * render view more button as a row in the table for those resources that have pagination
   */
  renderViewMoreTableBodyRow = (rowData) => {
    const {
      buttonArray, fieldNames, viewMoreLoading, onClickViewMore, data, totalResource, resourceName, handleClickNew,
    } = this.props;
    const enableViewMore = data.length - 1 < totalResource;

    if (resourceName === 'connections' || resourceName === 'jobpostings') {
      const redirectPath = (resourceName === 'connections') ? '/applications' : '/jobpostings';
      return (
        <TableRow key={rowData.id} style={{ height: '40px', cursor: 'default' }}>
          <TableCell
            key={rowData.id}
            colSpan={fieldNames.length + 1}
            style={styles.viewMoreTableCell}
          >
            <div style={styles.applicationsButtonContainer}>
              <Button onClick={() => this.props.history.push(redirectPath)} sx={styles.viewMoreApplicationsButton}>
                {rowData.value}
                <NavigateNext style={styles.navigateNextIcon} />
              </Button>
            </div>
          </TableCell>
        </TableRow>
      );
    }

    return (
      (enableViewMore || resourceName === 'practices')
      && (
        <TableRow key={rowData.id} style={{ height: '40px' }}>
          <TableCell
            key={rowData.id}
            colSpan={buttonArray || resourceName === 'practices' ? fieldNames.length + 1 : fieldNames.length}
            style={styles.viewMoreTableCell}
          >
            {
              viewMoreLoading !== 0
                ? 'Loading...'
                : (
                  <div style={styles.buttonsContainer}>
                    {/* pseudo-element */}
                    {resourceName === 'practices' && (
                      <div style={{ visibility: 'hidden' }}>view more</div>
                    )}
                    {
                      enableViewMore && (
                        <Button onClick={() => onClickViewMore()} sx={styles.viewMoreButton}>
                          {rowData.value}
                          <KeyboardArrowDown style={styles.keyDownIcon} />
                        </Button>
                      )
                    }
                  </div>
                )
            }
          </TableCell>
        </TableRow>
      )
    );
  };

  renderPaginationTableBodyRow = () => {
    const {
      classes, buttonArray, fieldNames, viewMoreLoading, resourceName, pagination, onClickViewMore, onClickButton, buttonTitle,
    } = this.props;
    const totalPage = Math.floor((pagination.totalSize + pagination.count - 1) / pagination.count);
    const currentPage = pagination.start / pagination.count;
    return (
      <TableRow key='paginationRow' style={{ height: '40px', cursor: 'default' }}>
        <TableCell
          key='paginationCol'
          colSpan={buttonArray || resourceName === 'practices' || resourceName === 'applications' ? fieldNames.length + 1 : fieldNames.length}
          style={styles.viewMoreTableCell}
        >
          {
            viewMoreLoading !== 0
              ? 'Loading...'
              : (
                <div style={styles.paginationContainer} id="paginationContainer">
                  {renderPaginationImage({
                    isPrevious: true,
                    currentPage,
                    onClickViewMore,
                  })}
                  {[...Array(totalPage)].map((x, index) => (
                    renderPageNumber({
                      currentPage,
                      totalPage,
                      index,
                      onClickViewMore,
                    })
                  ))}
                  {renderPaginationImage({
                    totalPage,
                    currentPage,
                    pagination,
                    onClickViewMore,
                  })}
                  {renderAddIcon({
                    buttonTitle,
                    classObj: classes.paginationButton,
                    onClickButton,
                  })}
                </div>
              )
          }
        </TableCell>
      </TableRow>
    );
  };

  getData = (row, path) => {
    const { customRender } = this.props;
    const customRenderFunc = customRender && customRender[path];
    if (customRenderFunc) return customRenderFunc(row);

    let data = row;
    path.split('.').forEach((x) => {
      data = data[x];
    });
    return data;
  };

  renderArchiveButton = (rowDataId) => {
    const {
      resourceName, data, handleClickArchive, actions,
    } = this.props;

    const rowData = data.find(x => x.id === rowDataId);
    const isArchived = !!(rowData && rowData.deletedAt);
    const disableArchive = (data.filter(x => !x.deletedAt).length - 1 === 1) && !isArchived;

    if (resourceName !== 'practices') {
      return null;
    }

    return (
      <IconButton
        style={styles.iconButton}
        onClick={event => handleClickArchive(rowDataId, disableArchive)}
      >
        <Icon style={styles.archivedIcon}>
          {isArchived ? 'unarchive' : 'move_to_inbox'}
        </Icon>
      </IconButton>
    );
  };

  /**
   * @param rowData data object of each row
   * @param dataIndex index of each row
   * @description
   * render each row in a table, used by all resources
   */
  renderTableBodyRow = (rowData, dataIndex) => {
    const { anchorEl, isMenuOpen, isChecked } = this.state;
    const {
      fieldNames,
      buttonArray,
      resourceName,
      viewOnly,
      customAction,
      data,
      isSmallTable,
      usersCheckbox,
      handleClickArchive,
      onChangeUsersCheckbox,
      history,
      classes,
    } = this.props;
    const open = Boolean(isMenuOpen[dataIndex]);
    const rowId = rowData.id;
    const tableCellStyle = {
      ...styles.mainTableCell,
      padding: isSmallTable ? '0 60px 0 10px' : '20px 10px 20px 10px',
      whiteSpace: 'pre',
    };
    const isArchived = resourceName == 'practices' && rowData && rowData.deletedAt;
    const tableRowStyle = resourceName !== 'jobpostings' && resourceName !== 'connections'
      ? {
        height: '40px',
        mixBlendMode: isArchived && 'luminosity',
        color: isArchived && gray,
      } : null;
    const connectionRowStyle = (dataIndex % 2 !== 0) ? classes.connectionsRowStyle : classes.hover;

    if (rowData.blurDisplay) {
      tableCellStyle.color = '#9b9b9b';
    }
    if (resourceName === 'badges' && (!rowData.badges || !rowData.badges.length)) {
      return;
    }

    return (
      <TableRow
        key={rowData.id}
        className={connectionRowStyle}
        style={tableRowStyle}
      >
        {
          fieldNames.map((field, index) => {
            let fieldData = this.getFieldDataFromType(this.getData(rowData, field.key), field);
            fieldData = this.getFieldDataValues(fieldData, field.key, rowData);
            if ((resourceName === 'jobpostings' && field.title === 'Name')
              || (resourceName === 'connections' && field.title === 'Position Post')) {
              fieldData = formatPostTitle(fieldData);
            }
            return (
              !field.hideInDisplay
              && (
                <TableCell
                  key={field.key + rowData.id}
                  style={{
                    ...tableCellStyle,
                    width: field.width || 'auto',
                    minWidth: field.minWidth,
                    padding: field.padding ? field.padding : tableCellStyle.padding,
                  }}
                  onClick={() => !field.disableClick && this.onClickRowSelection(rowId)}
                >
                  {
                    (index === 0 && rowData.warningMsg)
                    && (
                      <Tooltip title={rowData.warningMsg}>
                        <Icon color="error" style={{ verticalAlign: 'middle' }}>
                          warning
                        </Icon>
                      </Tooltip>
                    )
                  }
                  {fieldData}
                </TableCell>
              )
            );
          })
        }

        {
          ['applications', 'otherchannelchats'].includes(resourceName) && (
            <TableCell
              key={`icon${rowData.id}`}
              style={{ ...tableCellStyle, position: 'relative', width: '20px' }}
              onClick={() => history.push(`/messages/${rowData.id}`)}
            >
              <MessageNotificationBubble
                unreadMessagesCount={rowData.unreadMessagesCount}
                pingeeStatus={rowData.pingeeStatus}
              />
            </TableCell>
          )
        }
        {viewOnly && (
          <TableCell
            width="5%"
            key={`iconButton${rowData.id}`}
            style={{
              ...tableCellStyle,
              position: 'relative',
              padding: '0',
            }}
          >
            <IconButton
              style={styles.iconButton}
              onClick={event => this.onClickRowSelection(rowData.id)}
            >
              <Icon style={styles.iconGray}>keyboard_arrow_right</Icon>
            </IconButton>
            {this.renderArchiveButton(rowData.id)}
          </TableCell>
        )}
        {
          ((buttonArray || !viewOnly) && !customAction)
          && (
            <TableCell
              key={`iconButton${rowData.id}`}
              style={{
                ...tableCellStyle,
                position: 'relative',
                padding: '0',
              }}
            >
              <IconButton
                style={styles.iconButton}
                onClick={event => this.handleMenuOpen(event, dataIndex)}
              >
                <MoreVert />
              </IconButton>
              <Menu id={rowData.id} open={open} anchorEl={anchorEl} onClose={() => this.handleMenuClose(dataIndex)}>
                <MenuItem onClick={() => this.onClickEdit(rowData)}>Edit</MenuItem>
                <MenuItem onClick={() => this.onClickDuplicate(rowData, dataIndex)}>Duplicate</MenuItem>
                {
                  // no DELETE endpoint yet in orders API
                  resourceName !== 'orders'
                  && <MenuItem onClick={() => this.onClickDelete(rowData, dataIndex)}>Delete</MenuItem>
                }
                {
                  buttonArray && buttonArray.map((button) => {
                    let kmlInput;
                    let buttonComponent = (
                      <MenuItem onClick={() => this.onClickCustomButton(rowId, button.btnTitle)}>
                        {this.formatResourceButtonName(button)}
                      </MenuItem>
                    );

                    if (button.btnTitle === 'kml') {
                      kmlInput = (
                        <input
                          id="kmlInput"
                          type="file"
                          accept=".kml"
                          ref={ref => this.kmlInput = ref}
                          style={{ display: 'none' }}
                          onChange={this.handleKmlFile.bind(this, rowId, 'kml')}
                        />
                      );
                      buttonComponent = (
                        <MenuItem onClick={() => this.kmlInput.click()}>
                          Upload .kml
                        </MenuItem>
                      );
                    }

                    return [buttonComponent, kmlInput];
                  })
                }
              </Menu>
            </TableCell>
          )
        }
      </TableRow>
    );
  };

  /**
   * render table head
   */
  renderTableHeader = () => {
    const {
      fieldNames,
      buttonArray,
      resourceName,
      viewOnly,
      customAction,
      onOpenNotificationDialog,
      onChangeAllUsersCheckbox,
      allUsersCheckbox,
      onClickViewMore,
      usersCheckbox,
    } = this.props;
    return (
      !(resourceName === 'taxes' || resourceName === 'customizations' || resourceName === 'categories')
      && (
        <TableRow
          key="header"
          style={{
            ...styles.tableHeadRow,
            height: '40px',
          }}
        >
          {fieldNames.map(
            field => !field.hideInDisplay
              && (
                <TableCell
                  variant="head"
                  style={{ ...styles.tableHeadCell, cursor: field.sort ? 'pointer' : 'default' }}
                  key={field.key}
                  onClick={(it) => {
                    field.sort ? onClickViewMore(1, field.sort) : null;
                  }}
                >
                  {field.icon
                    ? (
                      <div style={styles.iconHeaderContainer}>
                        <div
                          style={styles.clickableHeader}
                          onClick={() => onClickViewMore(1, true)}
                        >
                          {field.title.toUpperCase()}
                          <Icon style={styles.swapIcon}>{field.icon}</Icon>
                        </div>
                      </div>
                    ) : (
                    <div style={styles.headerContentContainer}>
                      {field.title.toUpperCase()}
                      {field.sort && <Icon style={styles.headerContentIcon}>swap_vert</Icon>}
                      {field.toolTip && <HelpIcon infoText={field.toolTip} />}
                    </div>
                    )}
                </TableCell>
              ),
          )}
          {viewOnly && resourceName !== 'connections' && resourceName !== 'jobpostings' && (
            <TableCell variant="head" style={{ ...styles.tableHeadCell, paddingRight: '40px', textAlign: 'right' }}>
              {resourceName === 'practices' && 'ACTIONS'}
            </TableCell>
          )}
          {viewOnly && resourceName !== 'practices' && (
            <TableCell variant="head" style={styles.tableHeadCell} />
          )}
          {((buttonArray || !viewOnly) && !customAction) && <TableCell style={styles.tableHeadCell} key="iconButton" />}
        </TableRow>
      )
    );
  };

  onClickAddResource = () => {
    const {
      actions, match, history, resourceName, viewPrivate,
    } = this.props;
    const { resource, subResource, resourceId } = match.params;
    // TODO: subResources may not need to call following action
    actions.clearErrors();
    const primaryResource = (resource === 'menu') ? resourceName : resource;
    if (subResource) {
      history.push(`/${primaryResource}/${resourceId}/${subResource}/create`);
    } else if (viewPrivate) {
      history.push(`/${primaryResource}/private/create`);
    } else {
      history.push(`/${primaryResource}/create`);
    }
  };

  handleMenuOpen = (event, index) => {
    this.setState({ anchorEl: event.currentTarget });
    this.state.isMenuOpen[index] = true;
  };

  handleMenuClose = (index) => {
    this.setState({ anchorEl: null });
    this.state.isMenuOpen[index] = false;
  };

  formatResourceButtonName(button) {
    let str = button.btnTitle;
    if (str === 'items') {
      return 'Product Items';
    } else if (str === 'zones') {
      return 'Delivery Zones';
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  handleKmlFile(resourceId, subResource, event) {
    const { apiToken, match } = this.props;
    const { resource } = match.params;

    event.stopPropagation();
    event.preventDefault();

    const confirmUpload = window.confirm(`Update coordinates of all delivery zones with ${event.target.files[0].name}?`);
    if (confirmUpload === true) {
      const file = event.target.files[0];
      const path = `${resource}/${resourceId}/${subResource}`;
      sendKmlFile(apiToken, path, file);
    }
    event.target.value = null;
  }

  /**
   * Render search field and add resource button
   */
  renderToolbar = () => {
    const {
      resourceName, isSmallTable, viewOnly, handleSearch, handleClearSearch, exportFunction, importFunction, hideCreate,
    } = this.props;
    let resourceToDisplay = depluralizeResourceName(resourceName);
    return (
      !(viewOnly || isSmallTable || hideCreate)
      && (
        <div style={styles.toolbarContainer}>
          <div>
            {exportFunction
            && (
              <Button variant="contained" onClick={() => exportFunction()} style={styles.exportButton}>
                {`Export ${resourceToDisplay}s`}
              </Button>
            )
            }
            {importFunction
            && (
              <span>
                  <input
                    id="importInput"
                    name="importInput"
                    type="file"
                    accept=".xlsx"
                    style={{ display: 'none' }}
                    onChange={e => importFunction(e.target.files[0])}
                  />
                  <label htmlFor="importInput" style={styles.importButton}>
                    <span style={styles.importButtonSpan}>
                      {`Import ${resourceToDisplay}s`}
                    </span>
                  </label>
                </span>
            )
            }

          </div>
          <div
            color="secondary"
            aria-label="add"
            style={styles.fabButton}
            onClick={e => this.onClickAddResource()}
          >
            <Icon>add_circle_outline</Icon>
            {`Add New ${resourceToDisplay}`}
          </div>

        </div>
      )
    );
  };

  renderTableBodyContent = () => {
    const {
      data, searchedProducts, resourceName, pagination,
    } = this.props;
    const tableData = searchedProducts && resourceName === 'products'
      ? searchedProducts
      : data;
    return (
      <Fragment>
        { tableData.map((rowData, index) => {
          if (rowData.id === 'viewMore' && !pagination) {
            return this.renderViewMoreTableBodyRow(rowData);
          } else if (rowData.id !== 'viewMore') {
            return this.renderTableBodyRow(rowData, index);
          }
        })}
        {pagination
        && this.renderPaginationTableBodyRow()
        }
      </Fragment>
    );
  };

  onCloseDeleteConfirmDialog = () => {
    this.setState({ isDeleteConfirmDialogOpen: false });
  };

  onClickConfirmDeleteButton = async () => {
    const {
      actions, apiToken, match, resourceName,
    } = this.props;
    const {
      resource, subResource, resourceId,
    } = match.params;
    const data = this.state.dataInDeleteConfirmDialog;
    const primaryResource = (resource === 'menu') ? resourceName : resource;
    actions.clearErrors();
    if (subResource) {
      actions.deleteResource(apiToken, primaryResource, resourceId, subResource, data.id);
    } else if (primaryResource === 'categories' && data.timeLimits && data.timeLimits.length > 0) {
      await actions.deleteResource(apiToken, primaryResource, data.id, 'time-limits', data.timeLimits[0].id);
      actions.deleteResource(apiToken, primaryResource, data.id);
    } else {
      actions.deleteResource(apiToken, primaryResource, data.id);
    }
    this.onCloseDeleteConfirmDialog();
  };

  /**
   * @description
   * determine table height base on conditions
   */
  tableHeightCalculator = () => {
    const { viewOnly, isSmallTable, resourceName } = this.props;
    let height = '';
    if (resourceName === 'products') {
      height = '82%'; // specially for products table because its title is in the table
    } else if (!viewOnly && !isSmallTable) {
      height = '90%'; // for resource table that come with a toolbar, e.g users, locations
    } else if (viewOnly && !isSmallTable) {
      height = '100%'; // for resource table without toolbar, only for orders
    } else if (isSmallTable) {
      height = '60%'; // for three small resource tables in menu page, e.g categories, customizations, taxes
    }
    return height;
  };

  renderContent = () => {
    const { data, resourceName } = this.props;
    return (
      <Fragment>
        <div style={{ ...styles.tableRootContainer }}>
          <Table style={{ minWidth: ['users', 'practices'].includes(resourceName) && '960px' }}>
            <TableHead style={styles.tableHead}>
              {this.renderTableHeader()}
            </TableHead>
            <TableBody style={styles.tableBody}>
              {this.renderTableBodyContent()}
            </TableBody>
          </Table>
        </div>
        <DeleteConfirmDialog
          resourceName={resourceName}
          isDialogOpen={this.state.isDeleteConfirmDialogOpen}
          deleteDataName={this.state.dataInDeleteConfirmDialog.name}
          onCloseDialog={this.onCloseDeleteConfirmDialog}
          onClickConfirmButton={this.onClickConfirmDeleteButton}
        />
        {this.renderToolbar()}
      </Fragment>
    );
  };

  render() {
    const { data, resourceName } = this.props;

    return (
      // if the user clicks the first cell of row, will be redirected to view page for that resource instance
      !data
        ? <div style={{ marginTop: '20px' }}><LoadingBar /></div>
        : (
          this.renderContent()
        )
    );
  }
}

DisplayResources.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  viewMoreLoading: PropTypes.number,
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  apiToken: PropTypes.string.isRequired,
  fieldNames: PropTypes.arrayOf(PropTypes.object).isRequired,
  resourceName: PropTypes.string.isRequired,
  viewOnly: PropTypes.bool,
  onClickViewMore: PropTypes.func,
  onClickButton: PropTypes.func,
  exportFunction: PropTypes.func,
  importFunction: PropTypes.func,
  customRender: PropTypes.objectOf(PropTypes.func),
  totalResource: PropTypes.number,
  buttonArray: PropTypes.arrayOf(PropTypes.object),
  isSmallTable: PropTypes.bool,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  searchedTerm: PropTypes.string,
  buttonTitle: PropTypes.string,
  searchedProducts: PropTypes.arrayOf(PropTypes.object),
  handleClickNew: PropTypes.func,
  viewPrivate: PropTypes.bool,
};

DisplayResources.defaultProps = {
  data: null,
  viewMoreLoading: 0,
  totalResource: 0,
  viewOnly: false,
  onClickViewMore: null,
  onClickButton: null,
  exportFunction: null,
  importFunction: null,
  buttonArray: null,
  isSmallTable: false,
  searchedTerm: '',
  searchedProducts: null,
  handleClickNew: null,
  viewPrivate: false,
};

const mapStateToProps = (state, props) => ({
  searchedProducts: props.searchedTerm && getSearchedProducts(state, props),
});

export default withStyles(commonResourceStyle)(withRouter(connect(mapStateToProps)(DisplayResources)));
