import { Switch } from '@mui/material';
import { styled } from '@mui/material/styles';

const GreenSwitch = styled(Switch)(({ theme }) => ({
  width: '40px',
  height: '21px',
  padding: 0,
  margin: theme.spacing(1),

  '& .MuiSwitch-switchBase': {
    padding: '1px',
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: theme.palette.common.white,
      '& + .MuiSwitch-track': {
        backgroundImage: 'linear-gradient(to bottom, #39b54a, #34aa44 98%)',
        opacity: 1,
        height: '20px',
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },

  '& .MuiSwitch-thumb': {
    width: '18px',
    height: '18px',
  },

  '& .MuiSwitch-track': {
    borderRadius: 20 / 2,
    border: `1px solid ${theme?.palette?.grey[400]}`,
    backgroundColor: '#f0f0f0',
    height: 19,
    opacity: 1,
    transition: theme?.transitions?.create(['background-color', 'border']),
  },
}));

export default GreenSwitch;
