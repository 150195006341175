import React from 'react';
import PropTypes from 'prop-types';
import FileInputComponent from 'react-file-input-previews-base64';
import { IconButton, Icon } from '@mui/material';

const uploadImageStyles = theme => ({
  imageContainer: {
    position: 'relative',
    width: '100%',
    height: '90%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: '50px',
  },
  closeButton: {
    backgroundColor: '#e0592a',
    color: '#fff',
    width: '30px',
    height: '30px',
    padding: '0',
    position: 'absolute',
    top: '5%',
    right: '8%',
  },
  imageStyle: {
    width: '80%',
    height: '72%',
    objectFit: 'cover',
  },
});

const styles = uploadImageStyles();

const UploadImage = (props) => {
  const { image, onChangeInput, type } = props;
  return (
    (image)
      ? (
        <div style={styles.imageContainer}>
          <img src={image} style={styles.imageStyle} alt="resource" />
          {
            type !== 'view'
            && (
            <IconButton
              style={styles.closeButton}
              onClick={() => onChangeInput(null)}
            >
              <Icon>close</Icon>
            </IconButton>
            )
          }
        </div>
      )
      : (
        <FileInputComponent
          callbackFunction={(image) => {
            const base64 = image && image.length && image[0].base64;
            return onChangeInput(base64);
          }}
          accept="image/*"
        />
      )
  );
};

UploadImage.propTypes = {
  image: PropTypes.string,
  type: PropTypes.string,
  onChangeInput: PropTypes.func.isRequired,
};

UploadImage.defaultProps = {
  image: null,
  type: null,
};

export default UploadImage;
