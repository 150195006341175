import {
  headerButtonStyle,
  hoverEffectOne,
  navy,
  orange,
} from '../../../css/style';

export const RegionalNeedsStyles = theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '32px',
    margin: '1% 2% 1% 2%',
    backgroundColor: '#f9f9f9',
    justifyContent: 'center',

    [theme && theme.breakpoints.down('sm')]: {
      padding: '20px',
    },
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '25px',
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',

    '& .MuiBadge-badge': {
      height: '20px',
      minWidth: '20px',
      borderRadius: '50%',
      fontSize: '10px',
    },

    '& .MuiBadge-anchorOriginTopRightCircle': {
      top: '6%',
      right: '6%',
    },

  },
  headerButton: {
    ...headerButtonStyle,
    color: '#ffffff',
    backgroundColor: `${orange}`,
    right: '0',
    cursor: 'pointer',
    ...hoverEffectOne,

    [theme && theme.breakpoints.down('sm')]: {
      maxWidth: '100px',
    },
  },
  resourceHeader: {
    display: 'flex',
    flexDirection: 'column',
    width: '96%',
    justifyContent: 'space-between',
    margin: '1.5% 3% 0 2%',
    position: 'relative',

    [theme && theme.breakpoints.down('sm')]: {
      margin: '6% 3% 0',
    },

    [theme && theme.breakpoints.down('xs')]: {
      margin: '32px 10px 16px 10px',
    },
  },
  resourceName: {
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#ffffff',
    fontFamily: 'circe-rounded',
    display: 'flex',

    [theme && theme.breakpoints.down('xs')]: {
      fontSize: '22px',
    },
  },
  subtitle: {
    display: 'flex',
    fontSize: 18,
    fontStyle: 'italic',
    flexDirection: 'row',
    alignItems: 'center',
    color: '#ffffff',
    fontFamily: 'circe-rounded',
    lineHeight: 2,

    [theme && theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
  practiceName: {
    fontSize: 20,
    fontWeight: 'bold',
    color: `${navy}`,
    textTransform: 'uppercase',
    fontFamily: 'circe-rounded',
  },
  card: {
    minHeight: '270px',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.3)',
  },
  cardHeader: {
    padding: '16px 16px 8px 16px',
    a: {
      textDecoration: 'none',
    },
  },
  cardContent: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f9f9f9',
    padding: '5px',
    borderRadius: 5,
    minHeight: '173px',
    maxHeight: '300px',
    overflowY: 'auto',
    a: {
      textDecoration: 'none',
      color: '#000000',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  chipRoot: {
    maxWidth: '118px',
    marginLeft: '8px',
    marginBottom: '1px',
    [theme && theme.breakpoints.down('sm')]: {
      maxWidth: '82px',
    },
  },
  chipRootFull: {
    marginLeft: '8px',
    marginBottom: '5px',
    minWidth: '55px',
    borderRadius: '5px',
    boxShadow: `0 1px 2px 0 ${navy}`,
  },
  chipRootLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
    paddingLeft: '5px',
    paddingRight: '5px',
  },
  bullet: {
    backgroundColor: '#9e9e9e',
    width: '8px',
    height: '8px',
    borderRadius: '50%',
  },
});
