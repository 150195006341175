import React from 'react';
import { isEmpty } from 'lodash';
import { Tooltip } from '@mui/material';
import {
  VET_TYPES_TITLES,
  EXPERIENCE_TYPE_CONFIG,
  EXPERIENCE_LEVELS_CONFIG,
  JOB_CATEGORIES,
  JOB_TYPE_SUB_CATEGORY_OBJ,
  SCHOOL_LOGOS_STORAGE_LINK,
} from '../../data/Constants';

const ExperienceDetailCard = ({
  classes,
  talentProfileItems,
  talent,
  isPreview,
  vetSchools,
}) => {
  if (!talentProfileItems) {
    return '';
  }

  const items = talentProfileItems.reduce((g, i) => {
    g[i.criteria.name] = g[i.criteria.name] || [];
    let val = i.strValue === 'CT / UT No Cert' ? 'Other / None' : i.strValue;

    if (i.str2Value && i.str2Value !== i.strValue) {
      val = `${i.str2Value} (${i.strValue})`;
    }

    if (i.numValue) {
      val = i.numValue;
    }

    const name = i.criteria ? i.criteria.name : '';

    if (name == 'job-category') {
      const jobCat = i && JOB_CATEGORIES.filter(item => item.key == i.strValue);
      val = jobCat.length > 0 ? jobCat[0].title : i.strValue;
    }

    g[i.criteria.name].push(val);

    return g;
  }, {});

  let vetTitle = VET_TYPES_TITLES[talent.type];

  if (talent.isStudent) {
    if (talent.type === 'dvm-doctor-of-veterinary-medicine') {
      vetTitle = 'Veterinary Student Extern';
    } else {
      vetTitle = `${vetTitle} Student Extern`;
    }
  }

  const getStudentYear = (currentMonth, condition1, condition2) => (currentMonth < 8 ? condition1 : condition2);

  const studentYearDetail = (talentProfileItems) => {
    let graduationDate = talentProfileItems.find(x => (x.criteria && x.criteria.name === 'graduation-date'));
    if (graduationDate) {
      graduationDate = graduationDate && graduationDate.strValue;
      graduationDate = graduationDate && graduationDate.split('/');
      const graduationYear = graduationDate && +graduationDate[0];
      const currentDate = new Date();
      const yearDiff = graduationYear - currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;

      if (yearDiff === 0) return 'Fourth-year';
      else if (yearDiff === 1) return getStudentYear(currentMonth, 'Third-year', 'Fourth-year');
      else if (yearDiff === 2) return getStudentYear(currentMonth, 'Second-year', 'Third-year');
      else if (yearDiff === 3) return getStudentYear(currentMonth, 'First-year', 'Second-year');
      else if (yearDiff === 4) return getStudentYear(currentMonth, 0, 'First-year');
    }
    return 0;
  };

  const itemKeys = talent.type && Object.keys(EXPERIENCE_TYPE_CONFIG)
    .filter(item => EXPERIENCE_LEVELS_CONFIG[talent.type][item] || talent.isStudent && EXPERIENCE_TYPE_CONFIG[item].isStudent) || [];

  let talentExperience = [{
    key: 'Employee type',
    value: vetTitle,
  }, ...itemKeys
    .filter(item => items[item]).map((k) => {
      // Display School Name instead of School ID
      if (k === 'school-id') {
        const schoolObj = vetSchools && vetSchools.find(s => items[k].includes(s.id)) || {};
        return {
          key: 'School name',
          value: schoolObj.name || 'Other',
          logo: `${SCHOOL_LOGOS_STORAGE_LINK}${schoolObj.id || 404}.png`,
        };
      } if (k === 'school-name') {
        return {};
      } else {
        return {
          key: EXPERIENCE_TYPE_CONFIG[k].title,
          value: items[k].map((v) => {
            if (k === 'job-type-sub-category') {
              return JOB_TYPE_SUB_CATEGORY_OBJ[v];
            }
            return v;
          }).join(', '),
        };
      }
    })];

  if (talent.isStudent && studentYearDetail(talentProfileItems) !== 0) {
    talentExperience.splice(5, 0, { key: 'Student Year', value: studentYearDetail(talentProfileItems) });
  }

  if (isPreview) {
    const talentType = talent.type;
    switch (talentType) {
      case 'non-clinical':
        talentExperience = talentExperience.filter(experience => experience.key === 'Job Category');
        break;
      case 'specialist-veterinarian':
        talentExperience = talentExperience.filter(experience => experience.key === 'Specialty');
        break;
      case 'dvm-doctor-of-veterinary-medicine':
      case 'vet-tech-or-assistant':
        talentExperience = talentExperience.filter(experience => experience.key === 'Sub-category');
        break;
      default:
        return null;
    }
  }

  return (
    <div className={classes.newExperienceDetails}>
      {talentExperience && talentExperience.map(exp => exp && exp.logo && (
        <Tooltip title={exp.value} key={`${exp.key}_tip`}>
          <div key={`${exp.key}_logo`} className={classes.experienceImageContainer}>
            {exp.logo
              && (
              <img
                className={classes.experienceImage}
                src={exp.logo}
                alt="School logo"
              />
              )
            }
          </div>
        </Tooltip>
      ))}
      {talentExperience && talentExperience.map(exp => !isEmpty(exp) && (
        <div key={exp.key} className={classes.experienceItem}>
          <div className={classes.newExperienceItemTitle}>
            {exp.key}
            :
          </div>
          <div className={classes.newExperienceItemValue}>{exp.value}</div>
        </div>
      ))}
    </div>
  );
};

export default (ExperienceDetailCard);
