import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty, orderBy, uniqBy } from 'lodash';
import {
  Paper, Typography, Button, Chip, Grid,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import {
  HQ_ADMIN_KEY,
  PRACTICE_ADMIN_KEY,
  REGIONAL_ADMIN_KEY,
  ADMIN_NO_PRACTICE_KEY,
  USER_ROLES,
  userPaginationCount,
  BASE_URLS,
} from '../../data/Constants';
import { commonResourceStyle } from '../../css/style';
import LoadingBar from '../subcomponents/LoadingBar';
import TextInput from '../subcomponents/TextInput';
import SingleSelect from '../subcomponents/SingleSelect';
import { validateEmail, handleTrailingSpaces } from '../../utils/Functions';
import PracticeAutocomplete from '../subcomponents/PracticeAutocomplete';
import PracticeSelection from '../subcomponents/PracticeSelection';

const restrictedMessage = 'Practice locations in grey should be added/removed in Workday';
const warningMessage = 'Regional Admins require 2+ practices, add one more practice or switch to Practice Admin.';

class UsersForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPractices: [],
      oldPMPracticeSelected: null,
    };
  }

  static getUserAccess = (user, type) => {
    const access = user && user.accessList ? user.accessList.filter(item => item.type === type) : [];
    return access;
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      resources, match, edit, admin,
    } = nextProps;
    const { userId } = match.params;
    const { users, adminPractices } = resources;
    if (userId && users && !prevState.role) {
      const filteredUsers = users.filter(item => item.id === Number(userId));
      if (filteredUsers.length) {
        const st = Object.assign(filteredUsers[0], prevState);
        const aggAccess = UsersForm.getUserAccess(st, 'aggregator');
        const prAccess = UsersForm.getUserAccess(st, 'practice');
        if (aggAccess.length) {
          st.role = HQ_ADMIN_KEY;
          st.practiceSelected = null;

          // Also get practices if the HQ admin has practice accesses
          st.selectedPractices = !isEmpty(prAccess) ? adminPractices?.filter(p => prAccess.filter(a => a.id === p.id).length) : [];
          st.hqAdminInitialPractices = st.selectedPractices;
        } else if (prAccess?.length) {
          st.role = prAccess.length > 1 ? REGIONAL_ADMIN_KEY : PRACTICE_ADMIN_KEY;
          st.practiceSelected = prAccess.length == 1 ? prAccess[0] : null;
          if (st.adminRole === PRACTICE_ADMIN_KEY) {
            st.oldPMPracticeSelected = st.practiceSelected;
          }
          st.selectedPractices = adminPractices?.filter(p => prAccess.filter(a => a.id === p.id).length);
        } else if (filteredUsers[0].isAdmin) {
          st.role = ADMIN_NO_PRACTICE_KEY;
        }
        st.importedPractices = prAccess?.filter(a => a.isImported === true);
        st.selectedPractices && st.selectedPractices.forEach((p) => {
          const selectedPrAccess = prAccess?.find(a => a.id === p.id) || {};
          p.isImported = selectedPrAccess.isImported;
        });
        if (!edit) {
          delete st.id;
        }
        return st;
      }
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.oldPMPracticeSelected && !!this.state.externalId && prevState.role === REGIONAL_ADMIN_KEY && this.state.role === PRACTICE_ADMIN_KEY) {
      this.setState({ practiceSelected: this.state.oldPMPracticeSelected });
    }

    if (prevState.externalId !== this.state.externalId && this.state.externalId) {
      const externalPractice = this.state.selectedPractices.filter(x => x.isImported);
      if (externalPractice.length === 1) {
        this.setState({ oldPMPracticeId: externalPractice[0].id });
      }
    }
  }

  componentDidMount = async () => {
    const {
      actions, apiToken, resources, match,
    } = this.props;
    const { adminPractices } = resources;
    if (!adminPractices) await actions.getAllResources(apiToken, BASE_URLS.getPractices);
    const { userId } = match.params;
    if (userId) {
      const { users, pagination } = resources;

      if (pagination && pagination.users) {
        sessionStorage.setItem('usersOffset', pagination.users.start);
      }

      if (!users) {
        const usersQueryOffset = sessionStorage.getItem('usersQueryOffset');

        if (!isEmpty(usersQueryOffset)) {
          await actions.getAllResources(apiToken, usersQueryOffset);
        } else {
          const usersOffset = sessionStorage.getItem('usersOffset') || 0;
          await actions.getAllResources(apiToken, `users?start=${usersOffset}&count=${userPaginationCount}`);
        }
      }
    }
  };

  save = (e) => {
    const {
      actions, loading, apiToken, history, edit,
    } = this.props;
    const user = Object.assign({ isAdmin: true }, this.state);
    user.name = handleTrailingSpaces(user.name);
    user.email = handleTrailingSpaces(user.email);
    user.adminRole = user.role;
    if (!validateEmail(user.email)) {
      return this.setState({ error: 'Invalid Email' });
    }
    this.setState({ error: '' });

    if (user.role === PRACTICE_ADMIN_KEY) {
      user.practice_id = this.state?.practiceSelected?.id;
      if (!user.id) {
        user.isRealTimeNotification = true;
      }
    }

    if (isEmpty(user.selectedPractices) && !isEmpty(this.state.importedPractices) && this.state.importedPractices.length === 1 && user.role === PRACTICE_ADMIN_KEY) {
      user.selectedPractices.push(this.state.importedPractices[0]);
    }

    delete user.importedPractices;
    delete user.warning;

    if (edit) {
      actions.updateResource(apiToken, user, 'users', this.state.id).then((e) => {
        if (!e.error) {
          history.goBack();
        }
      });
    } else {
      user.password = String(Math.random());
      user.isShiftAdmin = true;
      user.emailFrequency = 'Monthly';
      user.isRealTimeNotification = true;
      user.isRealTimeSmsNotification = true;
      actions.addResource(apiToken, user, 'users').then((e) => {
        if (!e.error) {
          history.goBack();
        }
      });
    }
  };

  render() {
    const {
      loading, viewMoreLoading, resources, admin, match, theme, edit,
    } = this.props;
    const { userId } = match.params;
    const { adminPractices } = resources;
    const {
      practiceSelected, selectedPractices, name, email, role, error, externalId, importedPractices, warning,
    } = this.state;

    const isFormValid = (name && email && role && ((role === PRACTICE_ADMIN_KEY && practiceSelected?.id)
      || (role === REGIONAL_ADMIN_KEY && selectedPractices.length > 1)
      || (role === HQ_ADMIN_KEY)
      || (role === ADMIN_NO_PRACTICE_KEY && !selectedPractices.length)
    ));

    let nonArchivedPractices = adminPractices && adminPractices.filter(practice => !practice.deletedAt && practice.active);

    if (role === REGIONAL_ADMIN_KEY && selectedPractices.length > 0 && externalId && this.state.oldPMPracticeSelected) {
      nonArchivedPractices = nonArchivedPractices.map(x => ({ ...x, disabledEdit: (x.id === this.state.oldPMPracticeSelected.id) }));
    }

    nonArchivedPractices = orderBy(nonArchivedPractices, ['name', 'id'], ['asc', 'asc']);

    const isHQAdmin = admin && admin.adminRole === 'HQ_ADMIN';

    const preventedEditOtherAdmin = !isHQAdmin && admin.id !== Number(userId);
    let roles = !isHQAdmin && admin.id === Number(userId) ? USER_ROLES.filter(item => item.key !== HQ_ADMIN_KEY) : USER_ROLES;
    roles = !edit ? roles.filter(item => item.key !== ADMIN_NO_PRACTICE_KEY) : roles; // don't show 'Admin without Practice' when creating new admin

    const stylesWithTheme = commonResourceStyle(theme);

    let disabledRoleField = false;
    let disabledRoleItems = [];

    if (importedPractices && importedPractices.length === 1 && externalId) {
      disabledRoleItems = [HQ_ADMIN_KEY, ADMIN_NO_PRACTICE_KEY];
    }

    if (isHQAdmin && importedPractices && (role === REGIONAL_ADMIN_KEY && importedPractices.length > 1) && externalId) {
      disabledRoleField = true;
    }

    return (
      (loading === true && viewMoreLoading === 0) || (!name && !role && !email && edit)
        ? <LoadingBar />
        : (
                  <div style={stylesWithTheme.container}>
                      <div style={stylesWithTheme.resourceHeader}>
                          <div style={stylesWithTheme.resourceNameLarge}>
                              <Typography sx={stylesWithTheme.resourceNameLarge}>
                                  ADMIN DETAIL
                              </Typography>
                              <Button
                                sx={!isFormValid ? stylesWithTheme.headerDisabledButton : stylesWithTheme.headerButton}
                                onClick={this.save}
                                disabled={!isFormValid || preventedEditOtherAdmin}
                              >
                                  SAVE
                              </Button>
                          </div>
                      </div>
                      <Paper sx={stylesWithTheme.paper}>
                          <div style={stylesWithTheme.paperContainer}>
                              {externalId && (<Typography sx={stylesWithTheme.resourceAlert}>{'>>>To edit unavailable fields, please update information in Workday.<<<'}</Typography>)}
                              <Typography sx={stylesWithTheme.resourceSub}>user information</Typography>
                              <Grid container spacing={2}>
                                  <Grid item md={6}>
                                      <TextInput
                                        value={name || ''}
                                        title="Name:"
                                        disabled={!!externalId || preventedEditOtherAdmin}
                                        onChange={name => this.setState({ name })}
                                      />
                                      <TextInput
                                        value={email || ''}
                                        type='email'
                                        title="Email Address:"
                                        disabled={!!externalId || preventedEditOtherAdmin}
                                        onChange={email => this.setState({ email })}
                                      />
                                  </Grid>

                                  <Grid item md={6}>
                                      <SingleSelect
                                        value={role || ''}
                                        title="Role:"
                                        items={roles || []}
                                        disabled={disabledRoleField || preventedEditOtherAdmin}
                                        // Disable Practice Role when there are 2 or more imported practices set to Regional/HQ Admin
                                        disabledItems={([REGIONAL_ADMIN_KEY, HQ_ADMIN_KEY].includes(role) && !isEmpty(importedPractices) && importedPractices.length > 1) ? [PRACTICE_ADMIN_KEY, ADMIN_NO_PRACTICE_KEY] : disabledRoleItems}
                                        onChange={(role) => {
                                          let practice = null;
                                          let practices = (role === REGIONAL_ADMIN_KEY || (role === HQ_ADMIN_KEY && selectedPractices.length)) ? selectedPractices : [];

                                          if (role === PRACTICE_ADMIN_KEY) {
                                            practice = practiceSelected;
                                            if (!isEmpty(importedPractices)) {
                                              practice = importedPractices[0];
                                              practices = importedPractices;
                                            }
                                          } else if (role === HQ_ADMIN_KEY && this.state.hqAdminInitialPractices) {
                                            practices = this.state.hqAdminInitialPractices;
                                          } else if (role === ADMIN_NO_PRACTICE_KEY) {
                                            practices = [];
                                          } else if (role === REGIONAL_ADMIN_KEY && practiceSelected && isEmpty(importedPractices)) {
                                            console.log(importedPractices);
                                            practices.push(practiceSelected);
                                            practices = uniqBy(practices, 'id');
                                          }

                                          this.setState({
                                            role,
                                            practiceSelected: practice || this.state.oldPMPracticeSelected,
                                            selectedPractices: practices,
                                          });

                                          if (role === REGIONAL_ADMIN_KEY && selectedPractices.length < 2) {
                                            this.setState({ warning: warningMessage });
                                          } else {
                                            this.setState({ warning: '' });
                                          }
                                        }}
                                        form
                                      />
                                      {role === REGIONAL_ADMIN_KEY && (
                                        <div style={{ marginTop: '20px' }}>
                                          <PracticeAutocomplete
                                            title='Please Select'
                                            items={nonArchivedPractices || []}
                                            fixedPractices={importedPractices}
                                            value={selectedPractices}
                                            handleStateChange={(value) => {
                                              this.setState({ selectedPractices: value });
                                              if (value.length < 2) {
                                                this.setState({ warning: warningMessage });
                                              } else {
                                                this.setState({ warning: '' });
                                              }
                                            }}
                                            form
                                            label="Practices"
                                            helpMessage="Please select 2+ practices"
                                            noOptionsText="No Practices"
                                            disableImportedItem
                                            disabled={preventedEditOtherAdmin}
                                          />
                                        </div>
                                      )}
                                      {role === PRACTICE_ADMIN_KEY && (
                                        <div style={{ marginTop: '20px' }}>
                                          <PracticeSelection
                                            value={practiceSelected || ''}
                                            title="Practice Name"
                                            items={nonArchivedPractices || []}
                                            disabled={!!externalId || !isEmpty(importedPractices) || preventedEditOtherAdmin}
                                            handleStateChange={(practice) => {
                                              if (!isEmpty(practice)) {
                                                this.setState({ practiceSelected: practice });
                                              }
                                            }}
                                            form
                                            label="Practice"
                                            noOptionsText="No Practice"
                                            lettersTypedToShowOptions={2}
                                          />
                                        </div>
                                      )}
                                  </Grid>
                                  {!isEmpty(importedPractices) && (
                                      <Grid item md={12}>
                                        <Typography sx={stylesWithTheme.subTitle}>{restrictedMessage}</Typography>
                                      </Grid>
                                  )}
                                  {(role === REGIONAL_ADMIN_KEY || (role === HQ_ADMIN_KEY && !isEmpty(selectedPractices))) && (
                                      <Grid item md={12}>
                                          {selectedPractices.map((p, i) => (
                                              <Chip
                                                key={p.id}
                                                label={p.name}
                                                color="secondary"
                                                size="small"
                                                onDelete={(p.isImported || p.disabledEdit) ? null : () => {
                                                  selectedPractices.splice(i, 1);
                                                  this.setState({ selectedPractices });
                                                  if (role === REGIONAL_ADMIN_KEY && selectedPractices.length < 2) {
                                                    this.setState({ warning: warningMessage });
                                                  }
                                                }}
                                                sx={{
                                                  ...stylesWithTheme.chipRootFull,
                                                  '& .MuiChip-label': stylesWithTheme.chipRootLabel,
                                                }}
                                                disabled={p.isImported || preventedEditOtherAdmin || p.disabledEdit}
                                              />
                                          ))}
                                      </Grid>
                                  )}
                              </Grid>
                              <div style={stylesWithTheme.formActions}>
                                  <div>
                                      {error && <Typography color="error">{error}</Typography>}
                                      {warning && <Typography sx={stylesWithTheme.formControlLabelWarnText}>{warning}</Typography>}
                                  </div>
                              </div>
                          </div>
                      </Paper>
                  </div>
        )
    );
  }
}

UsersForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  apiToken: PropTypes.string.isRequired,
};

UsersForm.defaultProps = {};

export default withStyles(commonResourceStyle, { withTheme: true })(withRouter(UsersForm));
