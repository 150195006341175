import {
  commonResourceStyle,
  hoverEffectOne,
  navy,
  orange,
  tiffany,
  gray,
} from '../../../css/style';

export const UserViewStyles = theme => ({
  ...commonResourceStyle(theme),
  usersViewContainer: {
    padding: '1% 2% 1% 2%',
    borderRadius: 5,
    boxShadow: 'none',
  },
  pageTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: 'rgb(36, 48, 96)',
    textTransform: 'uppercase',
    fontFamily: 'circe-rounded',
    margin: '12px 0',
  },
  newExperienceDetails: {
    backgroundColor: '#f7f7f7',
    borderRadius: 5,
    padding: '10px',
    paddingTop: '50px',
  },
  newExperienceItemTitle: {
    width: '40%',
    textAlign: 'right',
    fontStyle: 'italic',
    color: '#878787',
    paddingRight: '10px',
  },
  badgeWorkerItem: {
    display: 'flex',
    margin: '16px 8px',
    fontFamily: 'Asap',
    [theme && theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  badgeWorkerItemTitle: {
    fontStyle: 'italic',
    color: '#878787',
    paddingRight: '10px',
  },
  badgeWorkerItemValue: {
    width: '60%',
    [theme && theme.breakpoints.down('sm')]: {
      overflow: 'hidden',
      border: '5px solid #ffffff',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
      fontWeight: 'normal',
      height: '40px',
      padding: '5px',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
  },
  banner: {
    backgroundImage: `linear-gradient(to left, #8cd0e1, ${tiffany})`,
    padding: '24px',
    minHeight: '150px',
    borderRadius: '5px 5px 0 0',
  },
  badgedUserBanner: {
    backgroundImage: `linear-gradient(to left, #8cd0e1, ${tiffany})`,
    padding: '24px',
    minHeight: '120px',
    borderRadius: '5px 5px 0 0',
    display: 'flex',
    alignItems: 'center',
  },
  userIconContainer: {
    height: '155px',
    width: '155px',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
  },
  talentIconStyle: {
    width: '70px',
    height: '90px',
  },
  badgedUserTitle: {
    fontSize: 30,
    color: '#FFFFFF',
  },
  userName: {
    fontSize: 40,
    fontWeight: 700,
    color: '#FFFFFF',
  },
  subtitle: {
    color: '#FFFFFF',
    display: 'flex',
    fontSize: 20,
    fontStyle: 'italic',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginRight: '12px',
    color: 'white',
  },
  body: {
    padding: '24px',
  },
  bodyItem: {
    marginBottom: '12px',
  },
  userInfoTitle: {
    fontSize: 20,
    fontWeight: 700,
    fontStyle: 'italic',
    marginTop: '10px',
    marginBottom: '10px',
  },
  row: {
    padding: '10px',
    border: `1px solid ${navy}`,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  rowNoCursor: {
    padding: '10px',
    border: `1px solid ${navy}`,
  },
  searchItem: {
    '& p': {
      fontSize: '0.8rem',
    },
    minHeight: '36px',
    alignItems: 'center',
    display: 'flex',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  searchIcon: {
    marginRight: '2px',
    fontSize: '1rem',
  },
  backIcon: {
    cursor: 'pointer',
  },
  backText: {
    marginLeft: '4px',
    fontWeight: 700,
    cursor: 'pointer',
  },
  searchLocationText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '60%',
    marginRight: '15px',
  },
  dataGridContainer: {
    minHeight: '300px',
    width: '100%',

    '& .MuiDataGrid-cell': {
      '&:focus': {
        outline: 'none !important',
      },
      '&:focus-within': {
        outline: 'none !important',
      },
    },
    '& .MuiDataGrid-columnHeader': {
      backgroundColor: tiffany,
      color: '#fff',
      '&:focus': {
        outline: 'none !important',
      },
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
    },
    '& .MuiDataGrid-sortIcon': {
      color: '#fff',
      fontWeight: 'bold',
    },
  },
  disabledRow: {
    color: gray,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    [theme && theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      height: '100px',
    },
  },
  reportButtonGroup: {
    display: 'flex',
    flexDirection: 'row',

    [theme && theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  reportButton: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#ffffff',
    backgroundColor: `${orange}`,
    marginLeft: '10px',
    cursor: 'pointer',
    ...hoverEffectOne,

    [theme && theme.breakpoints.down('sm')]: {
      maxWidth: '100px',
    },
  },
  backButton: {
    with: '186px',
    height: '40px',
  },
  badgeInfoItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    height: '24px',
  },
  badgeInfo: {
    fontSize: '12px',
    fontStyle: 'italic',
    color: `${tiffany}`,
  },
  badgeWarning: {
    fontSize: '14px',
    color: 'red',
  },
});
