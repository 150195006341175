import React, { Component } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  IconButton,
  TextField,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';

import {
  hoverEffectTwo,
  navy,
  tiffany,
  orange,
  dialogCloseButtonStyle,
  dialogTitleContainerStyle,
  dialogFormRowStyle,
} from '../../css/style';

import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import FormAutocomplete from './FormAutocomplete';
import { ADMIN_NO_PRACTICE_KEY } from '../../data/Constants';

const PingUserDialogStyles = theme => ({
  cancel: {
    margin: 'auto',
    textDecoration: 'underline',
    fontFamily: 'Asap',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'italic',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: `${orange}`,
    cursor: 'pointer',
  },
  pingTitle: {
    fontFamily: 'Asap',
    fontSize: '16px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    color: `${navy}`,
  },
  checkBox: {
    marginLeft: '10px',
    display: 'flex',
    alignItems: 'center',
    [theme && theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  checkBoxlabel: {
    fontWeight: 'normal',
    color: 'black!important',
  },
  pinguserLabel: {
    textAlign: 'right',
    display: 'block',
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  dialogDescription: {
    fontFamily: 'Asap',
    fontSize: '14px',
    textAlign: 'center',
  },
  dialogDescriptionBold: {
    fontFamily: 'Asap',
    fontSize: '14px',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  badgeFilter: {
    color: '#fff',
    marginRight: '5px',
    marginTop: '5px',
    height: '25px',
    padding: '0px 8px',
  },
  titleContainer: {
    ...dialogTitleContainerStyle,
  },
  closeButton: {
    ...dialogCloseButtonStyle,
    color: theme && theme.palette.grey[500],
  },
  icon: {
    height: '30px',
    width: '30px',
    padding: '12px',
    textAlign: 'center',
    margin: '20px 0',
    border: `solid 3px ${tiffany}`,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: `${tiffany}`,
  },
  dialogActions: {
    justifyContent: 'center',
    padding: '8px 25px',
    margin: 0,
    cursor: 'pointer',
    [theme && theme.breakpoints.down('sm')]: {
      padding: '8px 15px',
    },
  },
  dialogContent: {
    padding: '8px 25px',
    [theme && theme.breakpoints.down('sm')]: {
      padding: '8px 15px',
    },
  },
  button: {
    backgroundColor: `${navy}`,
    color: 'white',
    width: '100%',
    margin: 0,
    padding: '14px',
    cursor: 'pointer',
    '&:disabled': {
      color: 'gray',
      backgroundColor: '#f0f0f0',
    },
    ...hoverEffectTwo,
  },
  typographyName: {
    marginTop: '15px',
    fontWeight: 'bold',
  },
  formRow: {
    ...dialogFormRowStyle,
  },
  dialogPaperStyle: {
    padding: '24px 4px',
    minWidth: '510px',
    [theme && theme.breakpoints.down('sm')]: {
      minWidth: 0,
    },
  },
  textareaInput: {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
  },
  dashletToolTipPopper: {
    fontSize: '12px',
    fontStyle: 'italic',
    maxWidth: '300px',
    padding: '10px',
  },
  charCounterContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '5px',
    zIndex: '99',
  },
  wordCounterText: {
    fontSize: '12px',
  },
  wordCounterToolTipIcon: {
    fontSize: '20px',
  },
  wordCounterToolTipButton: {
    padding: 0,
    margin: '6px',
  },
});

class PingUserDialog extends Component {
  constructor() {
    super();
    this.state = {
      selectedAdmins: [],
      message: '',
    };
  }

  handleSave() {
    const {
      handleSaveClick,
    } = this.props;
    const {
      selectedAdmins, message,
    } = this.state;
    handleSaveClick(selectedAdmins, message);
    this.handleClose();
  }

  handleClose() {
    const {
      handleCloseClick,
    } = this.props;
    this.setState({
      selectedAdmins: [],
      message: '',
    });
    handleCloseClick();
  }

  render() {
    const {
      classes, open, admins, theme,
    } = this.props;
    const adminList = admins.filter(x => x.enabled && x.adminRole !== ADMIN_NO_PRACTICE_KEY);
    const stylesWithTheme = PingUserDialogStyles(theme);

    return (
      adminList && adminList.length > 0
      && (
        <Dialog
          maxWidth="md"
          PaperProps={{ sx: stylesWithTheme.dialogPaperStyle }}
          open={open}
          onClose={() => this.handleClose()}
        >
          <DialogTitle sx={stylesWithTheme.dialogContent}>
            <div className={classes.titleContainer}>
              <NotificationsActiveIcon sx={stylesWithTheme.icon} />
              <Typography color="primary" sx={stylesWithTheme.title}>
                PING USER
              </Typography>
            </div>
            <IconButton
              aria-label="close"
              sx={stylesWithTheme.closeButton}
              onClick={() => this.handleClose()}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={stylesWithTheme.dialogContent}>
            <div className={classes.formRow}>
              <FormAutocomplete
                autocompleteOnly
                value={this.state.selectedAdmins}
                title="Search user by name"
                options={adminList}
                handleStateChange={value => this.setState({ selectedAdmins: Array.isArray(value) ? value : (value ? [value] : []) })}
                letterLimit={3}
              />
            </div>
            <div className={classes.textareaInput}>
              <TextField
                placeholder="(Optional) Add a message."
                label=""
                onChange={event => this.setState({ message: event.target.value })}
                variant="outlined"
                multiline
                fullWidth
                id="message"
                inputProps={{
                  autoCapitalize: 'none',
                  style: {
                    height: '120px',
                  },
                }}
                autoComplete="off"
              />
            </div>
            <div className={classes.charCounterContainer}>
              <Typography sx={stylesWithTheme.wordCounterText}>{`Characters: ${this.state.message.length}`}</Typography>
            </div>
          </DialogContent>
          <DialogActions sx={stylesWithTheme.dialogActions}>
            <Button
              sx={stylesWithTheme.button}
              onClick={() => this.handleSave()}
              color="primary"
              disabled={this.state.selectedAdmins.length < 1}
            >
              Ping User
            </Button>
          </DialogActions>
          <DialogActions style={{ textAlign: 'center' }}>
            <Typography sx={stylesWithTheme.cancel} onClick={() => this.handleClose()}>Close</Typography>
          </DialogActions>
        </Dialog>
      )
    );
  }
}


PingUserDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  actions: PropTypes.any.isRequired,
  apiToken: PropTypes.string.isRequired,
};

export default withStyles(PingUserDialogStyles, { withTheme: true })(PingUserDialog);
