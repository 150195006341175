import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import {
  Card, CardContent, Typography, Divider,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import MessageNotificationBubble from '../subcomponents/MessageNotificationBubble';
import { EXTERNSHIP } from '../../data/Constants';

const ConnectionCardStyles = theme => ({
  card: {
    width: '100%',
    margin: '10px 0',
    cursor: 'pointer',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: '-20px',
  },
  NameContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  image: {
    maxWidth: 45,
  },
  header: {
    marginLeft: '20px',
    fontSize: '14px',
  },
  divider: {
    margin: '15px 0',
  },
  title: {
    color: '#8c8c8c',
    fontSize: '14px',
  },
  title2: {
    color: '#8c8c8c',
    marginTop: '15px',
    fontSize: '14px',
  },
});

class ConnectionCard extends Component {
  render() {
    const {
      classes, data, backgroundColor, history, onClick,
    } = this.props;

    const styles = ConnectionCardStyles();

    return (
      <Card sx={styles.card} style={{ backgroundColor }} onClick={onClick}>
        <CardContent>
          {data.headerImage && (
            <div className={classes.headerContainer}>
              <div className={classes.NameContainer}>
                  {data.headerImage}
                  <Typography sx={styles.header}>{data.header}</Typography>
              </div>
              <div
                onClick={(event) => {
                  event.stopPropagation();
                  history.push(`/messages/${data.key}`);
                }}
              >
                <MessageNotificationBubble
                  unreadMessagesCount={data.unreadMessagesCount}
                />
              </div>
            </div>
          )}
          {data.headerImage && (<Divider sx={styles.divider} />)}
          <Typography sx={styles.title}>{data.title1}</Typography>
          <Typography>{data.value1}</Typography>
          {data.title2 && (<Typography sx={styles.title2}>{data.title2}</Typography>)}
          {data.value2 && (<Typography>{data.value2}</Typography>)}
          {data.value2 && data.value2 === EXTERNSHIP && data.value4 && data.value4.length > 0 && (<Typography sx={styles.title2}>{data.title4}</Typography>)}
          {data.value2 && data.value2 === EXTERNSHIP && data.value4 && data.value4.length > 0 && (<Typography>{data.value4.join(', ')}</Typography>)}
          {data.title3 && (<Typography sx={styles.title2}>{data.title3}</Typography>)}
          {data.value3 && (<Typography>{data.value3}</Typography>)}
        </CardContent>
      </Card>
    );
  }
}

ConnectionCard.propTypes = {
  data: PropTypes.object.isRequired,
  backgroundColor: PropTypes.string,
};

ConnectionCard.defaultProps = {
  backgroundColor: '#ffffff',
};

export default withStyles(ConnectionCardStyles)(ConnectionCard);
