import React, { Component } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  IconButton,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { badgeDialogStyles } from '../../css/dialogStyles';
import PracticeAutocomplete from './PracticeAutocomplete';

class AddFastpassPracticeDialog extends Component {
  constructor() {
    super();
    this.state = {
      selectedPractices: [],
    };
  }

  handleSave() {
    const {
      handleOnClose, actions, apiToken, talentId, fastpassBadgeId,
    } = this.props;
    const { selectedPractices } = this.state;

    if (!talentId || isEmpty(selectedPractices)) return;

    actions.addResource(apiToken, {
      practices: selectedPractices.map(p => p.id),
      talentId,
      fastpassBadgeId,
    }, 'fastpass/assign-practices-for-talent').then((e) => {
      if (!e.error) {
        handleOnClose(true);
      }
    });
  }

  render() {
    const {
      classes, handleOnClose, open, practices, theme, fastpassData, autoFocus,
    } = this.props;
    const { selectedPractices } = this.state;
    const {
      title, closeButton, button, dialogContent,
      disabledButton, dialogActions,
    } = badgeDialogStyles(theme);

    return (
      <Dialog
        maxWidth="md"
        PaperProps={{
          style: {
            padding: '24px 4px',
            minWidth: '500px',
          },
        }}
        open={open}
        onClose={() => handleOnClose()}
      >
        <DialogTitle sx={dialogContent}>
          <div className={classes.titleContainer}>
            <Typography color="primary" sx={title}>
              Add Fastpass Practice
            </Typography>

          </div>
          <IconButton
            aria-label="close"
            sx={closeButton}
            onClick={() => handleOnClose()}
          >
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={dialogContent}>
          <PracticeAutocomplete
            title='Type letters'
            items={practices || []}
            value={selectedPractices}
            handleStateChange={(value) => {
              this.setState({ selectedPractices: value });
            }}
            form
            noOptionsText="No Practices"
            fastpassData={fastpassData}
            autoFocus={autoFocus}
          />
        </DialogContent>
        <DialogActions sx={dialogActions}>
          <Button
            disabled={isEmpty(selectedPractices)}
            sx={!isEmpty(selectedPractices) ? button : disabledButton}
            onClick={() => this.handleSave()}
            color="primary"
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}


AddFastpassPracticeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  actions: PropTypes.any.isRequired,
  apiToken: PropTypes.string.isRequired,
};

AddFastpassPracticeDialog.defaultProps = {
  open: false,
};

export default withStyles(badgeDialogStyles, { withTheme: true })(AddFastpassPracticeDialog);
