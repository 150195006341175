import React, { Component, Fragment } from 'react';
import {
  Typography, Paper, Icon, IconButton, Grid, Button,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import { commonResourceStyle } from '../../css/style';
import {
  EXPERIENCE_LEVELS_CONFIG,
  EXPERIENCE_TYPE_CONFIG,
  EXPERIENCE_CATEGORY_SPECIAL_FIELD_CONFIG, EXPERIENCE_LEVELS_OBJ,
} from '../../data/Constants';
import TalentExperienceItem from './TalentExperienceItem';
import SingleSelect from './SingleSelect';

const styles = commonResourceStyle();

class TalentExperience extends Component {
  constructor(props) {
    super(props);
    this.state = { tier3: false };
  }

  setExperienceLevel(experienceLevel) {
    const { state, setState } = this.props;
    const { talentType } = state;
    const obj = { experienceLevel };
    obj.customExperienceLevel = false;
    if (talentType && experienceLevel && EXPERIENCE_LEVELS_CONFIG[talentType] && EXPERIENCE_LEVELS_CONFIG[talentType][experienceLevel]) {
      setState(Object.assign(obj, EXPERIENCE_LEVELS_CONFIG[talentType][experienceLevel]));
    } else {
      setState(obj);
    }
  }

  setExperienceLevelValue = () => {
    const { state, setState } = this.props;
    const { talentType } = state;
    const yearsFieldName = (talentType === 'non-clinical') ? 'years-in-animal-care' : 'years-in-position';

    const juniorYears = EXPERIENCE_LEVELS_CONFIG[talentType].Junior[yearsFieldName];
    const intermediateYears = EXPERIENCE_LEVELS_CONFIG[talentType].Intermediate[yearsFieldName];
    const seniorYears = EXPERIENCE_LEVELS_CONFIG[talentType].Senior[yearsFieldName];

    const yearsInCurrentPosition = state[yearsFieldName] || 0;
    if (yearsInCurrentPosition < intermediateYears) return 'Junior';
    else if (intermediateYears <= yearsInCurrentPosition && yearsInCurrentPosition < seniorYears) return 'Intermediate';
    else if (yearsInCurrentPosition >= seniorYears) return 'Senior';
    else return 'Custom';
  }

  render() {
    const {
      onClose, state, setState, customWidth, isDesktop, classes, theme,
    } = this.props;
    const { tier3 } = this.state;
    const { talentType } = state;
    const talentTypeConfig = EXPERIENCE_LEVELS_CONFIG[talentType] || {};
    const talentCategoryHiddenField = EXPERIENCE_CATEGORY_SPECIAL_FIELD_CONFIG[talentType] || {};
    const showExperienceLevel = talentType && (talentType !== 'specialist-veterinarian') && EXPERIENCE_LEVELS_CONFIG[talentType] && Object.keys(EXPERIENCE_LEVELS_CONFIG[talentType]).length;
    const experienceLevelValue = showExperienceLevel && this.setExperienceLevelValue();
    const stylesWithTheme = commonResourceStyle(theme);

    return (
      <Paper style={styles.innerPaper}>
        <Typography style={styles.resourceSub}>TALENT EXPERIENCE</Typography>
        <IconButton onClick={e => onClose(e)} style={styles.closeButton}><Icon>close</Icon></IconButton>
        {talentType && (
          <Grid container={isDesktop} spacing={2}>
            {Object.keys(EXPERIENCE_TYPE_CONFIG)
              .filter(key => talentTypeConfig[key] && !EXPERIENCE_TYPE_CONFIG[key].tier3 && talentCategoryHiddenField.key !== key)
              .map(key => (
                <TalentExperienceItem
                  key={key}
                  type={key}
                  config={EXPERIENCE_TYPE_CONFIG[key]}
                  state={state}
                  setState={e => setState(e)}
                />
              ))
            }
            {showExperienceLevel
              ? (
                <Grid item md={6}>
                  <SingleSelect
                    value={experienceLevelValue}
                    title="Experience Level"
                    items={EXPERIENCE_LEVELS_OBJ}
                    onChange={experienceLevel => this.setExperienceLevel(experienceLevel)}
                    form
                  />
                </Grid>
              ) : ''
            }
            {tier3 && (
              <Grid item md={12}>
                <Paper style={styles.innerPaper2}>
                  <IconButton
                    onClick={e => this.setState({ tier3: false })}
                    style={styles.closeButton}
                  >
                    <Icon>close</Icon>
                  </IconButton>
                  <Grid container={isDesktop} spacing={2}>
                    {Object.keys(EXPERIENCE_TYPE_CONFIG)
                      .filter(key => talentTypeConfig[key] && EXPERIENCE_TYPE_CONFIG[key].tier3)
                      .map((key, index) => (
                        <Fragment>
                          {
                            // render description befor comfort level questions (start from index 2)
                            index === 2 && (
                              <Typography sx={{ ...stylesWithTheme.resourceSub, margin: '15px 0 0 8px', width: '100%' }}>
                                Candidates minimum comfort level with the following tasks (5 is super high confidence):
                              </Typography>
                            )
                          }
                          <TalentExperienceItem
                            key={key}
                            type={key}
                            config={EXPERIENCE_TYPE_CONFIG[key]}
                            state={state}
                            setState={e => setState(e)}
                          />
                        </Fragment>
                      ))}
                  </Grid>
                </Paper>
              </Grid>
            )}
            {!tier3 && (
              <Grid item md={6} sx={stylesWithTheme.addMoreButtonContainer}>
                <Button variant="contained" sx={stylesWithTheme.addMoreButton} onClick={e => this.setState({ tier3: true })}>
                  <Icon sx={stylesWithTheme.addMoreButtonIcon}>add_circle_outline</Icon>
                  ADD MORE DETAILS
                </Button>
              </Grid>
            )}
          </Grid>
        )
        }
      </Paper>
    );
  }
}

export default withStyles(commonResourceStyle, { withTheme: true })(TalentExperience);
