import {
  combineReducers,
} from 'redux-immutable';
import loading from './LoadingReducer';
import errors from './ErrorReducer';
import message from './MessageReducer';
import resources from './ResourcesReducer';
import admin from './AuthReducer';


const rootReducer = combineReducers({
  loading,
  message,
  errors,
  resources,
  admin,
});

export default rootReducer;
