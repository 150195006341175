import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Icon, Tooltip, Button, Fade, Grid,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import { commonResourceStyle, orange } from '../../css/style';
import { IMG_STORAGE_LINK } from '../../data/Constants';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    const { dashlets } = props;
    const initState = {};
    dashlets.forEach(dashlet => initState[`isMouseHover${dashlet.pos}`] = false);
    initState.isToolTipOpen = new Array(6);
    this.state = initState;
  }

  nFormatter = (num) => {
    if (num >= 1000000000) {
      return `${(num / 1000000000).toFixed(1).replace(/\.0$/, '') }G`;
    }
    if (num >= 1000000) {
      return `${(num / 1000000).toFixed(1).replace(/\.0$/, '') }M`;
    }
    if (num >= 1000) {
      return `${(num / 1000).toFixed(1).replace(/\.0$/, '') }K`;
    }
    return num;
  };

  handleMouseHover = (key, isHover) => {
    if (!isHover) {
      let toolTipState = this.state.isToolTipOpen;
      toolTipState[key] = false;
      this.setState({ isToolTipOpen: toolTipState });
    }
    this.setState({
      [`isMouseHover${key}`]: isHover,
    });
  };

  handleOnClick = (data) => {
    const { history } = this.props;
    if (data.path) {
      history.push(data.path);
    }
  };

  handleToolTipStates = (data, isOpen, isFullyClose) => {
    let toolTipState = this.state.isToolTipOpen;
    toolTipState[data.pos] = isOpen;
    if (isFullyClose) {
      this.handleMouseHover(data.pos, false);
    }
    this.setState({ isToolTipOpen: toolTipState });
  }

  getDashlet = (data) => {
    const { classes, startPosition, theme, isDesktop } = this.props;
    const isMouseHover = this.state[`isMouseHover${data.pos}`];
    const hoverColor = '#ffffff';
    const slideDist = startPosition * 33;
    const cardSpecialStyle = {
      cursor: data.path && 'pointer',
      marginLeft: (data.pos === 0) && `calc(-${slideDist}% + 12px)`,
      backgroundColor: isMouseHover ? orange : hoverColor,
      // The margin-left is only applied to the first card. The slide of cards depends on this value
      // The 12px is for the first card's appearance
    };
    const { toolTipContentCloseIcon, dashlet,
      dashletIcon, dashletToolTip, dashletToolTipPopper } = commonResourceStyle(theme);
    const toolTipContent = (
      <div
        className={classes.toolTipContent}
        onClick={e => e.stopPropagation()}
      >
        <Icon
          sx={toolTipContentCloseIcon}
          onClick={() => this.handleToolTipStates(data, false, true)}
        >
          highlight_off
        </Icon>
        {data.toolTip}
      </div>
    );

    return (
      <Grid
        key={data.pos}
        item
        xs={5}
        sm={5}
        md={2}
        lg={2}
        sx={dashlet}
        style={cardSpecialStyle}
        onClick={() => this.handleOnClick(data)}
        onMouseEnter={() => this.handleMouseHover(data.pos, true)}
        onMouseLeave={() => this.handleMouseHover(data.pos, false)}
      >
        <div className={classes.dashletIconValue}>
          <Icon
            sx={dashletIcon}
            style={{ color: isMouseHover ? hoverColor : '#243060' }}
          >
            {data.icon}
          </Icon>
          <div
            className={classes.dashletValue}
            style={{ color: isMouseHover ? hoverColor : orange }}
          >
            {this.nFormatter(data.value)}
          </div>
          {
            isDesktop
              && (
                <Tooltip
                  title={toolTipContent}
                  sx={dashletToolTip}
                  style={{ color: isMouseHover ? hoverColor : '#b3b3b3' }}
                  classes={{ tooltip: classes.dashletToolTipPopper }}
                  placement='top'
                  arrow
                  open={!!this.state.isToolTipOpen[data.pos]}
                  onMouseEnter={() => this.handleToolTipStates(data, true)}
                  onClose={() => this.handleToolTipStates(data, false)}
                  interactive
                >
                  <Icon>
                    error_outline
                  </Icon>
                </Tooltip>
              )
          }
        </div>
        <div className={classes.dashletTitle} style={{ color: isMouseHover ? hoverColor : '#243060' }}>
          {data.title}
        </div>
        <div className={classes.dashletCaption} style={{ color: isMouseHover ? hoverColor : '#4bbdad' }}>
          {data.caption}
        </div>
      </Grid>
    );
  };

  shouldDisplayNav = (isPrev) => {
    const { startPosition, display, dashlets } = this.props;
    // Display prev navigation
    if (isPrev) {
      return startPosition > 0;
    }
    // Display next navigation
    return !(startPosition + display >= dashlets.length);
  };

  renderNextCircleAndImage = () => {
    const { classes, startPosition, handleOnClick, theme } = this.props;
    const { navIcon, headerImageContainer } = commonResourceStyle(theme);

    return (
      <Fragment>
        {this.shouldDisplayNav(false) && (
          <div className={classes.nextCircle} onClick={() => handleOnClick(startPosition + 3)}>
            <Icon sx={navIcon}>navigate_next</Icon>
          </div>
        )}
      <Grid item xs={1} sm={1} md={4} lg={3} sx={headerImageContainer}>
        <img
          src={`${IMG_STORAGE_LINK}image-dog.png`}
          alt="Dog"
          className={classes.headerDogImage}
        />
      </Grid>
      </Fragment>
    );
  }

  render() {
    const {
      dashlets, startPosition, display, handleOnClick, classes, theme, isDesktop,
    } = this.props;
    const shouldFadeIn = startPosition == 3;
    const { dashboard, navIcon, dashletContainer } = commonResourceStyle(theme);

    return (
      <Grid container justifyContent="flex-start" sx={dashboard}>
        {this.shouldDisplayNav(true) && (
          <div className={classes.prevCircle} onClick={() => handleOnClick(startPosition - 3)}>
            <Icon sx={navIcon}>navigate_before</Icon>
          </div>
        )}
        <Grid container={!isDesktop} sx={dashletContainer}>
          {
            isDesktop
              ? dashlets.map(dashlet => this.getDashlet(dashlet))
              : (
                <Fragment>
                  {!shouldFadeIn && dashlets.slice(0, 3).map(dashlet => <Fade in={!shouldFadeIn}>{this.getDashlet(dashlet)}</Fade>)}
                  {shouldFadeIn && dashlets.slice(3).map(dashlet => <Fade in={shouldFadeIn}>{this.getDashlet(dashlet)}</Fade>)}
                  <Grid item xs={5}>
                    {this.renderNextCircleAndImage()}
                  </Grid>
                </Fragment>
              )
          }
        </Grid>
        {isDesktop && this.renderNextCircleAndImage()}
      </Grid>
    );
  }
}

Dashboard.propTypes = {
  handleOnClick: PropTypes.func.isRequired,
  dashlets: PropTypes.array.isRequired,
  totalUnreadMessagesCount: PropTypes.number,
  display: PropTypes.number,
  startPosition: PropTypes.number,
};

Dashboard.defaultProps = {
  totalUnreadMessagesCount: 0,
  display: 3,
  startPosition: 0,
};

function DashboardWrapper({ theme, ...rest }) {
  const isDesktop = theme ? useMediaQuery(theme.breakpoints.up('md')) : true;
  return <Dashboard {...rest} theme={theme} isDesktop={isDesktop} />;
}

export default withStyles(commonResourceStyle, { withTheme: true })(withRouter(DashboardWrapper));
