import React from 'react';
import { createMemoryHistory } from 'history';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';
import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';


import rootReducer from '../reducers';

const middleware = routerMiddleware(createMemoryHistory);
const composeEnhancers = composeWithDevToolsDevelopmentOnly({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
});

export function configureStore(initialstate) {
  const store = createStore(
    rootReducer,
    initialstate,
    // To enable the devToolsExtension
    composeEnhancers(
      applyMiddleware(middleware, thunk),
    ),
  );

  return store;
}
