/* eslint-disable quote-props */
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import dayjs from 'dayjs';
import moment from 'moment';
import { isEmpty, max, trim } from 'lodash';
import {
  Button,
  Paper,
  Typography,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Hidden,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DataGrid } from '@mui/x-data-grid';

import BarChart from '../subcomponents/BarChart';
import MaterialUIPickers from '../subcomponents/MaterialUIPickers';
import { PracticeReportsStyles } from './styles/PracticeReportsStyles';
import { lightGray } from '../../css/style';
import { formatPostTitle } from '../../utils/Functions';

const categories = {
  'doctor': 'DVM',
  'vet-tech-or-assistant': 'VET TECH',
  'csr': 'CSR',
  'other': 'OTHER',
};

const confirmationColumns = [
  {
    field: 'shift_date',
    headerName: 'Shift Date',
    width: 110,
  },
  {
    field: 'role',
    headerName: 'Role',
    flex: 0.5,
  },
  {
    field: 'title',
    headerName: 'Title',
    flex: 1,
  },
  {
    field: 'worker_name',
    headerName: 'Worker Name',
    flex: 1,
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
  },
];

const employeeColumns = [
  {
    field: 'shift_date',
    headerName: 'Shift Date',
    width: 110,
  },
  {
    field: 'practice_name',
    headerName: 'Practice Name',
    flex: 1,
  },
  {
    field: 'worker_name',
    headerName: 'Worker Name',
    flex: 1,
  },
  {
    field: 'role',
    headerName: 'Role',
    flex: 0.5,
  },
];

const chartOptions = {
  scales: {
    y: {
      display: true,
      ticks: {
        beginAtZero: true,
        max: 100,
      },
    },
  },
  plugins: {
    legend: false,
  },
};

const pageSizeOptions = [5, 10, 25, 50, 100];

const responsivenessLabelMap = {
  firstAdminMsgMeanTime: 'Average time of First Admin Message',
  adminResponseMeanTime: 'Average time of Admin Response',
  dryChatConnectionCount: 'Number of Dry Chats',
};

class PracticeReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      practice: null,
      selectedDate: {},
      shiftsAvailableData: [],
      shiftsTakenData: [],
      confirmationData: [],
      employeeData: [],
      responsiveness: {},
    };
  }

  componentDidMount = async () => {
    await this.retrieveReportData(new Date());
  };

  retrieveReportData = async (selectedDate) => {
    const { actions, apiToken, match } = this.props;
    const { resourceId } = match.params;

    this.setState({ selectedDate });

    const selectedMonth = dayjs(selectedDate).month() + 1;
    const selectedYear = dayjs(selectedDate).year();

    if (isNaN(selectedMonth) || isNaN(selectedYear)) {
      return;
    }

    let queryParams = `reports?practiceId=${resourceId}&month=${selectedMonth}&year=${selectedYear}`;
    const res = await actions.getOneResource(apiToken, queryParams);

    if (res && res.response) {
      const reportData = res.response;
      this.setState({
        shiftsAvailableData: this.setData(reportData.shiftsAvailable, true),
        shiftsTakenData: this.setData(reportData.shiftsTaken, true),
        confirmationData: this.setData(reportData.confirmation),
        employeeData: this.setData(reportData.employee),
        practice: reportData.practice,
        responsiveness: reportData?.responsiveness || {},
      });
    }
  };

  setData = (data, isChart) => {
    if (isEmpty(data)) return [];
    if (isChart) {
      return Object.keys(categories).map(cat => data[cat] || 0);
    } else {
      return data.map((row, index) => ({
        ...row,
        id: index,
        shift_date: moment(row.shift_date).format('MMM.DD'),
        role: categories[row.role],
        title: formatPostTitle(row.title),
      }));
    }
  };

  renderHeader = () => {
    const { classes, theme } = this.props;
    const { practice, selectedDate } = this.state;
    const { practiceName } = PracticeReportsStyles(theme);

    if (!practice) {
      return null;
    }
    return (
      <div className={classes.titleContainer}>
        <Typography sx={practiceName}>
          {practice.name}
        </Typography>
        <div className={classes.root}>
          <MaterialUIPickers
            handleStateChange={date => this.retrieveReportData(date)}
            openTo='month'
            views={['year', 'month']}
            value={selectedDate}
          />
        </div>
      </div>
    );
  };

  render() {
    const {
      classes,
      match,
      history,
      isDesktop,
      theme,
    } = this.props;
    const {
      shiftsAvailableData,
      shiftsTakenData,
      employeeData,
      confirmationData,
      selectedDate,
      responsiveness,
    } = this.state;

    let maxValue = max([...shiftsAvailableData, ...shiftsTakenData]) || 0;

    if (maxValue < 10) {
      maxValue = 10;
    } else if (maxValue % 10 > 0) {
      maxValue = maxValue + (10 - (maxValue % 10));
    }

    chartOptions.scales.y.ticks.max = maxValue;

    chartOptions.layout = {
      padding: {
        left: '10px',
        right: isDesktop ? '100px' : '10px',
      },
    };

    const stylesWithTheme = PracticeReportsStyles(theme);

    return (
      <div className={classes.mainContainer}>
        <div className={classes.resourceHeader}>
          <div className={classes.resourceNameLarge}>
            <Typography sx={stylesWithTheme.resourceNameLarge}>
              PRACTICE REPORTS
            </Typography>
            <Hidden smDown>
              <Button
                sx={stylesWithTheme.headerButton}
                onClick={() => history.push(`/practices/${match.params.resourceId}/view`)}
              >
                BACK
              </Button>
            </Hidden>
          </div>
        </div>

        <Paper sx={stylesWithTheme.paper}>
          <div className={classes.reportContainer}>
            {this.renderHeader()}
          </div>
          <Grid container sx={stylesWithTheme.gridContainer} spacing={2}>
            {/* Shifts Available Chart */}
            <Grid item xs={12} md={6}>
              <Card sx={stylesWithTheme.card}>
                <CardHeader
                  title={(
                    <Typography color="secondary" sx={stylesWithTheme.title}>Shifts Available</Typography>
                  )}
                  subheader={dayjs(selectedDate).format('MMMM, YYYY')}
                />
                <CardContent sx={stylesWithTheme.cardContent}>
                  <BarChart
                    categories={categories}
                    key='avaiShifts'
                    data={{
                      labels: Object.values(categories),
                      datasets: [{
                        label: '# shifts',
                        data: shiftsAvailableData,
                      }],
                    }}
                    options={chartOptions}
                  />
                </CardContent>
              </Card>
            </Grid>
            {/* Shifts Taken Chart */}
            <Grid item xs={12} md={6}>
              <Card sx={stylesWithTheme.card}>
                <CardHeader
                  title={(
                    <Typography color="secondary" sx={stylesWithTheme.title}>Shifts Taken</Typography>
                  )}
                  subheader={dayjs(selectedDate).format('MMMM, YYYY')}
                />
                <CardContent sx={stylesWithTheme.cardContent}>
                  <BarChart
                    key='takenShifts'
                    categories={categories}
                    data={{
                      labels: Object.values(categories),
                      datasets: [{
                        label: '# shifts',
                        data: shiftsTakenData,
                      }],
                    }}
                    options={chartOptions}
                  />
                </CardContent>
              </Card>
            </Grid>
            {/* Confirmation Report */}
            <Grid item xs={12} md={12}>
              <Card sx={stylesWithTheme.card}>
                <CardHeader
                  title={(
                    <Typography color="secondary" sx={stylesWithTheme.title}>Confirmation Report</Typography>
                  )}
                  subheader={dayjs(selectedDate).format('MMMM, YYYY')}
                />
                <CardContent sx={stylesWithTheme.cardContent}>
                  <div className={classes.dataGridContainer}>
                    <DataGrid
                      autoHeight
                      rows={confirmationData}
                      columns={confirmationColumns}
                      initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                      }}
                      pageSizeOptions={pageSizeOptions}
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
            {/* Employee Report */}
            <Grid item xs={12} md={6}>
              <Card sx={stylesWithTheme.card}>
                <CardHeader
                  title={(
                    <Typography color="secondary" sx={stylesWithTheme.title}>Employee Report</Typography>
                  )}
                  subheader={dayjs(selectedDate).format('MMMM, YYYY')}
                />
                <CardContent sx={stylesWithTheme.cardContent}>
                  <div className={classes.dataGridContainer}>
                    <DataGrid
                      autoHeight
                      key='employeeTable'
                      rows={employeeData}
                      columns={employeeColumns}
                      initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                      }}
                      pageSizeOptions={pageSizeOptions}
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
            {/* Responsiveness Report */}
            {responsiveness && (
            <Grid item xs={12} md={6}>
              <Card sx={stylesWithTheme.card}>
                <CardHeader
                  title={(
                    <Typography color="secondary" sx={stylesWithTheme.title}>Responsiveness Report</Typography>
                  )}
                  subheader={dayjs(selectedDate).format('MMMM, YYYY')}
                />
                <CardContent sx={stylesWithTheme.cardContent}>
                <Grid container style={{ backgroundColor: lightGray }} rowSpacing={2}>
                  <Grid item xs={6} key='resp_item_label'>
                    {Object.keys(responsivenessLabelMap).map(key => (
                      <Grid item xs={12} sx={stylesWithTheme.responsiveneseItem} key={`${key}_label_item`}>
                        <Typography key={`${key}_label`}>
                          {`${responsivenessLabelMap[key]}:`}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                  <Grid item xs={6} key='resp_item_value'>
                    {Object.keys(responsivenessLabelMap).map((key) => {
                      let value = responsiveness[key];
                      if (['firstAdminMsgMeanTime', 'adminResponseMeanTime'].includes(key)) {
                        const milliseconds = responsiveness[key]; // 24 * 60 * 60 * 1000
                        const seconds = milliseconds / 1000; // 28 --> 28secs; 9000 --> 2h 30m; 1110 --> 18m 30s; 173910 --> 2 days 0 hours 18 mins 30 secs
                        const mins = seconds / 60;
                        const hours = mins / 60;
                        const days = hours / 24;

                        let sValue = seconds;
                        let mValue = 0;
                        let hValue = 0;
                        let dValue = 0;

                        if (parseInt(days) > 0) { // days
                          dValue = days;
                          hValue = calcTime(dValue, 24);
                          mValue = calcTime(hValue, 60);
                          sValue = calcTime(mValue, 60);
                        } else if (parseInt(hours) > 0) { // hours
                          hValue = hours;
                          mValue = calcTime(hValue, 60);
                          sValue = calcTime(mValue, 60);
                        } else if (parseInt(mins) > 0) { // mins
                          mValue = mins;
                          sValue = calcTime(mValue, 60);
                        }
                        value = `${timeStr(dValue, 'day')} ${timeStr(hValue, 'hour')} ${timeStr(mValue, 'min')} ${timeStr(sValue, 'sec')}`;
                      }
                      return (
                        <Grid item xs={12} sx={stylesWithTheme.responsiveneseItem} key={`${key}_item`}>
                          <Typography key={`${key}_value`}>
                            {` ${trim(value) || '-' }`}
                          </Typography>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
                </CardContent>
              </Card>
            </Grid>
            )}
          </Grid>
        </Paper>
      </div>
    );
  }
}

const calcTime = (value, multiplier) => {
  const dDividend = parseInt(value) > 0 ? parseInt(value) : 1;
  return value % dDividend > 0 ? (value % dDividend) * multiplier : 0;
};

const timeStr = (value, unit) => {
  const intValue = parseInt(value);
  return intValue > 0 ? intValue > 1 ? `${intValue} ${unit}s` : `${intValue} ${unit}` : '';
};

function PracticeReportsWrapper({ theme, ...rest }) {
  const isDesktop = theme ? useMediaQuery(theme.breakpoints.up('md')) : true;
  return <PracticeReports {...rest} theme={theme} isDesktop={isDesktop} />;
}

export default withStyles(PracticeReportsStyles, { withTheme: true })(withRouter(PracticeReportsWrapper));
