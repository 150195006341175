import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { FormLabel, FormControl } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Editor } from '@tinymce/tinymce-react';

import { editorApiKey } from '../../data/Constants';
import { commonResourceStyle, navy } from '../../css/style';

const editorInputStyle = theme => ({
  ...commonResourceStyle(theme),
  formControl: {
    '& .tox.tox-tinymce': {
      borderRadius: '4px',
      borderColor: `${navy}`,
      borderWidth: '1px',
    },
  },
});

const editorSetting = {
  plugins: 'link image code table',
  toolbar: 'undo redo | formatselect | bold italic forecolor | alignleft aligncenter alignright',
  resize: false,
  height: '100%',
  width: '100%',
};

class EditorInput extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      value, title, classes, customFormControlStyle, customLabelStyle,
      isRequired, onChange, disabled = false, theme,
    } = this.props;
    const displayError = isRequired && isEmpty(value);
    const stylesWithTheme = editorInputStyle(theme);

    return (
      <FormControl fullWidth sx={stylesWithTheme.formControl} style={customFormControlStyle || stylesWithTheme.formControl}>
        <div style={stylesWithTheme.formControlLabelContainer}>
          <FormLabel sx={stylesWithTheme.formControlLabel} style={customLabelStyle || {}}>{title}</FormLabel>
          {displayError && (
            <FormLabel sx={stylesWithTheme.formControlLabelWarnText}>This field is required</FormLabel>
          )}
        </div>
        <Editor
          apiKey={editorApiKey}
          value={value}
          init={editorSetting}
          onEditorChange={value => onChange(value)}
          disabled={disabled}
        />
      </FormControl>
    );
  }
}

EditorInput.propTypes = {
  value: PropTypes.string,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  customFormControlStyles: PropTypes.object,
  isRequired: PropTypes.bool,
  disable: PropTypes.bool,
};

EditorInput.defaultProps = {
  isRequired: false,
};

export default withStyles(editorInputStyle, { withTheme: true })(EditorInput);
