import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Icon, Paper, Typography, Switch, Tooltip, IconButton,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import {
  practicesPaginationCount,
  resourceFields,
  IMG_STORAGE_LINK,
  BASE_URLS,
} from '../../data/Constants';
import DisplayResources from '../crud/DisplayResources';
import types from '../../actions/ActionTypes';
import LoadingBar from '../subcomponents/LoadingBar';
import SearchComponent from '../subcomponents/SearchComponent';
import ArchiveDialog from '../subcomponents/ArchiveDialog';
import MessageNotificationBubble from '../subcomponents/MessageNotificationBubble';
import { utils } from '../../utils/Functions';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LensIcon from '@mui/icons-material/Lens';
import { practicesPageStyles } from './styles/PracticesStyles';
import {
  gray,
  green,
  orange,
  switchStyles,
} from '../../css/style';

const selectValues = [
  {
    key: 'all status',
    title: 'All status',
  },
  {
    key: 'active',
    title: 'Active',
  },
  {
    key: 'inactive',
    title: 'Inactive',
  },
  {
    key: 'archived',
    title: 'Archived',
  },
];

class Practices extends Component {
  constructor(props) {
    let newVar = super(props);
    this.state = {
      orderBy: 'asc',
      searchValue: '',
      selectValue: 'active',
      open: false,
      archiveId: null,
      archiveName: '',
      fetchedPractices: null,
    };
  }

  componentDidMount = async () => {
    const {
      actions, apiToken, pagination, history,
    } = this.props;

    const searchValue = get(pagination, 'search', '');
    const start = get(pagination, 'start', '');
    const filter = get(pagination, 'filter', 'active');

    let queryParams = `practices?start=${start}&count=${practicesPaginationCount}&orderby=${this.state.orderBy}&search=${searchValue}&filter=${filter}`;

    let filtersObj = {};
    if (location.search) {
      filtersObj = utils.queryParamsToObject(location.search);
      queryParams = `practices${location.search}`;

      if (filtersObj.search) {
        this.setState({
          searchValue: filtersObj.search,
        });
      }

      if (filtersObj.filter) {
        this.setState({
          selectValue: filtersObj.filter,
        });
      } else {
        queryParams += '&filter=active';
        this.setState({
          selectValue: 'active',
        });
      }
    }
    history.replace(queryParams.replace('practices', ''));

    await actions.getAllResources(apiToken, queryParams).then((practicesResponse) => {
      if (!practicesResponse.error) {
        this.setState({
          searchValue: practicesResponse.response && practicesResponse.response.search || '',
          selectValue: practicesResponse.response && practicesResponse.response.filter || 'active',
          fetchedPractices: practicesResponse.response && practicesResponse.response.list,
        });
        this.retrieveStatsData(practicesResponse?.response?.list?.map(p => p.id).join(','));
      }
    });
    const practiceFilter = document.getElementById('practiceFilter');

    practiceFilter && practiceFilter.addEventListener('keydown', e => this.handleKeyDown(e));
  };

  retrieveStatsData = (practiceIds) => {
    const { actions, apiToken } = this.props;
    const { fetchedPractices } = this.state;

    const ids = practiceIds || (fetchedPractices || []).map(p => p.id).join(',');
    if (ids) {
      actions.getAllResources(apiToken, `practices/stats?practiceIds=${ids}`).then((statsResp) => {
        if (!statsResp.error) {
          this.setState({ stats: statsResp.response.list });
        }
      });
    }
  };

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.handleSearch();
    }
  };

  componentWillUnmount() {
    const practiceFilter = document.getElementById('practiceFilter');
    practiceFilter && practiceFilter.removeEventListener('keydown', e => this.handleKeyDown(e));
  }

  getActive = (data) => {
    const { actions, apiToken, theme } = this.props;
    const { fetchedPractices } = this.state;
    const { switchRoot } = switchStyles(theme);
    return (
          <Switch
            checked={data.active}
            disableRipple
            sx={switchRoot}
            onClick={() => {
              actions.updateResource(apiToken, { active: !data.active }, 'practices', data.id)
                .then(() => actions.getAllResources(apiToken, BASE_URLS.getPractices));
              const p = fetchedPractices.filter(item => item.id === data.id)[0];
              p.active = !data.active;
              this.setState({ fetchedPractices });
            }}
          />
    );
  };

  getFullAddress = (data) => {
    const { theme } = this.props;
    const stylesWithTheme = practicesPageStyles(theme);

    const location = data.location || '';
    const city = data.city ? `, ${data.city}` : '';
    const state = data.state || '';
    return (
      <Typography sx={stylesWithTheme.customRenderText}>
          {`${location}${city} ${state}`}
      </Typography>
    );
  };

  getPositions = (data) => {
    const { theme } = this.props;
    const stylesWithTheme = practicesPageStyles(theme);

    if (!data) {
      return <Typography>None</Typography>;
    }

    const isArchived = !!data.deletedAt;

    return (
      <div style={stylesWithTheme.positions}>
        <Icon style={isArchived ? stylesWithTheme.archivedIcon : stylesWithTheme.icon}>business_center</Icon>
        <Typography style={stylesWithTheme.numbers}>
          {data.activePostingsCount || 0}
        </Typography>
        <Typography style={stylesWithTheme.positionCustomRenderText}>
            &nbsp;Active Posts
        </Typography>
        <div style={stylesWithTheme.divider} />
        <Icon style={stylesWithTheme.archivedIcon}>move_to_inbox</Icon>
        <Typography style={stylesWithTheme.positionCustomRenderText}>
            {`${data.archivedPostingsCount || 0} Archived Posts`}
        </Typography>
        <div style={stylesWithTheme.divider} />
        <Icon style={isArchived ? stylesWithTheme.archivedIcon : stylesWithTheme.icon}>insert_emoticon</Icon>
        <Typography style={stylesWithTheme.numbers}>
            {data.connectedPostingsCount || 0}
        </Typography>
        <Typography style={stylesWithTheme.positionCustomRenderText}>
            &nbsp;Applications
        </Typography>
      </div>
    );
  };

  getReliefStats = (data) => {
    const { theme } = this.props;
    const stylesWithTheme = practicesPageStyles(theme);
    const { stats } = this.state;

    const { open, closed, taken } = data && stats ? stats.find(r => r.practiceId === data.id) || {} : {};

    if (!open && !closed && !taken) {
      return (<Typography style={stylesWithTheme.positionCustomRenderText}>&nbsp;</Typography>);
    }

    return (
      <div style={stylesWithTheme.positions}>
        <Typography style={stylesWithTheme.positionCustomRenderText}>
          Relief
        </Typography>
        <Tooltip title='Relief shift needs from today into the future'>
          <LensIcon style={{ ...stylesWithTheme.icon, color: green }} />
        </Tooltip>
        <Typography style={stylesWithTheme.numbers}>
          {open || 0}
        </Typography>
        <Typography style={stylesWithTheme.positionCustomRenderText}>
            &nbsp;Open
        </Typography>
        <div style={stylesWithTheme.divider} />
        <Tooltip title='Unfilled available relief shifts from yesterday into the past'>
          <LensIcon style={{ ...stylesWithTheme.archivedIcon, color: gray }} />
        </Tooltip>
        <Typography style={stylesWithTheme.positionCustomRenderText}>
            {`${closed || 0} Closed`}
        </Typography>
        <div style={stylesWithTheme.divider} />
        <Tooltip title='Shifts marked taken at any time future or past'>
          <LensIcon style={{ ...stylesWithTheme.archivedIcon, color: orange }} />
        </Tooltip>
        <Typography style={stylesWithTheme.numbers}>
            {taken || 0}
        </Typography>
        <Typography style={stylesWithTheme.positionCustomRenderText}>
            &nbsp;Taken
        </Typography>
      </div>
    );
  };

  getWaitingMsg = (data) => {
    const { theme, history } = this.props;
    const stylesWithTheme = practicesPageStyles(theme);
    const { stats } = this.state;

    const { totalUnreadMessagesCount } = data && stats ? stats.find(r => r.practiceId === data.id) || {} : {};

    return (
      <div style={stylesWithTheme.positions}>
        {totalUnreadMessagesCount > 0 && (
          <IconButton
            style={stylesWithTheme.iconButton}
            onClick={() => history.push(`/applications?practices=${data.id}`)}
          >
            <MessageNotificationBubble pingeeStatus={totalUnreadMessagesCount} />
          </IconButton>
        )}
      </div>
    );
  };


  handleChange = (e, key) => {
    this.setState({
      [key]: e.target.value,
    });
  };

  setQueryParams(queryParams) {
    const query = queryParams.replace('practices', '');
    history.pushState(null, '', query);
  }

  clearFilters = () => {
    const { searchValue, selectValue } = this.state;
    if (searchValue.length || selectValue.length) {
      this.setState({
        searchValue: '',
        selectValue: 'active',
      }, () => this.handleSearch());
    }
  };

  handleSearch = () => {
    const { actions, apiToken } = this.props;
    const { orderBy, searchValue, selectValue } = this.state;
    const filter = `&filter=${selectValue}`;

    let queryParams = `practices?start=0&count=${practicesPaginationCount}&orderby=${orderBy}&search=${searchValue}${filter}`;
    this.setQueryParams(queryParams);
    actions.getAllResources(apiToken, queryParams).then((response) => {
      this.setState({
        searchValue: response.response.search || '',
        selectValue: response.response.filter || 'active',
        fetchedPractices: response.response && response.response.list,
      });
      this.retrieveStatsData(response?.response?.list?.map(p => p.id).join(','));
    });
  };

  onClickViewMore = async (pageNo, sort) => {
    const {
      actions,
      apiToken,
      pagination,
      loading,
    } = this.props;
    this.setState({ open: false });
    if (!loading) {
      const searchParam = pagination.search ? `&search=${pagination.search}` : '';
      const filterParam = pagination.filter ? `&filter=${pagination.filter}` : '';
      let queryParams;
      if (!pageNo) {
        queryParams = `practices?start=${pagination.start + pagination.count}&count=${practicesPaginationCount}&orderby=${this.state.orderBy}${searchParam}${filterParam}`;
      } else {
        queryParams = `practices?start=${(pageNo - 1) * pagination.count}&count=${practicesPaginationCount}&orderby=${this.state.orderBy}${searchParam}${filterParam}`;
      }
      if (sort) {
        const orderBy = this.state.orderBy === 'asc' ? 'desc' : 'asc';
        queryParams = `practices?start=0&count=${pagination.count}&orderby=${orderBy}${searchParam}${filterParam}`;
        this.setState({ orderBy });
      }
      this.setQueryParams(queryParams);
      actions.updateLoadingState(types.VIEW_MORE_RESOURCE_REQUESTED);
      actions.getAllResources(apiToken, queryParams).then((e) => {
        if (!e.error) {
          this.setState({
            fetchedPractices: e.response.list,
          });

          this.retrieveStatsData(e?.response?.list?.map(p => p.id).join(','));
        }
      });
      actions.updateLoadingState(types.VIEW_MORE_RESOURCE_SUCCESS);
    }
  };

  handleClickArchive = (id, disableArchive) => {
    const {
      actions, apiToken, pagination,
    } = this.props;
    const { fetchedPractices } = this.state;

    if (disableArchive) {
      actions.displayErrorMessage('The last practice should not be archived');
      return;
    }
    let practice = fetchedPractices?.find(practice => practice?.id === id);
    if (!practice) return;

    if (!practice.deletedAt) {
      this.setState({
        open: true,
        archiveId: id,
        archiveName: practice.name,
      });
    } else {
      practice.deletedAt = null;
      actions.updateResource(apiToken, practice, 'practices', id)
        .then((response) => {
          if (!response.error) {
            const currentPage = (pagination.start / pagination.count) + 1;
            this.onClickViewMore(currentPage);
          }
        })
        .then((response) => {
          if (response) {
            this.setState({
              searchValue: response.response.search || '',
              selectValue: response.response.filter || 'active',
            });
          }
        });
    }
  };

  handleClickDialog = (type, id) => {
    const { actions, apiToken, pagination } = this.props;
    const { orderBy } = this.state;

    if (type === 'confirm') {
      const practice = { deletedAt: new Date() };

      actions.updateResource(apiToken, practice, 'practices', id)
        .then((response) => {
          if (!response.error) {
            const currentPage = (pagination.start / pagination.count) + 1;
            this.onClickViewMore(currentPage);
          }
        })
        .then((response) => {
          this.setState({
            searchValue: (response && response.response.search) || '',
            selectValue: (response && response.response.filter) || 'active',
            open: false,
            archiveId: null,
            archiveName: '',
          });
        });

      return;
    }

    this.setState({
      open: false,
      archiveId: null,
      archiveName: '',
    });
  };

  render() {
    const {
      loading, adminPractices, actions, apiToken, pagination, viewMoreLoading, history, classes, admin, theme,
    } = this.props;
    const stylesWithTheme = practicesPageStyles(theme);
    const { selectValue, searchValue, fetchedPractices } = this.state;
    let mutablePractices = fetchedPractices ? fetchedPractices : adminPractices;

    const customRender = {
      address: this.getFullAddress,
      positions: this.getReliefStats,
      waitingMsg: this.getWaitingMsg,
      active: this.getActive,
    };

    return (
      (loading === true && viewMoreLoading === 0)
        ? <LoadingBar />
        : (
          <div style={stylesWithTheme.container}>
              <img style={stylesWithTheme.resourceImage} src={`${IMG_STORAGE_LINK}search-dog-s.png`} />
              <div className={classes.resourceHeader}>
                  <div style={stylesWithTheme.resourceName}>
                      <Typography style={stylesWithTheme.resourceName}>
                          <img style={stylesWithTheme.resourceLogo} src={`${IMG_STORAGE_LINK}practice.png`} />
                          PRACTICES
                      </Typography>
                      <Button sx={stylesWithTheme.headerButton} onClick={e => history.push('/newpractice')}>
                          <AddCircleOutlineIcon style={stylesWithTheme.buttonIcon} />
                          <span style={{ verticalAlign: 'super' }}>ADD NEW PRACTICE</span>
                      </Button>
                  </div>
              </div>
              <div
                className={classes.searchComponentContainer}
                style={window.innerWidth < 1100 ? { margin: '3% 3% 0 3%' } : { margin: '1% 3% 0 3%' }}
              >
                  <Paper style={stylesWithTheme.searchComponentPaper} id='practiceFilter'>
                      <SearchComponent
                        selectValues={selectValues}
                        selected={selectValue}
                        search={searchValue}
                        handleChange={(e, key) => this.handleChange(e, key)}
                        onHandleSearch={() => this.handleSearch()}
                        filter
                      />
                  </Paper>
              </div>
              {
                  (searchValue.length || selectValue !== 'active')
                    ? (
                          <div className={classes.clearFilterDiv}>
                              <Button
                                disableRipple
                                variant='text'
                                sx={stylesWithTheme.clearFilterText}
                                onClick={() => this.clearFilters()}
                              >
                                  clear search
                              </Button>
                          </div>
                    )
                    : <div />
              }
              <Paper style={stylesWithTheme.paper}>
                  <DisplayResources
                    data={mutablePractices}
                    actions={actions}
                    fieldNames={resourceFields.practices}
                    customRender={customRender}
                    resourceName="practices"
                    apiToken={apiToken}
                    viewOnly
                    admin={admin}
                    onClickViewMore={this.onClickViewMore}
                    totalResource={pagination && pagination.totalSize}
                    viewMoreLoading={viewMoreLoading}
                    handleClickArchive={this.handleClickArchive}
                    pagination={pagination}
                  />
              </Paper>
              <ArchiveDialog
                open={this.state.open}
                handleOnClick={(type, id) => this.handleClickDialog(type, id)}
                resource="practice"
                resourceName={this.state.archiveName}
                resourceId={this.state.archiveId}
              />
          </div>
        )
    );
  }
}

Practices.propTypes = {
  loading: PropTypes.bool.isRequired,
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  apiToken: PropTypes.string.isRequired,
  adminPractices: PropTypes.array,
};

Practices.defaultProps = {
  adminPractices: [],
};

export default withStyles(practicesPageStyles, { withTheme: true })(withRouter(Practices));
