import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  IconButton,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Close } from '@mui/icons-material';

import { badgeDialogStyles } from '../../css/dialogStyles';

class RemoveFastpassUserBadgeDialog extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const {
      classes, handleOnClick, open, badge, theme,
    } = this.props;
    const {
      title, closeButton, dialogDescriptionBold,
      dialogDescription, button, dialogActions,
    } = badgeDialogStyles(theme);

    return (
      <Dialog
        maxWidth="md"
        PaperProps={{
          style: {
            padding: '24px 4px',
            minWidth: '310px',
          },
        }}
        open={open}
        onClose={() => handleOnClick('close')}
      >
        <DialogTitle className={classes.dialogContent}>
          <div className={classes.titleContainer}>
            <Typography color="primary" sx={title}>
              Unselect Fastpass Badge
            </Typography>
          </div>
          <IconButton
            aria-label="close"
            sx={closeButton}
            onClick={() => handleOnClick('close')}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Typography sx={dialogDescriptionBold}>
            {`Are you sure you want to unselect the badge ${badge.title}?`}
          </Typography>
          <Typography sx={dialogDescription} style={{ margin: '10px 0' }}>
            If so, all of the fastpass related to this badge will be removed.
          </Typography>
        </DialogContent>
        <DialogActions sx={dialogActions}>
          <Button
            sx={button}
            onClick={() => handleOnClick()}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

RemoveFastpassUserBadgeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  badge: PropTypes.object.isRequired,
  handleOnClick: PropTypes.func.isRequired,
};

export default withStyles(badgeDialogStyles, { withTheme: true })(RemoveFastpassUserBadgeDialog);
