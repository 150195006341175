import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import LoadingBar from '../subcomponents/LoadingBar';

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: '2vw',
    paddingRight: '2vw',
  },
  title: {
    fontFamily: 'Asap',
    fontWeight: 'bold',
    fontSize: '20px',
    paddingBottom: '4vh',
  },
  verticalChartsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '40%',
    height: '100%',
    minHeight: '200px',
    minWidth: '350px',
  },
  chartElement: {
    paddingTop: '2vh',
    height: '50%',
    width: '100%',
    minHeight: '200px',
    minWidth: '350px',
  },
});

class Home extends Component {
  componentDidMount() {
    const {
      resources, loading, actions, apiToken,
    } = this.props;
    if (!loading) {
      actions.getAllResources(apiToken, 'users?practices=');
    }
  }

  render() {
    const { resources, loading } = this.props;
    return (
      (loading === true)
        ? <LoadingBar />
        : ''
    );
  }
}

Home.propTypes = {
  loading: PropTypes.bool.isRequired,
  resources: PropTypes.objectOf(PropTypes.any).isRequired,
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  apiToken: PropTypes.string.isRequired,
};

export default withRouter(Home);
