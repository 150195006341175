const base_url = window.api_host;
export const sendKmlFile = (apiToken, path, file) => {
  const url = base_url + path;

  const formData = new FormData();
  formData.append('kml', file);

  fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${apiToken}`,
    },
    body: formData,
  }).then((response) => {
    if (response.status === 200) {
      response.text().then((outputLog) => {
        window.alert(outputLog);
      });
    } else {
      window.alert(`There was a problem. Status Code: ${response.status}`);
    }
  });
};
