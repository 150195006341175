import {
  headerButtonStyle,
  hoverEffectOne,
  navy,
  orange,
  gray,
  tiffany,
} from '../../../css/style';

export const PracticeReportsStyles = theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '40px',
    margin: '1% 2% 1% 2%',

    [theme && theme.breakpoints.down('sm')]: {
      padding: '20px',
    },
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '25px',
  },
  reportContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  gridContainer: {
    marginTop: '10px',

    [theme && theme.breakpoints.down('xs')]: {
      marginTop: '32px',
    },
  },
  reportButtonGroup: {
    display: 'flex',
    flexDirection: 'row',

    [theme && theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  reportButton: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#ffffff',
    backgroundColor: `${orange}`,
    marginLeft: '10px',
    cursor: 'pointer',
    ...hoverEffectOne,

    [theme && theme.breakpoints.down('sm')]: {
      maxWidth: '100px',
    },
  },
  headerButton: {
    ...headerButtonStyle,
    color: '#ffffff',
    backgroundColor: `${orange}`,
    right: '0',
    cursor: 'pointer',
    ...hoverEffectOne,

    [theme && theme.breakpoints.down('sm')]: {
      maxWidth: '100px',
    },
  },
  resourceHeader: {
    display: 'flex',
    flexDirection: 'column',
    width: '96%',
    alignItems: 'left',
    justifyContent: 'space-between',
    margin: '3% 3% 0 2%',
    position: 'relative',
  },
  resourceNameLarge: {
    fontSize: '30px',
    fontWeight: 'bold',
    color: 'white',
    textTransform: 'uppercase',
    fontFamily: 'circe-rounded',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    [theme && theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      height: 100,
    },
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: `${navy}`,
    textTransform: 'uppercase',
    fontFamily: 'circe-rounded',
  },
  practiceName: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: `${navy}`,
    textTransform: 'uppercase',
    fontFamily: 'circe-rounded',
    lineHeight: 2,

    [theme && theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
  column: {
    width: '50%',
  },
  columnContainer: {
    width: '50%',
    wordBreak: 'break-all',
  },
  cardContent: {
    padding: '25px 25px 15px 25px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  dataGridContainer: {
    minHeight: 300,
    width: '100%',

    '& .MuiDataGrid-cell': {
      '&:focus': {
        outline: 'none !important',
      },
      '&:focus-within': {
        outline: 'none !important',
      },
    },

    '& .MuiDataGrid-columnHeader': {
      backgroundColor: tiffany,
      color: '#fff',
      '&:focus': {
        outline: 'none !important',
      },
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
    },
    '& .MuiDataGrid-sortIcon': {
      color: '#fff',
      fontWeight: 'bold',
    },
  },
  disabledRow: {
    color: gray,
  },
  badgeFilter: {
    color: '#fff',
    marginLeft: '5px',
    height: '25px',
    padding: '0px 8px',
  },
  responsiveneseItem: {
    '& p': {
      fontFamily: 'Asap',
      fontSize: '14px',
      padding: '0 5px',
    },
    minHeight: '36px',
    alignItems: 'center',
    display: 'flex',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    padding: '8px',
  },
});
