import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isEqual, some } from 'lodash';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';
import { ExpandMore } from '@mui/icons-material';
import { FormLabel } from '@mui/material';
import { withStyles } from '@mui/styles';

import { commonResourceStyle, autocompleteOptionStyle } from '../../css/style';

const styles = commonResourceStyle();

class MultiSelect extends Component {
  constructor(props) {
    super(props);
  }

  convertTitleCase = (string) => {
    var splitString = string.toLowerCase().split(' ');
    for (var i = 0; i < splitString.length; i++) {
      splitString[i] = splitString[i].charAt(0).toUpperCase() + splitString[i].substring(1);
    }
    return splitString.join(' ');
  };

  customizeLabel = (item) => {
    const { customLabelConfig, type } = this.props;
    let itemTitle = item.title || item.name;
    if (customLabelConfig && customLabelConfig[type]) {
      let typeConfiguration = customLabelConfig[type];
      let keyVal = item.key || '';
      let titleVal = itemTitle || '';
      if (typeConfiguration.useLabel && item.label) {
        keyVal = item.label;
      }
      if (typeConfiguration.capitalizeTitle) {
        titleVal = this.convertTitleCase(titleVal);
      }
      if (typeConfiguration.capitalizeKey && typeConfiguration.append) {
        itemTitle = `${keyVal.toUpperCase()} - ${titleVal}`;
      } else if (typeConfiguration.append) {
        itemTitle = `${keyVal} - ${titleVal}`;
      }
    }
    return itemTitle;
  };

  /**
   * customLabelConfig allows users to specify modifications to the dropdowns
   */
  render() {
    const {
      value, items, title, onChange, form,
      customLabelConfig, type, label, classes, filter, disabled, unSelctableTitle, error,
    } = this.props;
    const style = form ? styles.formControlMulti : styles.filter;
    if (!isEmpty(customLabelConfig) && customLabelConfig[type]) {
      if (customLabelConfig[type].sort) {
        items.sort((a, b) => (a.key < b.key ? -1 : (a.key > b.key) ? 1 : 0));
      }
    }
    return (
      <FormControl fullWidth style={style}>
        {label && <FormLabel style={styles.formControlLabel}>{label}</FormLabel>}
        <Select
          disabled={disabled}
          multiple
          displayEmpty
          value={value}
          onChange={(e) => {
            let selectedValue = e.target.value.indexOf('') >= 0 ? [] : e.target.value;
            onChange(selectedValue);
          }}
          input={<OutlinedInput id="select-multiple-checkbox" labelWidth={0} />}
          renderValue={selected => (
            selected.length
              ? (
                <span className={classes.selectedText}>
                  {selected.map(x => x.title || x.name).join(', ')}
                </span>
              )
              : title
          )}
          error={error}
          IconComponent={() => (
            <ExpandMore
              style={{
                color: disabled ? '' : 'black',
                fontSize: '19px',
                marginRight: '16px',
                marginTop: '4px',
              }}
            />
          )}
        >
        {
          !unSelctableTitle
          && (
            <MenuItem value="" sx={autocompleteOptionStyle}>
              <Checkbox checked={value.length === 0} />
              <ListItemText primary={title} />
            </MenuItem>
          )
        }
          {items.filter(item => item).map(item => (
            <MenuItem classes={filter ? { selected: classes.selectedItem } : {}} key={item.id || item.key} value={item} sx={autocompleteOptionStyle}>
              <Checkbox checked={value.filter(v => isEqual(v, item)).length > 0} />
              <ListItemText
                primary={this.customizeLabel(item)}
                classes={
                  filter && value.filter(v => isEqual(v, item)).length > 0
                    ? { primary: classes.selectedText }
                    : {}
                }
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

MultiSelect.propTypes = {
  value: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  appendLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  customLabelConfig: PropTypes.object,
  type: PropTypes.string,
  label: PropTypes.string,
  filter: PropTypes.bool,
  error: PropTypes.bool,
};

MultiSelect.defaultProps = {
  appendLabel: false,
  disabled: false,
  customLabelConfig: {},
  type: '',
  label: '',
  filter: false,
  error: false,
};

export default withStyles(commonResourceStyle)(MultiSelect);
