import React from 'react';
import {
  Button, Typography, TextField, InputAdornment, Icon, Dialog, DialogContent, IconButton,
} from '@mui/material';
import PropTypes from 'prop-types';
import { IMG_STORAGE_LINK } from '../data/Constants';
import { passwordDialogStyles } from '../css/dialogStyles';

const styles = passwordDialogStyles();

const NewPasswordDialog = (props) => {
  const {
    dialogState, onClose, onClickConfirm, onTextFieldChange, errorText,
  } = props;
  return (
    <Dialog
      maxWidth="xl"
      open={dialogState}
      onClose={() => onClose()}
    >
      <DialogContent style={{ ...styles.dialogContainer, height: '550px' }}>
        <IconButton
          style={styles.closeButtonStyle}
          onClick={() => onClose()}
        >
          <Icon>close</Icon>
        </IconButton>
        <img src={`${IMG_STORAGE_LINK}password.svg`} alt="password" style={styles.topImage} />
        <Typography style={styles.dialogTitle}>
          NEW PASSWORD
        </Typography>
        <Typography style={styles.dialogDescription}>
          Enter your email address below and we’ll send you a verification code to reset your password.
        </Typography>
        <form autoComplete="off">
          <TextField
            id="newPassword"
            style={styles.inputTextField}
            placeholder="NEW PASSWORD"
            type="password"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>lock_outline</Icon>
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
            onChange={event => onTextFieldChange(event, 'first')}
          />
          <TextField
            id="reTypedNewPassword"
            style={styles.inputTextField}
            type="password"
            placeholder="RE-TYPE NEW PASSWORD"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>lock_outline</Icon>
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
            onChange={event => onTextFieldChange(event, 'retype')}
          />
        </form>
        <Button
          style={styles.bottomButton}
          onClick={() => onClickConfirm()}
        >
          CONFIRM NEW PASSWORD
        </Button>
        <Typography style={styles.errorTextStyle}>{errorText}</Typography>
      </DialogContent>
    </Dialog>
  );
};

NewPasswordDialog.propTypes = {
  dialogState: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onClickConfirm: PropTypes.func.isRequired,
  onTextFieldChange: PropTypes.func.isRequired,
};

export default NewPasswordDialog;
