import React from 'react';
import { withStyles } from '@mui/styles';
import { IMG_STORAGE_LINK } from '../../data/Constants';

const pageNumberSpanCommon = {
  fontSize: '12px',
  width: '32px',
  height: '32px',
  lineHeight: '32px',
  marginRight: '4px',
  cursor: 'pointer',
  textAlign: 'center',
};

const paginationStyles = theme => ({
  paginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '85px',
    position: 'relative',
    fontWeight: 'bold',
    fontFamily: 'Asap',
  },
  pageNumberSpan: {
    ...pageNumberSpanCommon,
  },
  selectedPageNumberSpan: {
    ...pageNumberSpanCommon,
    backgroundColor: 'rgb(75, 189, 173)',
    color: '#fff',
    borderRadius: '50%',
    border: '1px solid rgb(75, 189, 173)',
  },
  pageDotSpan: {
    fontSize: '18px',
    width: '32px',
    height: '40px',
    display: 'inlineBlock',
    lineHeight: '32px',
    marginRight: '4px',
    textAlign: 'center',
  },
  leftArrow: {
    transform: 'rotate(180deg)',
    marginRight: '4px',
    cursor: 'pointer',
  },
  rightArrow: {
    cursor: 'pointer',
  },
});

const Pagination = ({
  classes,
  pagination,
  paginationCount,
  onClickViewMore,
}) => {
  const totalPage = Math.ceil(pagination.totalSize / paginationCount);
  const currentPage = Math.floor(pagination.start / paginationCount);

  return (
    <div className={classes.paginationContainer}>
      {
        currentPage != 0
        && (
          <img
            src={`${IMG_STORAGE_LINK}ic-keyboard-backspace.svg`}
            className={classes.leftArrow}
            onClick={() => onClickViewMore(currentPage)}
          />
        )
      }
      {
        [...Array(totalPage)].map((x, i) => {
          if (i === 0 || (currentPage < 3 && i < 3) || currentPage - 1 === i || currentPage === i || currentPage + 1 === i || i === totalPage - 1) {
            return (
              <span
                key={i + 1}
                className={i === currentPage ? classes.selectedPageNumberSpan : classes.pageNumberSpan}
                onClick={() => onClickViewMore(i + 1)}
              >
                {i + 1}
              </span>
            );
          } else if ((i === currentPage - 2 && currentPage > 2) || (i === totalPage - 2 && currentPage < totalPage && totalPage > 4)) {
            return (
              <span key={i + 1} className={classes.pageDotSpan}>
                ...
              </span>
            );
          }
        })
      }
      {
        totalPage != 1
        && totalPage != currentPage + 1
        && pagination.totalSize > 0
        && (
          <img
            src={`${IMG_STORAGE_LINK}ic-keyboard-backspace.svg`}
            className={classes.rightArrow}
            onClick={() => onClickViewMore(currentPage + 2)}
          />
        )
      }
    </div>
  );
};

export default withStyles(paginationStyles)(Pagination);
