import {
  tiffany,
  navy,
  navyDark,
  gray,
  orange,
  darkOrange2,
  bgLightGray,
  hoverEffectTwo,
  dialogCloseButtonStyle,
  dialogTitleContainerStyle,
  dialogFormRowStyle,
} from './style';

export const passwordDialogStyles = theme => ({
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  titleContainer: {
    ...dialogTitleContainerStyle,
  },
  closeButton: {
    ...dialogCloseButtonStyle,
    color: 'grey',
  },
  icon: {
    height: '50px',
    width: '50px',
    textAlign: 'center',
    margin: '20px 0',
    border: `solid 3px ${tiffany}`,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textFieldContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textFieldVerificationCode: {
    margin: '15px 0',
    width: '47px',
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    },
  },
  textField: {
    width: '100%',
  },
  dialogActions: {
    justifyContent: 'center',
    padding: '10px 80px',
    margin: 0,
  },
  button: {
    backgroundColor: `${navy}`,
    color: 'white',
    width: '100%',
    padding: '14px',
    '&:disabled': {
      backgroundColor: '#f0f0f0',
    },
    margin: 0,
    ...hoverEffectTwo,
  },
});

export const badgeDialogStyles = theme => ({
  badgesHeader: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: `${navy}`,
    fontFamily: 'circe-rounded',
    marginTop: '20px',
  },
  checkBox: { paddingRight: '2px' },
  publicBadgeLabel: {
    textAlign: 'right',
    display: 'block',
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  dialogDescription: {
    fontFamily: 'Asap',
    fontSize: '14px',
    textAlign: 'center',
  },
  dialogDescriptionBold: {
    fontFamily: 'Asap',
    fontSize: '14px',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  badgeFilter: {
    color: '#fff',
    marginRight: '5px',
    marginTop: '5px',
    height: '25px',
    padding: '0px 8px',
  },
  titleContainer: {
    ...dialogTitleContainerStyle,
  },
  closeButton: {
    ...dialogCloseButtonStyle,
    color: theme.palette.grey[500],
  },
  icon: {
    height: '30px',
    width: '30px',
    padding: '12px',
    textAlign: 'center',
    margin: '20px 0',
    border: `solid 3px ${tiffany}`,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: `${tiffany}`,
  },
  dialogActions: {
    justifyContent: 'center',
    padding: '8px 25px',
    margin: 0,
  },
  dialogContent: {
    padding: '10px 25px !important',
  },
  button: {
    backgroundColor: `${navy}`,
    color: 'white',
    width: '100%',
    margin: 0,
    padding: '14px',
    ...hoverEffectTwo,
  },
  disabledButton: {
    backgroundColor: '#fff !important',
    color: gray,
    width: '100%',
    margin: 0,
    padding: '14px',
  },
  typographyName: {
    marginTop: '15px',
    fontWeight: 'bold',
  },
  formRow: {
    ...dialogFormRowStyle,
  },
  switchContainer: {
    margin: '10px 0',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '14px',
    fontFamily: 'Asap',
  },
  helpPopoverText: {
    whiteSpace: 'pre-line',
  },
  helpPopover: {
    maxWidth: '400px',
    padding: '10px',
  },
  helpOutlineIcon: {
    padding: '9px',
    height: '26px',
    width: '47px',
    cursor: 'pointer',
  },
});

const icon = {
  height: '50px',
  width: '50px',
  textAlign: 'center',
  margin: '20px 0',
  border: 'solid 3px #4bbdad',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#4bbdad',
};

export const refreshPageDialogStyles = theme => ({
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  dialogDescription: {
    fontFamily: 'Asap',
    fontSize: '14px',
    textAlign: 'center',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: 1,
    top: 1,
    color: 'gray',
  },
  icon: {
    ...icon,
    padding: '12px',
  },
  refreshIcon: {
    height: '40px',
    width: '40px',
    textAlign: 'center',
    margin: '10px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#4bbdad',
  },
  whiskerIcon: {
    marginLeft: 8,
    width: '65px',
    height: '14px',
  },
  dialogActions: {
    justifyContent: 'center',
    padding: '8px 25px',
    margin: 0,
  },
  dialogContent: {
    padding: '8px 25px',
    maxWidth: '400px',
  },
  refreshButton: {
    width: '100%',
    height: '40px',
    textAlign: 'center',
    color: '#ffffff',
    borderRadius: '5px',
    backgroundColor: navy,

    '&:hover': {
      backgroundColor: `${navyDark}`,
    },
  },
});
