import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router';
import { Badge } from '@mui/material';

import DateUtilities from './utils';
import { dateTimeFormat, formatISODateOnly } from './dateUtils';
import Circle from './Circle';
import { green, gray, orange, errorRed } from '../../css/style';
import { IMG_STORAGE_LINK, IMG_STORAGE_TALENT_LINK } from '../../data/Constants';
import { fontWeight } from '@mui/system';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 45,

    '& .MuiBadge-colorPrimary': {
      color: '#fff',
      backgroundColor: `${green}`,
    },

    '& .MuiBadge-colorSecondary': {
      color: '#fff',
      backgroundColor: `${gray}`,
      left: '50%',
      bottom: 0,
    },

    '& .MuiBadge-badge': {
      height: '16px',
      minWidth: '16px',
      borderRadius: '50%',
      fontSize: '10px',
      [theme && theme.breakpoints.down('sm')]: {
        height: '15px',
        minWidth: '15px',
      },
    },

    '& .MuiBadge-anchorOriginTopLeftCircular': {
      left: '24%',
    },

    '& .MuiBadge-anchorOriginTopRightCircular': {
      right: '24%',
    },

    '& .MuiBadge-anchorOriginBottomLeftCircular': {
      left: '50%',
      bottom: '0%',
    },
  },
  day: {
    margin: '0 10px !important',
    fontFamily: 'Asap',
    fontSize: '14px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#000000',
    [theme && theme.breakpoints.down('sm')]: {
      margin: '0 !important',
    },
  },

  blank: {
    width: 36,
    height: 36,
    margin: '0 10px !important',
    [theme && theme.breakpoints.down('sm')]: {
      width: 35,
    },
    [theme && theme.breakpoints.down('sm')]: {
      margin: '0 !important',
      width: 35,
      minWidth: 25,
    },
  },
  pastDayBtnContainer: {
    backgroundColor: '#d8d8d8',
    height: 45,
    display: 'flex',
    alignItems: 'center',

    '& .MuiBadge-colorError': {
      color: '#fff',
      backgroundColor: `${orange}`,
    },
  },
  dayBtnContainer: {
    height: 45,
    display: 'flex',
    alignItems: 'center',

    '& .MuiBadge-colorError': {
      color: '#fff',
      backgroundColor: `${orange}`,
    },
  },
  boostBadge: {
    '& .MuiBadge-badge': {
      height: '16px',
      minWidth: '16px',
      borderRadius: '50%',
      fontSize: '13px',
      fontWeight: '700',
      backgroundColor: 'white',
      padding: 0,
    },

    '& .MuiBadge-anchorOriginBottomRightCircular': {
      right: '26%',
      bottom: '8%',
      color: errorRed,
    },
    '& .MuiBadge-anchorOriginBottomLeftCircular': {
      left: '26%',
      bottom: '8%',
    },
  },
});

class Week extends Component {
  onSelect = (day) => {
    if (!this.isDisabled(day)) this.props.onSelect(day);
  };

  isDisabled = (day) => {
    const { disabledToDate, readOnly } = this.props;
    if (readOnly) return true;

    let minDate = this.props.minDate;
    let maxDate = this.props.maxDate;

    return (
      (minDate && DateUtilities.isBefore(day, minDate))
      || (maxDate && DateUtilities.isAfter(day, maxDate))
      || (disabledToDate && DateUtilities.isBefore(day, disabledToDate))
    );
  };

  isSelected = day => this.props.selectedDates && DateUtilities.dateIn(this.props.selectedDates, day);

  isNotAvailable = day => this.props.notAvailableDates && DateUtilities.dateIn(this.props.notAvailableDates, day);

  isPending = day => !isEmpty(this.props.pendingDates) && DateUtilities.dateIn(this.props.pendingDates, day);

  hasMultipleNeeds = (day) => {
    const { multiNeedsData } = this.props;
    if (multiNeedsData && multiNeedsData[day]) {
      return (multiNeedsData[day].available + multiNeedsData[day].taken + (multiNeedsData[day].pending || 0)) > 1 ? true : false;
    }
    return false;
  };

  hasMultipleTakensOnly = (day) => {
    const { multiNeedsData } = this.props;
    if (multiNeedsData && multiNeedsData[day]) {
      return (multiNeedsData[day].available == 0 && !multiNeedsData[day].pending) && multiNeedsData[day].taken > 1 ? true : false;
    }
    return false;
  };

  render() {
    const {
      classes,
      onMultiNeedsClick,
      multiNeedsData,
      enabledNeedsClick,
      selectedDates,
      notAvailableDates,
      pendingDates,
      initPendingDates,
      coronadoErDayColor,
      referenceOnly,
      surgeryNeededDates,
      boostDates,
    } = this.props;

    const dateToday = formatISODateOnly(new Date());

    const dayInNumeric = new dateTimeFormat('en-US', {
      day: 'numeric',
    });

    return (
      <div className={classes.root}>
        {this.props.week.map((day, i) => {
          if (day) {
            const formattedDate = formatISODateOnly(day);
            const isToday = day && dateToday === formattedDate;
            const isPastDate = day && (Date.parse(day) < Date.parse(dateToday));
            const isDisabled = this.isDisabled(day);
            const isPending = this.isPending(day);
            const isSelected = this.isSelected(day);
            const isNotAvailable = this.isNotAvailable(day);
            const hasMultipleNeeds = this.hasMultipleNeeds(formattedDate);
            const hasMultipleTakensOnly = this.hasMultipleTakensOnly(formattedDate);

            let available;
            let taken;
            let pending = !isEmpty(pendingDates)
              ? pendingDates.filter(pendingDate => DateUtilities.isSameDay(pendingDate, day)).length : 0;

            let initPending = !isEmpty(initPendingDates)
              ? initPendingDates.filter(date => DateUtilities.isSameDay(date, day)).length : 0;

            if (multiNeedsData && multiNeedsData[formattedDate]) {
              available = multiNeedsData[formattedDate].available;
              taken = multiNeedsData[formattedDate].taken;
              pending = multiNeedsData[formattedDate].pending || 0;
            }

            const isBoosted = !isEmpty(boostDates)
              ? boostDates.filter(boostDate => DateUtilities.isSameDay(DateUtilities.midDayDate(boostDate), day)).length : 0;

            const surgeryNeeded = !isEmpty(surgeryNeededDates)
              ? surgeryNeededDates.filter(surDate => DateUtilities.isSameDay(DateUtilities.midDayDate(surDate), day)).length : 0;

            return (
              <div
                key={`day_container_${i}`}
                className={isPastDate ? classes.pastDayBtnContainer : classes.dayBtnContainer}
              >
                <Badge
                  key={`avai_icon_${i}`}
                  overlap="circular"
                  badgeContent={available + taken + pending > 1 ? available : null}
                  color="primary"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  sx={{
                    '& .MuiBadge-colorPrimary': {
                      backgroundColor: coronadoErDayColor || `${green}`,
                    },
                  }}
                >
                  <Badge
                    key={`taken_icon_${i}`}
                    overlap="circular"
                    badgeContent={available + taken + pending > 1 ? taken : null}
                    color='error'
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <Badge
                      key={`pending_icon_${i}`}
                      overlap="circular"
                      badgeContent={available + taken + pending > 1 ? pending : null}
                      color="secondary"
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      <Badge
                        key={`surgery_icon_${i}`}
                        overlap="circular"
                        badgeContent={surgeryNeeded && (
                          <span>
                            S
                            <small>x</small>
                          </span>
                        )}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        className={classes.boostBadge}
                      >
                        <Badge
                          key={`boost_icon_${i}`}
                          overlap="circular"
                          badgeContent={isBoosted && (
                            <img
                              width='16'
                              src={`${IMG_STORAGE_TALENT_LINK}dollar.svg`}
                              alt="boost"
                            />
                          )}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          className={classes.boostBadge}
                        >
                          <Circle
                            key={`day-${i}`}
                            label={dayInNumeric.format(day)}
                            disabled={isDisabled}
                            checked={isSelected}
                            onCheck={(e) => {
                              if (referenceOnly) return;
                              if (hasMultipleNeeds || enabledNeedsClick) {
                                let iniAvailable = available;
                                let iniTaken = taken;

                                // Set initial value for multi-need from single need
                                if (!hasMultipleNeeds) {
                                  iniAvailable = DateUtilities.dateIn(selectedDates, day) ? 1 : 0;
                                  iniTaken = DateUtilities.dateIn(notAvailableDates, day) ? 1 : 0;
                                }
                                onMultiNeedsClick({
                                  selectedDate: day,
                                  dayKey: formattedDate,
                                  available: iniAvailable,
                                  taken: iniTaken,
                                  pending: pending || 0,
                                  initPending,
                                });
                              } else {
                                this.onSelect(day);
                              }
                            }}
                            isNotAvailable={isNotAvailable}
                            isPending={isPending}
                            isToday={isToday}
                            hasMultipleNeeds={hasMultipleNeeds}
                            hasMultipleTakensOnly={hasMultipleTakensOnly}
                            className={classes.day}
                            coronadoErDayColor={coronadoErDayColor}
                          />
                        </Badge>
                      </Badge>
                    </Badge>
                  </Badge>
                </Badge>
              </div>
            );
          }
          return <div className={classes.blank} key={`blank-${i}`} />;
        })}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withRouter(Week));
