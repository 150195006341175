
export const resourceFormStyles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    alignItems: 'center',
  },
  title: {
    fontFamily: 'circe-rounded',
    fontSize: '24px',
    fontWeight: 'bold',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    height: '90%',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  gridContainer: {
    padding: '2% 20px 10px 20px',
    margin: '0',
    minWidth: '500px',
  },
  subResourceContainer: {
    width: '100%',
    padding: '0px 2%',
  },
  subResourceContainerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 0',
    alignItems: 'center',
  },
  subgridContainer: {
    maxHeight: '650px',
    borderRadius: '1%',
  },
  subgridItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    background: '#f4f4f4',
    borderRadius: '5px',
    padding: '1%',
    width: '100%',
    margin: '0px 2px',
  },
  gridItem: {
    paddingBottom: '0',
    minHeight: '90px',
  },
  subgridItem: {
    margin: '0 5px',
  },
  itemTitle: {
    fontFamily: 'Asap',
    fontWeight: 'bold',
  },
  customizationTitle: {
    padding: '20px 0 20px 0',
    fontFamily: 'circe-rounded',
    fontWeight: '700',
    fontSize: '14px',
  },
  isolatedField: {
    width: '50%',
    paddingTop: '2%',
    minWidth: '500px',
    height: '90%',
  },
  buttonWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '5%',
  },
  applyChangeButton: {
    border: '2px solid #e0592a',
    color: '#e0592a',
    fontSize: '11px',
    width: '140px',
    minHeight: '35px',
    fontFamily: 'Asap',
    borderRadius: '0px',
    boxShadow: 'none',
    padding: '0',
    marginLeft: '20px',
    position: 'absolute',
    right: '2%',
  },
  textFieldStyle: {
    border: '1px solid #979797',
    borderRadius: '8px',
    width: '90%',
    background: '#FFFFFF',
  },
  errorTextFieldStyle: {
    border: '1px solid red',
    borderRadius: '8px',
    width: '100%',
  },
  errorMessageStyle: {
    color: 'red',
  },
  fieldTypeStyle: {
    border: '1px solid #979797',
    borderRadius: '8px',
    height: '45px',
    paddingLeft: '10px',
    fontFamily: 'Asap',
    background: '#FFFFFF',
  },
  customizationDropdownStyle: {
    border: '1px solid #979797',
    borderRadius: '8px',
    height: '45px',
    paddingLeft: '10px',
    fontFamily: 'Asap',
    background: '#FFFFFF',
    margin: 'auto',
    marginTop: '30px',
    width: '300px',
  },
  normalInputPropStyle: {
    style: {
      height: '45px',
      padding: '0 0 0 10px',
      fontFamily: 'Asap',
    },
  },
  descriptionInputPropStyle: {
    style: {
      padding: '10px',
      fontFamily: 'Asap',
    },
  },
  timeLimitSmallContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '98%',
    height: '50px',
  },
  iconSmallContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '98%',
  },
  checkBoxAndLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '200px',
  },
  iconCheckBox: {
    padding: '0',
    color: '#e0592a',
    marginRight: '8px',
  },
  switchAndText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  yesOrNoText: {
    fontFamily: 'Asap',
    fontSize: '14px',
    paddingTop: '18px',
    width: '20px',
  },
  daysButton: {
    width: '30px',
    minWidth: '30px',
    height: '30px',
    fontFamily: 'Asap',
    fontSize: '10px',
    marginRight: '4px',
    boxShadow: 'none',
    borderRadius: '0',
  },
  imgAndTimePicker: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  alarmImg: {
    width: '25px',
    height: '25px',
    padding: '5px',
  },
  timePickerStyle: {
    width: '60px',
  },
  timePickerInputProp: {
    style: {
      fontFamily: 'Asap',
      fontSize: '14px',
    },
  },
  dashBetweenTimePicker: {
    padding: '5px 10px 5px 10px',
  },
  viewResourceBtnWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    height: '5%',
  },
  viewResourceButtons: {
    border: '2px solid #e0592a',
    color: '#e0592a',
    fontSize: '14px',
    width: '140px',
    minHeight: '35px',
    fontFamily: 'Asap',
    borderRadius: '0px',
    boxShadow: 'none',
    padding: '0',
    marginRight: '20px',
    backgroundColor: '#fff',
  },
  itemCustomizationsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '70px',
    alignItems: 'center',
    minWidth: '1000px',
  },
  itemCustomizationsField: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '20%',
    height: '100%',
    alignItems: 'center',
  },
  itemCustomizationsFieldText: {
    fontFamily: 'Asap',
    marginRight: '10px',
  },
  iosSwitchStyles: {
    switchBase: {
      '&$checked': {
        color: '#fff',
        '& + $bar': {
          backgroundColor: '#52d869',
          opacity: 1,
        },
      },
    },
    checked: {
      opacity: 1,
    },
    icon: {
      width: '16px',
      height: '16px',
      marginTop: '6px',
    },
    bar: {
      backgroundColor: '#c5d0de',
      height: '20px',
      width: '34px',
      borderRadius: 13,
      opacity: '1',
    },
  },
  itemCustomizationContainer: {
    width: '100%',
  },
  itemCustomizationTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  addCusTextLink: {
    fontFamily: 'Asap',
    fontStyle: 'italic',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  addItemCusDialogContainer: {
    width: '500px',
    height: '420px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0,
    position: 'relative',
  },
  itemCusDialogCloseBtn: {
    position: 'absolute',
    width: '50px',
    right: '2%',
    top: '5px',
    color: '#000',
  },
  itemCusDialogTitle: {
    fontFamily: 'Asap',
    fontWeight: 'bold',
    fontSize: '25px',
    marginTop: '20px',
  },
  itemCusBtnInDialog: {
    backgroundColor: '#e0592a',
    color: '#fff',
    width: '200px',
    marginTop: '30px',
  },
  itemCusDialogCancelText: {
    fontFamily: 'Asap',
    textDecoration: 'underline',
    marginTop: '10px',
  },
});
