import React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { commonResourceStyle } from '../../css/style';
import { FormLabel } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

const styles = commonResourceStyle();

export default function MultipleSelection(props) {
  const [selected, setSelected] = React.useState([]);
  const style = props.form ? styles.formControlMulti : styles.filter;

  React.useEffect(() => {
    setSelected(props.value);
  });

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelected(typeof value === 'string' ? value.split(',') : value);
    props.onChange(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <div>
      <FormControl fullWidth style={style}>
        {props.label && <FormLabel style={styles.formControlLabel}>{props.label}</FormLabel>}
        <Select
          labelId="multiple-checkbox-label"
          id="multiple-checkbox"
          multiple
          displayEmpty
          value={selected}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-checkbox" labelWidth={0} />}
          error={props.error}
          renderValue={(_selected) => {
            if (_selected.length === 0) {
              return <span style={styles.placeholder}>{props.title || 'Please Select'}</span>;
            }

            return _selected.join(', ');
          }}
          IconComponent={() => (
            <ExpandMore
              style={{
                color: props.disabled ? '' : 'black',
                fontSize: '19px',
                marginRight: '16px',
                marginTop: '4px',
              }}
            />
          )}
        >
          {props.items.map(name => (
            <MenuItem classes={props.filter ? { selected: props.classes.selectedItem } : {}} key={name} value={name}>
              <Checkbox checked={selected.indexOf(name) > -1} />
              <ListItemText style={styles.selectedText} primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
