import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { navy } from '../../css/style';

export default function MaterialUIPickers(props) {
  const {
    variant = 'inline',
    openTo = 'month',
    views = ['year', 'month'],
    label = 'Select month',
  } = props;

  const [selectedDate, setSelectedDate] = useState(() => {
    const { selectedDate } = props;
    return selectedDate || new Date();
  });

  useEffect(() => {
    setSelectedDate(props.selectedDate);
  }, [props.selectedDate]);

  useEffect(() => {
    const { handleStateChange = () => { } } = props;
    handleStateChange(selectedDate);
  }, [selectedDate]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        color='secondary'
        variant={variant}
        openTo={openTo}
        views={views}
        label={label}
        value={dayjs(selectedDate)}
        onChange={(date) => {
          setSelectedDate(date);
        }}
        loading
        slotProps={{
          textField: { size: 'small' },
          popper: {
            sx: {
              '& .MuiPickersMonth-monthButton.Mui-selected': {
                backgroundColor: `${navy} !important`,
              },
            },
          },
        }}
      />
    </LocalizationProvider>
  );
}
