import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  IconButton,
  TextField,
  FormLabel,
  Checkbox,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Close, MoveToInbox } from '@mui/icons-material';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

import { badgeDialogStyles } from '../../css/dialogStyles';

class CreateBadgeDialog extends Component {
  constructor() {
    super();
    this.state = {
      name: '',
      isPublic: false,
    };
  }

  handleSave() {
    const {
      classes, handleOnClick, actions, apiToken,
    } = this.props;
    const { name, isPublic } = this.state;
    actions.addResource(apiToken, {
      title: name,
      isPublic,
    }, 'badges');

    handleOnClick();
  }

  render() {
    const {
      classes, handleOnClick, open, theme,
    } = this.props;
    const { isPublic } = this.state;
    const stylesWithTheme = badgeDialogStyles(theme);

    return (
      <Dialog
        maxWidth="md"
        PaperProps={{
          style: {
            padding: '24px 4px',
            minWidth: '510px',
          },
        }}
        open={open}
        onClose={() => handleOnClick('close')}
      >
        <DialogTitle style={stylesWithTheme.dialogContent}>
          <div className={classes.titleContainer}>
            <VerifiedUserIcon className={classes.icon} />
            <Typography color="primary" sx={stylesWithTheme.title}>
              CREATE NEW BADGE
            </Typography>
          </div>
          <IconButton
            aria-label="close"
            sx={stylesWithTheme.closeButton}
            onClick={() => handleOnClick('close')}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent style={stylesWithTheme.dialogContent}>
          <FormLabel style={stylesWithTheme.publicBadgeLabel}>Public</FormLabel>
          <div className={classes.formRow}>
            <TextField
              label="Badge Name"
              onChange={event => this.setState({ name: event.target.value })}
              variant="outlined"
              fullWidth
              id="name"
              inputProps={{ autoCapitalize: 'none' }}
              autoComplete="off"
            />
            <Checkbox
              onChange={e => this.setState({ isPublic: !isPublic })}
              value="isPublic"
            />
          </div>
        </DialogContent>
        <DialogActions style={stylesWithTheme.dialogActions}>
          <Button
            sx={stylesWithTheme.button}
            onClick={() => this.handleSave()}
            color="primary"
          >
            SAVE
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}


CreateBadgeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  actions: PropTypes.any.isRequired,
  apiToken: PropTypes.string.isRequired,
};

export default withStyles(badgeDialogStyles, { withTheme: true })(CreateBadgeDialog);
