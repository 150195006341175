import React from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { commonResourceStyle } from '../../css/style';

const { loadingText, loadingContainer } = commonResourceStyle();

class LoadingBar extends React.Component {
  render() {
    return (
        <div style={loadingContainer}>
            <CircularProgress size={80} />
            <Typography sx={loadingText}>Loading</Typography>
        </div>
    );
  }
}

export default LoadingBar;
