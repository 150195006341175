/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import Users from '../resources/Users';
import Home from '../Home/Home';
import Connections from '../resources/Connections';
import JobPostings from '../resources/JobPostings';
import Practices from '../resources/Practices';
import Settings from '../resources/Settings';
import Badges from '../resources/Badges';
import Messages from '../resources/Messages';
import Applications from '../resources/Applications';
import { filterBadges } from '../../utils/Functions';
import OtherChannelChats from '../resources/OtherChannelChats';
import HomeCalenlar from '../resources/HomeCalenlar';

class ListResources extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.match.url !== this.props.match.url) {
      try {
        window.scrollTo(0, 0);
      } catch (e) {
        console.log(e);
      }
    }
  }

  render() {
    const {
      loading, resources, actions, apiToken, viewMoreLoading, match, admin, getPrivate,
    } = this.props;
    const { resource, subResource } = match.params;
    const { isExternshipAdmin, isShiftAdmin } = admin;
    const isExternshipOnly = admin && admin.isExternshipAdmin && !admin.isShiftAdmin;

    switch (resource) {
      case 'connections':
        return isExternshipOnly ? ( // Old list view
          <Connections
            loading={loading}
            connections={resources.connections}
            filters={resources.filters}
            adminPractices={resources.adminPractices}
            jobpostings={resources.jobpostings}
            statistics={resources['connections/statistics']}
            actions={actions}
            apiToken={apiToken}
            viewMoreLoading={viewMoreLoading}
            admin={admin}
            pagination={resources.pagination && resources.pagination.connections ? resources.pagination.connections : null}
          />
        ) : (
          <HomeCalenlar
            loading={loading}
            resources={resources}
            connections={resources.connections}
            adminPractices={resources.adminPractices}
            jobpostings={resources.jobpostings}
            filters={resources.filters}
            statistics={resources['connections/statistics']}
            actions={actions}
            apiToken={apiToken}
            viewMoreLoading={viewMoreLoading}
            admin={admin}
            pagination={resources.pagination && resources.pagination.jobpostings ? resources.pagination.jobpostings : null}
          />
        );
      case 'jobpostings':
        return (
          <JobPostings
            loading={loading}
            resources={resources}
            connections={resources.connections}
            adminPractices={resources.adminPractices}
            jobpostings={resources.jobpostings}
            filters={resources.filters}
            statistics={resources['connections/statistics']}
            actions={actions}
            apiToken={apiToken}
            viewMoreLoading={viewMoreLoading}
            admin={admin}
            pagination={resources.pagination && resources.pagination.jobpostings ? resources.pagination.jobpostings : null}
          />
        );
      case 'applications':
        return (
          <Applications
            loading={loading}
            connections={resources.connections}
            filters={resources.filters}
            adminPractices={resources.adminPractices}
            jobpostings={resources['jobpostings/titles']}
            statistics={resources['connections/statistics']}
            actions={actions}
            apiToken={apiToken}
            viewMoreLoading={viewMoreLoading}
            admin={admin}
            pagination={resources.pagination && resources.pagination.connections ? resources.pagination.connections : null}
          />
        );
      case 'otherchannelchats':
        if (isExternshipAdmin && isShiftAdmin) return null;
        return (
          <OtherChannelChats
            loading={loading}
            otherChannelCons={resources[`connections/other/${isExternshipAdmin ? 'reliefchannel' : 'externchannel'}`]}
            filters={resources.filters}
            adminPractices={resources.adminPractices}
            jobpostings={resources['jobpostings/titles']}
            actions={actions}
            apiToken={apiToken}
            viewMoreLoading={viewMoreLoading}
            admin={admin}
            pagination={resources.pagination && resources.pagination['connections/other/externchannel'] ? resources.pagination['connections/other/externchannel'] : null}
          />
        );
      case 'practices':
        return (
          <Practices
            loading={loading}
            adminPractices={resources.adminPractices}
            actions={actions}
            admin={admin}
            apiToken={apiToken}
            viewMoreLoading={viewMoreLoading}
            pagination={resources.pagination && resources.pagination.practices ? resources.pagination.practices : null}
          />
        );
      case 'users':
        return (
          <Users
            loading={loading}
            viewMoreLoading={viewMoreLoading}
            users={getPrivate ? resources.privatetalents : resources.users}
            getPrivate={getPrivate}
            actions={actions}
            apiToken={apiToken}
            admin={admin}
            adminPractices={resources.adminPractices}
            pagination={resources.pagination && (getPrivate ? (resources.pagination.privatetalents ? resources.pagination.privatetalents : null) : (resources.pagination.users ? resources.pagination.users : null))}
          />
        );
      case 'badges':
        return (
          <Badges
            loading={loading}
            badges={filterBadges(admin, resources.badges)}
            userbadges={resources.userbadges}
            actions={actions}
            admin={admin}
            apiToken={apiToken}
            viewMoreLoading={viewMoreLoading}
            pagination={resources.pagination && resources.pagination.userbadges ? resources.pagination.userbadges : null}
          />
        );
      case 'settings':
        return (
          <Settings
            loading={loading}
            actions={actions}
            apiToken={apiToken}
            admin={admin}
          />
        );
      case 'messages':
        return (
          <Messages
            loading={loading}
            actions={actions}
            apiToken={apiToken}
            admin={admin}
            adminPractices={resources.adminPractices}
          />
        );
      default:
        return <Home resources={resources} loading={loading} actions={actions} apiToken={apiToken} />;
    }
  }
}

ListResources.propTypes = {
  resources: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.bool,
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  apiToken: PropTypes.string.isRequired,
  viewMoreLoading: PropTypes.number,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

ListResources.defaultProps = {
  loading: null,
  viewMoreLoading: 0,
};

export default withRouter(ListResources);
