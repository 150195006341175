import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UploadImage from './UploadImage';

const displayImagesStyles = theme => ({
  multipleImageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    height: '100%',
  },
});

const styles = displayImagesStyles();

export default class DisplayImages extends Component {
    static propTypes = {
      data: PropTypes.any,
      onChange: PropTypes.func.isRequired,
      allowMultiple: PropTypes.bool.isRequired,
    }

    constructor(props) {
      super(props);
      // Set undefined and null to be the same check
      const images = ((props.data === undefined || props.data === null) && props.allowMultiple) ? [] : (props.data === undefined) ? null : props.data;
      this.state = { images };
    }

    onChangeImage = (base64Url, position) => {
      let { images } = this.state;
      const { resourceName } = this.props;

      if (position !== undefined) {
        if (base64Url === null) {
          images.splice(position, 1);
        } else if (position > images.length) {
          resourceName === 'companies' ? images.push(base64Url) : images.push({ src: base64Url });
        } else {
          images[position] = resourceName === 'companies' ? base64Url : { src: base64Url };
        }
      } else {
        images = base64Url;
      }

      this.setState({ images });
      this.props.onChange(images);
    }

    renderMultipleImage = (images) => {
      const { type, resourceName } = this.props;
      return (
        <div style={styles.multipleImageContainer}>
          {images.map(
            (image, index) => (
              <UploadImage
                key={image.id}
                onChangeInput={(base64Url) => { this.onChangeImage(base64Url, index); }}
                image={resourceName === 'companies' ? image : image.src}
                type={type}
              />
            ),
          )}
          <UploadImage onChangeInput={(base64Url) => { this.onChangeImage(base64Url, images.length); }} type={type} />
        </div>
      );
    }


    render() {
      const { images } = this.state;
      const { allowMultiple, type } = this.props;
      return (
        (allowMultiple)
          ? this.renderMultipleImage(images)
          : (
            <UploadImage
              onChangeInput={(base64Url) => { this.onChangeImage(base64Url); }}
              image={images}
              type={type}
            />
          )
      );
    }
}
