import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormLabel,
  Typography,
  Paper,
  Popover,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@mui/material';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { withStyles } from '@mui/styles';
import { commonResourceStyle, autocompleteOptionStyle } from '../../css/style';
import { isEmpty } from 'lodash';

class SingleSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      help: null,
      helpMsg: null,
    };
  }

  disabledOption = (item) => {
    const { disabledItems, disabledItem } = this.props;

    if (!isEmpty(disabledItem) && (item.key === disabledItem || item.title === disabledItem)) {
      return true;
    }

    if (disabledItems && disabledItems.length > 0 && (disabledItems.includes(item.title) || disabledItems.includes(item.key))) {
      return true;
    }

    return false;
  };

  render() {
    const {
      value, title, items, form, customExperienceLevel, filter, theme,
      classes, disabled, helpMessage, isRequired, onChange, fullItems, disabledItems, key, disabledItem,
    } = this.props;
    const { help, helpMsg } = this.state;
    const displayError = isRequired && !value;

    const stylesWithTheme = commonResourceStyle(theme);

    return (
      <FormControl fullWidth style={form ? stylesWithTheme.formControl : stylesWithTheme.filter}>
        {form
        && (
          <div style={stylesWithTheme.formControlLabelContainer}>
            <FormLabel style={stylesWithTheme.formControlLabel}>
              {title}
              {helpMessage && (
                <HelpOutlineIcon
                  style={stylesWithTheme.helpOutlineIcon}
                  onClick={e => this.setState({
                    help: e.target,
                    helpMsg: helpMessage,
                  })}
                />
              )}
            </FormLabel>
            {displayError && (
              <FormLabel className={classes.formControlLabelWarnText}>This field is required</FormLabel>
            )}
          </div>
        )}
        <Select
          disabled={disabled}
          displayEmpty
          IconComponent={ExpandMore}
          inputProps={{
            classes: {
              icon: classes.expandMoreIcon,
            },
          }}
          value={value}
          onChange={e => onChange(e.target.value)}
          input={<OutlinedInput id="select-single" labelwidth={0} error={displayError} />}
          renderValue={(selected) => {
            if (customExperienceLevel) {
              return 'Custom';
            }
            if (!selected) {
              return form ? 'Please Select' : title;
            }
            const item = items.filter(x => selected === (x.id || x.key))[0];
            if (item) {
              return (<span>{item.title || item.name}</span>);
            }
            return '';
          }}
          MenuProps={{
            getcontentanchorel: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
          key={key || 'select'}
        >
          {!form && !fullItems && (
            <MenuItem value="" sx={autocompleteOptionStyle}>
              <Checkbox checked={!value} />
              <ListItemText primary={title} />
            </MenuItem>
          )}
          {items.map(item => (
            <MenuItem
              key={item.id || item.key || item.title || item.name}
              value={item.id || item.key}
              disabled={this.disabledOption(item)}
              sx={autocompleteOptionStyle}
            >
              <Checkbox checked={value === item.id || value === item.key} />
              <ListItemText
                primary={item.title || item.name}
                classes={
                  filter && (value === item.id || value === item.key)
                    ? { primary: classes.selectedText }
                    : {}
                }
              />
            </MenuItem>
          ))}
        </Select>
        <Popover
          open={!!help}
          anchorEl={help}
          onClose={e => this.setState({ help: null })}
        >
          <Paper className={classes.helpPopover}>
            <Typography className={classes.helpPopoverText}>{helpMsg}</Typography>
          </Paper>
        </Popover>
      </FormControl>
    );
  }
}

SingleSelect.propTypes = {
  value: PropTypes.any,
  title: PropTypes.string.isRequired,
  form: PropTypes.bool,
  disabled: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  helpMessage: PropTypes.string,
  isRequired: PropTypes.bool,
  disabledItems: PropTypes.array,
  disabledItem: PropTypes.string,
  key: PropTypes.string,
};

SingleSelect.defaultProps = {
  isRequired: false,
};

export default withStyles(commonResourceStyle, { withTheme: true })(SingleSelect);
