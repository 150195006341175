import { TextField, List, ListItem, Paper, Popper, Typography } from '@mui/material';
import React, { Component } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

export class LocationSearchInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: props.address || '',
    };
  }

  handleChange = (address) => {
    const { onChange } = this.props;

    this.setState({ address });
    if (!address) {
      const addressObj = {
        state: '',
        city: '',
        postalCode: '',
        address: '',
        lat: '',
        lng: '',
      };
      onChange(addressObj);
    }
  };

  handleSelect = (address) => {
    const { onChange } = this.props;
    this.setState({
      address,
    });
    const numberWord = /[0-9]\w+/g;
    const suggestedPostalCode = address.match(numberWord);

    geocodeByAddress(address)
      .then(results => Promise.all([results[0], getLatLng(results[0])]))
      .then(([result, latlon]) => {
        const state = result.address_components.filter(x => x.types.indexOf('administrative_area_level_1') >= 0)
          .map(x => x.short_name);
        const city = result.address_components.filter(x => x.types.indexOf('locality') >= 0 || x.types.indexOf('sublocality') >= 0 || x.types.indexOf('administrative_area3') >= 0)
          .map(x => x.short_name);
        const postalCode = result.types.includes('postal_code')
          ? result.address_components.filter(x => x.types.indexOf('postal_code') >= 0).map(x => x.short_name)
          : suggestedPostalCode;
        let address = state.length ? state[0] : address;
        if (city.length) {
          address = city[0];
        }
        if (postalCode.length) {
          address = `${address}, ${postalCode[0]}`;
        }
        const addressObj = {
          state: state.length ? state[0] : '',
          city: city.length ? city[0] : '',
          postalCode: postalCode.length ? postalCode[0] : '',
          address,
          lat: latlon.lat,
          lng: latlon.lng,
        };
        onChange(addressObj);
      })
      .catch(error => console.error('Error', error));
  };

  render() {
    const {
      placeholder, variant, onChange, fullWidth, InputProps,
    } = this.props;
    const searchOptions = {
      componentRestrictions: { country: ['us'] },
      types: ['(regions)'],
    };
    const { anchorRef, address } = this.state;
    return (
      <PlacesAutocomplete
        value={address}
        searchOptions={searchOptions}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({
          getInputProps, suggestions, getSuggestionItemProps, loading,
        }) => {
          suggestions = suggestions.filter(suggestion => suggestion.types && suggestion.types.includes('postal_code'));
          return (
            <div>
              <TextField
                onFocus={e => this.setState({ anchorRef: e.currentTarget })}
                variant={variant}
                placeholder={placeholder}
                value={address}
                fullWidth={fullWidth}
                InputProps={InputProps}
                {...getInputProps({
                  className: 'location-search-input',
                  autoComplete: 'new-password',
                })}
              />
              <Popper
                open={(loading || suggestions.length) ? true : false}
                anchorEl={anchorRef}
                placement="bottom-start"
              >
                <Paper>
                  <List className="autocomplete-dropdown-container">
                    {loading && <Typography variant="h5">Loading...</Typography>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';
                      return (
                        <ListItem
                          key={suggestion.description}
                          button
                          {...getSuggestionItemProps(suggestion, {
                            className,
                          })}
                        >
                          {suggestion.description}
                        </ListItem>
                      );
                    })}
                  </List>
                </Paper>
              </Popper>
            </div>
          );
        }}
      </PlacesAutocomplete>
    );
  }
}
