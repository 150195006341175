import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import {
  isEmpty,
  omit,
  isEqual,
  orderBy,
} from 'lodash';
import {
  Typography, Paper, Icon, TextField,
  Grid, Button, Checkbox, FormLabel,
  FormControlLabel, FormControl, Popover,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { commonResourceStyle } from '../../css/style';
import LoadingBar from '../subcomponents/LoadingBar';
import {
  VET_TYPES,
  SPECIALITIES_MAP,
  JOB_CATEGORIES,
  BASE_URLS, VET_STAFF_CATEGORIES, DVM_STAFF_CATEGORIES,
  HQ_ADMIN_KEY,
} from '../../data/Constants';
import SingleSelect from '../subcomponents/SingleSelect';
import TextInput from '../subcomponents/TextInput';
import {
  validateEmail,
  validateMobile,
  prunePhoneNumber,
  handleTrailingSpaces,
  calculateIntervals,
} from '../../utils/Functions';
import UsersForm from './UsersForm';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import PracticeSelection from '../subcomponents/PracticeSelection';

const subResourceTypes = ['specialist-veterinarian', 'non-clinical', 'dvm-doctor-of-veterinary-medicine', 'vet-tech-or-assistant'];

class PrivateUsersForm extends Component {
  constructor(props) {
    super(props);
    const { resources, match, edit } = props;
    const { userId } = match.params;
    const { privatetalents } = resources;
    let st = {};
    st.isDefaultBulletin = false;
    st.isDefaultReliefHome = true;
    st.isDefaultRelief = true;
    st.createDefault = true;
    st.range = '';
    st.isPasted = false;
    st.extraRoles = [];
    st.isHadCoronadoBadge = false;
    st.intervalDate = `${dayjs().format('YYYY')}-01-01`;
    if (userId && privatetalents) {
      const filteredUsers = privatetalents.filter(item => item.id === Number(userId));
      if (filteredUsers.length) {
        const user = filteredUsers[0];
        const talentPII = user.talentPII || {};
        const talent = user.talent || {};
        const talentProfileItems = user.talentProfileItems || [];
        const savedSearches = user.savedSearches || [];
        const relief = savedSearches.filter(x => x.isDefaultRelief);
        const reliefHome = savedSearches.filter(x => x.isDefaultReliefHome);
        if (talent.intervalDate) st.intervalDate = dayjs(talent.intervalDate).format('YYYY-MM-DD');
        st.id = edit ? user.id : null;
        st.email = user.email;
        st.externalId = user.externalId;
        st.firstName = talentPII.firstName;
        st.lastName = talentPII.lastName;
        st.mobile = talentPII.mobile;
        st.role = 'Private User';
        st.practiceId = edit ? talent.practice_id : UsersForm.getUserAccess(user, 'practice').id;
        st.type = talent.type;

        st.isDefaultRelief = relief.length && true || false;
        st.isDefaultReliefHome = reliefHome.length && true || false;
        st.isDefaultBulletin = false;
        st.range = relief.length && relief[0].range || '';
        const speciality = talentProfileItems.filter(item => item.criteria === 'speciality');
        st.specialty = speciality && speciality.length && speciality[0].str2Value;
        const jobCategory = talentProfileItems.filter(item => item.criteria === 'job-category');
        st.jobCategory = jobCategory && jobCategory.length && jobCategory[0].strValue;
        const subCategory = talentProfileItems.filter(item => item.criteria === 'sub-category');
        st.subCategory = subCategory && subCategory.length && subCategory[0].strValue;
        const vetSubCategory = talentProfileItems.filter(item => item.criteria === 'vet-sub-category');
        st.vetSubCategory = vetSubCategory && vetSubCategory.length && vetSubCategory[0].strValue;

        // Set extraRoles
        const extraRolesCri = talentProfileItems.find(item => item.criteria === 'extra-roles');
        st.extraRoles = extraRolesCri ? PrivateUsersForm.setExtraRoles(extraRolesCri) : [];
      }
      st.createDefault = st.isDefaultRelief && st.isDefaultReliefHome;
    }

    this.state = st;
  }

  static setExtraRoles = (extraRolesCri) => {
    if (isEmpty(extraRolesCri)) return [];
    const extraRolesDataItems = JSON.parse(extraRolesCri.longTextValue);
    let extraRoles = [];
    !isEmpty(extraRolesDataItems) && extraRolesDataItems.forEach((item, index) => {
      if (!isEmpty(item.profileItems)) {
        const exSpecialty = item.profileItems.find(i => i.criteria === 'speciality') || {};
        const exJobCategory = item.profileItems.find(i => i.criteria === 'job-category') || {};
        const exSubCategory = item.profileItems.find(i => i.criteria === 'sub-category') || {};
        const exVetSubCategory = item.profileItems.find(i => i.criteria === 'vet-sub-category') || {};
        extraRoles.push({
          key: index + 1,
          specialty: exSpecialty.str2Value,
          jobCategory: exJobCategory.strValue,
          subCategory: exSubCategory.strValue,
          vetSubCategory: exVetSubCategory.strValue,
          talentType: item.talentType,
        });
      } else {
        extraRoles.push({
          key: index + 1,
          talentType: item.talentType,
        });
      }
    });
    return extraRoles;
  };

  componentDidUpdate(prevProps, prevState) {
    const { resources } = this.props;
    const { privatetalents } = resources;
    if (!isEqual(resources.privatetalents, prevProps.resources.privatetalents)) {
      const { match, edit } = this.props;
      const { userId } = match.params;
      let st = {};
      st.intervalDate = `${dayjs().format('YYYY')}-01-01`;
      if (userId && privatetalents) {
        const filteredUsers = privatetalents.filter(item => item.id === Number(userId));
        if (filteredUsers.length) {
          const user = filteredUsers[0];
          const talentPII = user.talentPII || {};
          const talent = user.talent || {};
          const talentProfileItems = user.talentProfileItems || [];
          const savedSearches = user.savedSearches || [];
          const relief = savedSearches.filter(x => x.isDefaultRelief);
          const reliefHome = savedSearches.filter(x => x.isDefaultReliefHome);

          st.id = edit ? user.id : null;
          st.email = user.email;
          st.externalId = user.externalId;
          st.firstName = talentPII.firstName;
          st.lastName = talentPII.lastName;
          st.mobile = talentPII.mobile;
          st.role = 'Private User';
          st.practiceId = edit ? talent.practice_id : UsersForm.getUserAccess(user, 'practice').id;
          st.type = talent.type;
          if (talent.intervalDate) st.intervalDate = moment(talent.intervalDate).format('YYYY-MM-DD');
          st.isDefaultRelief = relief.length && true || false;
          st.isDefaultReliefHome = reliefHome.length && true || false;
          st.isDefaultBulletin = false;
          st.range = relief.length && relief[0].range || '';
          const speciality = talentProfileItems.filter(item => item.criteria === 'speciality');
          st.specialty = speciality && speciality.length && speciality[0].str2Value;
          const jobCategory = talentProfileItems.filter(item => item.criteria === 'job-category');
          st.jobCategory = jobCategory && jobCategory.length && jobCategory[0].strValue;
          const subCategory = talentProfileItems.filter(item => item.criteria === 'sub-category');
          st.subCategory = subCategory && subCategory.length && subCategory[0].strValue;
          const vetSubCategory = talentProfileItems.filter(item => item.criteria === 'vet-sub-category');
          st.vetSubCategory = vetSubCategory && vetSubCategory.length && vetSubCategory[0].strValue;

          // Set extraRoles
          const extraRolesCri = talentProfileItems.find(item => item.criteria === 'extra-roles');
          st.extraRoles = extraRolesCri ? PrivateUsersForm.setExtraRoles(extraRolesCri) : [];
        }
        st.createDefault = st.isDefaultRelief && st.isDefaultReliefHome;
      }
      this.setState(st, () => {
        this.checkExtraRolesValid();
      });
    }
  }

  componentDidMount = async () => {
    const {
      actions, loading, apiToken, resources, match,
    } = this.props;
    const { adminPractices } = resources;
    if (!adminPractices) {
      await actions.getAllResources(apiToken, BASE_URLS.getPractices);
    }
    const { userId } = match.params;
    if (userId) {
      await actions.getOneResource(apiToken, 'privatetalents', userId);
      await actions.getAllResources(apiToken, `badges/user/${userId}/badges`).then((res) => {
        const response = res.response;
        const badges = response.badges;
        const coronadoBadge = badges && badges.find(x => x.title === 'Coronado ER');
        if (coronadoBadge) this.setState({ isHadCoronadoBadge: true });
      });
    }
  };

  serializeProfileItems = ({
    type,
    specialty,
    jobCategory,
    subCategory,
    vetSubCategory,
  }) => {
    let profileItems = [];

    // JOBS-243 Hide telemedicine options --> editing workers and assign ‘in-person’ by default
    const jobTypeSubCategory = 'in-person';

    if (type === 'specialist-veterinarian') {
      profileItems.push(
        {
          criteria: 'speciality',
          strValue: 'not-listed',
          str2Value: specialty,
        },
        {
          criteria: 'job-type-sub-category',
          strValue: jobTypeSubCategory,
        },
      );
    } else if (type === 'non-clinical') {
      profileItems.push({
        criteria: 'job-category',
        strValue: jobCategory,
      });
    } else if (type === 'dvm-doctor-of-veterinary-medicine') {
      profileItems.push(
        {
          criteria: 'sub-category',
          strValue: subCategory,
        },
        {
          criteria: 'job-type-sub-category',
          strValue: jobTypeSubCategory,
        },
      );
    } else if (type === 'vet-tech-or-assistant' && vetSubCategory === 'certified-vet-tech') {
      profileItems = [
        {
          criteria: 'vet-sub-category',
          strValue: vetSubCategory,
        },
        {
          criteria: 'administering-anesthesia',
          numValue: 1,
        },
        {
          criteria: 'central-line',
          numValue: 1,
        },
        {
          criteria: 'monitoring-anesthesia',
          numValue: 1,
        },
        {
          criteria: 'catheterization',
          numValue: 1,
        },
        {
          criteria: 'filling-presciptions',
          numValue: 1,
        },
        {
          criteria: 'job-type-sub-category',
          strValue: jobTypeSubCategory,
        },
      ];
    } else if (type === 'vet-tech-or-assistant' && vetSubCategory === 'vet-assistant') {
      profileItems = [
        {
          criteria: 'vet-sub-category',
          strValue: vetSubCategory,
        },
        {
          criteria: 'monitoring-anesthesia',
          numValue: 1,
        },
        {
          criteria: 'catheterization',
          numValue: 1,
        },
        {
          criteria: 'dog-cat-cpr-assistance',
          numValue: 1,
        },
        {
          criteria: 'dog-cat-restraint',
          numValue: 1,
        },
        {
          criteria: 'sterilize-equipment',
          numValue: 1,
        },
        {
          criteria: 'job-type-sub-category',
          strValue: jobTypeSubCategory,
        },
      ];
    }
    return profileItems;
  };

  save = async (e) => {
    const {
      actions, loading, apiToken, history, edit,
    } = this.props;
    const {
      firstName, lastName, mobile,
      email, practiceId, isDefaultBulletin, isDefaultReliefHome, isDefaultRelief, range,
      type, specialty, jobCategory, subCategory, vetSubCategory, extraRoles, intervalDate,
    } = this.state;

    let profileItems = this.serializeProfileItems({
      type,
      specialty,
      jobCategory,
      subCategory,
      vetSubCategory,
    });

    let extraTypes = [];

    if (extraRoles) {
      let extraRoleItems = [];
      extraRoles.forEach((roleObj) => {
        const roleItem = {
          talentType: roleObj.talentType,
          profileItems: [],
        };
        roleItem.profileItems = this.serializeProfileItems({
          type: roleObj.talentType,
          specialty: roleObj.specialty,
          jobCategory: roleObj.jobCategory,
          subCategory: roleObj.subCategory,
          vetSubCategory: roleObj.vetSubCategory,
        });
        extraRoleItems.push(roleItem);
        extraTypes.push(roleObj.talentType);
      });

      if (!isEmpty(extraRoleItems) || this.state.id) {
        profileItems.push({
          criteria: 'extra-roles',
          longTextValue: JSON.stringify(extraRoleItems),
        });
      }
    }

    const user = {
      email: handleTrailingSpaces(email),
      firstName: handleTrailingSpaces(firstName),
      lastName: handleTrailingSpaces(lastName),
      mobile: mobile || '5555555555',
      type,
      extraTypes: !isEmpty(extraTypes) ? JSON.stringify(extraTypes) : null,
      profileItems,
      practice_id: practiceId,
      isDefaultBulletin,
      isDefaultReliefHome,
      isDefaultRelief,
      range,
      notificationFrequency: 'REAL_TIME',
    };
    if (this.state.isHadCoronadoBadge) {
      user.intervalDate = isNaN(new Date(intervalDate).getDate()) ? '2024-01-01' : intervalDate;
    }
    if (!validateEmail(user.email)) {
      return this.setState({ error: 'Invalid Email' });
    }
    if (user.mobile && !validateMobile(user.mobile)) {
      return this.setState({ error: 'Invalid Mobile' });
    }
    this.setState({ error: '' });
    if (edit) {
      actions.updateResource(apiToken, user, 'privatetalents', this.state.id).then((e) => {
        if (!e.error) {
          history.push('/users/privatetalents');
        }
      });
    } else {
      user.password = String(Math.random());
      actions.addResource(apiToken, user, 'privatetalents').then((e) => {
        if (!e.error) {
          history.push('/users/privatetalents');
        }
      });
    }
  };

  cancel = (e) => {
    const {
      history,
    } = this.props;

    history.goBack();
  };

  handleOnClickAdd = () => {
    const { extraRoles } = this.state;
    extraRoles.push({
      key: extraRoles.length + 1,
    });
    this.setState({
      extraRoles,
      hasExtraSubTypes: false,
    });
  };

  handleOnClickRemove = () => {
    const { extraRoles } = this.state;
    let exRoles = [...extraRoles];
    exRoles.pop();
    this.setState({
      extraRoles: exRoles,
    }, () => this.checkExtraRolesValid());
  };

  checkExtraRolesValid = () => {
    const { extraRoles } = this.state;
    let hasExtraSubTypes = true;
    if (!isEmpty(extraRoles)) {
      hasExtraSubTypes = extraRoles
        .map(r => !!(r.specialty || r.jobCategory || r.subCategory || r.vetSubCategory))
        .reduce((acc, cur) => cur && acc);
    }
    this.setState({ hasExtraSubTypes });
  };

  renderSubCategory = (extraRoleKey) => {
    const { admin } = this.props;
    const {
      type, specialty, jobCategory, subCategory, vetSubCategory, externalId, extraRoles,
    } = this.state;
    let extraRoleObj = {};
    let talentType = type;
    let specialtyVal = specialty;
    let jobCategoryVal = jobCategory;
    let subCategoryVal = subCategory;
    let vetSubCategoryVal = vetSubCategory;
    let exRoles = [];

    const isHQAdmin = admin && admin.adminRole === 'HQ_ADMIN';

    if (extraRoleKey) {
      exRoles = [...extraRoles];
      extraRoleObj = exRoles.find(r => r.key === extraRoleKey) || {};
      talentType = extraRoleObj.talentType;
      specialtyVal = extraRoleObj.specialty;
      jobCategoryVal = extraRoleObj.jobCategory;
      subCategoryVal = extraRoleObj.subCategory;
      vetSubCategoryVal = extraRoleObj.vetSubCategory;
    }

    if (talentType === 'specialist-veterinarian') {
      return (
        <Grid item xs={12} sm={6} key={`${specialtyVal}_${extraRoleKey || ''}_cont`}>
          <SingleSelect
            value={specialtyVal || ''}
            title="Specialty"
            items={SPECIALITIES_MAP || []}
            onChange={(specialty) => {
              if (extraRoleKey) {
                extraRoleObj.specialty = specialty;
                extraRoleObj = omit(extraRoleObj, ['jobCategory', 'subCategory', 'vetSubCategory']);
                this.setState({ extraRoles: exRoles }, () => this.checkExtraRolesValid());
              } else {
                this.setState({
                  specialty,
                  subCategorySelected: true,
                });
              }
            }}
            disabled={(!!externalId && !extraRoleKey && !isHQAdmin)}
            form
            key={`${specialtyVal}_${extraRoleKey || ''}`}
          />
        </Grid>
      );
    } else if (talentType === 'non-clinical') {
      return (
        <Grid item xs={12} sm={6} key={`${jobCategoryVal}_${extraRoleKey || ''}_cont`}>
          <SingleSelect
            value={jobCategoryVal || ''}
            title="Job Category"
            items={JOB_CATEGORIES || []}
            onChange={(jobCategory) => {
              if (extraRoleKey) {
                extraRoleObj.jobCategory = jobCategory;
                extraRoleObj = omit(extraRoleObj, ['specialty', 'subCategory', 'vetSubCategory']);
                this.setState({ extraRoles: exRoles }, () => this.checkExtraRolesValid());
              } else {
                this.setState({
                  jobCategory,
                  subCategorySelected: true,
                });
              }
            }}
            disabled={(!!externalId && !extraRoleKey && !isHQAdmin)}
            form
            key={`${jobCategoryVal}_${extraRoleKey || ''}`}
          />
        </Grid>
      );
    } else if (talentType === 'dvm-doctor-of-veterinary-medicine') {
      const dvmUserCategories = DVM_STAFF_CATEGORIES.filter(category => category.key !== 'all');
      return (
        <Grid item xs={12} sm={6} key={`${subCategoryVal}_${extraRoleKey || ''}_cont`}>
          <SingleSelect
            value={subCategoryVal || ''}
            title="Sub-category"
            items={dvmUserCategories || []}
            onChange={(subCategory) => {
              if (extraRoleKey) {
                extraRoleObj.subCategory = subCategory;
                extraRoleObj = omit(extraRoleObj, ['specialty', 'jobCategory', 'vetSubCategory']);
                this.setState({ extraRoles: exRoles }, () => this.checkExtraRolesValid());
              } else {
                this.setState({
                  subCategory,
                  subCategorySelected: true,
                });
              }
            }}
            disabled={(!!externalId && !extraRoleKey && !isHQAdmin)}
            form
            key={`${subCategoryVal}_${extraRoleKey || ''}`}
          />
        </Grid>
      );
    } else if (talentType === 'vet-tech-or-assistant') {
      const vetUserCategories = VET_STAFF_CATEGORIES.filter(category => category.key !== 'all');
      return (
        <Grid item xs={12} sm={6} key={`${vetSubCategoryVal}_${extraRoleKey || ''}_cont`}>
          <SingleSelect
            value={vetSubCategoryVal || ''}
            title="Sub-category"
            items={vetUserCategories || []}
            onChange={(vetSubCategory) => {
              if (extraRoleKey) {
                extraRoleObj.vetSubCategory = vetSubCategory;
                extraRoleObj = omit(extraRoleObj, ['specialty', 'jobCategory', 'subCategory']);
                this.setState({ extraRoles: exRoles }, () => this.checkExtraRolesValid());
              } else {
                this.setState({
                  vetSubCategory,
                  subCategorySelected: true,
                });
              }
            }}
            disabled={(!!externalId && !extraRoleKey && !isHQAdmin)}
            form
            key={`${vetSubCategoryVal}_${extraRoleKey || ''}`}
          />
        </Grid>
      );
    }
    return null;
  };

  renderExtraRoleInputs = () => {
    const { extraRoles } = this.state;
    const exRoles = [...extraRoles];
    return (
      extraRoles.map(role => (
        <Fragment>
          <Grid item xs={12} sm={6} key={role.key}>
            <SingleSelect
              key={`${role.key}_talentType`}
              value={role.talentType || ''}
              title={`Additional Role ${role.key}`}
              items={VET_TYPES || []}
              onChange={(type) => {
                const exRole = exRoles.find(r => r.key === role.key);
                if (exRole) {
                  exRole.talentType = type;
                } else {
                  exRoles.push({
                    key: exRoles.length + 1,
                    talentType: type,
                  });
                }
                this.setState({ extraRoles: exRoles }, () => this.checkExtraRolesValid());
              }}
              form
            />
          </Grid>
          {this.renderSubCategory(role.key)}
        </Fragment>
      ))
    );
  };

  render() {
    const {
      classes, loading, viewMoreLoading, edit, resources, theme, admin,
    } = this.props;
    const { adminPractices } = resources;
    const {
      id, practiceId, firstName, lastName, email, mobile, error,
      type, requireSubCategory, subCategorySelected, createDefault, hasExtraSubTypes, extraRoles,
      range, help, helpMsg, externalId, specialty, jobCategory, subCategory, vetSubCategory,
      intervalDate, isHadCoronadoBadge,
    } = this.state;
    const isNew = !id;
    const isEdit = id && edit;
    const isFormValid = (firstName && lastName && email
      && type && practiceId && (!requireSubCategory || subCategorySelected))
      && (isEmpty(extraRoles) || (!isEmpty(extraRoles) && hasExtraSubTypes));
    let nonArchivedPractices = adminPractices && adminPractices.filter(practice => !practice.deletedAt && practice.active);
    nonArchivedPractices = orderBy(nonArchivedPractices, ['name', 'id'], ['asc', 'asc']);
    const isHQAdmin = admin.adminRole === HQ_ADMIN_KEY;
    const stylesWithTheme = commonResourceStyle(theme);
    const practiceSeleted = practiceId && nonArchivedPractices.find(x => x.id === practiceId);
    return (
      (loading === true && viewMoreLoading === 0)
        ? <LoadingBar />
        : (
          <div style={stylesWithTheme.container}>
            <div style={stylesWithTheme.resourceHeader}>
              <div style={stylesWithTheme.resourceNameLarge}>
                <Typography sx={stylesWithTheme.resourceNameLarge}>
                  {isNew && 'CREATE NEW STAFF'}
                  {isEdit && 'EDIT STAFF'}
                </Typography>
                <Button
                  variant="contained"
                  sx={stylesWithTheme.headerButtonCancel}
                  onClick={this.cancel}
                >
                  Cancel
                </Button>
                <Button
                  sx={!isFormValid ? stylesWithTheme.headerDisabledButton : stylesWithTheme.headerButton}
                  onClick={this.save}
                  disabled={!isFormValid}
                >
                  SAVE
                </Button>
              </div>
            </div>
            <Paper sx={stylesWithTheme.paper}>
              <div style={stylesWithTheme.paperContainer}>
                {externalId && (<Typography sx={stylesWithTheme.resourceAlert}>{'>>>To edit unavailable fields, please update information in Workday.<<<'}</Typography>)}
                <Typography sx={stylesWithTheme.resourceSub}>user information</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextInput
                      value={firstName || ''}
                      title="First Name"
                      disabled={!!externalId}
                      onChange={firstName => this.setState({ firstName })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextInput
                      value={lastName || ''}
                      title="Last Name"
                      disabled={!!externalId}
                      onChange={lastName => this.setState({ lastName })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextInput
                      value={mobile || ''}
                      type='tel'
                      title="Phone"
                      placeholder="555-555-5555"
                      onChange={mobile => this.setState({ mobile: prunePhoneNumber(mobile) })}
                      onPaste={() => this.setState({ isPasted: true })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextInput
                      value={email || ''}
                      type='email'
                      title="Email"
                      disabled={!!externalId}
                      onChange={email => this.setState({ email })}
                    />
                  </Grid>
                  {isHadCoronadoBadge && (
                    <>
                      <Grid item xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'column', marginTop: 3 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <label style={{
                            color: 'rgba(0,0,0,0.6)',
                            fontFamily: 'ASAP',
                            fontSize: '1rem',
                            marginBottom: 10,
                          }}
                          >
                            Interval Date
                          </label>
                          <DatePicker
                            value={dayjs(intervalDate)}
                            disabled={!isHQAdmin}
                            onChange={(newValue) => {
                              this.setState({ intervalDate: dayjs(newValue).format('YYYY-MM-DD') });
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} sm={6} />
                    </>
                  )}
                  <Grid item xs={12} sm={6}>
                    <SingleSelect
                      value={type || ''}
                      title="Vet Type"
                      disabled={!!externalId}
                      items={VET_TYPES || []}
                      onChange={type => this.setState({
                        type,
                        requireSubCategory: subResourceTypes.includes(type),
                        subCategorySelected: null,
                      })}
                      form
                    />
                  </Grid>
                  {this.renderSubCategory()}
                  {this.renderExtraRoleInputs()}
                  <Grid item xs={12}>
                    <Button
                      sx={stylesWithTheme.addNewSocialMedia}
                      disabled={(!type || !(!requireSubCategory || subCategorySelected)) || (!isEmpty(extraRoles) && !hasExtraSubTypes)}
                      onClick={this.handleOnClickAdd}
                    >
                      <Icon>add_circle_outline</Icon>
                      ADD MORE ROLES
                    </Button>
                    {!isEmpty(this.state.extraRoles) && (
                      <Button
                        sx={stylesWithTheme.addNewSocialMedia}
                        disabled={!type || !(!requireSubCategory || subCategorySelected)}
                        onClick={this.handleOnClickRemove}
                      >
                        <Icon>remove_circle_outline</Icon>
                        REMOVE THE LAST ROLE
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <PracticeSelection
                      value={practiceSeleted || ''}
                      title="PLease Select"
                      items={nonArchivedPractices || []}
                      handleStateChange={(practice) => {
                        if (practice) {
                          const practiceId = practice.id;
                          this.setState({ practiceId });
                          if (practiceId) {
                            const newRange = nonArchivedPractices?.filter(x => x.id === practiceId)[0]?.defaultRange;
                            this.setState({ range: newRange });
                          }
                        }
                      }}
                      disabled={!!externalId}
                      form
                      label="Home Practice"
                      noOptionsText="No Practice"
                      lettersTypedToShowOptions={2}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      style={{
                        marginLeft: '3px',
                      }}
                      control={(
                        <Checkbox
                          style={{ paddingLeft: 0 }}
                          checked={createDefault}
                          onChange={(e) => {
                            if (!createDefault && practiceId) {
                              const newRange = nonArchivedPractices?.filter(x => x.id === practiceId)[0]?.defaultRange;
                              this.setState({ range: newRange });
                            }
                            this.setState({
                              createDefault: e.target.checked,
                              isDefaultRelief: e.target.checked,
                              isDefaultReliefHome: e.target.checked,
                            });
                          }}
                        />
                      )}
                      label={(
                        <p>
                          <b>Check</b>
                          {' '}
                          the box to create default WhiskerBot searches (Home, Network).
                          <br />
                          <b>Unchecking</b>
                          {' '}
                          a checked box will delete them.
                        </p>
                      )}
                    />
                  </Grid>
                  {createDefault
                    && (
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>

                          <FormLabel style={stylesWithTheme.formControlLabel}>
                            Relief Range (miles)
                            <HelpOutlineIcon
                              style={stylesWithTheme.helpOutlineIcon}
                              onClick={e => this.setState({
                                help: e.target,
                                helpMsg: 'The Relief Range is a (generous) distance for sharing your in-network staff. \nFor example, if you want to share staff with a hospital 20 miles away, put the slider on 30 miles to be sure of inclusion.',
                              })}
                            />
                          </FormLabel>
                          <TextField
                            value={range}
                            onChange={e => this.setState({ range: e.target.value })}
                            fullWidth
                            variant="outlined"
                          />
                          <Popover
                            open={!!help}
                            anchorEl={help}
                            onClose={e => this.setState({ help: null })}
                          >
                            <Paper style={stylesWithTheme.helpPopover}>
                              <Typography sx={stylesWithTheme.helpPopoverText}>{helpMsg}</Typography>
                            </Paper>
                          </Popover>
                        </FormControl>
                      </Grid>
                    )}
                </Grid>
                <div style={stylesWithTheme.formActions}>
                  <div>
                    {error && <Typography color="error">{error}</Typography>}
                  </div>
                </div>
              </div>
            </Paper>
          </div>
        )
    );
  }
}

PrivateUsersForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  apiToken: PropTypes.string.isRequired,
  private: PropTypes.bool,
};

PrivateUsersForm.defaultProps = {
  private: true,
};

export default withStyles(commonResourceStyle, { withTheme: true })(withRouter(PrivateUsersForm));
