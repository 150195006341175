import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  TextField, Checkbox, Typography, AppBar, Tabs, Tab,
} from '@mui/material';

import { sevenDaysInAWeek } from '../../data/Constants';

const hoursStyles = theme => ({
  hoursContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    height: '35px',
  },
  dayInWeekText: {
    fontFamily: 'Asap',
    fontSize: '14px',
    width: '90px',
    textAlign: 'right',
  },
  timeInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '10px',
    alignItems: 'center',
  },
  dashInbetween: {
    padding: '10px 30px 10px 10px',
  },
  inputProps: {
    step: 300, // 5 min
    style: {
      fontSize: '15px',
      fontFamily: 'Asap',
    },
  },
  inputLabelProps: {
    shrink: true,
  },
  textField: {
    height: '35px',
  },
  checkBoxLabel: {
    fontFamily: 'Asap',
    width: '60px',
  },
  sectionTitle: {
    fontFamily: 'Asap',
    fontWeight: 'bold',
    padding: '12px',
  },
  tabAppBar: {
    width: '500px',
    boxShadow: 'none',
  },
  tabHeader: {
    fontFamily: 'Asap',
    textTransform: 'none',
  },
  tabContentContainer: {
    border: '1px solid #9a9a9a',
    backgroundColor: '#fff',
    borderRadius: '0 0 8px 8px',
    height: '260px',
    padding: '10px 10px 20px 10px',
  },
  sameAsOpeningHoursContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sameAsOpeningHoursText: {
    fontSize: '14px',
    fontFamily: 'Asap',
  },
  checkBoxColor: {
    color: '#000',
  },
  emptyDiv: {
    height: '15px',
  },
});

const styles = hoursStyles();

export default class Hours extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    data: null,
  };

  constructor(props) {
    super(props);
    const { data } = props;
    const hours = !data ? [] : data;
    if (hours.length === 0) {
      Object.keys(sevenDaysInAWeek).forEach(day => hours.push({ day, open: null, close: null }));
    }

    const openingHoursCheckBox = new Array(7);
    const deliveryCheckBox = new Array(7);
    // If open and close times are null then location is closed
    hours.forEach((hour, index) => {
      if (!hour.open && !hour.close) {
        openingHoursCheckBox[index] = true;
      }
      if (!hour.deliveryOpen && !hour.deliveryClose) {
        deliveryCheckBox[index] = true;
      }
    });

    this.state = {
      hours,
      isClosedChecked: openingHoursCheckBox,
      isNoDeliveryChecked: deliveryCheckBox,
      sameAsOpeningCheckbox: false,
      tabValue: 1,
    };
  }

  onChangeHours = (event, updatedHour, openOrClose) => {
    const inputVal = event ? event.target.value : null;
    let { hours, isNoDeliveryChecked } = this.state;
    const deliveryStateArrCpy = isNoDeliveryChecked.slice();
    const { onChange } = this.props;
    hours = hours.map((hour, index) => {
      if (hour.day === updatedHour.day) {
        if (inputVal !== '' && isNoDeliveryChecked[index]) {
          deliveryStateArrCpy[index] = !deliveryStateArrCpy[index];
        }
        hour[openOrClose] = inputVal === '' ? null : inputVal;
      }
      return hour;
    });
    this.setState({
      hours,
      isNoDeliveryChecked: deliveryStateArrCpy,
    });
    onChange(hours);
  };

  onCheckBoxChange = (event, updatedHour, index, isDelivery) => {
    // e.g. open or deliveryOpen
    const openHourFieldName = isDelivery ? 'deliveryOpen' : 'open';
    const closeHourFieldName = isDelivery ? 'deliveryClose' : 'close';

    const { hours } = this.state;
    const { onChange } = this.props;
    const dataPassUp = hours.map((hour) => {
      if (hour.day === updatedHour.day) {
        hour[openHourFieldName] = null;
        hour[closeHourFieldName] = null;
      }
      return hour;
    });
    onChange(dataPassUp);

    // update checkboxes state for delivery and opening hours
    if (isDelivery) {
      const deliveryStateCopy = this.state.isNoDeliveryChecked.slice();
      deliveryStateCopy[index] = !deliveryStateCopy[index];
      this.setState({ isNoDeliveryChecked: deliveryStateCopy });
    } else {
      const openingStateCopy = this.state.isClosedChecked.slice();
      openingStateCopy[index] = !openingStateCopy[index];
      this.setState({ isClosedChecked: openingStateCopy });
    }
  };

  getTime = (hour) => {
    const timeArray = hour.split(':');
    const time = new Date();
    time.setHours(timeArray[0]);
    time.setMinutes(timeArray[1]);

    return time;
  };

  renderHoursOfDays = (day, hour, index, isDelivery) => {
    const openTimeToRender = isDelivery ? hour.deliveryOpen : hour.open;
    const closeTimeToRender = isDelivery ? hour.deliveryClose : hour.close;
    return (
      <div
        key={hour.day}
        style={{
          ...styles.hoursContainer,
          height: this.state.tabValue === 2 ? '30px' : '35px',
        }}
      >
        <div style={styles.dayInWeekText}>{`${day} :`}</div>
        <div style={styles.timeInputContainer}>
          <TextField
            id="time"
            type="time"
            value={openTimeToRender || ''}
            onChange={event => this.onChangeHours(event, hour, isDelivery ? 'deliveryOpen' : 'open')}
            InputLabelProps={styles.inputLabelProps}
            inputProps={styles.inputProps}
            style={{
              ...styles.textField,
              border:
                !openTimeToRender && closeTimeToRender
                  ? '1px solid red'
                  : 'none',
            }}
            InputProps={{ disableUnderline: true }}
          />
          <div style={styles.dashInbetween}> - </div>
          <TextField
            id="time"
            type="time"
            value={closeTimeToRender || ''}
            onChange={event => this.onChangeHours(event, hour, isDelivery ? 'deliveryClose' : 'close')}
            InputLabelProps={styles.inputLabelProps}
            inputProps={styles.inputProps}
            style={{
              ...styles.textField,
              border:
                openTimeToRender && !closeTimeToRender
                  ? '1px solid red'
                  : 'none',
            }}
            InputProps={{ disableUnderline: true }}
          />
          <Checkbox
            checked={
              isDelivery
                ? this.state.isNoDeliveryChecked[index]
                : this.state.isClosedChecked[index]
            }
            onChange={event => this.onCheckBoxChange(event, hour, index, isDelivery)}
            style={styles.checkBoxColor}
          />
          <Typography style={styles.checkBoxLabel}>Close</Typography>
        </div>
      </div>
    );
  };

  onSameAsOpeningCheckBoxChange = () => {
    const { onChange } = this.props;
    const { hours } = this.state;
    const newHours = hours.map((hour) => {
      hour.deliveryOpen = hour.open;
      hour.deliveryClose = hour.close;
      return hour;
    });
    onChange(newHours);
    this.setState({
      hours: newHours,
      sameAsOpeningCheckbox: !this.state.sameAsOpeningCheckbox,
    });
  };

  render() {
    const { hours } = this.state;
    const isDelivery = true;
    return (
      <AppBar position="static" color="default" style={styles.tabAppBar}>
        <Tabs
          variant="fullWidth"
          value={this.state.tabValue}
          style={{ backgroundColor: '#fff' }}
          indicatorColor="primary"
        >
          <Tab
            label="Opening Hours"
            value={1}
            style={styles.tabHeader}
            onClick={() => this.setState({ tabValue: 1 })}
          />
          <Tab
            label="Delivery Hours"
            value={2}
            style={styles.tabHeader}
            onClick={() => this.setState({ tabValue: 2 })}
          />
        </Tabs>
        <div style={styles.tabContentContainer}>
          {this.state.tabValue === 2 ? (
            <div style={styles.sameAsOpeningHoursContainer}>
              <Checkbox
                checked={this.state.sameAsOpeningCheckbox}
                onChange={this.onSameAsOpeningCheckBoxChange}
                style={styles.checkBoxColor}
              />
              <Typography style={styles.sameAsOpeningHoursText}>
                Same as Opening Hours
              </Typography>
            </div>
          ) : (
            <div style={styles.emptyDiv} />
          )}
          {this.state.tabValue === 1
            && Object.keys(sevenDaysInAWeek).map((day, index) => {
              const dayHours = hours.filter(hour => hour.day === day);
              const hoursToRender = dayHours.length === 0 ? null : dayHours[0];
              return this.renderHoursOfDays(
                sevenDaysInAWeek[day],
                hoursToRender,
                index,
              );
            })}
          {this.state.tabValue === 2
            && Object.keys(sevenDaysInAWeek).map((day, index) => {
              const dayHours = hours.filter(hour => hour.day === day);
              const hoursToRender = dayHours.length === 0 ? null : dayHours[0];
              return this.renderHoursOfDays(
                sevenDaysInAWeek[day],
                hoursToRender,
                index,
                isDelivery,
              );
            })}
        </div>
      </AppBar>
    );
  }
}
