
import React from 'react';
import { Button, Icon } from '@mui/material';
import { IMG_STORAGE_LINK, TIMESTAMPS } from '../../data/Constants';
import { commonResourceStyle } from '../../css/style';
const styles = commonResourceStyle();

export const renderAddIcon = ({
  buttonTitle,
  onClickButton,
  styleObj = {},
  classObj = {},
}) => {
  if (!onClickButton || !buttonTitle) {
    return null;
  }
  return (
    <Button
      key="addButton"
      variant="contained"
      onClick={onClickButton}
      style={styleObj}
      sx={classObj}
    >
      <Icon
        style={{
          marginRight: '10px',
          fontSize: '19px',
        }}
      >
        add_circle_outline
      </Icon>
      {buttonTitle}
    </Button>
  );
};

export const renderPaginationImage = ({
  isPrevious,
  currentPage,
  totalPage,
  pagination,
  onClickViewMore,
}) => {
  if (isPrevious) {
    return (
      currentPage != 0 && (
        <img
          src={`${IMG_STORAGE_LINK}ic-keyboard-backspace.svg`}
          id="prePageImage"
          style={{
            transform: 'rotate(180deg)',
            marginRight: '4px',
            cursor: 'pointer',
          }}
          onClick={() => {
            onClickViewMore(currentPage);
          }}
        />
      )
    );
  } else {
    return (
      totalPage != 1
      && totalPage != currentPage + 1
      && pagination.totalSize > 0 && (
        <img
          src={`${IMG_STORAGE_LINK}ic-keyboard-backspace.svg`}
          id="nextPageImage"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (totalPage !== currentPage + 1) {
              onClickViewMore(currentPage + 2);
            }
          }}
        />
      )
    );
  }
};

export const renderPageNumber = ({
  currentPage,
  totalPage,
  index,
  onClickViewMore,
}) => {
  if (
    index === 0
    || (currentPage < 3 && index < 3)
    || currentPage - 1 === index
    || currentPage === index
    || currentPage + 1 === index
    || index === totalPage - 1
  ) {
    return (
      <span
        key={`page_${index + 1}`}
        style={
          index === currentPage
            ? {
              ...styles.pageNumberSpan,
              ...{
                backgroundColor: 'rgb(75, 189, 173)',
                color: '#fff',
                borderRadius: '50%',
                border: '1px solid rgb(75, 189, 173)',
              },
            }
            : styles.pageNumberSpan
        }
        onClick={() => onClickViewMore(index + 1)}
      >
        {index + 1}
      </span>
    );
  } else if (
    (index === currentPage - 2 && currentPage > 2)
    || (index === totalPage - 2 && currentPage < totalPage && totalPage > 4)
  ) {
    return (
      <span key={index + 1} style={styles.pageDotSpan} id="morePage">
        ...
      </span>
    );
  }
};

export const getMoodIcon = (interval, classObj = {}) => {
  let mood;
  let color;

  if (interval <= TIMESTAMPS.eightHoursTS) {
    mood = 'sentiment_satisfied_alt';
    color = '#99cc33';
  } else if (interval <= TIMESTAMPS.twentySixHoursTS) {
    mood = 'sentiment_neutral';
    color = '#ffcc00';
  } else {
    mood = 'sentiment_very_dissatisfied';
    color = '#cc3300';
  }

  return <Icon sx={classObj.moodIcon} style={{ color }}>{mood}</Icon>;
};

export const nFormatter = (num) => {
  if (num >= 1000000000) {
    return `${(num / 1000000000).toFixed(1).replace(/\.0$/, '')}G`;
  }
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(1).replace(/\.0$/, '')}M`;
  }
  if (num >= 1000) {
    return `${(num / 1000).toFixed(1).replace(/\.0$/, '')}K`;
  }
  return num;
};
