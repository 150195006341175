import ResourcesAPI from '../api/ResourcesAPI';
import types from './ActionTypes';
import Config from '../../config.json';

export function displayErrorMessage(error) {
  return {
    type: types.RENDER_MESSAGE,
    error,
  };
}

export function clearMessage() {
  return {
    type: types.CLEAR_MESSAGE,
  };
}

export function updateLoadingState(type) {
  return {
    type,
  };
}

export function dataRetrievalFailure(error) {
  return {
    type: types.ERROR, error,
  };
}

export function clearErrors() {
  return {
    type: types.ERROR,
  };
}

function registerUserSuccess(data) {
  const loggedInAdmin = data.data;
  const resourcePath = ['users'];
  return {
    type: types.REGISTER_USER_SUCCESS, loggedInAdmin, resourcePath,
  };
}

function registerUserFailure(error) {
  return {
    type: types.REGISTER_USER_FAILURE, error,
  };
}

export function registerAdmin(user) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.REGISTER_USER_REQUEST));
    return ResourcesAPI.signUpUser(user)
      .then(
        data => dispatch(registerUserSuccess(data)),
        (error) => {
          dispatch(registerUserFailure(error));
        },
      );
  };
}

export function sendSignUpRequest(adminInfo) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.SEND_SIGN_UP_REQUEST));
    return ResourcesAPI.sendSignUpRequest(adminInfo)
      .then(
        data => dispatch(sendSignUpRequestSuccess(data)),
        (error) => {
          dispatch(sendSignUpRequestFailure(error));
        },
      );
  };
}

function sendSignUpRequestSuccess(data) {
  return {
    type: types.SEND_SIGN_UP_REQUEST_SUCCESS,
    data,
  };
}

function sendSignUpRequestFailure(error) {
  return {
    type: types.SEND_SIGN_UP_REQUEST_FAILURE, error,
  };
}

export function loginAdmin(admin) {
  updateLoadingState(types.LOGIN_REQUESTED);

  return dispatch => ResourcesAPI.loginAdmin(admin)
    .then(admin => dispatch(loginAdminSuccess(admin)),
      error => dispatch(loginAdminFailure(error)));
}

export function loginAdminSuccess(admin) {
  const loggedInAdmin = admin.data;

  return {
    type: types.LOGIN_SUCCESS, loggedInAdmin,
  };
}

export function loginAdminFailure(error) {
  return {
    type: types.LOGIN_FAILURE, error,
  };
}

export function updateAdmin(apiToken, newResource, ...resourcePath) {
  updateLoadingState(types.UPDATE_ADMIN_REQUESTED);
  return dispatch => ResourcesAPI.updateResource(apiToken, newResource, resourcePath)
    .then(response => dispatch(updateAdminSuccess(response.data, resourcePath)),
      error => dispatch(dataRetrievalFailure(error)));
}

export function updateAdminSuccess(response, resourcePath) {
  return {
    type: types.UPDATE_ADMIN_SUCCESS,
    loggedInAdmin: response,
    resourcePath,
  };
}

function updateAdminFailure(error) {
  return {
    type: types.UPDATE_ADMIN_FAILURE, error,
  };
}

export function signoutUser() {
  return {
    type: types.SIGNOUT_USER,
  };
}

export function filterProducts(category) {
  return {
    type: types.FILTER_PRODUCTS, category,
  };
}

export function setAdmin(admin) {
  return {
    type: types.SET_ADMIN, admin,
  };
}

export function setAdminStripeEnabled(stripeEnabled) {
  return {
    type: types.SET_STRIPE_ENABLED,
    stripeEnabled,
  };
}

/**
 * Change Password actions:
 *    get recovery token
 *    verify token
 *    reset password
 */
export function getRecoveryTokenSuccess(returnedAdmin) {
  return {
    type: types.GET_RECOVERY_TOKEN_SUCCESS,
    returnedAdmin,
  };
}

export function getRecoveryToken(adminEmail) {
  return dispatch => ResourcesAPI.addResource(Config.general.apiKey, adminEmail, ['users', 'forgot-password'])
    .then(res => dispatch(getRecoveryTokenSuccess(res)))
    .catch(err => dispatch(dataRetrievalFailure(err)));
}

export function resetPassword(adminObj) {
  return dispatch => ResourcesAPI.addResource(Config.general.apiKey, adminObj, ['users', 'reset-password'])
    .then(res => dispatch(resetPasswordSuccess(res)))
    .catch(err => dispatch(dataRetrievalFailure(err)));
}

export function resetPasswordSuccess(returnedAdmin) {
  return {
    type: types.CONFIRM_RESET_PASSWORD_SUCCESS,
    returnedAdmin,
  };
}

/*
 * Resource Actions
 * ...resourcePath is the full path to the resource. It can include multiple nested resources such as
 * ('categories') -> all categories
 * ('categories',1) -> category with id 1
 * ('categories',1,'products') -> all products of category with id 1
 * ('categories',1,'products',2) -> product with id 2 of category with id 1
 * ...
 */

export function deleteFastpass(apiToken, ...resourcePath) {
  updateLoadingState(types.DELETE_RESOURCE_REQUESTED);

  return dispatch => ResourcesAPI.deleteFastpass(apiToken, resourcePath)
    .then(response => response,
      error => dispatch(dataRetrievalFailure(error)));
}

export function deleteResource(apiToken, ...resourcePath) {
  updateLoadingState(types.DELETE_RESOURCE_REQUESTED);

  return dispatch => ResourcesAPI.deleteResource(apiToken, resourcePath)
    .then(() => { dispatch(deleteResourceSuccess(resourcePath)); },
      error => dispatch(dataRetrievalFailure(error)));
}

export function deleteResourceSuccess(resourcePath) {
  return {
    type: types.DELETE_RESOURCE_SUCCESS, resourcePath,
  };
}

export function addResource(apiToken, resource, ...resourcePath) {
  updateLoadingState(types.ADD_RESOURCE_REQUESTED);
  return dispatch => ResourcesAPI.addResource(apiToken, resource, resourcePath)
    .then(response => dispatch(addResourceSuccess(response.data, resourcePath)),
      error => dispatch(dataRetrievalFailure(error)));
}

export function duplicateResource(apiToken, resourcePath) {
  updateLoadingState(types.ADD_RESOURCE_REQUESTED);
  return dispatch => ResourcesAPI.duplicateResource(apiToken, resourcePath)
    .then(response => dispatch(addResourceSuccess(response.data, resourcePath)),
      error => dispatch(dataRetrievalFailure(error)));
}

export function addResourceSuccess(response, resourcePath) {
  return {
    type: types.ADD_RESOURCE_SUCCESS,
    response,
    resourcePath,
  };
}

export function uploadResource(apiToken, resource, ...resourcePath) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.UPLOAD_RESOURCE_REQUESTED));
    return ResourcesAPI.uploadResource(apiToken, resource, resourcePath)
      .then(response => dispatch(uploadResourceSuccess(
        response, resourcePath,
      )), error => dispatch(dataRetrievalFailure(error)));
  };
}

export function uploadResourceSuccess(response, resourcePath) {
  return {
    type: types.UPLOAD_RESOURCE_SUCCESS,
    response,
    resourcePath,
  };
}

export function getDownloadResourcesSuccess(response, resourcePath) {
  return {
    type: types.GET_DOWNLOAD_RESOURCES_SUCCESS,
    response,
    resourcePath,
  };
}
export function downloadResource(apiToken, ...resourcePath) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.GET_DOWNLOAD_RESOURCE_REQUESTED));
    return ResourcesAPI.downloadResource(apiToken, resourcePath)
      .then(response => dispatch(getDownloadResourcesSuccess(
        response, resourcePath,
      )), error => dispatch(dataRetrievalFailure(error)));
  };
}

export function updateResource(apiToken, newResource, ...resourcePath) {
  updateLoadingState(types.UPDATE_RESOURCE_REQUESTED);
  return dispatch => ResourcesAPI.updateResource(apiToken, newResource, resourcePath)
    .then(response => dispatch(updateResourceSuccess(response.data, resourcePath)),
      error => dispatch(dataRetrievalFailure(error)));
}

export function updateResourceSuccess(response, resourcePath) {
  return {
    type: types.UPDATE_RESOURCE_SUCCESS,
    response,
    resourcePath,
  };
}

export function getAllResourcesSuccess(response, resourcePath) {
  return {
    type: types.GET_ALL_RESOURCES_SUCCESS,
    response,
    resourcePath,
  };
}

export function getAllResources(apiToken, ...resourcePath) {
  return (dispatch) => {
    if (!resourcePath.includes('jobpostings/candidates')
      && !resourcePath.includes('jobpostings/titles')
    ) {
      dispatch(updateLoadingState(types.GET_ALL_RESOURCES_REQUESTED));
    }
    return ResourcesAPI.getAllResources(apiToken, resourcePath)
      .then(response => dispatch(getAllResourcesSuccess(
        response.data, resourcePath,
      )),
      error => dispatch(dataRetrievalFailure(error)));
  };
}

export function getOneResourceSuccess(response, resourcePath) {
  return {
    type: types.GET_ONE_RESOURCE_SUCCESS,
    response,
    resourcePath,
  };
}

export function getOneResource(apiToken, ...resourcePath) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.GET_ONE_RESOURCE_REQUESTED));
    return ResourcesAPI.getResource(apiToken, resourcePath)
      .then(response => dispatch(getOneResourceSuccess(
        response.data, resourcePath,
      )),
      error => dispatch(dataRetrievalFailure(error)));
  };
}

export function sendPushNotificationSuccess(response) {
  return {
    type: types.SEND_PUSH_NOTIFICATION_SUCCESS,
    response,
  };
}

export function sendPushNotification(apiToken, message, ...resourcePath) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.SEND_PUSH_NOTIFICATION));
    return ResourcesAPI.addResource(apiToken, message, resourcePath, true)
      .then(res => dispatch(sendPushNotificationSuccess(res)),
        error => dispatch(dataRetrievalFailure(error)));
  };
}

export function addResourceFilter(resource, filter) {
  return {
    type: types.ADD_RESOURCES_FILTER,
    resource,
    filter,
  };
}

function verifyUserSuccess(user, resourcePath) {
  const loggedInAdmin = user.data;
  return {
    type: types.VERIFY_USER_SUCCESS,
    loggedInAdmin,
    successMsg: 'Your account has been verified.',
  };
}

// Verify user
export function verifyUser(resourcePath) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.VERIFY_USER_REQUEST));
    return ResourcesAPI.addResource(null, {}, resourcePath)
      .then(
        (verifiedRecoveredUser) => {
          dispatch(verifyUserSuccess(verifiedRecoveredUser, resourcePath));
        },
        error => dispatch(dataRetrievalFailure(error)),
      );
  };
}

export function createStripe(apiToken, resource, ...resourcePath) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.CREATE_STRIPE_REQUESTED));
    return ResourcesAPI.addResource(apiToken, resource, resourcePath)
      .then(response => dispatch(createStripeSuccess(response.data, resourcePath)),
        error => dispatch(dataRetrievalFailure(error)));
  };
}

function createStripeSuccess(response, resourcePath) {
  return {
    type: types.CREATE_STRIPE_SUCCESS,
    response,
    resourcePath,
  };
}

export function cancelStripeSubscription(apiToken, resource, ...resourcePath) {
  return (dispatch) => {
    dispatch(updateLoadingState(types.CANCEL_STRIPE_SUBSCRIPTION_REQUESTED));
    return ResourcesAPI.addResource(apiToken, resource, resourcePath)
      .then(response => dispatch(cancelStripeSubscriptionSuccess(response.data, resourcePath)),
        error => dispatch(dataRetrievalFailure(error)));
  };
}

function cancelStripeSubscriptionSuccess(response, resourcePath) {
  return {
    type: types.CANCEL_STRIPE_SUBSCRIPTION_SUCCESS,
    response,
    resourcePath,
  };
}
