import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  IconButton,
  TextField,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Close } from '@mui/icons-material';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

import { badgeDialogStyles } from '../../css/dialogStyles';
import { badgeColor, handleTrailingSpaces } from '../../utils/Functions';
import UserAutocomplete from './UserAutocomplete';

class InviteBadgeDialog extends Component {
  constructor() {
    super();
    this.state = {
      isBadgeStaff: false,
      staffId: null,
      badges: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.open) return null;
    const badges = {};
    nextProps.badges.forEach((x) => {
      badges[x.id] = false;
    });
    return {
      email: '',
      badges,
    };
  }

  getBadge(title, id) {
    const { theme } = this.props;
    const stylesWithTheme = badgeDialogStyles(theme);
    return (
      <Button
        sx={stylesWithTheme.badgeFilter}
        onClick={() => this.badgeSelected(id || title)}
        key={title}
        style={{
          backgroundColor: badgeColor(id || title),
          marginTop: 0,
        }}
      >
        {title}
      </Button>
    );
  }

  handleSave() {
    const {
      handleOnClick, actions, apiToken, staff,
    } = this.props;
    const {
      email,
      badges,
      isBadgeStaff,
      staffId,
    } = this.state;

    const badgIds = Object.keys(badges).filter(x => badges[x]);
    const canSave = badgIds?.length && (email || staffId);
    if (!canSave) return;

    let emailInPayload = email;
    if (isBadgeStaff) {
      const targetStaff = staff.filter(item => item.id === staffId);
      emailInPayload = targetStaff.length > 0 && targetStaff[0].email;
    }

    actions.addResource(apiToken, {
      email: handleTrailingSpaces(emailInPayload),
      badges: badgIds,
    }, 'userbadges').then((e) => {
      if (!e.error) {
        handleOnClick();
      }
    });
  }

  badgeSelected(id, value) {
    const { badges } = this.state;
    badges[id] = !badges[id];
    this.setState({ badges });
  }

  render() {
    const {
      classes, handleOnClick, open, badges, staff, theme,
    } = this.props;
    const { email, isBadgeStaff, staffId } = this.state;
    const badgIds = this.state.badges && Object.keys(this.state.badges).filter(x => this.state.badges[x]);
    const canSave = badgIds?.length && (email || staffId);
    const stylesWithTheme = badgeDialogStyles(theme);

    return (
      <Dialog
        maxWidth="md"
        PaperProps={{
          style: {
            padding: '24px 4px',
            minWidth: '410px',
          },
        }}
        open={open}
        onClose={() => handleOnClick('close')}
      >
        <DialogTitle style={stylesWithTheme.dialogContent}>
          <div className={classes.titleContainer}>
            <VerifiedUserIcon className={classes.icon} />
            <Typography color="primary" sx={stylesWithTheme.title}>
              INVITE USER
            </Typography>
          </div>
          <IconButton
            aria-label="close"
            sx={stylesWithTheme.closeButton}
            onClick={() => handleOnClick('close')}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent style={stylesWithTheme.dialogContent}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={isBadgeStaff}
                onChange={e => this.setState({
                  isBadgeStaff: !isBadgeStaff,
                  email: '',
                })}
                sx={stylesWithTheme.checkBox}
                value={isBadgeStaff}
              />
            )}
            label="Staff"
          />
          {
            isBadgeStaff
              ? (
                <UserAutocomplete
                  handleStateChange={(selectedStaff) => {
                    this.setState({
                      staffId: selectedStaff?.id,
                      selectedStaff,
                    });
                  }}
                  title='User name/email'
                  items={staff || []}
                  value={this.state.selectedStaff || {}}
                  key='staff_autocomplete'
                  noMargin
                />
              ) : (
                <TextField
                  label="Email"
                  onChange={event => this.setState({ email: event.target.value })}
                  variant="outlined"
                  fullWidth
                  inputProps={{ autoCapitalize: 'none' }}
                  autoComplete="off"
                />
              )
          }
          <div className={classes.badgesHeader}>Badges</div>
          {badges.map(badge => (
            <FormControlLabel
              key={badge.id}
              control={(
                <Checkbox
                  checked={this.state.badges[badge.id]}
                  onChange={e => this.badgeSelected(badge.id)}
                  sx={stylesWithTheme.checkBox}
                  value={badge.title}
                />
              )}
              label={this.getBadge(badge.title, badge.id)}
            />
          ))}

        </DialogContent>
        <DialogActions style={stylesWithTheme.dialogActions}>
          <Button
            disabled={!(canSave)}
            sx={canSave ? stylesWithTheme.button : ''}
            onClick={() => this.handleSave()}
            color="primary"
          >
            BADGE NOW
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}


InviteBadgeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  actions: PropTypes.any.isRequired,
  apiToken: PropTypes.string.isRequired,
  badges: PropTypes.array.isRequired,
};

export default withStyles(badgeDialogStyles, { withTheme: true })(InviteBadgeDialog);
