import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Paper, TextField, Typography, Icon, Button, IconButton,
  Dialog, DialogContent, DialogActions, Switch,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import DisplayResources from '../crud/DisplayResources';
import {
  HQ_ADMIN,
  PRACTICE_ADMIN,
  REGIONAL_ADMIN,
  ADMIN_NO_PRACTICE,
  ADMIN_ROLE,
  BASE_URLS,
  PRACTICE_ROLE,
  REGIONAL_ROLE,
  REGIONAL_ADMIN_ROLE,
  PRACTICE_ADMIN_ROLE,
  resourceFields,
  USER_ROLES,
  USER_STATUSES,
  userPaginationCount,
  VET_TYPES,
  VET_TYPES_TITLES,
  IMG_STORAGE_LINK,
} from '../../data/Constants';
import { commonResourceStyle, switchStyles } from '../../css/style';
import LoadingBar from '../subcomponents/LoadingBar';
import types from '../../actions/ActionTypes';
import { utils } from '../../utils/Functions';
import SearchableSelect from '../subcomponents/SearchableSelect';
import SingleSelect from '../subcomponents/SingleSelect';
import PracticeAutocomplete from '../subcomponents/PracticeAutocomplete';

const peopleLogo = `${IMG_STORAGE_LINK}ic-people.png`;
const privateLogo = `${IMG_STORAGE_LINK}private-users.png`;

const styles = commonResourceStyle();

class Users extends Component {
  constructor(props) {
    super(props);
    let params = {};
    if (location.search) {
      params = utils.queryParamsToObject(location.search);
    }
    this.state = {
      userToDelete: null,
      selectedPractices: [],
      selectedHomes: [],
      selectedRoles: [],
      selectedStatus: 'Active',
      selectedVetTypes: params.talentTypes
        ? VET_TYPES.filter(type => params.talentTypes.split(',').includes(type.key))
        : [],
      searchName: params.name || '',
      search: location.search,
      fetchedUsers: null,
      fetchedPrivateUsers: null,
      filteredPractices: [],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      actions,
      apiToken,
      getPrivate,
      adminPractices,
    } = this.props;
    if (prevProps.getPrivate !== this.props.getPrivate) {
      if (location.search) {
        let queryParams = `${getPrivate ? 'privatetalents' : 'users'}${location.search}`;
        this.setQueryParams(queryParams);
        actions.getAllResources(apiToken, queryParams).then((resp) => {
          if (!resp.errors && resp.response) {
            this.setState({
              [(getPrivate ? 'fetchedPrivateUsers' : 'fetchedUsers')]: resp.response.list,
            });
          }
        });
        sessionStorage.setItem('usersQueryOffset', queryParams);
      } else {
        this.setState({
          selectedPractices: [],
          selectedHomes: [],
          selectedRoles: [],
          selectedVetTypes: [],
          searchName: '',
          selectedStatus: 'Active',
        }, () => this.applyFilters());
      }
    }
  }

  componentDidMount = async () => {
    const {
      actions,
      apiToken,
      getPrivate,
      adminPractices,
    } = this.props;
    let filtersObj = {};
    let queryParams = this.getQueryParams();

    if (location.search) {
      filtersObj = utils.queryParamsToObject(location.search);
      queryParams = `${getPrivate ? 'privatetalents' : 'users'}${location.search}`;
    }

    const resp = await actions.getAllResources(apiToken, queryParams);
    if (!resp.errors) {
      this.setState({
        [(getPrivate ? 'fetchedPrivateUsers' : 'fetchedUsers')]: resp.response.list,
      });
    }

    let practiceList = adminPractices;

    if (!practiceList) {
      const practicesResp = await actions.getAllResources(apiToken, BASE_URLS.getPractices);
      practiceList = await practicesResp && practicesResp.response;
    }
    this.setState({ filteredPractices: practiceList });

    if (filtersObj.practices) {
      utils.setPrevFilter(filtersObj.practices, practiceList, 'id', (selectedItems) => {
        this.setState({ ['selectedPractices']: selectedItems });
      });
    } else if (filtersObj.homes) {
      utils.setPrevFilter(filtersObj.homes, practiceList, 'id', (selectedItems) => {
        this.setState({ ['selectedHomes']: selectedItems });
      });
    }

    if (filtersObj.roles) {
      utils.setPrevFilter(filtersObj.roles, USER_ROLES, 'key', (selectedItems) => {
        this.setState({ ['selectedRoles']: selectedItems });
      });
    }

    if (filtersObj.status) {
      utils.setPrevFilter(filtersObj.status, USER_STATUSES, 'key', (selectedItems) => {
        this.setState({ ['selectedStatus']: (selectedItems.length > 0 && selectedItems[0]) ? selectedItems[0].key : '' });
      });
    }
    sessionStorage.setItem('usersQueryOffset', queryParams);
    const userFilter = document.getElementById(getPrivate ? 'staffFilter' : 'adminFilter');
    userFilter && userFilter.addEventListener('keydown', e => this.handleKeyDown(e));
  };

  componentWillUnmount() {
    const { getPrivate } = this.props;
    const userFilter = document.getElementById(getPrivate ? 'staffFilter' : 'adminFilter');
    userFilter && userFilter.removeEventListener('keydown', e => this.handleKeyDown(e));
  }

  getQueryParams = () => {
    const { getPrivate } = this.props;
    return `${getPrivate ? 'privatetalents' : 'users'}?start=0&count=${userPaginationCount}${this.getFilterParams()}`;
  };

  getFilterParams = (e) => {
    const {
      selectedPractices,
      selectedHomes,
      selectedRoles,
      selectedVetTypes,
      searchName,
      selectedStatus,
    } = this.state;
    return `&practices=${selectedPractices.map(item => item.id).join(',') || ''
    }&homes=${selectedHomes.map(item => item.id).join(',') || ''
    }&roles=${selectedRoles.map(item => item.key).join(',') || ''
    }&talentTypes=${selectedVetTypes.map(item => item.key).join(',') || ''
    }&name=${searchName || ''
    }&status=${selectedStatus || ''
    }`;
  };

  deleteUser = (user) => {
    const {
      actions, loading, apiToken, getPrivate, pagination,
    } = this.props;
    actions.deleteResource(apiToken, getPrivate ? 'privatetalents' : 'users', user.id).then((resp) => {
      let currentPage = pagination.start / pagination.count;
      if (pagination.totalSize === pagination.start + 1 && currentPage > 0) {
        currentPage = currentPage - 1;
      }
      this.onClickViewMore(currentPage + 1);
    });
    this.setState({ userToDelete: null });
  };

  editUser = (user) => {
    const {
      actions, loading, apiToken, history, getPrivate,
    } = this.props;
    history.push(`/users${getPrivate ? '/private' : ''}/${user.id}/edit`);
  };

  setQueryParams(queryParams) {
    const { getPrivate } = this.props;
    const query = queryParams.replace(getPrivate ? 'privatetalents' : 'users', '');
    sessionStorage.setItem('usersQueryOffset', `users${query}`);
    history.pushState(null, '', query);
  }

  applyFilters = () => {
    const {
      actions,
      apiToken,
      loading,
      getPrivate,
    } = this.props;
    if (!loading) {
      const queryParams = this.getQueryParams();
      this.setQueryParams(queryParams);
      if (location.search) this.setState({ ['search']: location.search });
      actions.updateLoadingState(types.VIEW_MORE_RESOURCE_REQUESTED);
      actions.getAllResources(apiToken, queryParams).then((resp) => {
        if (!resp.errors) {
          this.setState({
            [(getPrivate ? 'fetchedPrivateUsers' : 'fetchedUsers')]: resp.response.list,
          });
        }
      });
      actions.updateLoadingState(types.VIEW_MORE_RESOURCE_SUCCESS);
    }
  };

  clearFilters = () => {
    const {
      selectedPractices, selectedRoles, selectedStatus, selectedVetTypes, searchName, selectedHomes,
    } = this.state;
    if (selectedPractices.length || selectedRoles.length || selectedStatus || selectedVetTypes.length || searchName || selectedHomes) {
      this.setState({
        selectedPractices: [],
        selectedHomes: [],
        selectedRoles: [],
        selectedStatus: '',
        selectedVetTypes: [],
        searchName: '',
      }, () => this.applyFilters());
    }
  };

  onClickViewMore = async (pageNo) => {
    const {
      actions,
      apiToken,
      pagination,
      loading,
      getPrivate,
    } = this.props;
    if (!loading) {
      let queryParams;
      if (!pageNo) {
        queryParams = `${getPrivate ? 'privatetalents' : 'users'}?start=${pagination.start + pagination.count}&count=${userPaginationCount}${this.getFilterParams()}`;
      } else {
        queryParams = `${getPrivate ? 'privatetalents' : 'users'}?start=${(pageNo - 1) * pagination.count}&count=${userPaginationCount}${this.getFilterParams()}`;
      }
      actions.updateLoadingState(types.VIEW_MORE_RESOURCE_REQUESTED);
      actions.getAllResources(apiToken, queryParams).then((resp) => {
        if (!resp.errors) {
          this.setState({
            [(getPrivate ? 'fetchedPrivateUsers' : 'fetchedUsers')]: resp.response.list,
          });
        }
      });
      actions.updateLoadingState(types.VIEW_MORE_RESOURCE_SUCCESS);
      sessionStorage.setItem('usersQueryOffset', queryParams);
    }
  };

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.applyFilters();
    }
  };

  getFilter = (items, type, title, equalID) => {
    const value = this.state[type];
    if (['selectedHomes', 'selectedPractices'].includes(type)) { // Practice filter
      return (
        <PracticeAutocomplete
          handleStateChange={(val) => {
            if (val) {
              return this.setState({ [type]: val });
            }
          }}
          title={title}
          items={items}
          value={value}
        />
      );
    }
    return (
      <SearchableSelect
        value={value}
        title={title}
        items={items}
        onChange={value => this.setState({ [type]: value })}
        equalID={equalID}
        filter
      />
    );
  };

  getFilterSingle = (items, type, title, equalID) => {
    const value = this.state[type];
    return (
      <SingleSelect
        value={value}
        title={title}
        items={items}
        onChange={value => this.setState({ [type]: value })}
        equalID={equalID}
        filter
      />
    );
  };

  getSearch = (type, title) => {
    const value = this.state[type];
    return (
      <div style={styles.nameSearch}>
        <TextField
          variant='outlined'
          placeholder={title}
          fullWidth
          value={value}
          inputProps={{ sx: styles.filterInput }}
          onChange={e => this.setState({ [type]: e.target.value })}
        />
      </div>
    );
  };

  getFilters = () => {
    const {
      adminPractices,
      getPrivate,
      classes,
      theme,
    } = this.props;
    const {
      selectedPractices,
      selectedRoles,
      selectedStatus,
      searchName,
      selectedVetTypes,
      selectedHomes,
      filteredPractices,
    } = this.state;

    const stylesWithTheme = commonResourceStyle(theme);

    let filtersApplied = getPrivate
      ? searchName.length || selectedVetTypes.length || selectedHomes.length
      : searchName.length || selectedPractices.length || selectedRoles.length || selectedStatus;

    return (
      <div className={classes.filterFrontContainer}>
        <Paper sx={stylesWithTheme.paperFilters} id={getPrivate ? 'staffFilter' : 'adminFilter'}>
          {!getPrivate && this.getSearch('searchName', 'Search by name')}
          {!getPrivate && adminPractices && this.getFilter(adminPractices, 'selectedPractices', 'All Practices')}
          {!getPrivate && this.getFilter(USER_ROLES, 'selectedRoles', 'All Roles')}
          {!getPrivate && this.getFilterSingle(USER_STATUSES, 'selectedStatus', 'All Statuses')}
          {getPrivate && this.getSearch('searchName', 'Search by name')}
          {getPrivate && this.getFilter(VET_TYPES, 'selectedVetTypes', 'All Job Categories')}
          {getPrivate && filteredPractices && this.getFilter(filteredPractices, 'selectedHomes', 'All Practices')}
          {getPrivate && this.getFilterSingle(USER_STATUSES, 'selectedStatus', 'All Statuses')}
          <Button
            variant="contained"
            color="secondary"
            style={{
              marginLeft: '20px',
              maxHeight: '46px',
              boxShadow: 'none',
            }}
            onClick={e => this.applyFilters()}
            sx={stylesWithTheme.filterButton}
          >
            APPLY Filter
          </Button>
        </Paper>
        {
          filtersApplied
            ? (
              <Button
                sx={stylesWithTheme.clearFilterText}
                onClick={() => this.clearFilters()}
                disableRipple
                variant='text'
              >
                clear filter
              </Button>
            )
            : <div />
        }
      </div>
    );
  };

  getUserAccess = (user, type) => {
    const access = user && user.accessList ? user.accessList.filter(item => item.type === type) : [];
    return access.length ? access : [];
  };

  getCategoryColumn = (item) => {
    const { talent } = item;
    const talentType = talent && talent.type;
    return talentType ? VET_TYPES_TITLES[talentType] : '';
  };

  getMobileColumn = (item) => {
    const { talentPII } = item;
    let mobile = talentPII && talentPII.mobile;
    if (mobile) {
      mobile = mobile.replace(/\D/g, '');
      let areaCode = mobile.slice(0, 3);
      let body = mobile.slice(3, 6);
      let tail = mobile.slice(6, 10);
      return `(${areaCode}) ${body}-${tail}`;
    }
    return '';
  };

  getPracticeColumn = (item) => {
    const role = this.getRoleColumn(item);
    if (role === HQ_ADMIN) {
      return 'HQ';
    } else if (role === REGIONAL_ADMIN_ROLE) {
      return 'HQ/Regional';
    } else if (role === REGIONAL_ADMIN) {
      const userAccess = this.getUserAccess(item, 'practice');
      return `${userAccess.length} Practices`;
    } else {
      const userAccess = this.getUserAccess(item, 'practice');
      return userAccess.length ? userAccess[0].name : '';
    }
  };

  getTalentHomePracticeColumn = (item) => {
    const { adminPractices } = this.props;
    if (!adminPractices || !item || !item.talent) {
      return ' - ';
    }
    const { practice_id } = item.talent;
    const practice = adminPractices.filter(x => x.id == practice_id);
    return practice.length ? practice[0].name : ' - ';
  };

  getLocationColumn = (item) => {
    const role = this.getRoleColumn(item);
    if ([HQ_ADMIN, REGIONAL_ADMIN, REGIONAL_ADMIN_ROLE].includes(role)) {
      return 'Regional';
    } else {
      const userAccess = this.getUserAccess(item, 'practice');
      return userAccess.length ? userAccess[0].location : '';
    }
  };

  getRoleColumn = (item) => {
    const { getPrivate } = this.props;
    if (getPrivate) {
      return 'Private User';
    }
    const userAccessAggregator = this.getUserAccess(item, 'aggregator');
    const userAccessPractice = this.getUserAccess(item, 'practice');

    if (userAccessAggregator.length) {
      if (!userAccessPractice.length) return HQ_ADMIN;
      if (userAccessPractice.length === 1) return PRACTICE_ADMIN_ROLE;
      if (userAccessPractice.length > 1) return REGIONAL_ADMIN_ROLE;
    }

    if (userAccessPractice.length > 1) return REGIONAL_ADMIN;
    if (userAccessPractice.length === 1) return PRACTICE_ADMIN;
    return ADMIN_NO_PRACTICE;
  };

  getTalentTypeIcon = (data) => {
    let image = null;

    if (data && data.talent && data.talent.type) {
      image = `${IMG_STORAGE_LINK}talent-types/${data.talent.type}.png`;
    }
    return (
      <div style={styles.fullNameColumn}>
        {image && <img style={styles.fullNameColumnImage} src={image} alt="Selectable Icon" />}
      </div>
    );
  };

  getActive = (data) => {
    const {
      classes, actions, apiToken, getPrivate, admin, theme,
    } = this.props;
    const { fetchedUsers, fetchedPrivateUsers } = this.state;
    const list = getPrivate ? fetchedPrivateUsers : fetchedUsers;
    const isHQAdmin = admin && admin.adminRole === 'HQ_ADMIN';
    const { switchRoot } = switchStyles(theme);

    return (
      <Switch
        checked={data.enabled}
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        sx={switchRoot}
        onClick={() => {
          actions.updateResource(apiToken, { enabled: !data.enabled, isPrivateTalent: getPrivate }, 'users', data.id);
          const p = list.filter(item => item.id === data.id)[0];
          p.enabled = !data.enabled;
          if (getPrivate) this.setState({ fetchedPrivateUsers: list });
          else this.setState({ fetchedUsers: list });
        }}
        disabled={!getPrivate && (!isHQAdmin && admin.id !== data.id)}
      />
    );
  };

  getAdminActionsColumn = (item) => {
    const { admin } = this.props;
    const isHQAdmin = admin && admin.adminRole === 'HQ_ADMIN';
    const showDelete = admin.id === item.id || !isHQAdmin ? false : true;

    return (
      <div>
        {showDelete && (
          <IconButton onClick={(e) => {
            e.stopPropagation();
            this.setState({ userToDelete: item });
          }}
          >
            <Icon>delete</Icon>
          </IconButton>
        )}
        {!showDelete && <div style={{ width: 48, display: 'inline-flex' }} />}

        <IconButton onClick={(e) => {
          e.stopPropagation();
          this.editUser(item);
        }}
        >
          <Icon>keyboard_arrow_right</Icon>
        </IconButton>
      </div>
    );
  };

  getStaffActionsColumn = (item) => {
    const { admin, theme } = this.props;
    const stylesWithTheme = commonResourceStyle(theme);

    var showDelete = true;
    if (admin.id === item.id) {
      showDelete = false;
    }
    if ((admin.privateAccess === PRACTICE_ADMIN || admin.privateAccess === REGIONAL_ADMIN) && this.getRoleColumn(item) === HQ_ADMIN) {
      showDelete = false;
    }

    return (
      <div>
        {showDelete && (
          <IconButton onClick={(e) => {
            e.stopPropagation();
            this.setState({ userToDelete: item });
          }}
          >
            <Icon>delete</Icon>
          </IconButton>
        )}
        {!showDelete && <div style={{ width: 48, display: 'inline-flex' }} />}
        <IconButton style={stylesWithTheme.iconButton}>
          <Icon style={stylesWithTheme.icon}>keyboard_arrow_right</Icon>
        </IconButton>
      </div>
    );
  };

  render() {
    const {
      classes, users, actions, loading, apiToken, viewMoreLoading, pagination, admin, history, getPrivate, theme,
    } = this.props;
    const { userToDelete, fetchedUsers, fetchedPrivateUsers } = this.state;
    const customRender = {
      practice: this.getPracticeColumn,
      location: this.getLocationColumn,
      talentTypeIcon: this.getTalentTypeIcon,
      homePractice: this.getTalentHomePracticeColumn,
      role: this.getRoleColumn,
      actions: getPrivate ? this.getStaffActionsColumn : this.getAdminActionsColumn,
      category: this.getCategoryColumn,
      mobile: this.getMobileColumn,
      active: this.getActive,
    };
    const mutableUsers = getPrivate ? fetchedPrivateUsers : fetchedUsers;
    const isHQAdmin = admin && admin.adminRole === 'HQ_ADMIN';
    const stylesWithTheme = commonResourceStyle(theme);

    return (
      (loading === true && viewMoreLoading === 0)
        ? <LoadingBar />
        : (
          <div style={stylesWithTheme.container} onKeyDown={this.handleKeyDown}>
            <img style={stylesWithTheme.resourceImage} src={`${IMG_STORAGE_LINK}search-dog-s.png`} />
            <div className={classes.resourceHeader}>
              <div style={stylesWithTheme.resourceNameLarge}>
                <Typography style={stylesWithTheme.resourceNameLarge}>
                  <img style={stylesWithTheme.resourceLogoSub} src={getPrivate ? privateLogo : peopleLogo} />
                  {getPrivate ? 'STAFF' : 'ADMIN'}
                </Typography>
                {isHQAdmin
                  && (
                    <Button
                      sx={stylesWithTheme.headerButton}
                      onClick={e => history.push(getPrivate ? '/users/private/create' : '/users/create')}
                    >
                      <AddCircleOutlineIcon style={stylesWithTheme.buttonIcon} />
                      <span
                        style={{ verticalAlign: 'super' }}
                      >
                        {getPrivate ? 'ADD NEW STAFF' : 'ADD NEW USER'}
                      </span>
                    </Button>
                  )
                }
              </div>
            </div>
            {this.getFilters()}
            <Paper style={stylesWithTheme.paper}>
              <DisplayResources
                data={mutableUsers}
                actions={actions}
                fieldNames={getPrivate ? resourceFields.privateUsers : resourceFields.users}
                customRender={customRender}
                resourceName={getPrivate ? 'privateusers' : 'users'}
                apiToken={apiToken}
                customAction
                onClickViewMore={this.onClickViewMore}
                totalResource={pagination && pagination.totalSize}
                viewMoreLoading={viewMoreLoading}
                viewPrivate={getPrivate}
                pagination={pagination}
                hideCreate
              />
            </Paper>
            <Dialog
              open={!!(userToDelete) || false}
              onClose={e => this.setState({ userToDelete: null })}
            >

              <DialogContent style={stylesWithTheme.userDialog}>
                <div style={stylesWithTheme.dialogClose}>
                  <IconButton onClick={e => this.setState({ userToDelete: null })}>
                    <Icon>close</Icon>
                  </IconButton>
                </div>
                <div style={stylesWithTheme.deleteIcon}>
                  <Icon style={{ fontSize: '30px' }}>delete</Icon>
                </div>
                <Typography style={stylesWithTheme.userDialogTitle} gutterBottom>
                  DELETE USER
                </Typography>
                <Typography gutterBottom style={{ marginBottom: '30px' }}>
                  Are you sure you want to delete the user below ?
                </Typography>
                <Typography gutterBottom style={stylesWithTheme.boldWidth}>
                  {userToDelete && `${userToDelete.name} - ${this.getRoleColumn(userToDelete.role)}`}
                </Typography>
                <Typography gutterBottom style={stylesWithTheme.boldWidth}>
                  {userToDelete && this.getPracticeColumn(userToDelete)}
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={e => this.deleteUser(userToDelete)}
                  fullWidth
                  variant="contained"
                  sx={stylesWithTheme.deleteUserBtn}
                  disableRipple
                >
                  Yes, Delete User
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        )
    );
  }
}

Users.propTypes = {
  loading: PropTypes.bool.isRequired,
  adminPractices: PropTypes.arrayOf(PropTypes.object),
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  apiToken: PropTypes.string.isRequired,
  getPrivate: PropTypes.bool,
};

Users.defaultProps = {
  adminPractices: null,
  getPrivate: false,
};

export default withStyles(commonResourceStyle, { withTheme: true })(withRouter(Users));
