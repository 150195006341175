import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Select, Button, TextField, OutlinedInput, MenuItem, ListItemText, Checkbox,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { hoverEffectTwo, navy, autocompleteOptionStyle } from '../../css/style';
import ExpandMore from '@mui/icons-material/ExpandMore';

const searchComponentStyles = theme => ({
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: '100%',
  },
  notchedOutline: {
    borderColor: 'solid 1px black !important',
  },
  filterInput: {
    '&::placeholder': {
      color: 'black !important',
      opacity: '100%',
    },
  },
  textFieldStyle: {
    marginLeft: '1%',
    width: '75%',
    display: 'flex',
    justifyContent: 'center',
  },
  inputStyle: {
    fontSize: '16px',
    textAlign: 'left',
  },
  select: {
    width: '12%',
    cursor: 'pointer',
  },
  selectedText: {
    fontWeight: 400,
  },
  searchButton: {
    color: '#ffffff',
    backgroundColor: `${navy}`,
    borderRadius: '5px',
    width: '8%',
    height: '45px',
    marginRight: '15px',
    fontSize: '12px',
    fontWeight: 'bold',
    ...hoverEffectTwo,
  },
  root: {
    '&:disabled': {
      backgroundColor: '#f0f0f0',
    },
  },
  clearLinkContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  clearSearchLink: {
    fontSize: '12px',
    fontStyle: 'italic',
    marginLeft: '6px',
    color: '#f16122',
    cursor: 'pointer',
  },
  expandMoreIcon: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: 'black',
    marginRight: '14px',
    marginTop: '3px',
  },
});

const styles = searchComponentStyles();

const initialState = {
  searchValue: '',
  selectValue: '',
};

class SearchComponent extends Component {
  onClickClearSearch = () => {
    const { onHandleClearSearch } = this.props;
    onHandleClearSearch();
    this.setState(initialState);
  };

  checkEnterPressed = (e) => {
    if (e.keyCode === 13) {
      this.props.onHandleSearch();
    }
  };

  render() {
    const {
      classes, selectValues, handleChange, onHandleSearch, selected, search,
      filter, customized, searchFieldText, applyButtonText, customWidth,
    } = this.props;
    return (
      <div className={classes.searchContainer}>
        <TextField
          autoFocus
          sx={styles.textFieldStyle}
          style={customWidth && { width: customWidth.textSearch }}
          placeholder={searchFieldText || 'Search by Practice Name or Location'}
          variant="outlined"
          inputProps={{ style: styles.inputStyle, sx: styles.filterInput }}
          type="term"
          value={search}
          onChange={e => handleChange(e, 'searchValue')}
          onKeyDown={e => this.checkEnterPressed(e)}
          InputProps={{
            classes: {
              notchedOutline: classes.notchedOutline,
            },
          }}
        />
        <Select
          value={selected}
          sx={styles.select}
          style={customWidth && { width: customWidth.singleSelect }}
          onChange={e => handleChange(e, 'selectValue')}
          input={(<OutlinedInput labelwidth={0} />)}
          renderValue={
            value => (customized
              ? selectValues.find(item => item.key == value)
              : value.charAt(0).toUpperCase() + value.slice(1))
          }
          IconComponent={ExpandMore}
          inputProps={{
            classes: {
              icon: classes.expandMoreIcon,
            },
          }}
          MenuProps={{
            getcontentanchorel: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          }}
        >
          {
            selectValues.map((value, index) => (
              <MenuItem key={value.key} value={value.key} sx={autocompleteOptionStyle}>
                <Fragment>
                  <Checkbox checked={filter && value.key === selected} />
                  <ListItemText
                    primary={customized ? value : value.title}
                    classes={
                      filter && value.key === selected
                        ? { primary: classes.selectedText }
                        : {}
                    }
                  />
                </Fragment>
              </MenuItem>
            ))
          }
        </Select>
        <Button
          sx={styles.searchButton}
          classes={{ disabled: classes.root }}
          onClick={() => onHandleSearch()}
        >
          {applyButtonText || 'SEARCH'}
        </Button>
      </div>
    );
  }
}

SearchComponent.propTypes = {
  handleChange: PropTypes.func.isRequired,
  selectValues: PropTypes.array.isRequired,
  onHandleSearch: PropTypes.func,
  onHandleClearSearch: PropTypes.func,
  selected: PropTypes.string,
  search: PropTypes.string,
  filter: PropTypes.bool,
};

SearchComponent.defaultProps = {
  onHandleSearch: null,
  onHandleClearSearch: null,
  selected: 'all',
  search: '',
  filter: false,
};

export default withStyles(searchComponentStyles)(withRouter(SearchComponent));
