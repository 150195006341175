import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button, Typography, TextField, Icon, Hidden,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { loginPageStyles } from '../css/loginPageStyles';
import { handleTrailingSpaces } from '../utils/Functions';
import { IMG_STORAGE_LINK } from '../data/Constants';

const Logo = `${IMG_STORAGE_LINK}Jobs_Logo.svg`;

class UnderConstruction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      companyName: null,
      name: null,
      mobile: null,
      error: '',
    };
  }

  onChangeInput = (field, event) => {
    const newState = event.target.value;
    this.setState({ [field]: newState });
  };

  onClickSendEmail = async () => {
    const { actions, history } = this.props;
    const {
      name, email, mobile, companyName,
    } = this.state;
    if (!(name && email)) {
      return this.setState({ error: 'Please provide name and email.' });
    }

    const adminInfo = {
      email: handleTrailingSpaces(email),
      name: handleTrailingSpaces(name),
      subject: '[Employer Sign Up] Contact Information',
      message: 'Here\'s my contact info. Please notify me when Unleashed is open for 3rd party business.',
      phone: mobile,
      company: companyName ? handleTrailingSpaces(companyName) : '',
    };

    await actions.sendSignUpRequest(adminInfo);
    history.push('/');
  };

  render() {
    const { errors, classes, theme } = this.props;
    const { error } = this.state;
    const stylesWithTheme = loginPageStyles(theme);

    return (
      <div className={classes.container}>
        <Hidden smDown>
          <div className={classes.leftContainerImage} />
        </Hidden>
        <Hidden smDown>
          <div className={classes.leftContainer} />
        </Hidden>
        <div className={classes.rightContainer}>
          <img src={Logo} className={classes.underConstructionLogo} alt=" Logo" />
          <Typography sx={stylesWithTheme.underConstructionTitle}>UNDER CONSTRUCTION</Typography>

          <Typography sx={stylesWithTheme.underConsTextField}>
            {'1) If you got here accidentally, here is where you go to '}
            <a
              href='/'
              style={stylesWithTheme.lookingForJobLink}
            >
              sign in
            </a>
            {'.'}
          </Typography>

          <Typography sx={stylesWithTheme.underConsTextField}>
            {`2) If you are interested in using Unleashed, please leave your contact info so that we can notify you
            when Unleashed is open for 3rd party business.`}
          </Typography>
          <div className={classes.inputSection}>
            <TextField
              placeholder="My Name"
              label="My Name"
              onChange={event => this.onChangeInput('name', event)}
              sx={stylesWithTheme.underConsTextField}
              variant="outlined"
              id="name"
              inputProps={{ autoCapitalize: 'none' }}
              autoComplete="off"
            />
            <TextField
              placeholder="Email Address"
              label="Email Address"
              onChange={event => this.onChangeInput('email', event)}
              sx={stylesWithTheme.underConsTextField}
              variant="outlined"
              id="email"
              inputProps={{ autoCapitalize: 'none' }}
              autoComplete="off"
            />
            <TextField
              placeholder="Company Name"
              label="Company Name"
              onChange={event => this.onChangeInput('companyName', event)}
              sx={stylesWithTheme.underConsTextField}
              variant="outlined"
              id="companyName"
              inputProps={{ autoCapitalize: 'none' }}
              autoComplete="off"
            />
            <TextField
              placeholder="Phone Number"
              label="Phone Number"
              onChange={event => this.onChangeInput('mobile', event)}
              sx={stylesWithTheme.underConsTextField}
              variant="outlined"
              name="phone"
              inputProps={{ autoCapitalize: 'none' }}
              autoComplete="off"
            />
          </div>
          <div className={classes.sendEmailBtnContainer}>
            <Button
              sx={stylesWithTheme.sendEmailBtn}
              variant="contained"
              color="primary"
              onClick={() => this.onClickSendEmail()}
            >
              SEND
              <Icon sx={stylesWithTheme.signUpRightArrow}>email</Icon>
            </Button>
          </div>
          {
            (errors || error)
            && (
              <div className={classes.signUpErrors}>
                {errors || error}
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

UnderConstruction.propTypes = {
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.any,
};

UnderConstruction.defaultProps = {
  errors: null,
};

export default withStyles(loginPageStyles, { withTheme: true })(withRouter(UnderConstruction));
