import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Typography,
  ButtonBase,
  Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { orange, green, gray } from '../../css/style';

const useCircleStyles = makeStyles(theme => ({
  root: props => ({
    [theme && theme?.breakpoints?.up('sm')]: {
      width: props.enlarged ? '72px' : '36px',
      height: props.enlarged ? '72px' : '36px',
    },
    [theme && theme?.breakpoints?.down('sm')]: {
      width: props.enlarged ? '72px!important' : '35px !important',
      height: props.enlarged ? '72px' : '35px',
      fontSize: props.enlarged ? '18px' : '14px',
    },
    [theme && theme?.breakpoints?.down('xs')]: {
      width: props.enlarged ? '72px!important' : '25px !important',
      height: props.enlarged ? '72px' : '25px',
      fontSize: props.enlarged ? '18px' : '14px',
    },
  }),
}));

const Circle = ({
  label,
  disabled,
  checked,
  onCheck,
  className,
  isToday,
  isNotAvailable,
  isPending,
  hasMultipleNeeds,
  enlarged,
  hasMultipleTakensOnly,
  tooltipTitle,
  isUntaken = false,
  style = {},
  coronadoErDayColor,
}) => {
  const styleProps = { enlarged, isUntaken };
  const classes = useCircleStyles(styleProps);

  const handleClick = useCallback(
    () => {
      if (!disabled) {
        onCheck(!checked);
      }
    },
    [onCheck, disabled, checked],
  );

  const renderCircle = () => (
    <ButtonBase
      className={classNames(classes.root, className)}
      sx={{
        borderRadius: '50%',
        background: checked ? coronadoErDayColor || green : isPending ? gray
          : isNotAvailable || hasMultipleNeeds || hasMultipleTakensOnly ? 'white' : isToday ? '#f0f0f0' : 'rgba(0, 0, 0, 0)',
        border: isPending ? 'none' : 1,
        borderWidth: enlarged ? '2px' : '1px',
        borderColor: isNotAvailable || hasMultipleTakensOnly ? orange : checked || hasMultipleNeeds ? coronadoErDayColor || green : isUntaken ? gray : 'rgba(0, 0, 0, 0)',
      }}
      style={style}
      onClick={handleClick}
    >
      <Typography
        color='inherit'
        variant='body1'
        sx={{
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: enlarged ? '18px' : 'inherit',
          color: disabled ? '#c7c7c7' : checked || isPending
            ? 'white' : isNotAvailable || hasMultipleTakensOnly ? orange
              : hasMultipleNeeds ? coronadoErDayColor || green : 'unset',
        }}
      >
        {label}
      </Typography>
    </ButtonBase>
  );

  return tooltipTitle ? (
    <Tooltip title={tooltipTitle}>
      { renderCircle() }
    </Tooltip>
  ) : renderCircle();
};

Circle.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  onCheck: PropTypes.func.isRequired,
  className: PropTypes.string,
  isPending: PropTypes.bool,
  style: PropTypes.object,
};

Circle.defaultProps = {
  onCheck: () => {},
  checked: false,
};

export default Circle;
