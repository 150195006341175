import { tiffany, orange, gray } from './style';

export const commonCardStyles = theme => ({
  card: {
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.3)',
    position: 'relative',
  },
  badgeFilter: {
    color: '#fff',
    marginRight: '5px',
    marginTop: '5px',
    height: '25px',
    padding: '0px 8px',
  },
  itemMatchButton: {
    padding: 0,
    height: '16px',
    lineHeight: '16px',
    fontSize: '0.6rem',
    width: '60px',
    textTransform: 'uppercase',
    borderRadius: '3px',
    textAlign: 'center',
    color: '#fff',
  },
  statisticsItem: {
    '& span': { marginRight: '5px' },
    display: 'flex',
    alignItems: 'center',
  },
  clickableStatisticsItem: {
    '& span': { marginRight: '5px' },
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  statistics: {
    color: `${tiffany}`,
    fontFamily: 'Asap',
    fontSize: '14px',
  },
  divider: {
    width: '1px',
    height: '16px',
    backgroundColor: '#cacaca',
  },
  talentTypeIconContainer: {
    textAlign: 'right',
  },
  talentTypeIcon: {
    maxWidth: '45px',
    maxHeight: '45px',
    marginRight: '10px',
  },
  jobTitle: {
    fontFamily: 'Asap',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '1rem',
    height: '60px',
    maxWidth: '300px',
    lineHeight: '30px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflowWrap: 'break-word',
  },
  archived: {
    color: `${orange}`,
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    right: 10,
    bottom: 5,
    fontFamily: 'Asap',
    fontSize: 14,
  },
  newIconContainer: {
    position: 'absolute',
    right: 0,
    top: '5px',
    cursor: 'pointer',
  },
  newIcon: {
    color: '#ababab',
  },
  matchItemInfo: {
    backgroundColor: '#f9f9f9',
    padding: '5px',
    marginTop: '5px',
    borderRadius: '5px',
  },
  matchIteminfoItem: {
    display: 'flex',
    height: 40,
    alignItems: 'center',
  },
  matchIteminfoIcon: {
    color: `${gray}`,
    marginRight: '5px',
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  connectedStyle: {
    minWidth: 140,
    margin: 'auto',
    fontSize: '12px',
  },
  button: {
    width: '50%',
  },
  overflowTextEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflowWrap: 'break-word',
  },
  boostedBtn: {
    backgroundColor: `${orange}`,
    color: '#fff',
    height: '25px',
    fontSize: 12,
    padding: '0px 8px',
    '&:hover': {
      backgroundColor: `${orange}`,
    },
  },
  boostedBtnContainer: {
    position: 'absolute',
    bottom: '-10px',
    right: '40px',
    zIndex: 10,
    display: 'flex',
  },
  boostedBtnWhiteBorder: {
    width: '10px',
    backgroundColor: 'white',
    marginBottom: '10px',
  },
});
