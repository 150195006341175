import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';

import {
  FormLabel, TextField, Paper, Chip, InputAdornment, Badge,
  OutlinedInput, MenuItem, Checkbox, ListItemText, FormControl,
  Popover, Typography,
} from '@mui/material';

import ExpandMore from '@mui/icons-material/ExpandMore';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { withStyles } from '@mui/styles';

import { commonResourceStyle, autocompleteOptionStyle } from '../../css/style';

const styles = commonResourceStyle();

class SearchableSelect extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();

    this.state = {
      searchText: '',
      open: false,
      help: null,
      helpMsg: null,
    };
  }

  convertTitleCase = (string) => {
    var splitString = string.toLowerCase().split(' ');
    for (var i = 0; i < splitString.length; i++) {
      splitString[i] = splitString[i].charAt(0).toUpperCase() + splitString[i].substring(1);
    }
    return splitString.join(' ');
  };

  customizeLabel = (item) => {
    const { customLabelConfig, type } = this.props;
    let itemTitle = item.title || item.name;
    if (customLabelConfig && customLabelConfig[type]) {
      let typeConfiguration = customLabelConfig[type];
      let keyVal = item.key || '';
      let titleVal = itemTitle || '';
      if (typeConfiguration.useLabel && item.label) {
        keyVal = item.label;
      }
      if (typeConfiguration.capitalizeTitle) {
        titleVal = this.convertTitleCase(titleVal);
      }
      if (typeConfiguration.capitalizeKey && typeConfiguration.append) {
        itemTitle = `${keyVal.toUpperCase()} - ${titleVal}`;
      } else if (typeConfiguration.append) {
        itemTitle = `${keyVal} - ${titleVal}`;
      }
    }
    return itemTitle;
  };

  handleSelect = (item) => {
    const { value, onChange, equalID } = this.props;
    let newValues = value.slice(0);
    let itemIndex = equalID ? value.findIndex(it => it.id === item.id) : value.indexOf(item);
    if (itemIndex === -1) {
      newValues.push(item);
    } else {
      newValues.splice(itemIndex, 1);
    }
    this.textInput.current && this.textInput.current.select();
    onChange(newValues);
  };

  renderChips = () => {
    const { open } = this.state;
    const { value, classes, labelCount } = this.props;
    let prunedValue = value;
    if (!open && value.length > labelCount) {
      prunedValue = value.slice(0, labelCount);
    }
    return (
      prunedValue.map(item => (
        <Chip
          key={item.key || item.id}
          label={item.title || item.name}
          color="secondary"
          size="small"
          onDelete={() => this.handleSelect(item)}
          sx={{
            ...styles.chipRoot,
            '& .MuiChip-label': styles.chipRootLabel,
          }}
        />
      ))
    );
  };

  renderInputAdornment = () => {
    const {
      classes, value, labelCount, hideSelections,
    } = this.props;
    const { open } = this.state;
    if (hideSelections) return;
    return (
      <Fragment>
        <ScrollMenu
          alignCenter={false}
          alignOnResize={false}
          scrollBy={1}
          wrapperClassName={`${classes.chipContainer} ${open ? classes.chipContainerOpen : classes.chipContainerClosed}`}
          innerWrapperClass={classes.scrollMenuInner}
        >
        <div style={{ display: 'flex' }}>
          {this.renderChips()}
          {
            (!open && value.length > labelCount)
            && (
              <InputAdornment position="end" className={classes.practiceSelectEndAdornment}>
                <Badge
                  badgeContent={`+${value.length - labelCount}`}
                  color="primary"
                  anchorOrigin={{
                    vertical: 'middle',
                    horizontal: 'right',
                  }}
                />
              </InputAdornment>
            )
          }
        </div>
        </ScrollMenu>
        <InputAdornment position="end"><ExpandMore style={styles.filterExpandIcon} /></InputAdornment>
      </Fragment>
    );
  };

  renderMenu = () => {
    const {
      classes, onChange, value, title, filter, items, equalID, showTitle, disableImportedItem,
    } = this.props;
    const {
      anchorRef, searchText, open, helpMsg,
    } = this.state;
    let filteredItems = items.filter((item) => {
      const itemDisplay = item.title || item.name || '';
      return !searchText || itemDisplay.toLowerCase().includes(searchText.toLowerCase());
    });
    if (filteredItems.length > 40) {
      filteredItems = filteredItems.slice(0, 40);
    }
    return (
      <Popover
        open={open}
        anchorEl={anchorRef}
        onClose={e => this.setState({ open: false })}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Paper>
          {showTitle && (
            <MenuItem value="" onClick={() => onChange([])} sx={autocompleteOptionStyle}>
              <Checkbox checked={value.length === 0} />
              <ListItemText primary={title} />
            </MenuItem>
          )}
          {
            filteredItems.filter(item => item).map((item) => {
              const itemChecked = equalID ? value.filter(it => it.id === item.id).length > 0 : value.indexOf(item) > -1;
              return (
                <MenuItem
                  classes={filter ? { selected: classes.selectedItem } : {}}
                  className={classes.selectableMenuItem}
                  key={item.id || item.key}
                  value={item}
                  onClick={e => this.handleSelect(item)}
                  disabled={disableImportedItem && item.isImported}
                  sx={autocompleteOptionStyle}
                >
                  <Checkbox checked={itemChecked} />
                  <ListItemText
                    primary={this.customizeLabel(item)}
                    classes={{ primary: filter && itemChecked ? classes.selectedText : classes.menuListText }}
                  />
                </MenuItem>
              );
            })
          }
        </Paper>
      </Popover>
    );
  };

  /**
   * customLabelConfig allows users to specify modifications to the dropdowns
   */
  render() {
    const {
      value, items, title, form, theme,
      customLabelConfig, type, label, classes, fullWidth,
      ignoreFormControlStyle, isRequired, helpMessage, hideSelections,
    } = this.props;
    const {
      searchText, open, help, helpMsg,
    } = this.state;
    const stylesWithTheme = commonResourceStyle(theme);
    const displayError = isRequired && (!value || !value.length);
    const style = form ? stylesWithTheme.formControlMulti : stylesWithTheme.filter;

    if (!isEmpty(customLabelConfig) && customLabelConfig[type]) {
      if (customLabelConfig[type].sort) {
        items.sort((a, b) => (a.key < b.key ? -1 : (a.key > b.key) ? 1 : 0));
      }
    }
    return (
      <FormControl fullWidth sx={ignoreFormControlStyle ? {} : style}>
        <div style={stylesWithTheme.formControlLabelContainer}>
          {label && (
            <FormLabel sx={stylesWithTheme.formControlLabel}>
              {label}
              {helpMessage && (
                <HelpOutlineIcon
                  style={stylesWithTheme.helpOutlineIcon}
                  onClick={e => this.setState({
                    help: e.target,
                    helpMsg: helpMessage,
                  })}
                />
              )}
            </FormLabel>
          )}
          {displayError && (
            <FormLabel style={stylesWithTheme.formControlLabelWarnText}>This field is required</FormLabel>
          )}
        </div>
        <TextField
          onClick={(e) => {
            this.setState({
              anchorRef: e.currentTarget,
              open: true,
            });
          }}
          variant='outlined'
          placeholder={(!open && !hideSelections && value && value.length) ? '' : title}
          value={searchText}
          error={displayError}
          style={(open && value.length && !hideSelections) ? styles.inputMarginBottom : styles.inputMarginNone}
          onChange={e => this.setState({ searchText: e.target.value })}
          fullWidth={fullWidth}
          input={<OutlinedInput multiline id="select-multiple-checkbox" labelwidth={0} />}
          inputRef={this.textInput}
          InputProps={{
            endAdornment: this.renderInputAdornment(),
          }}
          inputProps={{
            autoComplete: 'new-password',
            style: form ? {
              width: 'auto',
              minWidth: 'max-content',
            } : null,
            sx: form ? null : styles.searchableInput,
          }}
        />
        {this.renderMenu()}
        <Popover
          open={!!help}
          anchorEl={help}
          onClose={e => this.setState({ help: null })}
        >
          <Paper style={styles.helpPopover}>
            <Typography style={styles.helpPopoverText}>{helpMsg}</Typography>
          </Paper>
        </Popover>
      </FormControl>
    );
  }
}

SearchableSelect.propTypes = {
  value: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  labelCount: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  appendLabel: PropTypes.bool,
  customLabelConfig: PropTypes.object,
  type: PropTypes.string,
  label: PropTypes.string,
  filter: PropTypes.bool,
  fullWidth: PropTypes.bool,
  showTitle: PropTypes.bool,
  disableImportedItem: PropTypes.bool,
};

SearchableSelect.defaultProps = {
  appendLabel: false,
  labelCount: 2,
  customLabelConfig: {},
  type: '',
  label: '',
  filter: false,
  fullWidth: true,
  showTitle: true,
  disableImportedItem: false,
};

export default withStyles(commonResourceStyle, { withTheme: true })(SearchableSelect);
