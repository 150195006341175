import React, { useEffect, Fragment } from 'react';
import {
  TextField,
  Checkbox,
  Button, ListItem,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isEmpty } from 'lodash';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { badgeColor } from '../../utils/Functions';
import { autocompleteOptionStyle } from '../../css/style';

const useStyles = makeStyles(theme => ({
  root: props => ({
    width: '100%',
    fontSize: '14px',
    backgroundColor: '#ffffff',
    margin: props.noMargin ? '0px' : '10px 0',

    [theme && theme.breakpoints.up('md')]: {
      margin: props.noMargin ? '0px' : '20px 0px 0px 0px',
    },

    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: '5px 30px 5px 5px !important',
    },

    '& .MuiChip-deleteIcon': {
      color: 'rgba(255, 255, 255, 0.7)',
    },

    '& .MuiChip-root': {
      color: '#fff',
      backgroundColor: '#243060',
      maxWidth: '100px',
      marginLeft: '5px',
      marginBottom: '1px',
    },

    '& .MuiChip-label': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
    },

    '& .MuiInputAdornment-positionEnd': {
      margin: '3px 12px',
    },

    '& .MuiBadge-badge': {
      height: '24px',
    },
    badgeFilter: {
      color: '#fff',
      marginRight: '5px',
      marginTop: '5px',
      height: '25px',
      padding: '0px 8px',
    },
  }),
}));

const _filterOptions = createFilterOptions({
  limit: 5,
  stringify: option => `${option.name} ${option.badgeTitle}`,
});

export default function UserAutocomplete(props) {
  const {
    title,
    lettersTypedToShowOptions = 2,
    displayError,
    disabled = false,
    noMargin = false,
    noOptionsText = 'No users',
    defaultOption,
    fastpassData = [],
  } = props;
  const styleProps = { noMargin };
  const classes = useStyles(styleProps);
  const [inputValue, setInputValue] = React.useState('');
  const [value, setValue] = React.useState(() => (props.value || []));
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);

  const filterOptions = (option, state) => {
    const results = _filterOptions(option, state);

    if (!isEmpty(defaultOption) && !results.includes(defaultOption)) {
      results.push(defaultOption);
    }

    return results;
  };

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    const { handleStateChange } = props;
    handleStateChange(value || {});
  }, [value]);

  useEffect(() => {
    const { items } = props;
    if (!inputValue || inputValue.length <= lettersTypedToShowOptions) {
      setOptions([]);
      setOpen(false);
      return;
    }
    let filteredValues = items.filter(c => (c.name.toLowerCase().includes(inputValue.toLowerCase()))
      || (c.badgeTitle && c.badgeTitle.toLowerCase().includes(inputValue.toLowerCase())));
    setOptions(filteredValues || []);
    setOpen(isEmpty(value) || (!isEmpty(value) && inputValue.length !== value.name.length));
  }, [inputValue]);

  return (
    <Autocomplete
      id="user-autocomplete"
      classes={classes}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      disabled={disabled}
      getOptionDisabled={(option) => {
        const isSeleted = fastpassData.find(x => x.email === option.email);
        return !!isSeleted;
      }}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={option => (option.name ? option.name : '')}
      filterOptions={filterOptions}
      inputValue={inputValue}
      value={value}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      blurOnSelect
      noOptionsText={noOptionsText}
      options={options}
      popupIcon={null}
      renderInput={params => (
        <TextField
          {...params}
          id="select-multiple-checkbox"
          variant="outlined"
          placeholder={title}
          onFocus={e => e.target.placeholder = ''}
          onBlur={e => e.target.placeholder = title}
          error={displayError}
        />
      )}
      renderOption={(props, option, { selected }) => {
        const isSeleted = fastpassData.find(x => x.email === option.email);
        return (
          <ListItem {...props} key={option.id} sx={autocompleteOptionStyle}>
            <Checkbox checked={!!isSeleted || selected} disabled={isSeleted} key={`${option.id}_checkbox`} />
              {' '}
              {option.key || option.name || ''}
              {option.badgeId
                && (
                  <Button
                    justifyContent='flexEnd'
                    key={`${option.id}_badge`}
                    style={{
                      backgroundColor: badgeColor(option.badgeId),
                      color: '#fff',
                      margin: '0 5px',
                      height: 25,
                      padding: '0px 8px',
                      float: 'right',
                    }}
                  >
                    {option.badgeTitle}
                  </Button>
                )
              }
          </ListItem>
        );
      }}
    />
  );
}
