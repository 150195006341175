import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Typography, Icon, Dialog, DialogContent, IconButton,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import { navy, hoverEffectTwo, dialogCloseButtonStyle } from '../../css/style';

const deleteConfirmationDialogStyles = theme => ({
  dialogContent: {
    padding: '8px 25px',
  },
  closeButton: {
    ...dialogCloseButtonStyle,
    color: theme && theme.palette.grey[500],
  },
  dialogDescription: {
    fontFamily: 'Asap',
    fontSize: '14px',
    marginBottom: '30px',
  },
  dataToBeDeleted: {
    fontFamily: 'Asap',
    fontWeight: 'bold',
    fontSize: '18px',
    padding: '40px 0 40px 0',
  },
  confirmButton: {
    backgroundColor: '#e0592a',
    color: '#fff',
    width: '200px',
    height: '40px',
    borderRadius: '0',
    fontFamily: 'Asap',
    fontSize: '12px',
  },
  button: {
    backgroundColor: `${navy}`,
    color: 'white',
    width: '100%',
    padding: '14px',
    ...hoverEffectTwo,
  },
});

const DeleteConfirmDialog = (props) => {
  const {
    isDialogOpen,
    onCloseDialog,
    deleteDataName,
    onClickConfirmButton,
    buttonText,
    description,
    theme,
  } = props;

  const stylesWithTheme = deleteConfirmationDialogStyles(theme);

  return (
    <Dialog
      maxWidth="xs"
      PaperProps={{
        style: { padding: '24px 4px', minWidth: '310px' },
      }}
      open={isDialogOpen}
      onClose={() => onCloseDialog()}
    >
      <DialogContent sx={stylesWithTheme.dialogContent}>
        <IconButton
          aria-label="close"
          sx={stylesWithTheme.closeButton}
          onClick={() => onCloseDialog()}
        >
          <Icon>close</Icon>
        </IconButton>
        <Typography gutterBottom align="center" sx={stylesWithTheme.dialogDescription}>
          {description}
        </Typography>
        {deleteDataName
        && (
          <Typography sx={stylesWithTheme.dataToBeDeleted}>{deleteDataName}</Typography>
        )}
        <Button
          sx={stylesWithTheme.button}
          onClick={() => onClickConfirmButton()}
          color="primary"
        >
          {buttonText}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

DeleteConfirmDialog.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  buttonText: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onCloseDialog: PropTypes.func,
  onClickButton: PropTypes.func,
  deleteDataName: PropTypes.string,
  resourceName: PropTypes.string,
};

DeleteConfirmDialog.defaultProps = {
  isDialogOpen: false,
  buttonText: '',
  description: '',
};

export default withStyles(deleteConfirmationDialogStyles, { withTheme: true })(DeleteConfirmDialog);
