import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import {
  dialogTitleContainerStyle,
  hoverEffectTwo,
  dialogCloseButtonStyle,
  tiffany,
  orange,
} from '../css/style';
import { IMG_STORAGE_LINK } from '../data/Constants';
import {
  Button, Typography, Dialog, DialogTitle, DialogActions,
} from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Slide from '@mui/material/Slide';

const styles = theme => ({
  dialogContainer: {
    width: '500px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0,
    position: 'relative',
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  titleContainer: {
    ...dialogTitleContainerStyle,
  },
  closeButton: {
    ...dialogCloseButtonStyle,
    color: 'grey',
  },
  icon: {
    height: '50px',
    width: '50px',
    textAlign: 'center',
    margin: '20px 0',
    border: `solid 3px ${tiffany}`,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogActions: {
    justifyContent: 'center',
    padding: '10px 80px',
    margin: 0,
  },
  button: {
    backgroundColor: `${orange}`,
    color: 'white',
    textTransform: 'capitalize',
    padding: '4px 14px',

    margin: 0,
    ...hoverEffectTwo,
  },
  resourceLogo: {
    verticalAlign: 'middle',
    marginRight: '10px',
    color: tiffany,
    width: '24px',
  },
});
const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: !!error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);

    if (window?.location?.pathname !== '/connections') {
      setTimeout(() => {
        this.refresh();
      }, 15000);
    }
  }

  componentWillUnmount = () => {
    this.setState({ hasError: false });
  };

  refresh() {
    const { history } = this.props;
    if (window?.location?.pathname !== '/connections') {
      history.push('/');
    }
    this.setState({ hasError: false });
  }

  renderError() {
    const { theme } = this.props;
    const { hasError } = this.state;
    const stylesWithTheme = styles(theme);

    return (
      <React.Fragment>
        <Dialog
          open={hasError}
          TransitionComponent={Transition}
          keepMounted
          onClose={e => this.refresh()}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle style={{ display: 'flex', alignItems: 'center' }}>
            <img style={stylesWithTheme.resourceLogo} src={`${IMG_STORAGE_LINK}Jobs_logo_horozontal.svg`} />
            <Typography color="primary" sx={stylesWithTheme.title}>
              Something went wrong
            </Typography>
          </DialogTitle>
          <DialogActions style={{ padding: '20px', justifyContent: 'center' }}>
            <Button style={stylesWithTheme.button} onClick={e => this.refresh()}>
              <RestartAltIcon />
              {'  '}
              Restart
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.renderError();
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(ErrorBoundary));
