import React, { useEffect, Fragment } from 'react';
import {
  TextField,
  Chip,
  Checkbox,
  InputAdornment,
  Badge, ListItem,
} from '@mui/material';
import { isEmpty } from 'lodash';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { makeStyles } from '@mui/styles';
import { autocompleteOptionStyle } from '../../css/style';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    fontSize: '14px',
    backgroundColor: '#ffffff',
    margin: '10px 0',
    [theme && theme.breakpoints.up('md')]: {
      margin: '20px 0px 20px 20px',
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: '5px 30px 5px 5px !important',
    },

    '& .MuiAutocomplete-input': {
      '&::placeholder': {
        color: 'rgba(0, 0, 0, 0.87)',
        opacity: 1,
        '&:focus': {
          opacity: 0.5,
        },
      },
    },
    '& .Mui-focused': {
      '& .MuiAutocomplete-input': {
        '&::placeholder': {
          color: 'rgba(0, 0, 0, 0.87)',
          opacity: 0.5,
        },
      },
    },

    '& .MuiChip-deleteIcon': {
      color: 'rgba(255, 255, 255, 0.7)',
    },
    '& .MuiChip-root': {
      color: '#fff',
      backgroundColor: '#243060',
      maxWidth: '118px',
      marginLeft: '5px',
      marginBottom: '1px',
    },
    '& .MuiChip-label': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
    },
    '& .MuiInputAdornment-positionEnd': {
      margin: '3px 12px',
    },
    '& .MuiBadge-badge': {
      height: '24px',
    },
  },
}));
const filterOptions = createFilterOptions({
  limit: 5,
  stringify: option => option.name,
});
export default function SchoolAutocomplete(props) {
  const {
    title,
    labelCount = 2,
    lettersTypedToShowOptions = 2,
  } = props;
  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState('');
  const [value, setValue] = React.useState(() => (props.selectedSchools || []));
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  useEffect(() => {
    setValue(props.selectedSchools);
  }, [props.selectedSchools]);
  useEffect(() => {
    const { handleStateChange } = props;
    handleStateChange(value);
  }, [value]);
  useEffect(() => {
    const { schoolList } = props;
    if (!inputValue || inputValue.length <= lettersTypedToShowOptions) {
      setOptions([]);
      setOpen(false);
      return;
    }
    let filteredSchools = schoolList.filter(c => c.name.toLowerCase().includes(inputValue.toLowerCase()));
    setOptions(filteredSchools || []);
    setOpen(true);
  }, [inputValue]);
  const renderChips = (value, getTagProps) => {
    let prunedValue = value;
    if (value.length > labelCount) {
      prunedValue = value.slice(0, labelCount);
    }
    return (
      <Fragment>
        {prunedValue.map((option, index) => (
          <Chip
            variant="outlined"
            label={option.name}
            size="small"
            {...getTagProps({ index })}
            sx={{
              '& .MuiChip-deleteIcon': { color: 'rgba(255, 255, 255, 0.7)' },
            }}
          />
        ))}
        {
          (value.length > labelCount)
          && (
            <InputAdornment position="end" className={classes.practiceSelectEndAdornment}>
              <Badge badgeContent={`+${value.length - labelCount}`} color="primary" />
            </InputAdornment>
          )
        }
      </Fragment>
    );
  };
  return (
    <Autocomplete
      id="school-autocomplete"
      classes={classes}
      sx={{
        '& .MuiAutocomplete-tag': {
          maxWidth: '118px',
        },
      }}
      open={open}
      onOpen={() => {
        setOpen(inputValue.length > lettersTypedToShowOptions);
      }}
      multiple
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={option => (option.name ? option.name : '')}
      filterOptions={filterOptions}
      inputValue={inputValue}
      value={value}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      noOptionsText="All Schools"
      options={options}
      popupIcon={null}
      renderInput={params => (
        <TextField
          {...params}
          id="school-item-checkbox"
          variant="outlined"
          placeholder={isEmpty(value) ? title : ''}
          onFocus={e => e.target.placeholder = 'Type letters'}
          onBlur={e => e.target.placeholder = isEmpty(value) ? title : ''}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <ListItem {...props} key={option.id} sx={autocompleteOptionStyle}>
          <Checkbox checked={selected || ((option.key || option.name) == value)} key={`${option.id}_checkbox`} />
            {' '}
            {option.key || option.name || ''}
        </ListItem>
      )}
      renderTags={(value, getTagProps) => renderChips(value, getTagProps)}
      ListboxProps={{ style: { maxHeight: '500px' } }}
    />
  );
}
