import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  IconButton,
  Icon,
  TextField,
  FormLabel,
  Checkbox,
  Paper,
  Popover,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Close } from '@mui/icons-material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { badgeDialogStyles } from '../../css/dialogStyles';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { isEqual, isNil } from 'lodash';

class EditBadgeDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      help: false,
      helpMsg: '',
    };
  }

  componentDidMount() {
    const st = {};
    for (const badge of this.props.badges) {
      st[badge.id] = {
        title: badge.title,
        isPublic: badge.isPublic,
        editable: badge.config && !isNil(badge.config.editable) ? badge.config.editable : true,
      };
    }

    this.setState(st);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(this.props.badges, prevProps.badges)) {
      const st = {};
      for (const badge of this.props.badges) {
        st[badge.id] = {
          title: badge.title,
          isPublic: badge.isPublic,
          editable: badge.config && !isNil(badge.config.editable) ? badge.config.editable : true,
        };
      }

      this.setState(st);
    }
  }

  handleSave() {
    const {
      handleOnClick, actions, apiToken,
    } = this.props;
    Object.keys(this.state).forEach((id) => {
      const val = this.state[id];
      if (val.editable) {
        if (val.title === '#DELETED#') {
          actions.deleteResource(apiToken, 'badges', id);
        } else {
          actions.updateResource(apiToken, val, 'badges', id);
        }
      }
    });

    handleOnClick();
  }

  setinputValue(id, value) {
    const st = this.state;
    st[id].title = value;
    this.setState(st);
  }

  setIsPublicValue(id) {
    const st = this.state;
    st[id].isPublic = !st[id].isPublic;
    this.setState(st);
  }

  checkDisabled(badge) {
    if (badge && badge.config && badge.config.editable === false) return true;
    else return false;
  }

  render() {
    const {
      classes, handleOnClick, open, badges, theme,
    } = this.props;
    const { help, helpMsg } = this.state;
    const stylesWithTheme = badgeDialogStyles(theme);
    return (
      <Dialog
        maxWidth="md"
        PaperProps={{
          style: {
            padding: '24px 4px',
            minWidth: '510px',
          },
        }}
        open={open}
        onClose={() => handleOnClick('close')}
      >
        <DialogTitle style={stylesWithTheme.dialogContent}>
          <div className={classes.titleContainer}>
            <VerifiedUserIcon className={classes.icon} />
            <Typography color="primary" sx={stylesWithTheme.title}>
              EDIT BADGES
            </Typography>
          </div>
          <IconButton
            aria-label="close"
            sx={stylesWithTheme.closeButton}
            onClick={() => handleOnClick('close')}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent style={stylesWithTheme.dialogContent}>
          <FormLabel style={{ ...stylesWithTheme.publicBadgeLabel, paddingRight: '60px' }}>Public</FormLabel>
          {badges && badges.map(badge => this.state[badge.id] && this.state[badge.id].title !== '#DELETED#' && (
            <div className={classes.formRow} key={badge.id}>
              <TextField
                label="Badge Name"
                defaultValue={badge.title}
                onChange={event => this.setinputValue(badge.id, event.target.value)}
                variant="outlined"
                fullWidth
                inputProps={{ autoCapitalize: 'none' }}
                autoComplete="off"
                disabled={this.checkDisabled(badge)}
              />
              <Checkbox
                defaultChecked={badge.isPublic}
                onChange={e => this.setIsPublicValue(badge.id)}
                value="isPublic"
                disabled={this.checkDisabled(badge)}
              />

              {badge?.config?.allowFastpass && !badge?.config?.editable ? (
                <HelpOutlineIcon
                  color="secondary"
                  style={stylesWithTheme.helpOutlineIcon}
                  onClick={e => this.setState({
                    help: e.target,
                    helpMsg: 'This badge has FastPass users currently and so cannot be manually changed.',
                  })}
                />
              ) : (
                <Button color="secondary" onClick={() => this.setinputValue(badge.id, '#DELETED#')} disabled={this.checkDisabled(badge)}>
                <Icon>delete</Icon>
              </Button>
              )}
              <Popover
                open={!!help}
                anchorEl={help}
                onClose={e => this.setState({ help: false })}
              >
                <Paper style={stylesWithTheme.helpPopover}>
                  <Typography sx={stylesWithTheme.helpPopoverText}>{helpMsg}</Typography>
                </Paper>
              </Popover>
            </div>
          ))}

        </DialogContent>
        <DialogActions style={stylesWithTheme.dialogActions}>
          <Button
            sx={stylesWithTheme.button}
            onClick={() => this.handleSave()}
            color="primary"
          >
            SAVE
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}


EditBadgeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  badges: PropTypes.array.isRequired,
  actions: PropTypes.any.isRequired,
  apiToken: PropTypes.string.isRequired,
};

export default withStyles(badgeDialogStyles, { withTheme: true })(EditBadgeDialog);
