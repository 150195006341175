import React, { Component, Fragment } from 'react';

import PropTypes from 'prop-types';
import { FormLabel, Select } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ExpandMore } from '@mui/icons-material';

import { commonResourceStyle } from '../../css/style';

const WARNING_TEXT = 'This field is required';
const PLACEHOLDER = 'Please Select';

const {
  formControl,
  formControlLabelContainer,
  formControlLabel,
  formControlLabelWarnText,
} = commonResourceStyle();

const useStyles = makeStyles(theme => ({
  formControl,
  formControlLabelContainer,
  formControlLabel,
  formControlLabelWarnText,
  selectComponent: {
    width: '100%',
  },
  selectRenderValue: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  expandMoreIcon: {
    fontSize: '19px',
    marginRight: '15px',
    marginTop: '4px',
  },
}));

const CalendarSelect = (props) => {
  const {
    value, title, isRequired, onClick, disabled,
  } = props;
  const displayError = isRequired && !value;
  const classes = useStyles();

  return (
    <div className={classes.formControl}>
      <div className={classes.formControlLabelContainer}>
        <FormLabel className={classes.formControlLabel}>
          {title}
        </FormLabel>
        {
          displayError && (
            <FormLabel className={classes.formControlLabelWarnText}>
              {WARNING_TEXT}
            </FormLabel>
          )
        }
      </div>
      <Select
        className={classes.selectComponent}
        disabled={disabled}
        open={false}
        value="value"
        variant="outlined"
        error={displayError}
        onClick={disabled ? () => {} : onClick}
        renderValue={() => <div className={classes.selectRenderValue}>{value || PLACEHOLDER}</div>}
        IconComponent={() => (
          <ExpandMore
            className={classes.expandMoreIcon}
            style={{ color: disabled ? '' : 'black', fontSize: '19px' }}
          />
        )}
      />
    </div>
  );
};


CalendarSelect.propTypes = {
  value: PropTypes.any,
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  helpMessage: PropTypes.string,
  isRequired: PropTypes.bool,
};

CalendarSelect.defaultProps = {
  isRequired: false,
};

export default CalendarSelect;
