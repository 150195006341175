import isString from 'lodash/isString';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Button, Typography, TextField, Icon, Hidden, Grid,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import ResetPasswordDialog from './ResetPasswordDialog';
import RecoveryTokenDialog from './RecoveryTokenDialog';
import NewPasswordDialog from './NewPasswordDialog';
import { IMG_STORAGE_LINK, LOGIN_ERROR_MESSAGES } from '../data/Constants';
import { loginPageStyles } from '../css/loginPageStyles';
import { orange } from '../css/style';
import { handleTrailingSpaces, updateAdminAccessList } from '../utils/Functions';
import SubscriptionPlan from './SubscriptionPlan';

const Logo = `${IMG_STORAGE_LINK}Jobs_Logo.svg`;

const FORGOT_PASSWORD = 'Change Password';
const CHANGE_PASSWORD = 'CHANGE PASSWORD';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: null,
      name: null,
      mobile: null,
      forgotPasswordDialogOpen: false,
      resetPasswordDialogOpen: false,
      enteredEmail: '',
      recoveryToken1: '',
      recoveryToken2: '',
      recoveryToken3: '',
      recoveryToken4: '',
      recoveryToken5: '',
      newPassword: '',
      reTypedNewPassword: '',
      errorText: '',
      rememberUser: '',
      showSubscriptionPlan: false,
      invalidEmail: '',
      invalidPassword: '',
      isAdminSite: true,
    };
  }

  componentDidMount() {
    const { actions, match, history } = this.props;
    const { verificationUuid } = match.params;

    if (verificationUuid) {
      actions.verifyUser(['users', 'verify', verificationUuid])
        .then((res) => {
          if (!res.error) {
            localStorage.setItem('admin', JSON.stringify(res.loggedInAdmin));
            history.push('/settings');
          }
        });
    }

    if (window.location.pathname === '/signup'
      || this.props.signup
      || history.location.pathname === '/subscription'
    ) {
      history.push('/under-construction');
    }
  }

  handleCloseDialog = (dialog) => {
    this.setState({
      forgotPasswordDialogOpen: false,
      resetPasswordDialogOpen: false,
    });
  };

  handleOnClickDialog = (type, data) => {
    const { actions, history } = this.props;

    if (type === 'forgotPassword') {
      actions.getRecoveryToken({ email: data.email })
        .then((e) => {
          if (!e.error) {
            this.setState({
              forgotPasswordDialogOpen: false,
              resetPasswordDialogOpen: true,
              email: data.email,
            });
          }
        });
    } else {
      actions.resetPassword(data).then((e) => {
        if (!e.error) {
          this.setState({
            resetPasswordDialogOpen: false,
            email: data.email,
          });
        }
      });
    }
  };

  onChangeInput = (field, event) => {
    const newState = event.target.value;
    this.setState({ [field]: newState });
  };

  onClickLogin = () => {
    const { actions } = this.props;
    const {
      forgotPasswordDialog,
      recoveryTokenDialog,
      newPasswordDialog,
      enteredEmail,
      enteredToken,
      recoveryToken1,
      recoveryToken2,
      recoveryToken3,
      recoveryToken4,
      recoveryToken5,
      newPassword,
      reTypedNewPassword,
      errorText,
      rememberUser,
      invalidEmail,
      invalidPassword,
      ...admin
    } = this.state;

    if (invalidEmail || invalidPassword) return;

    actions.loginAdmin(admin)
      .then((res) => {
        if (!res.error) {
          localStorage.setItem('admin', JSON.stringify(res.loggedInAdmin));
          this.setState({ invalidEmail: null, invalidPassword: null });
        } else {
          const message = isString(res.error) ? res.error : isString(res.error.message) ? res.error.message : '';
          switch (message) {
            case 'EMAIL_REQUIRED':
            case 'INVALID_EMAIL':
            case 'ACCESS_DENIED':
            case 'EMPLOYERS_ACCESS_DENIED':
              this.setState({ invalidEmail: LOGIN_ERROR_MESSAGES[message] });
              break;
            case 'PASSWORD_REQUIRED':
            case 'INVALID_PASSWORD':
              this.setState({ invalidPassword: LOGIN_ERROR_MESSAGES[message], invalidEmail: null });
              break;
            default:
              this.setState({ invalidEmail: message });
              break;
          }
        }
      });
  };

  handleKeyPress(e) {
    if (!this.state.email || !this.state.password) {
      return;
    }
    if (e.key === 'Enter') {
      this.onClickLogin();
    }
  }

  onClickSignup = () => {
    const { actions, history } = this.props;
    const {
      name, email, mobile, password,
    } = this.state;
    if (!(name && email && password)) {
      return this.setState({ error: 'Please provide complete details' });
    }
    const admin = {
      name: handleTrailingSpaces(name),
      email: handleTrailingSpaces(email),
      mobile,
      password,
      isAdmin: true,
    };

    actions.registerAdmin(admin)
      .then((res) => {
        if (!res.error) {
          const { loggedInAdmin } = res;
          // Create customer on Stripe
          actions.createStripe(res.loggedInAdmin.token, {
            id: loggedInAdmin.id,
            email: loggedInAdmin.email,
          }, 'stripe-payment', 'customers')
            .then((response) => {
              if (!response.error) {
                updateAdminAccessList(loggedInAdmin, actions, {
                  id: loggedInAdmin.accessList[0].id,
                  stripeCustomerId: response.response.id,
                });
                this.setState({
                  showSubscriptionPlan: true,
                  showSignup: false,
                });
                location.href = '/subscription';
              }
            });
        }
      });
  };

  getSignupForm = () => {
    const { errors, classes, theme } = this.props;
    const { error } = this.state;
    const {
      name, email, mobile, password,
    } = this.state;

    const stylesWithTheme = loginPageStyles(theme);

    return (
      <div className={classes.rightContainer}>
        <Typography sx={stylesWithTheme.welcomeTextBox}>WELCOME TO</Typography>
        <img src={Logo} className={classes.logo} alt=" Logo" />
        <div className={classes.inputSection}>
          <TextField
            placeholder="My Name"
            label="My Name"
            onChange={event => this.onChangeInput('name', event)}
            sx={stylesWithTheme.signUpTextField}
            variant="outlined"
            id="name"
            inputProps={{ autoCapitalize: 'none' }}
            autoComplete="off"
          />
          <TextField
            placeholder="Email Address"
            label="Email Address"
            onChange={event => this.onChangeInput('email', event)}
            sx={stylesWithTheme.signUpTextField}
            variant="outlined"
            id="email"
            inputProps={{ autoCapitalize: 'none' }}
            autoComplete="off"
          />
          <TextField
            placeholder="Phone Number"
            label="Phone Number"
            onChange={event => this.onChangeInput('mobile', event)}
            sx={stylesWithTheme.signUpTextField}
            variant="outlined"
            name="phone"
            inputProps={{ autoCapitalize: 'none' }}
            autoComplete="off"
          />
          <Typography sx={stylesWithTheme.passwordLabel}>
            CREATE PASSWORD:
          </Typography>
          <TextField
            type="password"
            variant="outlined"
            onChange={event => this.onChangeInput('password', event)}
            sx={stylesWithTheme.signUpTextField}
            placeholder="Password"
            id="password"
            inputProps={{ autoCapitalize: 'none' }}
            autoComplete="off"
          />
        </div>
        <div className={classes.signUpActionBtnsContainer}>
          <div
            className={classes.signin}
            onClick={(e) => {
              localStorage.removeItem('admin');
              location.href = '/';
            }}
          >
            <Icon sx={stylesWithTheme.signUpLeftArrow}>trending_flat</Icon>
            <Typography color="secondary" sx={stylesWithTheme.sigupTextDesc}>
              BACK
            </Typography>
          </div>
          <Button
            sx={stylesWithTheme.nextBtn}
            variant="contained"
            color="primary"
            onClick={() => this.onClickSignup()}
          >
            next
            <Icon sx={stylesWithTheme.signUpRightArrow}>trending_flat</Icon>
          </Button>
        </div>
        {
          (errors || error)
          && (
            <div className={classes.signUpErrors}>
              {errors || error}
            </div>
          )
        }
      </div>
    );
  };

  getLoginForm = () => {
    const { classes, theme } = this.props;
    const {
      forgotPasswordDialogOpen,
      resetPasswordDialogOpen,
      invalidEmail,
      invalidPassword,
    } = this.state;

    const stylesWithTheme = loginPageStyles(theme);

    return (
      <div className={classes.rightContainer}>
        <Typography sx={stylesWithTheme.welcomeTextBox}>WELCOME TO</Typography>
        <img src={Logo} className={classes.logo} alt=" Logo" />
        <div className={classes.inputSectionLogin}>
          <Grid container>
          <Grid item xs={false} md={3} />
            <Grid item xs={12} md={9} sx={stylesWithTheme.loginTitleContainer}>
              <Typography sx={stylesWithTheme.loginTitle}>Practice Log In: </Typography>
            </Grid>
            <Grid item xs={false} md={3} />
            <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
              <TextField
                id="email"
                placeholder="Email Address"
                onChange={(event) => {
                  this.onChangeInput('email', event);
                  this.setState({ invalidEmail: null });
                }}
                sx={stylesWithTheme.textField}
                variant="outlined"
                error={Boolean(invalidEmail)}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={stylesWithTheme.loginErrorContainer}>
              { invalidEmail
                && (
                <Typography
                  id="emailError"
                  sx={stylesWithTheme.loginError}
                >
                  {invalidEmail.includes('Wrong user')
                    ? (
                      <Fragment>
                        {`${invalidEmail} - Go to `}
                        <a style={{ color: `${orange}` }} href='https://app.jobsunleashed.com'>Worker's login</a>
                      </Fragment>
                    )
                    : invalidEmail}
                </Typography>
                )
              }
            </Grid>

          </Grid>
        </div>
        <div className={classes.inputSectionLogin}>
          <Grid container>
            <Grid item xs={false} md={3} />
            <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
              <TextField
                onKeyPress={(e) => { this.handleKeyPress(e); }}
                id="password"
                type="password"
                variant="outlined"
                onChange={(event) => {
                  this.onChangeInput('password', event);
                  this.setState({ invalidPassword: null });
                }}
                sx={stylesWithTheme.textField}
                placeholder="Password"
                error={Boolean(invalidPassword)}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={stylesWithTheme.loginErrorContainer}>
              { invalidPassword
                && (
                <Typography
                  id="pwError"
                  sx={stylesWithTheme.loginError}
                >
                  {invalidPassword}
                </Typography>
                )
              }
            </Grid>
          </Grid>
        </div>

        <div className={classes.forgetPassword}>
          <Typography
            color="primary"
            sx={stylesWithTheme.forgotPasswordText}
            onClick={() => this.setState({ forgotPasswordDialogOpen: true })}
          >
            Change Password ?
          </Typography>
        </div>
        {
          forgotPasswordDialogOpen || resetPasswordDialogOpen
            ? (
              <ResetPasswordDialog
                type={forgotPasswordDialogOpen ? 'forgotPassword' : 'changePassword'}
                open={forgotPasswordDialogOpen || resetPasswordDialogOpen || false}
                title={forgotPasswordDialogOpen ? FORGOT_PASSWORD : CHANGE_PASSWORD}
                handleCloseDialog={dialog => this.handleCloseDialog(dialog)}
                handleOnClick={(type, data) => this.handleOnClickDialog(type, data)}
              />
            ) : null
        }
        <Button
          sx={stylesWithTheme.loginBtn}
          variant="contained"
          color="primary"
          onClick={() => this.onClickLogin()}
        >
          Sign In
        </Button>
        <div className={classes.signup}>
          <Typography color="secondary" sx={stylesWithTheme.sigupText}>
            Don't have an account yet?
          </Typography>

          <Button
            onClick={() => location.href = '/signup'}
            variant="contained"
            color="primary"
            sx={stylesWithTheme.signUpBtn}
          >
            SIGN UP HERE!
          </Button>
        </div>
        <Typography sx={stylesWithTheme.lookingForJobText}>
          Looking for a Job?
          <a
            href='https://app.jobsunleashed.com'
            style={stylesWithTheme.lookingForJobLink}
          >
            Create an Account Here!
          </a>
        </Typography>
      </div>
    );
  };

  onClickReset = () => {
    const { actions } = this.props;
    const {
      enteredEmail, newPassword, reTypedNewPassword, newPasswordDialog,
    } = this.state;
    const reqObj = {
      email: enteredEmail,
      password: newPassword,
    };
    if (newPassword === reTypedNewPassword) {
      actions.confirmResetPassword(reqObj)
        .then((res) => {
          if (!res.error) {
            this.setState({
              newPasswordDialog: !newPasswordDialog,
              recoveryToken1: '',
              recoveryToken2: '',
              recoveryToken3: '',
              recoveryToken4: '',
              errorText: '',
            });
          }
        });
    } else {
      this.setState({ errorText: 'Passwords are different' });
    }
  };

  onRememberCheckBoxChange = (event) => {
    const { actions } = this.props;
    this.setState({ rememberUser: event.target.checked });
  };

  render() {
    const {
      classes, actions, admin, resources,
    } = this.props;
    const { showSignup, showSubscriptionPlan } = this.state;
    return (
      <div className={classes.container}>
        <Hidden smDown>
          <div className={classes.leftContainerImage} />
        </Hidden>
        <Hidden smDown>
          <div className={classes.leftContainer} />
        </Hidden>
        {!showSignup && !showSubscriptionPlan && this.getLoginForm()}
        {/* {showSignup && !showSubscriptionPlan && this.getSignupForm()}
        {showSubscriptionPlan && admin
        && (
          <SubscriptionPlan
            admin={admin}
            actions={actions}
            resources={resources}
          />
        )} */}
      </div>
    );
  }
}

Login.propTypes = {
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.any,
};

Login.defaultProps = {
  errors: null,
};

export default withStyles(loginPageStyles, { withTheme: true })(withRouter(Login));
