import React, { Component } from 'react';
import moment from 'moment';
import Week from './Week';
import { defaultUtils as utils } from './dateUtils';
import { withStyles } from '@mui/styles';
import { withRouter } from 'react-router';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    lineHeight: '1.25',
    position: 'relative',
    [theme && theme.breakpoints.down('md')]: {
      padding: '0px 3px 5px 5px',
    },
    [theme && theme.breakpoints.up('md')]: {
      padding: '0px 25px 10px 25px',
    },
  },
});

class Weeks extends Component {
  render() {
    const { classes, displayDate } = this.props;
    return <div className={classes.root}>{this.renderWeeks(displayDate)}</div>;
  }

  renderWeeks = () => {
    const {
      notAvailableDates,
      maxDate,
      minDate,
      readOnly,
      selectedDates,
      pendingDates,
      initPendingDates,
      displayDate,
      onSelect,
      onMultiNeedsClick,
      multiNeedsData,
      enabledNeedsClick,
      disabledToDate,
      coronadoErDayColor,
      referenceOnly,
      surgeryNeededDates,
      boostDates,
    } = this.props;

    const weekArray = utils.getWeekArray(
      displayDate,
      moment.localeData().firstDayOfWeek(),
    );

    return weekArray.map(
      (s, i) => (
        <Week
          key={i}
          week={s}
          selectedDates={selectedDates}
          pendingDates={pendingDates}
          initPendingDates={initPendingDates}
          notAvailableDates={notAvailableDates}
          multiNeedsData={multiNeedsData}
          onSelect={onSelect}
          minDate={minDate}
          maxDate={maxDate}
          readOnly={readOnly}
          onMultiNeedsClick={onMultiNeedsClick}
          enabledNeedsClick={enabledNeedsClick}
          disabledToDate={disabledToDate}
          coronadoErDayColor={coronadoErDayColor}
          referenceOnly={referenceOnly}
          surgeryNeededDates={surgeryNeededDates}
          boostDates={boostDates}
        />
      ),
      this,
    );
  };
}

export default withStyles(styles)(withRouter(Weeks));
