import React, { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  IconButton,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Close } from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { refreshPageDialogStyles } from '../../css/dialogStyles';

class RefreshPageDialog extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const {
      classes,
      open = false,
      handleClose = () => {},
      theme,
    } = this.props;

    const stylesWithTheme = refreshPageDialogStyles(theme);
    return (
      <Dialog
        maxWidth="md"
        PaperProps={{
          style: {
            padding: '24px 4px',
            minWidth: '310px',
          },
        }}
        open={open}
        onClose={() => handleClose()}
      >
        <DialogTitle sx={stylesWithTheme.dialogContent}>
          <div className={classes.titleContainer}>
            <RefreshIcon sx={stylesWithTheme.refreshIcon} />
          </div>
          <IconButton
            aria-label="close"
            sx={stylesWithTheme.closeButton}
            onClick={() => handleClose()}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={stylesWithTheme.dialogContent}>
          <Typography sx={stylesWithTheme.dialogDescription} style={{ fontSize: '16px' }}>
            The calendar information is not up to date, please refresh the page and try again.
          </Typography>
        </DialogContent>
        <DialogActions sx={stylesWithTheme.dialogActions}>
          <Button
            key="refresh_btn"
            sx={stylesWithTheme.refreshButton}
            onClick={() => window.location.reload()}
          >
            REFRESH
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}


RefreshPageDialog.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default withStyles(refreshPageDialogStyles, { withTheme: true })(RefreshPageDialog);
