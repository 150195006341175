import React from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { getChartColor } from '../../utils/Functions';
import {
  CategoryScale, LinearScale, Chart, BarElement,
} from 'chart.js';

Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
);

const BarChart = (props) => {
  const {
    data, legend, options, categories,
  } = props;

  if (isEmpty(data)) return null;

  const chartColors = {
    bg: [],
    border: [],
  };

  Object.values(categories).forEach((label, index) => {
    const { bgColor, borderColor } = getChartColor(index);
    chartColors.bg.push(bgColor);
    chartColors.border.push(borderColor);
  });

  const reportConfig = {
    backgroundColor: chartColors.bg,
    borderColor: chartColors.border,
    borderWidth: 1,
    barPercentage: 0.3,
    borderSkipped: false,
  };

  data.datasets[0] = !isEmpty(data.datasets) ? { ...data.datasets[0], ...reportConfig } : {};

  return (
    <Bar data={data} legend={legend} options={options} />
  );
};

BarChart.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  legend: PropTypes.objectOf(PropTypes.any),
  options: PropTypes.objectOf(PropTypes.any),
};

BarChart.defaultProps = {
  legend: {},
  options: {},
};

export default BarChart;
