import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  IconButton,
  Grid,
  CircularProgress,
  Switch,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Close } from '@mui/icons-material';

import { badgeDialogStyles } from '../../css/dialogStyles';
import UserAutocomplete from './UserAutocomplete';
import {
  tiffany,
  navy,
  gray,
  hoverEffectTwo,
  dialogCloseButtonStyle,
  dialogTitleContainerStyle,
  dialogFormRowStyle,
} from '../../css/style';

import { styled } from '@mui/material/styles';

const CustomedSwitch = styled(Switch)(({ theme }) => ({
  width: '40px',
  height: '21px',
  padding: 0,
  margin: theme.spacing(1),

  '& .MuiSwitch-switchBase': {
    padding: 1,
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: theme && theme.palette.common.white,
      '& + .MuiSwitch-track': {
        opacity: 1,
        height: 20,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },

  '& .MuiSwitch-thumb': {
    width: '18px',
    height: '18px',
  },

  '& .MuiSwitch-track': {
    borderRadius: 20 / 2,
    border: `1px solid ${navy}`,
    backgroundColor: navy,
    height: 19,
    opacity: 1,
    transition: theme && theme.transitions.create(['background-color', 'border']),
  },
}));

class AddFastpassStaffDialog extends Component {
  constructor() {
    super();
    this.state = {
      selectedUser: {},
      isSaving: false,
      isStaff: true,
    };
  }

  handleSave() {
    const {
      handleOnClose, actions, apiToken, allStaff, practiceId,
    } = this.props;
    const { selectedUser, isStaff } = this.state;
    if (!practiceId) return;

    this.setState({ isSaving: true });
    if (allStaff) {
      actions.getAllResources(apiToken, `fastpass/assign-all-talents-for-practice/${practiceId}`).then((res) => {
        this.setState({ isSaving: false });
        handleOnClose(true);
      });
    } else {
      if (isEmpty(selectedUser)) return;
      actions.addResource(apiToken, {
        practiceId: Number(practiceId),
        userId: selectedUser.id,
        fastpassBadgeId: !isStaff ? selectedUser.fpBadgeId : null,
      }, 'fastpass').then((e) => {
        this.setState({ selectedUser: {}, isSaving: false });
        handleOnClose(true);
      });
    }
  }

  render() {
    const {
      classes, handleOnClose, open, staff, allStaff = false, badgedUsers, theme, fastpassData,
    } = this.props;
    const { selectedUser, isSaving, isStaff } = this.state;

    const userList = isStaff ? staff : badgedUsers;

    const title = !allStaff ? isStaff ? 'Add Fastpass Staff' : 'Add Badged Users' : 'Add All Local Staff';

    const stylesWithTheme = badgeDialogStyles(theme);

    return (
      <Dialog
        maxWidth="md"
        PaperProps={{
          style: {
            padding: '24px 4px',
            minWidth: allStaff ? '310px' : '500px',
          },
        }}
        open={open}
        onClose={() => handleOnClose()}
      >
        <DialogTitle sx={stylesWithTheme.dialogContent}>
          <div className={classes.titleContainer}>
            <Typography color="primary" sx={stylesWithTheme.title} style={!isStaff && !allStaff ? { color: navy } : {}}>
              {title}
            </Typography>

          </div>
          <IconButton
            aria-label="close"
            sx={stylesWithTheme.closeButton}
            onClick={() => handleOnClose()}
          >
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={stylesWithTheme.dialogContent}>
          {allStaff
            ? (<Typography>FastPass all staff from this location?</Typography>)
            : (
              <Fragment>
                <Grid container alignItems="center" sx={stylesWithTheme.switchContainer}>
                  <Grid item>Badged Users</Grid>
                  <Grid item>
                    <CustomedSwitch
                      key='switch_user_type'
                      checked={isStaff}
                      disableRipple
                      onChange={(e) => {
                        this.setState({ isStaff: e.target.checked });
                      }}
                    />
                  </Grid>
                  <Grid item>Staff</Grid>
                </Grid>
                <UserAutocomplete
                  handleStateChange={selectedUser => this.setState({ selectedUser })}
                  title='User name/email'
                  items={userList || []}
                  value={this.state.selectedUser || {}}
                  key='fastpass_staff_autocomplete'
                  fastpassData={fastpassData}
                  noMargin
                />
              </Fragment>
            )
          }
        </DialogContent>
        <DialogActions sx={stylesWithTheme.dialogActions}>
          <Button
            disabled={!allStaff && isEmpty(selectedUser)}
            sx={allStaff || !isEmpty(selectedUser) ? stylesWithTheme.button : stylesWithTheme.disabledButton}
            onClick={() => !isSaving && this.handleSave()}
            color="primary"
            variant="contained"
            endIcon={isSaving ? <CircularProgress size={20} /> : null}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}


AddFastpassStaffDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  actions: PropTypes.any.isRequired,
  apiToken: PropTypes.string.isRequired,
};

AddFastpassStaffDialog.defaultProps = {
  open: false,
};

export default withStyles(badgeDialogStyles, { withTheme: true })(AddFastpassStaffDialog);
