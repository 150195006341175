import {
  headerButtonStyle,
  hoverEffectOne,
  navy,
  tiffany,
  orange,
} from '../../../css/style';

export const PracticesViewStyles = theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '40px',
    margin: '1% 2% 1% 2%',

    [theme && theme.breakpoints.down('sm')]: {
      padding: '20px',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '25px',
  },
  subHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '30px',
    marginBottom: '25px',

    [theme && theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  headerButton: {
    ...headerButtonStyle,
    color: '#ffffff',
    backgroundColor: `${orange}`,
    right: '0',
    cursor: 'pointer',
    ...hoverEffectOne,

    [theme && theme.breakpoints.down('sm')]: {
      maxWidth: '100px',
    },
  },
  resourceHeader: {
    display: 'flex',
    flexDirection: 'column',
    width: '96%',
    alignItems: 'left',
    justifyContent: 'space-between',
    margin: '3% 3% 0 2%',
    position: 'relative',
  },
  resourceNameLarge: {
    fontSize: '30px',
    fontWeight: 'bold',
    color: 'white',
    textTransform: 'uppercase',
    fontFamily: 'circe-rounded',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    [theme && theme.breakpoints.down('sm')]: {
      maxWidth: 'calc(100% - 120px)',
    },
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: `${navy}`,
    textTransform: 'uppercase',
    fontFamily: 'circe-rounded',
  },
  imageContainer: {
    display: 'flex',
    width: '150px',
    minWidth: '100px',
    height: '100px',
    border: `1px solid ${navy}`,
    borderRadius: '5px',
    marginRight: '20px',
    textAlign: 'center',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',

    [theme && theme.breakpoints.down('sm')]: {
      minWidth: '100px',
    },
  },
  contactPointContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

    [theme && theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '5px',
  },
  icon: {
    color: `${tiffany}`,
    display: 'flex',
    justifyContent: 'center',
  },
  iconText: {
    marginLeft: '10px',
    marginRight: '15px',
  },
  column: {
    width: '50%',
    [theme && theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  socialMedia: {
    color: '#979797',
    margin: '20px 0 10px 0',
  },
  socialMediasContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '5px',
  },
  columnContainer: {
    width: '50%',
    wordBreak: 'break-all',
  },
  image: {
    textAlign: 'center',
  },
  practiceLogo: {
    maxWidth: '149px',
    maxHeight: '99px',
  },
  carouselContainer: {
    paddingBottom: '30px',
    position: 'relative',
    width: '450px',
    height: '400px',
    float: 'left',
    border: 'solid 1px #979797',
    marginRight: '30px',

    [theme && theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
      minHeight: '200px',
    },
  },
  practiceImage: {
    display: 'block',
    maxHeight: '399px',
    margin: 'auto',
    maxWidth: '449px',
  },
  dashlet: {
    width: 200,
    height: 100,
    display: 'flex',
    alignItems: 'flex-start',
    alignSelf: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontFamily: 'Asap',
    borderRadius: '5px',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.3)',
    backgroundColor: '#fff',
    minWidth: 'calc(100% / 3 - 24px)',
    padding: '5px 15px',
    color: navy,

    '&:hover': {
      backgroundColor: orange,
      color: '#fff !important',
    },
  },
  dashletValue: {
    fontSize: 24,
  },
  dashletIcon: {
    marginRight: '5px',
    fontSize: 40,
    display: 'flex',
    justifyContent: 'flex-end',
    minWidth: '30px',
  },
  dashletTitle: {
    fontSize: '14px',
    marginBottom: '5px',
    fontStyle: 'italic',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  dashletCaption: {
    height: '12px',
    fontSize: '14px',
    fontStyle: 'italic',
    textAlign: 'left',
  },
  reportButton: {
    width: '200px !important',
    height: '45px',
    fontFamily: 'Asap',
    fontSize: '12px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#ffffff',
    margin: '10px 0px',
    borderRadius: '5px',
    backgroundColor: `${navy}`,

    [theme && theme.breakpoints.down('sm')]: {
      margin: '10px 0 40px 0',
    },

    '&:hover': {
      backgroundColor: orange,
      color: '#fff !important',
    },
  },
});
