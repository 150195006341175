import React, { useEffect, useState, Fragment } from 'react';
import { isEmpty } from 'lodash';
import {
  FormLabel,
  TextField,
  Chip,
  Checkbox,
  InputAdornment,
  Badge,
  FormControl,
  Typography,
  Paper,
  Popover, ListItem,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { commonResourceStyle, autocompleteOptionStyle } from '../../css/style';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    fontSize: '14px',
    backgroundColor: '#ffffff',

    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: '5px 30px 5px 5px !important',
    },

    '& .MuiAutocomplete-input': {
      '&::placeholder': {
        color: 'rgba(0, 0, 0, 0.87)',
        opacity: 1,
        '&:focus': {
          opacity: 0.5,
        },
      },
    },

    '& .Mui-focused': {
      '& .MuiAutocomplete-input': {
        '&::placeholder': {
          color: 'rgba(0, 0, 0, 0.87)',
          opacity: 0.5,
        },
      },
    },

    '& .MuiChip-deleteIcon': {
      color: 'rgba(255, 255, 255, 0.7)',
    },

    '& .MuiChip-root': {
      color: '#fff',
      backgroundColor: '#243060',
      maxWidth: '130px',
      marginLeft: '5px',
      marginBottom: '1px',
    },
    '& .MuiChip-label': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
    },

    '& .MuiInputAdornment-positionEnd': {
      margin: '3px 12px',
    },

    '& .MuiBadge-badge': {
      height: '24px',
    },
  },
}));

const componentStyle = {
  chipOverflow: {
    maxWidth: '130px',
  },
  chipBadge: {
    marginLeft: '18px',
    marginTop: '2px',
  },
  inputEndAdornment: {
    position: 'absolute',
    right: '40px',
  },
};

const filterOptions = createFilterOptions({
  limit: 5,
  stringify: option => option.name,
});

export default function FormAutocomplete({
  value,
  title,
  label,
  labelCount = 2,
  letterLimit = 2,
  handleStateChange,
  isRequired,
  helpMessage,
  options,
  autocompleteOnly = false,
  multiple = false,
  disableCloseOnSelect = false,
}) {
  const classes = useStyles();
  const styles = commonResourceStyle();
  const [inputValue, setInputValue] = useState('');
  const [selectedValues, setSelectedValues] = useState(() => (value || []));
  const [openOptionBox, setOpenOptionBox] = useState(false);
  const [help, setHelp] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState(options || []);
  const displayError = isRequired && (!selectedValues || !selectedValues.length);

  const renderChips = (value, getTagProps) => {
    let prunedValue = value;
    if (value.length > labelCount) {
      prunedValue = value.slice(0, labelCount);
    }
    return (
      <Fragment>
        {prunedValue.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            variant="outlined"
            label={option.name}
            size="small"
            color="secondary"
            sx={{
              ...styles.chipRootFull,
              '& .MuiChip-label': styles.chipRootLabel,
              '& .MuiChip-deleteIcon': { color: 'rgba(255, 255, 255, 0.7)' },
            }}
          />
        ))}
        {
          (value.length > labelCount)
          && (
            <InputAdornment position="end" style={componentStyle.inputEndAdornment}>
              <Badge badgeContent={`+${value.length - labelCount}`} color="primary" style={componentStyle.chipBadge} />
            </InputAdornment>
          )
        }
      </Fragment>
    );
  };

  return (
    <FormControl fullWidth style={autocompleteOnly ? { marginTop: 0 } : styles.formControl}>
      {!autocompleteOnly && (
        <div style={styles.formControlLabelContainer}>
          {label && (
            <FormLabel style={styles.formControlLabel}>
              {label}
              {helpMessage && (
                <HelpOutlineIcon
                  style={styles.helpOutlineIcon}
                  onClick={(e) => {
                    setHelp(e.target);
                  }}
                />
              )}
            </FormLabel>
          )}
          {displayError && (
            <FormLabel style={styles.formControlLabelWarnText}>This field is required</FormLabel>
          )}
        </div>
      )}
      <Autocomplete
        id="formAutocomplete"
        classes={classes}
        sx={{
          '& .MuiAutocomplete-tag': {
            maxWidth: '130px',
          },
        }}
        disableCloseOnSelect={disableCloseOnSelect}
        autoHighlight
        multiple={multiple}
        open={openOptionBox}
        onClose={() => {
          setOpenOptionBox(false);
        }}
        options={filteredOptions}
        popupIcon={null}
        value={selectedValues}
        inputValue={inputValue}
        filterOptions={filterOptions}
        getOptionLabel={option => (option.name ? option.name : '')}
        getOptionSelected={(option, value) => option.id === value.id}
        onInputChange={(event, newInputValues) => {
          setInputValue(newInputValues);
          if (newInputValues.length >= letterLimit) {
            const filtered = options.filter(option => option.name.toLowerCase().includes(newInputValues.toLowerCase()));
            setFilteredOptions(filtered);
            setOpenOptionBox(true);
          } else {
            setFilteredOptions([]);
            setOpenOptionBox(false);
          }
        }}
        onChange={(event, newValue) => {
          setSelectedValues(newValue);
          handleStateChange(newValue);
        }}
        renderOption={(props, option, { selected }) => (
          <ListItem {...props} key={option.id} sx={autocompleteOptionStyle}>
            <Checkbox checked={selected || ((option.key || option.name) == value)} key={`${option.id}_checkbox`} />
              {' '}
              {option.key || option.name || ''}
          </ListItem>
        )}
        renderInput={params => (
          <TextField
            {...params}
            id="form-autocomplete-checkbox"
            variant="outlined"
            placeholder={isEmpty(value) ? title : ''}
            onFocus={e => e.target.placeholder = title || ''}
            onBlur={e => e.target.placeholder = isEmpty(value) ? title : ''}
            error={displayError}
          />
        )}
        renderTags={(value, getTagProps) => renderChips(value, getTagProps)}
      />
      <Popover
        open={!!help}
        anchorEl={help}
        onClose={e => setHelp(null)}
      >
        <Paper style={styles.helpPopover}>
          <Typography style={styles.helpPopoverText}>{helpMessage}</Typography>
        </Paper>
      </Popover>
    </FormControl>
  );
}
