import { Map } from 'immutable';
import types from '../actions/ActionTypes';

const initialState = Map({ loading: false, viewMoreLoading: 0 });

export default function loadingReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_REQUESTED:
    case types.UPDATE_RESOURCE_REQUESTED:
    case types.ADD_RESOURCE_REQUESTED:
    case types.DELETE_RESOURCE_REQUESTED:
    case types.GET_ALL_RESOURCES_REQUESTED:
    case types.GET_ONE_RESOURCE_REQUESTED:
    case types.UPLOAD_RESOURCE_REQUESTED:
    case types.GET_DOWNLOAD_RESOURCE_REQUESTED:
    case types.GET_RECENT_ORDERS_REQUESTED:
    case types.VERIFY_USER_REQUEST:
    case types.SEND_PUSH_NOTIFICATION:
    case types.CREATE_STRIPE_REQUESTED:
    case types.CANCEL_STRIPE_SUBSCRIPTION_REQUESTED:
      return state.set('loading', true);
    case types.VIEW_MORE_RESOURCE_REQUESTED:
      return state.set('viewMoreLoading', state.get('viewMoreLoading') + 1);
    case types.VIEW_MORE_RESOURCE_SUCCESS:
      return state.set('viewMoreLoading', state.get('viewMoreLoading') - 1);
    case types.GET_ALL_RESOURCES_SUCCESS:
    case types.GET_ONE_RESOURCE_SUCCESS:
    case types.GET_DOWNLOAD_RESOURCES_SUCCESS:
    case types.UPLOAD_RESOURCE_SUCCESS:
    case types.ERROR:
    case types.LOGIN_SUCCESS:
    case types.REGISTER_USER_SUCCESS:
    case types.SEND_SIGN_UP_REQUEST_SUCCESS:
    case types.SEND_SIGN_UP_REQUEST_FAILURE:
    case types.UPDATE_RESOURCE_SUCCESS:
    case types.ADD_RESOURCE_SUCCESS:
    case types.DELETE_RESOURCE_SUCCESS:
    case types.SIGNOUT_USER:
    case types.GET_RECENT_ORDERS_SUCCESS:
    case types.SEND_PUSH_NOTIFICATION_SUCCESS:
    case types.VERIFY_USER_SUCCESS:
    case types.CREATE_STRIPE_SUCCESS:
    case types.CANCEL_STRIPE_SUBSCRIPTION_SUCCESS:
    default:
      return state.set('loading', false);
  }
}
