import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  TextField, IconButton, Icon, Typography, Popover, Paper,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const DISABLE_INFO_TEXT = 'If the send button is greyed-out, than the talent user has deleted the matched connection, or the post has been deleted, or this user has left the chat.';
const MAX_INPUT_ROW = 8;

const styles = theme => ({
  inputBoxContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: '10px 20px 10px 20px',
    boxShadow: '0 -8px 30px 0 rgba(0, 0, 0, 0.05)',
  },
  textInputField: {
    width: '90%',
    borderRadius: '25px',
    backgroundColor: '#f0f0f0',

    [theme && theme.breakpoints.down('sm')]: {
      marginRight: '10px',
    },
  },
  sendButton: {
    color: '#ffffff',
    padding: '5px',
    height: '50px',
    width: '50px',
    transform: 'rotate(-45deg)',
  },
  leaveButton: {
    color: '#ffffff',
    padding: '5px',
    height: '50px',
    width: '50px',
  },
  helpOutlineIcon: {
    marginLeft: '2px',
    height: '18px',
  },
  helpPopoverText: {
    whiteSpace: 'pre-line',
  },
  helpPopover: {
    maxWidth: '400px',
    padding: '10px',
  },
});

class MessageInputBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      help: null,
      helpMsg: null,
    };
  }

  render() {
    const {
      classes,
      onClickSendButton,
      onClickLeaveButton,
      onChangeTextField,
      textFieldValue,
      disableSending,
      onKeyPress,
      showLeaveBtn,
      theme,
    } = this.props;
    const { help, helpMsg } = this.state;

    const stylesWithTheme = styles(theme);

    return (
      <div className={classes.inputBoxContainer}>
        <TextField
          placeholder="Type a message here…"
          sx={stylesWithTheme.textInputField}
          InputProps={{ disableUnderline: true }}
          inputProps={{ style: { padding: '15px' } }}
          value={textFieldValue}
          onChange={onChangeTextField}
          onKeyPress={onKeyPress}
          disabled={disableSending}
          multiline
          maxRows={MAX_INPUT_ROW}
          variant="standard"
        />
        <IconButton
          sx={stylesWithTheme.sendButton}
          style={{ backgroundColor: disableSending ? '#b3b3b3' : '#4bbdad' }}
          onClick={onClickSendButton}
          disabled={disableSending}
        >
          <Icon>send</Icon>
        </IconButton>
          { showLeaveBtn && (
            <IconButton
              sx={stylesWithTheme.leaveButton}
              style={{ backgroundColor: disableSending ? '#b3b3b3' : '#4bbdad' }}
              onClick={onClickLeaveButton}
              disabled={disableSending}
            >
              <ExitToAppIcon />
            </IconButton>
          )}
        {disableSending && (
          <HelpOutlineIcon
            sx={stylesWithTheme.helpOutlineIcon}
            onClick={e => this.setState({
              help: e.target,
              helpMsg: DISABLE_INFO_TEXT,
            })}
          />
        )}
        <Popover
          open={!!help}
          anchorEl={help}
          onClose={e => this.setState({ help: null })}
        >
          <Paper sx={stylesWithTheme.helpPopover}>
            <Typography sx={stylesWithTheme.helpPopoverText}>{helpMsg}</Typography>
          </Paper>
        </Popover>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(MessageInputBox);
