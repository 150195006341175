import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEqual, uniqBy } from 'lodash';
import {
  Paper,
  Typography,
  Hidden,
  Grid,
  Button,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import {
  VET_TYPES,
  BASE_URLS,
  jobPostingsPaginationCount,
  HQ_ADMIN_KEY,
  IMG_STORAGE_LINK,
} from '../../data/Constants';
import { commonResourceStyle } from '../../css/style';
import types from '../../actions/ActionTypes';
import LoadingBar from '../subcomponents/LoadingBar';
import SearchableSelect from '../subcomponents/SearchableSelect';
import SingleSelect from '../subcomponents/SingleSelect';
import JobPostingCard from '../subcomponents/JobPostingCard';
import { utils, isEnterpriseUser } from '../../utils/Functions';
import Pagination from '../subcomponents/Pagination';
import PracticeAutocomplete from '../subcomponents/PracticeAutocomplete';

const styles = commonResourceStyle();

const PARAM_STR = Object.freeze({
  active: 'false',
  archived: 'true',
});

class JobPostings extends Component {
  statuses = [
    {
      id: PARAM_STR.active,
      title: 'Active',
    },
    {
      id: PARAM_STR.archived,
      title: 'Archived',
    },
  ];


  constructor(props) {
    super(props);
    const postingsFilters = (props.filters && props.filters.postings) ? props.filters.postings : {};
    this.state = {
      selectedPractices: postingsFilters.selectedPractices || [],
      selectedTalentTypes: postingsFilters.selectedTalentTypes || [],
      isArchived: postingsFilters.isArchived || PARAM_STR.active,
      jobpostings: props.jobpostings,
      isEnterprise: false,
    };
  }

  componentDidMount = async () => {
    const {
      admin, actions, apiToken, adminPractices, pagination,
    } = this.props;

    let filtersObj = {};

    const isEnterprise = isEnterpriseUser(admin, actions);
    this.setState({ isEnterprise });

    let queryParams = `jobpostings${this.getFilterParams()}`;

    if (location.search) {
      filtersObj = utils.queryParamsToObject(location.search);
      queryParams = `jobpostings${location.search}`;
    }

    let fetchingOffset = 0;
    const prevOffset = sessionStorage.getItem('jobPostingOffset');
    if (prevOffset) {
      fetchingOffset = prevOffset;
      sessionStorage.removeItem('jobPostingOffset');
    } else if (pagination && pagination.start) {
      fetchingOffset = pagination.start;
    }
    queryParams += `&start=${fetchingOffset}&count=${jobPostingsPaginationCount}`;

    await actions.getAllResources(apiToken, queryParams).then((res) => {
      if (!res.error) {
        this.setState({ jobpostings: res.response.list });
      }
    });
    await actions.getAllResources(apiToken, 'badges', 'all');

    if (adminPractices && filtersObj.practices) {
      utils.setPrevFilter(filtersObj.practices, adminPractices, 'id', (selectedItems) => {
        this.setState({ ['selectedPractices']: selectedItems });
      });
    } else if (filtersObj.practices) {
      await actions.getAllResources(apiToken, BASE_URLS.getPractices).then((resp) => {
        utils.setPrevFilter(filtersObj.practices, resp.response, 'id', (selectedItems) => {
          this.setState({ ['selectedPractices']: selectedItems });
        });
      });
    } else {
      await actions.getAllResources(apiToken, BASE_URLS.getPractices);
    }

    if (filtersObj.talentTypes) {
      utils.setPrevFilter(filtersObj.talentTypes, VET_TYPES, 'key', (selectedItems) => {
        this.setState({ ['selectedTalentTypes']: selectedItems });
      });
    }

    if (filtersObj.isArchived) {
      utils.setPrevFilter(filtersObj.isArchived, this.statuses, 'id', (selectedItems) => {
        this.setState({ ['isArchived']: selectedItems[0].id });
      });
    }

    const postFilter = document.getElementById('postFilter');
    postFilter && postFilter.addEventListener('keydown', e => this.handleKeyDown(e));
  };

  componentWillUnmount() {
    const postFilter = document.getElementById('postFilter');
    postFilter && postFilter.removeEventListener('keydown', e => this.handleKeyDown(e));
  }

  componentDidUpdate = (prevProps) => {
    const { jobpostings } = this.props;
    if (!isEqual(prevProps?.resources?.jobpostings, this.props.jobpostings)) {
      const newJobPostings = jobpostings && jobpostings.filter(job => (!job.parent_job_posting_id && (this.state.isArchived === PARAM_STR.archived ? job.deletedAt : !job.deletedAt)));
      this.setState({ jobpostings: newJobPostings });
    }
  };

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.applyFilters();
    }
  };

  getFilterParams = (e) => {
    const {
      selectedPractices,
      selectedTalentTypes,
      isArchived,
    } = this.state;
    return `?practices=${selectedPractices?.map(item => item.id)?.join(',')}&talentTypes=${selectedTalentTypes?.map(item => item.key)?.join(',')}&isArchived=${isArchived || ''}`;
  };


  setQueryParams(queryParams) {
    const query = queryParams.replace('jobpostings', '');
    history.pushState(null, '', query);
  }


  applyFilters = () => {
    const {
      actions,
      apiToken,
      loading,
    } = this.props;
    const {
      selectedPractices,
      selectedTalentTypes,
      isArchived,
    } = this.state;

    if (!loading) {
      let queryParams = `jobpostings${this.getFilterParams()}`;
      this.setQueryParams(queryParams);
      queryParams += `&start=0&count=${jobPostingsPaginationCount}`;
      actions.updateLoadingState(types.VIEW_MORE_RESOURCE_REQUESTED);
      actions.getAllResources(apiToken, queryParams);
      actions.updateLoadingState(types.VIEW_MORE_RESOURCE_SUCCESS);
    }
    actions.addResourceFilter('postings', {
      selectedPractices,
      selectedTalentTypes,
      isArchived,
    });
  };

  clearFilters = () => {
    const { selectedPractices, selectedTalentTypes, isArchived } = this.state;
    if (selectedPractices.length || selectedTalentTypes.length || isArchived.length) {
      this.setState({
        selectedPractices: [],
        selectedTalentTypes: [],
        isArchived: PARAM_STR.active,
      }, () => this.applyFilters());
    }
  };

  getFilter = (items, type, title, isSingle, fullItems) => {
    const value = this.state[type];
    if (type === 'selectedPractices') {
      return (
        <PracticeAutocomplete
          handleStateChange={(val) => {
            if (val) {
              return this.setState({ [type]: val });
            }
          }}
          title={title}
          items={items}
          value={value}
          labelCount={3}
        />
      );
    } else if (isSingle) {
      return (
        <SingleSelect
          value={value}
          title={title}
          items={items}
          fullItems={fullItems}
          onChange={value => this.setState({ [type]: value }, () => this.applyFilters())}
          filter
        />
      );
    }
    return (
      <SearchableSelect
        value={value}
        title={title}
        items={items}
        onChange={value => this.setState({ [type]: value })}
        filter
      />
    );
  };

  getFilters = () => {
    const { adminPractices, classes } = this.props;
    const { selectedPractices, selectedTalentTypes, isArchived } = this.state;
    const practices = adminPractices && adminPractices.filter(x => x.active);

    return (
      <div className={classes.filterFrontContainer}>
        <Paper sx={styles.paperFilters}>
          {this.getFilter(VET_TYPES, 'selectedTalentTypes', 'All Categories')}
          {practices && this.getFilter(practices, 'selectedPractices', 'All Practices')}
          {this.getFilter(this.statuses, 'isArchived', 'Active', true, true)}
          <Button
            color="primary"
            sx={styles.searchButton}
            onClick={e => this.applyFilters()}
            disableRipple
          >
            APPLY FILTER
          </Button>
        </Paper>
        {
          (selectedPractices.length || selectedTalentTypes.length || isArchived === PARAM_STR.archived)
            ? (
              <Button
                style={styles.clearFilterText}
                onClick={() => this.clearFilters()}
                disableRipple
                variant='text'
              >
                clear filter
              </Button>
            )
            : <div />
        }
      </div>
    );
  };

  onClickViewMore = (pageNo) => {
    const {
      actions,
      apiToken,
      pagination,
      loading,
    } = this.props;
    if (!loading) {
      const start = pagination && pagination.start ? pagination.start : 0;
      let queryParams = location.search ? `jobpostings${location.search}` : `jobpostings${this.getFilterParams()}`;
      queryParams += !pageNo
        ? `&start=${start + jobPostingsPaginationCount}&count=${jobPostingsPaginationCount}`
        : `&start=${(pageNo - 1) * jobPostingsPaginationCount}&count=${jobPostingsPaginationCount}`;
      actions.getAllResources(apiToken, queryParams).then((res) => {
        if (!res.error) {
          this.setState({ jobpostings: res.response.list });
        }
      });
    }
  };

  render() {
    const {
      classes, actions, loading, apiToken, viewMoreLoading, pagination, history, resources, admin,
    } = this.props;
    const { badges } = resources;
    const { jobpostings, isEnterprise } = this.state;
    const isHQAdmin = admin && admin.adminRole === HQ_ADMIN_KEY;
    const isShowButton = isHQAdmin || (!isHQAdmin && admin.isShiftAdmin);
    let badgeTitles = {};
    if (badges) {
      badges.forEach(x => badgeTitles[x.id] = x.title);
    }
    let jobPostingList = jobpostings ? [...jobpostings].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) : [];
    jobPostingList = uniqBy(jobPostingList, 'id');

    return (
      (loading === true && viewMoreLoading === 0)
        ? <LoadingBar />
        : (
          <div style={styles.greenContainer} id='postFilter'>
            <img className={classes.resourceImage} src={`${IMG_STORAGE_LINK}search-dog-s.png`} />
            <div className={classes.resourceHeader}>
              <div style={styles.resourceNameLarge}>
                <Typography sx={{ ...styles.jobPostingResourceNameLarge, fontSize: { sx: '18px', md: '30px' } }}>
                  <img className={classes.resourceLogo} src={`${IMG_STORAGE_LINK}ic-jobs-unleashed.png`} />
                  POSTS
                </Typography>
                {isShowButton && (
                  <Hidden smDown>
                    <Button style={styles.jobPostingHeaderButton} onClick={e => history.push('/newposition')}>
                      CREATE NEW POST
                    </Button>
                  </Hidden>
                )}
              </div>
            </div>

            {this.getFilters()}
              {jobPostingList && jobPostingList.length > 0
              && (
                <Hidden smDown>
                <div style={styles.resultsContainer}>
                  <Typography style={styles.resultShownText}>
                    {`${jobPostingList.length} results shown`}
                  </Typography>
                </div>
                </Hidden>
              )}
            <Grid sx={styles.paperJobPostings} container spacing={2}>
              {jobPostingList && jobPostingList.map(item => (
                <Grid
                  item
                  key={item.id}
                  xs={12}
                  md={6}
                  lg={4}
                  xl={3}
                  style={{ backgroundColor: 'transparent', marginBottom: item.isBoostedByDates ? '8px' : 'unset' }}
                >
                  {!item.isDeleted && (
                    <JobPostingCard
                      item={item}
                      resources={resources}
                      apiToken={apiToken}
                      actions={actions}
                      badges={badgeTitles}
                      isEnterprise={isEnterprise}
                      admin={admin}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
            {
              pagination
              && (
                <Pagination
                  pagination={pagination}
                  paginationCount={jobPostingsPaginationCount}
                  onClickViewMore={this.onClickViewMore}
                />
              )
            }
            {isShowButton && (
              <div>
                <div
                  color="secondary"
                  aria-label="add"
                  className={classes.fabButton}
                  onClick={e => history.push('/newposition')}
                >
                  <AddCircleOutlineIcon />
                  Create New Post
                </div>
              </div>
            )}
          </div>
        )
    );
  }
}

JobPostings.propTypes = {
  loading: PropTypes.bool.isRequired,
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  apiToken: PropTypes.string.isRequired,
};

JobPostings.defaultProps = {};

export default withStyles(commonResourceStyle)(withRouter(JobPostings));
