import React, { useReducer, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { omit, isEmpty } from 'lodash';

import DateUtilities from './utils';
import Calendar from './Calendar';

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    minHeight: 480,
    display: 'flex',
    [theme && theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: 20,
    },
  },
  dialogPaperNoButtons: {
    minHeight: 450,
    display: 'flex',
    [theme && theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: 20,
    },
  }
}));

function initState(selectedDates, notAvailableDates, pendingDates, multipleNeeds) {
  return {
    selectedDates: selectedDates ? [...selectedDates] : [],
    notAvailableDates: notAvailableDates ? [...notAvailableDates] : [],
    pendingDates: pendingDates ? [...pendingDates] : [],
    multipleNeeds: multipleNeeds ? { ...multipleNeeds } : {},
    minDate: null,
    maxDate: null,
  };
}

function reducer(state, action) {
  switch (action.type) {
    case 'setSelectedDates':
      return { ...state, selectedDates: action.payload };
    case 'setNotAvailableDates':
      return { ...state, notAvailableDates: action.payload };
    case 'setPendingDates':
      return { ...state, pendingDates: action.payload };
    case 'setMultipleNeeds':
      return { ...state, multipleNeeds: action.payload };
    default:
      return new Error('wrong action type in multiple date picker reducer');
  }
}

const DatePicker = ({
  open,
  readOnly,
  showAllButton,
  onCancel,
  onSubmit,
  onBackdropClick,
  initialDate,
  selectedDates: outerSelectedDates,
  notAvailableDates: outerNotAvailableDates,
  pendingDates: outerPendingDates,
  multipleNeeds: outerMultipleNeeds,
  initPendingDates,
  cancelButtonText,
  submitButtonText = 'Submit',
  allButtonText = 'All',
  resetButtonText = 'Reset',
  selectedDatesTitle = 'Dates Selected:',
  coronadoErDayColor,
  disabledRange,
  referenceOnly,
  lifeCycleBadgeTitle,
  jobPostingCalendar,
  outerBoostData = [],
  isReliefPost,
  jobPostingParentId,
}) => {
  if (cancelButtonText == null) {
    cancelButtonText = readOnly ? 'Dismiss' : 'Cancel';
  }

  const [{
    selectedDates, notAvailableDates, pendingDates, multipleNeeds, minDate, maxDate,
  }, dispatch] = useReducer(
    reducer,
    [outerSelectedDates,
      outerNotAvailableDates,
      outerPendingDates,
      outerMultipleNeeds],
    initState,
  );

  const classes = useStyles();

  const onSelect = useCallback(
    (day, needsObj) => {
      if (readOnly) return;

      // Set single need state from the available/taken value set in the multiple-need dialog
      if (needsObj) {
        // Set Multiple-needs obj
        let dataObj = { ...multipleNeeds };
        if (needsObj.available + needsObj.taken + needsObj.pending > 1) {
          dataObj[needsObj.dayKey] = {
            available: needsObj.available,
            taken: needsObj.taken,
            pending: needsObj.pending,
            pendingToAvai: needsObj.pendingToAvai,
            pendingToTaken: needsObj.pendingToTaken,
            date: needsObj.selectedDate,
          };
        } else {
          dataObj = omit(dataObj, [needsObj.dayKey]);
        }

        dispatch({ type: 'setMultipleNeeds', payload: dataObj }); // add it to the selected list

        // It's still multi-need or becomes unset
        if ((needsObj.available + needsObj.taken + needsObj.pending > 1)
          || (needsObj.available + needsObj.taken + needsObj.pending === 0)) {
          handleStatusChange(day, null, null, 'setSelectedDates', selectedDates); // filter it out of the selected list
          handleStatusChange(day, null, null, 'setNotAvailableDates', notAvailableDates); // filter it out of the 'Not Avai' list
          handleStatusChange(day, null, null, 'setPendingDates', pendingDates); // filter it out of the 'Not Avai' list
        } else if (needsObj.available === 1 && needsObj.taken === 0) { // set it to 'available' single need
          if (!DateUtilities.dateIn(selectedDates, day)) {
            handleStatusChange(day, 'setSelectedDates', selectedDates); // add to selected(avai)
          }
          handleStatusChange(day, null, null, 'setNotAvailableDates', notAvailableDates); // filter it out of the 'Not Avai' list
          if (needsObj.pending === 0) {
            handleStatusChange(day, null, null, 'setPendingDates', pendingDates); // filter it out of the pending list
          }
        } else if (needsObj.available === 0 && needsObj.taken === 1) { // set it to 'taken' single need
          if (!DateUtilities.dateIn(notAvailableDates, day)) {
            handleStatusChange(day, 'setNotAvailableDates', notAvailableDates); // add to 'Not Avai' list
          }
          handleStatusChange(day, null, null, 'setSelectedDates', selectedDates); // filter it out of the selected(avai) list
          if (needsObj.pending === 0) {
            handleStatusChange(day, null, null, 'setPendingDates', pendingDates); // filter it out of the pending list
          }
        } else if (needsObj.pending === 1 && needsObj.available === 0 && needsObj.taken === 0) { // set it to 'pending' single need
          if (!DateUtilities.dateIn(pendingDates, day)) {
            handleStatusChange(day, 'setPendingDates', pendingDates); // add to 'Pending' list
          }
          handleStatusChange(day, null, null, 'setSelectedDates', selectedDates); // filter it out of the selected(avai) list
          handleStatusChange(day, null, null, 'setNotAvailableDates', notAvailableDates); // filter it out of the 'Not Avai' list
        }
      } else if (!isEmpty(initPendingDates) && DateUtilities.dateIn(initPendingDates, day)) { // toggling: pending --> taken --> selected(avai)
        if (DateUtilities.dateIn(pendingDates, day)) { // pending --> taken
          handleStatusChange(day, 'setNotAvailableDates', notAvailableDates, 'setPendingDates', pendingDates);
        } else if (DateUtilities.dateIn(notAvailableDates, day)) { // taken --> selected(avai)
          handleStatusChange(day, 'setSelectedDates', selectedDates, 'setNotAvailableDates', notAvailableDates);
        } else if (DateUtilities.dateIn(selectedDates, day)) { // selected (avai) --> pending
          handleStatusChange(day, 'setPendingDates', pendingDates, 'setSelectedDates', selectedDates);
        }
      } else { // keep exsiting logic (toggling available/taken/unset)
        // eslint-disable-next-line no-lonely-if
        if (DateUtilities.dateIn(selectedDates, day)) { // selected(avai) --> taken
          handleStatusChange(day, 'setNotAvailableDates', notAvailableDates, 'setSelectedDates', selectedDates);
        } else if (DateUtilities.dateIn(notAvailableDates, day)) { // taken --> unset
          handleStatusChange(day, null, null, 'setNotAvailableDates', notAvailableDates);
        } else { // unset --> selected (avai)
          handleStatusChange(day, 'setSelectedDates', selectedDates);
        }
      }
    },
    [selectedDates, notAvailableDates, pendingDates, dispatch, readOnly],
  );

  const handleStatusChange = (
    day,
    toAddType,
    toAddList,
    toRemoveType,
    toRemoveList,
  ) => {
    if (!day) return;
    if (toAddType && toAddList) {
      dispatch({
        type: toAddType,
        payload: [...toAddList, day],
      });
    }
    if (toRemoveType && toRemoveList) {
      dispatch({
        type: toRemoveType,
        payload: toRemoveList.filter(date => !DateUtilities.isSameDay(date, day)),
      });
    }
  };

  const onRemoveAtIndex = useCallback(
    (index) => {
      if (readOnly) return;
      const newDates = [...selectedDates];
      if (index > -1) {
        newDates.splice(index, 1);
      }

      dispatch({ type: 'setSelectedDates', payload: newDates });
    },
    [selectedDates, dispatch, readOnly],
  );

  const dismiss = useCallback(
    () => {
      dispatch({ type: 'setSelectedDates', payload: [] });
      dispatch({ type: 'setNotAvailableDates', payload: [] });
      dispatch({ type: 'setPendingDates', payload: [] });
      dispatch({ type: 'setMultipleNeeds', payload: {} });
      onCancel();
    },
    [dispatch, onCancel],
  );

  const selectAllDates = useCallback(
    (dates) => {
      dispatch({ type: 'setSelectedDates', payload: [...selectedDates, ...dates] });
      dispatch({ type: 'setNotAvailableDates', payload: [] });
      dispatch({ type: 'setPendingDates', payload: [] });
      dispatch({ type: 'setMultipleNeeds', payload: {} });
      onCancel();
    },
    [selectedDates, dispatch, onCancel],
  );

  const handleCancel = useCallback(
    (e) => {
      e.preventDefault();
      dismiss();
    },
    [dismiss],
  );

  const handleOk = useCallback(
    (e, boostData) => {
      e.preventDefault();
      if (readOnly) return;
      onSubmit(selectedDates, notAvailableDates, pendingDates, multipleNeeds, boostData);
    },
    [onSubmit, selectedDates, notAvailableDates, pendingDates, multipleNeeds, readOnly],
  );

  useEffect(
    () => {
      if (open) {
        dispatch({
          type: 'setSelectedDates',
          payload: outerSelectedDates != null ? outerSelectedDates : [],
        });
        dispatch({
          type: 'setNotAvailableDates',
          payload: outerNotAvailableDates != null ? outerNotAvailableDates : [],
        });
        dispatch({
          type: 'setPendingDates',
          payload: outerPendingDates != null ? outerPendingDates : [],
        });
        dispatch({
          type: 'setMultipleNeeds',
          payload: outerMultipleNeeds != null ? outerMultipleNeeds : {},
        });
      }
    },
    [open, outerSelectedDates, outerNotAvailableDates, outerPendingDates, outerMultipleNeeds],
  );

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          onBackdropClick();
        }
      }}
      classes={{ paper: referenceOnly ? classes.dialogPaperNoButtons : classes.dialogPaper }}
    >
      <Calendar
        selectedDates={selectedDates}
        initialDate={initialDate}
        notAvailableDates={notAvailableDates}
        pendingDates={pendingDates}
        initPendingDates={initPendingDates}
        multipleNeeds={multipleNeeds}
        onSelect={onSelect}
        selectAllDates={selectAllDates}
        onRemoveAtIndex={onRemoveAtIndex}
        onRemoveAll={dismiss}
        minDate={minDate}
        maxDate={maxDate}
        onCancel={handleCancel}
        onOk={(e, boostData) => handleOk(e, boostData)}
        readOnly={readOnly}
        showAllButton={showAllButton}
        cancelButtonText={cancelButtonText}
        submitButtonText={submitButtonText}
        allButtonText={allButtonText}
        resetButtonText={resetButtonText}
        selectedDatesTitle={selectedDatesTitle}
        disabledRange={disabledRange}
        coronadoErDayColor={coronadoErDayColor}
        lifeCycleBadgeTitle={lifeCycleBadgeTitle}
        referenceOnly={referenceOnly}
        jobPostingCalendar={jobPostingCalendar}
        outerBoostData={outerBoostData}
        isReliefPost={isReliefPost}
        jobPostingParentId={jobPostingParentId}
      />
    </Dialog>
  );
};

DatePicker.propTypes = {
  open: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectedDates: PropTypes.array,
  notAvailableDates: PropTypes.array,
  pendingDates: PropTypes.array,
  multipleNeeds: PropTypes.object,
  cancelButtonText: PropTypes.string,
  submitButtonText: PropTypes.string,
  selectedDatesTitle: PropTypes.string,
  disabledRange: PropTypes.number,
  coronadoErDayColor: PropTypes.string,
  referenceOnly: PropTypes.bool,
};

DatePicker.defaultProps = {
  onCancel: () => {},
  onSubmit: () => {},
  open: false,
  referenceOnly: false,
};

export default DatePicker;
