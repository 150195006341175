import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { Button, DialogActions, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { isEmpty } from 'lodash';
import { orange, green, gray, navy } from '../../css/style';

const styles = theme => ({
  root: {
    borderTop: 'solid 1px #f4f4f4',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 10px 8px 10px',
    width: '95%',

    [theme && theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tag: {
    margin: '0 5px 0 10px',
    width: '15px',
    height: '15px',
    borderRadius: '2px',
  },
  tagContainer: {
    fontFamily: 'Lato',
    fontSize: '12px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#000000',
    display: 'flex',

    [theme && theme.breakpoints.down('xs')]: {
      margin: '8px',
    },
  },
  resetText: {
    color: '#243060',
    border: '1px solid #243060',
  },
  submitButton: {
    color: '#ffffff',
    borderRadius: '5px',
    backgroundColor: '#243060',

    '&:hover': {
      backgroundColor: '#243060',
      color: '#ffffff',
    },
    '&:disabled': {
      backgroundColor: '#f0f0f0',
    },
  },
  button: {
    width: '25%',
    height: '40px',
    fontFamily: 'Asap',
    fontSize: '12px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    borderRadius: '5px',
    marginRight: '10px',
  },
  allText: {
    color: '#ffffff',
    backgroundColor: `${green}`,
    border: `1px solid ${green}`,

    '&:hover': {
      backgroundColor: `${green}`,
      color: '#ffffff',
    },
  },
});

const CalendarActionButton = ({
  submitButtonText,
  allButtonText,
  resetButtonText,
  onOk,
  readOnly,
  showAllButton,
  onRemoveAll,
  onSelectAll,
  selectedDates,
  initPendingDates,
  classes,
  theme,
  coronadoErDayColor,
  referenceOnly,
  boostDetailsInvalid = false,
}) => {
  const resetSelected = () => selectedDates.map((date, index) => onRemoveAll(index));
  const shouldShowResetButton = selectedDates && selectedDates.length > 0 && !readOnly;
  const shouldShowAllButton = showAllButton && !readOnly;
  const stylesWithTheme = styles(theme);
  if (referenceOnly) {
    return (
      <DialogActions sx={stylesWithTheme.root} style={{ height: '40px', justifyContent: 'center' }}>
        <Typography
          variant='subtitle1'
          sx={{
            fontSize: '16px',
            fontWeight: 'bold',
            color: navy,
          }}
        >
          Reference Calendar - Read Only
        </Typography>
      </DialogActions>
    );
  }

  return (
    <DialogActions sx={stylesWithTheme.root}>
      <div className={classes.tagContainer}>
        <div className={classes.tag} style={{ backgroundColor: coronadoErDayColor || green }} />
        AVAILABLE
        <div className={classes.tag} style={{ backgroundColor: orange }} />
        TAKEN
        {!isEmpty(initPendingDates) && (
          <Fragment>
            <div className={classes.tag} style={{ backgroundColor: gray }} />
            <span>PENDING</span>
          </Fragment>
        )}
      </div>

      <div className={classes.actionContainer}>
        {shouldShowResetButton
        && (
          <Button
            sx={{ ...stylesWithTheme.button, ...stylesWithTheme.resetText }}
            onClick={resetSelected}
          >
            {resetButtonText}
          </Button>
        )}
        {shouldShowAllButton
        && (
            <Button
              sx={{ ...stylesWithTheme.button, ...stylesWithTheme.allText }}
              onClick={onSelectAll}
            >
              {allButtonText}
            </Button>
        )}
        {!readOnly
        && (
          <Button
            onClick={onOk}
            sx={{ ...stylesWithTheme.button, ...stylesWithTheme.submitButton }}
            disabled={boostDetailsInvalid}
          >
            {submitButtonText}
          </Button>
        )}
      </div>
    </DialogActions>
  );
};
export default withStyles(styles, { withTheme: true })(withRouter(CalendarActionButton));
