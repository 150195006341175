import { Map } from 'immutable';
import types from '../actions/ActionTypes';

export default function AuthReducer(state = Map({ admin: null, stripeEnabled: false }), action) {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
    case types.VERIFY_USER_SUCCESS:
    case types.REGISTER_USER_SUCCESS:
      localStorage.setItem('admin', JSON.stringify(action.loggedInAdmin));
      return state.set('admin', action.loggedInAdmin);
    case types.UPDATE_ADMIN_SUCCESS:
      const currentAdmin = JSON.parse(localStorage.getItem('admin'));
      const admin = Object.assign(currentAdmin, action.loggedInAdmin);
      localStorage.setItem('admin', JSON.stringify(admin));
      return state.set('admin', admin);
    case types.LOGIN_FAILURE:
      return state.set('admin', null);
    case types.REGISTER_USER_FAILURE:
    case types.SEND_SIGN_UP_REQUEST_SUCCESS:
    case types.SEND_SIGN_UP_REQUEST_FAILURE:
    case types.UPDATE_ADMIN_FAILURE:
    case types.SIGNOUT_USER:
      return state.merge({ admin: null, stripeEnabled: false });
    case types.SET_ADMIN:
      return state.set('admin', action.admin);
    case types.SET_STRIPE_ENABLED:
      return state.set('stripeEnabled', action.stripeEnabled);
    default:
      return state;
  }
}
