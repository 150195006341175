import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  hoverEffectOne,
  hoverEffectTwo,
  navy,
  tiffany,
  orange,
} from '../css/style';
import {
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Icon,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { loadStripe } from '@stripe/stripe-js';

const subscriptionPlanStyles = theme => ({
  rightContainer: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme && theme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'flex-start',
    },
  },
  content: {
    margin: '50px 20%',
    [theme && theme.breakpoints.down('sm')]: {
      margin: '50px',
    },
    [theme && theme.breakpoints.down('md')]: {
      margin: '50px',
    },
  },
  title: {
    color: `${navy}`,
    fontSize: '30px',
    fontWeight: 'bold',
  },
  buttonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    margin: '40px',
  },
  buttonLeft: {
    border: `1px solid ${tiffany}`,
    borderRight: 'none',
    color: `${tiffany}`,
    borderRadius: '4px 0 0 4px',
    width: '150px',
    ...hoverEffectOne,
  },
  buttonLeftSelected: {
    border: `1px solid ${tiffany}`,
    borderRight: 'none',
    color: '#ffffff',
    borderRadius: '4px 0 0 4px',
    width: '150px',
    backgroundColor: `${tiffany}`,
    ...hoverEffectOne,
  },
  buttonRight: {
    border: `1px solid ${tiffany}`,
    color: `${tiffany}`,
    borderRadius: '0 4px 4px 0',
    width: '150px',
    ...hoverEffectOne,
  },
  buttonRightSelected: {
    border: `1px solid ${tiffany}`,
    color: '#ffffff',
    borderRadius: '0 4px 4px 0',
    width: '150px',
    backgroundColor: `${tiffany}`,
    ...hoverEffectOne,
  },
  card: {
    minHeight: '260px',
    borderRadius: '5px',
    border: `solid 1px ${navy}`,
    borderTop: `solid 5px ${navy}`,
  },
  planTitle: {
    fontWeight: 'bold',
    color: `${navy}`,
    textAlign: 'center',
    fontSize: '20px',
  },
  planDescription: {
    fontSize: '12px',
    fontStyle: 'italic',
    textAlign: 'center',
    color: `${navy}`,
  },
  priceDescription: {
    fontSize: '12px',
    fontWeight: 'normal',
    fontStyle: 'italic',
    textAlign: 'center',
    color: `${orange}`,
  },
  enterpriseDescription: {
    fontSize: '18px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: `${navy}`,
    lineHeight: 1.3,
    margin: '45px 0',
  },
  planOriginalPrice: {
    fontSize: '24px',
    color: `${navy}`,
    position: 'absolute',
    marginTop: '15px',
  },
  lineThrough: {
    background: 'red',
    height: '2px',
    marginBottom: '-19px',
  },
  price: {
    fontSize: '48px',
    fontWeight: '500',
    textAlign: 'center',
    color: `${navy}`,
  },
  planText: {
    fontSize: '12px',
    textAlign: 'center',
    width: '60%',
    margin: 'auto',
    color: '#000000',
  },
  trialButton: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#ffffff',
    backgroundColor: `${orange}`,
    display: 'flex',
    width: '90%',
    margin: 'auto',
    marginTop: '15px',
    ...hoverEffectOne,
  },
  enterpriseButton: {
    fontSize: '12px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#ffffff',
    backgroundColor: `${navy}`,
    display: 'flex',
    width: '90%',
    margin: 'auto',
    marginTop: '15px',
    ...hoverEffectTwo,
  },
  backButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '30px',
  },
  backContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
  },
  backIcon: {
    color: `${navy}`,
    fontSize: '14px',
  },
  back: {
    color: `${navy}`,
    fontSize: '14px',
  },
});

const ENTERPRISE = 'ENTERPRISE';

const annualSubscriptionPlans = [
  {
    title: 'SMALL',
    description: '1-10 staff',
    originalPrice: '$420',
    price: '$264',
    priceDescription: 'Founder Pricing',
    text: 'per year/practice or $35 monthly',
  },
  {
    title: 'MEDIUM',
    description: '11-30 staff',
    originalPrice: '$540',
    price: '$264',
    priceDescription: 'Founder Pricing',
    text: 'per year/practice or $35 monthly',
  },
  {
    title: 'LARGE',
    description: '31+ staff',
    originalPrice: '$1200',
    price: '$264',
    priceDescription: 'Founder Pricing',
    text: 'per year/practice or $35 monthly',
  },
  {
    title: ENTERPRISE,
    description: 'Let\'s talk about how we can integrate with your current hiring systems',
  },
];

const monthlySubscriptionPlans = [
  {
    title: 'SMALL',
    description: '1-10 staff',
    originalPrice: '$60',
    price: '$35',
    priceDescription: 'Founder Pricing',
    text: 'per month/practice',
  },
  {
    title: 'MEDIUM',
    description: '11-30 staff',
    originalPrice: '$120',
    price: '$35',
    priceDescription: 'Founder Pricing',
    text: 'per month/practice',
  },
  {
    title: 'LARGE',
    description: '31+ staff',
    originalPrice: '$250',
    price: '$35',
    priceDescription: 'Founder Pricing',
    text: 'per month/practice',
  },
  {
    title: ENTERPRISE,
    description: 'Let\'s talk about how we can integrate with your current hiring systems',
  },
];

let stripe;

class SubscriptionPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMonthly: true,
    };
  }

  componentDidMount = async () => {
    const { admin, actions } = this.props;
    const res = await actions.getAllResources(admin.token, 'stripe-payment');
    if (!res.error) {
      stripe = await loadStripe(res.response.publishableKey);
    }
  };

  handleClick = (planTitle) => {
    const { resources, actions, admin } = this.props;
    const { isMonthly } = this.state;
    const {
      largeAPID, largeMPID, mediumAPID, mediumMPID, smallAPID, smallMPID,
    } = resources['stripe-payment'];
    let plan;

    switch (planTitle) {
      case 'SMALL':
        plan = isMonthly ? smallMPID : smallAPID;
        break;
      case 'MEDIUM':
        plan = isMonthly ? mediumMPID : mediumAPID;
        break;
      case 'LARGE':
        plan = isMonthly ? largeMPID : largeAPID;
        break;
      default:
        window.location.assign('https://www.jobsunleashed.com/employers/contact-us');
        break;
    }

    actions.createStripe(admin.token, {
      priceId: plan,
      email: admin.email,
      referenceId: admin.accessList[0].stripeCustomerId,
    }, 'stripe-payment', 'sessions')
      .then((res) => {
        if (!res.error) {
          return stripe.redirectToCheckout({
            sessionId: res.response.sessionId,
          });
        }
      });
  };

  renderPlanDetails = () => {
    const { classes, theme } = this.props;
    const { isMonthly } = this.state;
    const plans = isMonthly ? monthlySubscriptionPlans : annualSubscriptionPlans;
    const stylesWithTheme = subscriptionPlanStyles(theme);

    return plans.map(plan => (
      <Grid key={plan.title} item xs={12} md={6}>
        <Card sx={stylesWithTheme.card}>
          <CardContent>
              <Typography sx={stylesWithTheme.planOriginalPrice}>
                <div className={classes.lineThrough} />
                {plan.originalPrice}
              </Typography>
            <Typography sx={stylesWithTheme.planTitle}>{plan.title}</Typography>
            <Typography sx={plan.title === ENTERPRISE ? stylesWithTheme.enterpriseDescription : stylesWithTheme.planDescription}>
              {plan.description}
            </Typography>
            <Typography sx={stylesWithTheme.priceDescription}>{plan.priceDescription}</Typography>
            <Typography sx={stylesWithTheme.price}>{plan.price}</Typography>
            <Typography sx={stylesWithTheme.planText}>{plan.text}</Typography>
            <Button
              sx={plan.title === ENTERPRISE ? stylesWithTheme.enterpriseButton : stylesWithTheme.trialButton}
              onClick={() => this.handleClick(plan.title)}
            >
              {plan.title === ENTERPRISE ? 'DROP US A LINE' : 'START A FREE TRIAL'}
            </Button>
          </CardContent>
        </Card>
      </Grid>
    ));
  };

  render() {
    const { classes, theme } = this.props;
    const { isMonthly } = this.state;
    const stylesWithTheme = subscriptionPlanStyles(theme);

    return (
      <div className={classes.rightContainer}>
        <div className={classes.content}>
          <Typography sx={stylesWithTheme.title}>
            Sign up for a 30 day free trial.
          </Typography>
          <Typography sx={stylesWithTheme.title}>
            How big is your current practice?
          </Typography>
          <div className={classes.buttonContainer}>
            <Button
              variant="outlined"
              sx={!isMonthly ? stylesWithTheme.buttonLeftSelected : stylesWithTheme.buttonLeft}
              onClick={() => this.setState({ isMonthly: false })}
            >
              ANNUAL
            </Button>
            <Button
              variant="outlined"
              sx={!isMonthly ? stylesWithTheme.buttonRight : stylesWithTheme.buttonRightSelected}
              onClick={() => this.setState({ isMonthly: true })}
            >
              MONTHLY
            </Button>
          </div>
          <Grid container spacing={1} justify="center" alignItems="center">
            {this.renderPlanDetails()}
          </Grid>
          <div className={classes.backButton}>
            <div
              className={classes.backContainer}
              onClick={() => {
                localStorage.removeItem('admin');
                location.href = '/signup';
              }}
            >
              <Icon sx={stylesWithTheme.backIcon}>keyboard_backspace</Icon>
              <Typography sx={stylesWithTheme.back}>BACK</Typography>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(subscriptionPlanStyles, { withTheme: true })(SubscriptionPlan));
