import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { capitalize, isEmpty, isEqual } from 'lodash';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Radio,
  Button,
  Badge,
  Icon,
  RadioGroup,
  FormControlLabel,
  Grid,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import { capitalizeFirstLetter } from '../Datepicker/utils';
import Circle from '../Datepicker/Circle';
import { dateTimeFormat } from '../Datepicker/dateUtils';
import UserAutocomplete from './UserAutocomplete';
import {
  navy,
  green,
  orange,
  gray,
  darkRed,
  dialogCloseButtonStyle,
  dialogFormRowStyle,
} from '../../css/style';
import TextInput from './TextInput';
import { validateEmail } from '../../utils/Functions';

const MultipleNeedsDialogStyles = theme => ({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '16px',
    marginBottom: '16px',

    '& .MuiBadge-badge': {
      height: '40px',
      width: '40px',
      borderRadius: '50%',
      fontSize: '14px',
      fontWeight: 'bold',
    },

    '& .MuiBadge-anchorOriginBottomLeftCircle': {
      left: '50%',
      bottom: '0%',
    },

    '& .MuiBadge-colorPrimary': {
      color: '#fff',
      backgroundColor: `${green}`,
    },

    '& .MuiBadge-colorSecondary': {
      color: '#fff',
      backgroundColor: `${gray}`,
      left: '50%',
      bottom: 0,
    },

    '& .MuiSvgIcon-colorPrimary': {
      color: `${green}`,
    },

    '& .MuiBadge-colorError': {
      color: '#fff',
      backgroundColor: `${orange}`,
    },

    '& .MuiSvgIcon-colorError': {
      color: `${orange}`,
    },

    '& .MuiButtonGroup-grouped': {
      minWidth: '24px',
    },
    '& .MuiButton-outlined': {
      padding: '5px',
      border: 'none',
      width: '40px',
    },
  },
  titleRoot: {
    '&.MuiDialogTitle-root': {
      padding: '8px 24px',
    },
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: navy,
    textAlign: 'center',
  },
  closeButton: {
    ...dialogCloseButtonStyle,
    color: theme.palette.grey[500],
  },
  icon: {
    height: '30px',
    width: '30px',
    padding: '12px',
    textAlign: 'center',
    margin: '20px 0',
    border: 'solid 3px #4bbdad',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#4bbdad',
  },
  dialogActions: {
    justifyContent: 'center',
    padding: '8px 25px',
    margin: 0,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '16px 24px',
  },
  button: {
    backgroundColor: darkRed,
    color: 'white',
    width: '100%',
    margin: 0,
    padding: '14px',
    '&:hover': {
      backgroundColor: darkRed,
      color: '#ffffff',
    },
    '&:disabled': {
      backgroundColor: '#c7c7c7',
    },
  },
  formRow: {
    ...dialogFormRowStyle,
    width: 500,
    alignItems: 'center',
    justifyContent: 'center',
    [theme && theme.breakpoints.down('sm')]: {
      width: 200,
    },
  },
  formLabel: {
    '& label': {
      lineHeight: 'normal',
      padding: '14px 0',
    },
  },
  shiftLabel: {
    fontFamily: 'Asap',
    fontSize: '16px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: navy,
    minWidth: '100px',
  },
  day: {
    margin: `0 ${theme.spacing(1)}`,
    fontFamily: 'Asap',
    fontSize: '24px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#000000',
  },
  centerSubTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    margin: '8px 0',
    height: 16,
  },
  navigateIcon: {
    cursor: 'pointer',
    fontSize: '24px',
    fontWeight: 'bold',
    color: darkRed,
  },
  dialogFooter: {
    display: 'flex',
    alignItems: 'center',
    height: 40,
  },
});

const resetKeyObj = {
  known_taken: ['selectedAgencyUser', 'selectedOtherUser'],
  agency_taken: ['selectedTalentUser', 'selectedOtherUser'],
  unknown_taken: ['selectedAgencyUser', 'selectedTalentUser'],
};

const pairKeyObj = {
  known_taken: 'selectedTalentUser',
  agency_taken: 'selectedAgencyUser',
  unknown_taken: 'selectedOtherUser',
};

class CalendarConfirmationDialog extends Component {
  constructor(props) {
    super(props);
    const { confirmTakenDates = [] } = props;

    this.state = {
      currentStep: 1,
      totalStep: confirmTakenDates ? confirmTakenDates.length : 1,
      confirmTakenDates,
      isInValid: true,
    };
  }

  componentDidMount = () => {
    const {
      talentList = [],
      talentUserId,
    } = this.props;
    const {
      confirmTakenDates = [],
      currentStep,
      totalStep,
    } = this.state;

    // Set default selected user for Messages screen
    if (talentUserId) {
      let foundTalent = talentList.find(t => t.id === talentUserId);
      let category;
      if (foundTalent) {
        category = 'known_taken';
      }

      if (category) {
        confirmTakenDates.forEach((pageData) => {
          if (category === 'known_taken') {
            pageData.selectedTalentUser = foundTalent;
          } else {
            pageData.selectedAgencyUser = foundTalent;
          }
          pageData.confirmationCategory = category;
        });
      }
      this.setState({ confirmTakenDates });
    }

    // check form valid on loading for only 1 untaken
    if (currentStep === totalStep && confirmTakenDates.length && confirmTakenDates[0].isUntaken) {
      this.setState({ isInValid: !confirmTakenDates[0].untakenRowId });
    }
  };

  handleSave() {
    const { handleCallback } = this.props;
    const { confirmTakenDates } = this.state;
    const errorMessage = this.checkRequired();

    if (!errorMessage) {
      confirmTakenDates.forEach((item) => {
        if (item.isUntaken) {
          const selectedRow = item.takenDataRows.find(row => row.id === item.untakenRowId);
          item.confirmation = selectedRow?.confirmation;
          item.talentUserId = selectedRow?.talent_user_id;
          item.confirmationCategory = 'untaken';
        } else {
          switch (item.confirmationCategory) {
            case 'known_taken':
              item.confirmation = item.selectedTalentUser.name;
              item.talentUserId = item.selectedTalentUser.id;
              break;
            case 'agency_taken':
              if (item.selectedAgencyUser.code === 'OTHER') {
                item.confirmation = `other_${item.selectedAgencyUser.option}`;
              } else {
                item.confirmation = item.selectedAgencyUser.name;
              }
              item.talentUserId = null;
              break;
            case 'unknown_taken':
              item.confirmation = `${item.selectedOtherUser.name} (${item.selectedOtherUser.email})`;
              item.talentUserId = null;
              break;
            default:
              item.confirmation = 'other non-Unleashed user';
              item.talentUserId = null;
              break;
          }
        }
      });

      handleCallback(confirmTakenDates);
    }
  }

  handleClose() {
    const { handleCallback, setDefaultOnCancel } = this.props;
    const { confirmTakenDates } = this.state;

    if (setDefaultOnCancel) {
      confirmTakenDates.forEach((item) => {
        item.confirmationCategory = 'known_taken';
        item.confirmation = item.selectedTalentUser.name;
        item.talentUserId = item.selectedTalentUser.id;
      });
      handleCallback(confirmTakenDates);
    } else {
      handleCallback();
    }
  }

  checkRequired() {
    const { currentStep, confirmTakenDates } = this.state;
    const currentStepData = confirmTakenDates[currentStep - 1];
    let errorMessage;
    let errorKey;
    if (currentStepData.isUntaken) {
      errorMessage = !currentStepData.untakenRowId ? 'Please select an untaken user' : '';
      errorKey = 'selectedUntakenError';
    } else {
      const checkingKey = pairKeyObj[currentStepData.confirmationCategory];
      errorKey = `${checkingKey}Error`;
      errorMessage = checkingKey && isEmpty(currentStepData[checkingKey]) ? 'Please select a user' : '';
      const checkOtherAgency = checkingKey === 'selectedAgencyUser' && currentStepData && currentStepData.selectedAgencyUser && currentStepData.selectedAgencyUser.code === 'OTHER' && isEmpty(currentStepData.selectedAgencyUser.option);
      if (checkOtherAgency) errorMessage = 'Please enter the agency name';

      if (checkingKey === 'selectedOtherUser') {
        errorMessage = this.checkOtherUserInput(currentStepData.selectedOtherUser?.name, currentStepData.selectedOtherUser?.email);
      }
    }

    this.setState({ [errorKey]: errorMessage });
    return errorMessage;
  }

  // Navigate to previous/next step
  go(back) {
    const { currentStep, totalStep } = this.state;
    const errorMessage = this.checkRequired();
    if (!errorMessage) {
      const step = back ? (currentStep - 1) : (currentStep + 1);
      this.setState({ currentStep: step }, () => {
        if (step === totalStep) {
          this.checkFormValid();
        }
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { confirmTakenDates = [] } = nextProps;

    if (!isEqual(prevState.confirmTakenDates, confirmTakenDates)) {
      return {
        totalStep: confirmTakenDates.length,
        confirmTakenDates,
      };
    }
    return null;
  }

  checkOtherUserInput(name, email) {
    let error = '';

    if (name && email) {
      if (!validateEmail(email)) {
        error = 'The email is invalid';
      }
      return error;
    }

    error = error = !name && !email
      ? 'Please input user name and email' : `Please input ${!name ? 'name' : 'email'}`;
    return error;
  }

  checkFormValid() {
    const { currentStep, totalStep, confirmTakenDates } = this.state;
    let isInValid = this.state.isInValid;
    if (confirmTakenDates.length > 0) {
      if (currentStep === totalStep) {
        const current = confirmTakenDates[currentStep - 1];
        if (current.isUntaken) {
          isInValid = !current.untakenRowId;
        } else if (current.confirmationCategory === 'unknown_taken'
            && current.selectedOtherUser?.name && current.selectedOtherUser?.email
            && validateEmail(current.selectedOtherUser?.email)) {
          isInValid = false;
        } else if (current.confirmationCategory === 'known_taken' && !isEmpty(current.selectedTalentUser)) {
          isInValid = false;
        } else if (current.confirmationCategory === 'agency_taken' && !isEmpty(current.selectedAgencyUser) && (current.selectedAgencyUser.code !== 'OTHER' || (current.selectedAgencyUser.code === 'OTHER' && !isEmpty(current.selectedAgencyUser.option)))) {
          isInValid = false;
        } else {
          isInValid = true;
        }
      }
    }

    this.setState({ isInValid: isInValid ? true : false });
  }

  renderOTherFields = (currentStepData, fieldName) => {
    const {
      currentStep,
      confirmTakenDates = [],
    } = this.state;

    return (
      <TextInput
        value={currentStepData?.selectedOtherUser ? currentStepData?.selectedOtherUser[fieldName] : ''}
        title={capitalize(fieldName)}
        onChange={(val) => {
          const selectedOtherUser = Object.assign({}, currentStepData.selectedOtherUser);
          selectedOtherUser[fieldName] = val;

          confirmTakenDates[currentStep - 1] = {
            ...currentStepData,
            selectedOtherUser,
          };

          this.setState({
            selectedOtherUserError: this.checkOtherUserInput(selectedOtherUser?.name, selectedOtherUser?.email),
          });

          this.checkFormValid();

          this.setState({ confirmTakenDates });
        }}
        disabled={currentStepData.confirmationCategory !== 'unknown_taken'}
        key={`other_${fieldName}_${currentStep}`}
      />
    );
  };

  render() {
    const {
      classes,
      open,
      talentList = [],
      agencyList = [],
      theme,
      coronadoErDayColor,
    } = this.props;
    const {
      currentStep,
      totalStep,
      confirmTakenDates = [],
      selectedTalentUserError,
      selectedAgencyUserError,
      selectedOtherUserError,
      selectedUntakenError,
    } = this.state;

    const dayInNumeric = new dateTimeFormat('en-US', {
      day: 'numeric',
    });

    if (isEmpty(confirmTakenDates)) return null;
    const currentStepData = confirmTakenDates[currentStep - 1];
    currentStepData.confirmationCategory = currentStepData.confirmationCategory || 'known_taken';
    const dateTimeFormatted = moment(currentStepData.date).format('MMMM YYYY');
    const stylesWithTheme = MultipleNeedsDialogStyles(theme);

    return (
      <Dialog
        maxWidth="md"
        PaperProps={{
          style: {
            padding: '24px 4px',
            maxWidth: '660px',
          },
        }}
        open={open}
        // onClose={() => this.handleClose()}
        sx={stylesWithTheme.root}
      >
        <DialogTitle sx={stylesWithTheme.titleRoot}>
          <Typography variant='subtitle1' sx={stylesWithTheme.title}>{capitalizeFirstLetter(dateTimeFormatted)}</Typography>
        </DialogTitle>

        <DialogContent sx={stylesWithTheme.dialogContent}>
          <div className={classes.contentContainer}>
            { currentStepData.hasMultipleNeeds ? (
              <Badge
                key={`dialog_avai_icon_${currentStep}`}
                overlap="circular"
                badgeContent={currentStepData.available || '0'}
                color="primary"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                size="large"
                sx={{
                  '& .MuiBadge-colorPrimary': {
                    backgroundColor: coronadoErDayColor || `${green}`,
                  },
                }}
              >
                <Badge
                  key={`dialog_taken_icon_${currentStep}`}
                  overlap="circular"
                  badgeContent={currentStepData.taken || '0'}
                  color='error'
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <Badge
                    key={`pending_icon_${currentStep}`}
                    overlap="circular"
                    badgeContent={currentStepData.pending || null}
                    color="secondary"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <Circle
                      key={`dialog_day-${currentStep}`}
                      label={dayInNumeric.format(currentStepData.date)}
                      hasMultipleNeeds
                      hasMultipleTakensOnly={currentStepData.hasMultipleTakensOnly}
                      style={stylesWithTheme.day}
                      isUntaken={currentStepData.isUntaken}
                      enlarged
                      coronadoErDayColor={coronadoErDayColor}
                    />
                  </Badge>
                </Badge>
              </Badge>
            ) : (
                <Circle
                  key={`dialog_day-${currentStep}`}
                  label={dayInNumeric.format(currentStepData.date)}
                  style={stylesWithTheme.day}
                  isNotAvailable
                  enlarged
                  isUntaken={currentStepData.isUntaken}
                />
            )}
          </div>
          { currentStepData.isUntaken
            && (
                <RadioGroup
                  aria-label='untaken confirmation'
                  name='untakenConfirmation'
                  row
                  value={Number(currentStepData.untakenRowId)}
                  key={`untaken_${currentStep}`}
                  onChange={(e) => {
                    if (e.target.value) {
                      confirmTakenDates[currentStep - 1] = {
                        ...currentStepData,
                        untakenRowId: Number(e.target.value),
                      };
                      this.checkFormValid();
                      this.setState({ confirmTakenDates, selectedUntakenError: '' });
                    }
                  }}
                  justifycontent='center'
                >
                <Grid container>
                  <Grid item xs={12} sx={stylesWithTheme.centerSubTitle}>
                    <Typography sx={stylesWithTheme.shiftLabel}>
                      { currentStepData.shift ? `Untaken Shift ${currentStepData.shift}` : 'Untaken' }
                    </Typography>
                  </Grid>
                  <Grid item xs={1} key={`${currentStep}_before_title`}>&nbsp;</Grid>
                  <Grid item xs={10} style={{ marginTop: 16 }}>
                    <Typography>This previously taken shift that we are removing was assigned to:  </Typography>
                  </Grid>
                  <Grid item xs={1} key={`${currentStep}_after_title`}>&nbsp;</Grid>
                  {currentStepData?.takenDataRows?.map(row => (
                    <Grid container key={`container_${row.id}_${currentStep}`}>
                      <Grid item xs={1} key={`${row.id}_${currentStep}_before`}>&nbsp;</Grid>
                      <Grid
                        item
                        xs={10}
                        key={`${row.id}_${currentStep}`}
                      >
                        <FormControlLabel
                          value={row.id}
                          label={(
                          <Typography
                            align='right'
                            key={`${row.id}_label_${currentStep}`}
                          >
                            {row.confirmation}
                          </Typography>
                        )}
                          control={<Radio key={`${row.value}_radio_${currentStep}`} />}
                          style={{ marginTop: 20 }}
                          key={`${row.id}_control_${currentStep}`}
                          disabled={!!(confirmTakenDates.find(page => page.shift !== currentStepData.shift && page.isUntaken && Number(page.untakenRowId) === Number(row.id)))}
                        />
                      </Grid>
                      <Grid item xs={1} key={`${row.id}_${currentStep}_after`}>&nbsp;</Grid>
                    </Grid>
                  ))}
                </Grid>
                { selectedUntakenError && (
                  <Grid item xs={12} sx={stylesWithTheme.centerSubTitle}>
                    <Typography
                      color="error"
                      key={`untaken_error_${currentStep}`}
                    >
                      {selectedUntakenError}
                    </Typography>
                  </Grid>
                )
                }
              </RadioGroup>
            ) }
          { !currentStepData.isUntaken
            && (
              <RadioGroup
                aria-label='confirmation category'
                name='confirmationCategory'
                row
                value={currentStepData.confirmationCategory}
                key={`category_${currentStep}`}
                onChange={(e) => {
                  if (e.target.value) {
                    const resetKeys = resetKeyObj[e.target.value];

                    // Reset the others
                    resetKeys && resetKeys.forEach((resetKey) => {
                      currentStepData[resetKey] = {};
                      this.setState({ [`${resetKey}Error`]: '' });
                    });

                    confirmTakenDates[currentStep - 1] = {
                      ...currentStepData,
                      confirmationCategory: e.target.value,
                    };
                    this.checkFormValid();
                    this.setState({ confirmTakenDates });
                  }
                }}
                justifyContent='center'
              >
              <Grid container>
                <Grid item xs={12} sx={stylesWithTheme.centerSubTitle}>
                  <Typography sx={stylesWithTheme.shiftLabel}>
                    { currentStepData.shift ? `Taken Shift ${currentStepData.shift}` : null }
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>This shift was taken by </Typography>
                </Grid>
                {[{
                  label: 'this person: ',
                  value: 'known_taken',
                  nameComponent: (
                    <UserAutocomplete
                      handleStateChange={(selectedTalentUser) => {
                        confirmTakenDates[currentStep - 1] = {
                          ...currentStepData,
                          selectedTalentUser,
                        };

                        this.checkFormValid();
                        this.setState({ confirmTakenDates });

                        if (selectedTalentUser && selectedTalentUser.id) {
                          this.setState({ selectedTalentUserError: '' });
                        }
                      }}
                      title='User name/email'
                      items={talentList || []}
                      value={currentStepData.selectedTalentUser || {}}
                      key={`user_autocomplete_${currentStep}`}
                      displayError={selectedTalentUserError}
                      disabled={currentStepData.confirmationCategory !== 'known_taken'}
                    />
                  ),
                  errorComponent: selectedTalentUserError && (
                    <Typography
                      color="error"
                      key={`user_error_${currentStep}`}
                    >
                      {selectedTalentUserError}
                    </Typography>
                  ),
                }, {
                  label: 'an agency: ',
                  value: 'agency_taken',
                  nameComponent: (
                    <React.Fragment>
                      <UserAutocomplete
                        handleStateChange={(selectedAgencyUser) => {
                          confirmTakenDates[currentStep - 1] = {
                            ...currentStepData,
                            selectedAgencyUser,
                          };

                          this.checkFormValid();
                          this.setState({ confirmTakenDates });

                          const error = selectedAgencyUser
                            && (selectedAgencyUser.code !== 'OTHER' || (selectedAgencyUser.code === 'OTHER' && selectedAgencyUser.option))
                            ? '' : 'Please enter the agency name';
                          this.setState({ selectedAgencyUserError: error });
                        }}
                        title='Agency Name'
                        items={agencyList || []}
                        value={currentStepData.selectedAgencyUser || {}}
                        key={`agency_autocomplete_${currentStep}`}
                        disabled={currentStepData.confirmationCategory !== 'agency_taken'}
                        defaultOption={agencyList.find(x => x.code === 'OTHER')}
                        noOptionsText="No Agencies"
                      />
                      {currentStepData.selectedAgencyUser && currentStepData.selectedAgencyUser.code === 'OTHER' && (
                        <TextInput
                          value={currentStepData.selectedAgencyUser.option || ''}
                          title='Agency Name'
                          onChange={(option) => {
                            const selectedAgencyUser = Object.assign({}, currentStepData.selectedAgencyUser);
                            selectedAgencyUser.option = option;

                            confirmTakenDates[currentStep - 1] = {
                              ...currentStepData,
                              selectedAgencyUser,
                            };

                            this.checkFormValid();
                            this.setState({ confirmTakenDates });
                            this.setState({ selectedAgencyUserError: option ? '' : 'Please enter agency name' });
                          }}
                          disabled={currentStepData.confirmationCategory !== 'agency_taken'}
                        />
                      )}
                    </React.Fragment>
                  ),
                  errorComponent: selectedAgencyUserError && (
                    <Typography
                      color="error"
                      key={`agency_error_${currentStep}`}
                    >
                      {selectedAgencyUserError}
                    </Typography>
                  ),
                }, {
                  label: 'other non-Unleashed user',
                  value: 'unknown_taken',
                  nameComponent: (
                    <React.Fragment>
                      {currentStepData.confirmationCategory === 'unknown_taken' && this.renderOTherFields(currentStepData, 'name')}
                      {currentStepData.confirmationCategory === 'unknown_taken' && this.renderOTherFields(currentStepData, 'email')}
                    </React.Fragment>
                  ),
                  errorComponent: (selectedOtherUserError) && (
                    <Typography
                      color="error"
                      key={`other_error_${currentStep}`}
                    >
                      {selectedOtherUserError}
                    </Typography>
                  ),
                }].map(option => (
                  <Grid container key={`container_${option.value}_${currentStep}`}>
                    <Grid
                      item
                      xs={5}
                      key={`${option.value}_${currentStep}`}
                    >
                      <FormControlLabel
                        value={option.value}
                        label={(
                          <Typography
                            align='left'
                            key={`${option.value}_label_${currentStep}`}
                          >
                            {option.label}
                          </Typography>
                        )}
                        control={<Radio key={`${option.value}_radio_${currentStep}`} />}
                        style={{ marginTop: 20 }}
                        key={`${option.value}_control_${currentStep}`}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={7}
                      key={`${option.value}_name_${currentStep}`}
                    >
                      {option.nameComponent}
                    </Grid>
                    {option.errorComponent && (
                      <Grid item xs={12} align='right' key={`${option.value}_error_${currentStep}`}>
                        {option.errorComponent}
                      </Grid>
                    )}
                  </Grid>
                ))}
              </Grid>
            </RadioGroup>
            )
          }
        </DialogContent>
        <DialogActions sx={stylesWithTheme.dialogActions}>
          <Grid container>
            <Grid
              item
              xs={3}
              sx={stylesWithTheme.dialogFooter}
              style={{ justifyContent: 'flex-start' }}
              key={`arrow_back_grid_${currentStep}`}
            >
              { currentStep > 1 && currentStep <= totalStep
                ? (
                  <Icon
                    sx={stylesWithTheme.navigateIcon}
                    onClick={() => this.go('back')}
                    key={`arrow_back_${currentStep}`}
                  >
                    arrow_back
                  </Icon>
                )
                : null
              }

            </Grid>
            <Grid
              item
              xs={6}
              sx={stylesWithTheme.dialogFooter}
              style={{ justifyContent: 'center' }}
              key={`submit_grid_${currentStep}`}
            >
              { currentStep == totalStep
                && (
                  <Button
                    sx={stylesWithTheme.button}
                    style={{ maxWidth: '50%', height: 40 }}
                    onClick={() => this.handleSave()}
                    color="primary"
                    key={`submit_${currentStep}`}
                    disabled={this.state.isInValid}
                  >
                    SUBMIT
                  </Button>
                )
              }
            </Grid>
            <Grid
              item
              xs={3}
              sx={stylesWithTheme.dialogFooter}
              style={{ justifyContent: 'flex-end' }}
              key={`arrow_forward_grid_${currentStep}`}
            >
              { currentStep < totalStep
                && (
                  <Icon
                    sx={stylesWithTheme.navigateIcon}
                    onClick={() => this.go()}
                    key={`arrow_forward_${currentStep}`}
                  >
                    arrow_forward
                  </Icon>
                )
              }
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  }
}

CalendarConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  totalStep: PropTypes.number,
  confirmTakenDates: PropTypes.array,
  agencyList: PropTypes.array,
  talentList: PropTypes.array,
  coronadoErDayColor: PropTypes.string,
};

export default withStyles(MultipleNeedsDialogStyles, { withTheme: true })(CalendarConfirmationDialog);
