import {
  headerButtonStyle,
  hoverEffectOne,
  hoverEffectTwo,
  navy,
  tiffany,
  orange,
} from '../../../css/style';

export const badgesPageStyles = theme => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '98%',
    width: '100%',
    alignItems: 'center',
    overflow: 'hidden',
  },
  inputStyle: {
    padding: '0px 10px',
    height: 25,
  },
  fabButton: {
    position: 'fixed',
    width: 63,
    height: 63,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    fontFamily: 'Asap',
    fontSize: '9px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    cursor: 'pointer',
    justifyContent: 'center',
    alignItems: 'center',
    right: 20,
    bottom: 100,
    backgroundColor: `${orange}`,
    color: '#fff',
    borderRadius: '50%',
  },
  helpOutlineIcon: {
    marginLeft: '2px',
    marginTop: '6px',
    fontSize: '18px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  helpPopoverText: {
    whiteSpace: 'pre-line',
  },
  helpPopover: {
    maxWidth: '400px',
    padding: '10px',
  },
  inviteUserBtnIcon: {
    marginRight: '10px',
    fontSize: '19px',
  },
  resourceHeader: {
    display: 'flex',
    flexDirection: 'column',
    width: '94%',
    alignItems: 'left',
    justifyContent: 'space-between',
    margin: '3% 3% 0 3%',
    position: 'relative',
    [theme && theme.breakpoints.down('sm')]: {
      paddingTop: '30px',
    },
  },
  headerButtonGroup: {
    display: 'flex',
    flexDirection: 'row',

    [theme && theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  headerButton: {
    ...headerButtonStyle,
    color: '#fff',
    backgroundColor: `${orange}`,
    cursor: 'pointer',
    position: 'static',
    marginLeft: '10px',
    ...hoverEffectOne,

    [theme && theme.breakpoints.down('sm')]: {
      maxWidth: '130px',
      maxHeight: '30px',
      marginBottom: '5px',
    },
  },
  headerSecondButton: {
    ...headerButtonStyle,
    color: '#ffffff',
    backgroundColor: `${navy}`,
    cursor: 'pointer',
    position: 'static',
    marginLeft: '10px',
    ...hoverEffectTwo,

    [theme && theme.breakpoints.down('sm')]: {
      maxWidth: '130px',
      maxHeight: '30px',
      marginBottom: '5px',
    },
  },
  resourceLogo: {
    verticalAlign: 'sub',
    marginRight: '10px',
    width: '38px',
    height: '43px',
  },
  dashboard: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginRight: '5px',
    marginLeft: '5px',
    fontSize: '1.5rem',
  },
  archivedIcon: {
    color: '#D7D7D7D7',
    marginRight: '5px',
    marginLeft: '5px',
    fontSize: 18,
  },
  resourceName: {
    fontSize: '30px',
    fontWeight: 'bold',
    color: 'white',
    textTransform: 'uppercase',
    fontFamily: 'circe-rounded',
    display: 'flex',
    justifyContent: 'space-between',
  },
  paperContainer: {
    padding: '10px',
  },
  emptyPaper: {
    margin: '1% 2% 1% 2%',
    width: '94%',
    maxHeight: '95%',
    overflowX: 'hidden',
    overflowY: 'hidden',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  paper: {
    margin: '3% 2% 1% 2%',
    width: '94%',
    maxHeight: '95%',
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  searchComponentContainer: {
    margin: '15px 3% 10px 3%',
    width: '94%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  nameSearch: {
    height: '35px',
  },
  badgesSearch: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    fontFamily: 'Asap',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#000000',
  },
  badgesActions: {
    display: 'flex',
    flexDirection: 'row',
  },
  badgeFilter: {
    color: '#fff',
    marginLeft: '5px',
    height: '25px',
    padding: '0px 8px',
  },
  filter: {
    marginLeft: '5px',
    marginRight: '20px',
    height: '25px',
    color: '#fff',
    backgroundColor: `${tiffany}`,
    padding: '0px 8px',
  },
  searchComponentPaper: {
    width: '100%',
    maxHeight: '95%',
    minHeight: '80px',
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  clearFilterText: {
    marginTop: '-5px',
    color: `${navy}`,
    textDecoration: 'underline',
    float: 'right',
    fontStyle: 'italic',
    textTransform: 'lowercase',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'unset',
    },
  },
  addNewResButton: {
    backgroundColor: '#e0592a',
    color: '#fff',
    padding: '0',
    fontSize: '11px',
    height: '40px',
    width: '100px',
    fontFamily: 'Asap',
    boxShadow: 'none',
    marginLeft: '20px',
    marginRight: 'auto',
  },
  editResButton: {
    color: '#fff',
    padding: '0',
    fontSize: '11px',
    height: '40px',
    width: '100px',
    fontFamily: 'Asap',
    boxShadow: 'none',
    marginLeft: '20px',
    marginRight: 'auto',
  },
  positions: {
    display: 'flex',
    flexDireaction: 'row',
    alignItems: 'center',
    justifyContent: 'left',
  },
  divider: {
    borderRight: '1px solid grey',
    width: '20px',
    height: '15px',
    marginRight: '10px',
  },
  numbers: {
    color: `${tiffany}`,
    fontWeight: 'bold',
  },
  paperFilters: {
    display: 'flex',
  },
  filterFrontContainer: {
    margin: '30px 2% 0 2%',
    width: '94%',
    zIndex: '1',
  },
  searchButton: {
    width: '300px !important',
    height: '45px',
    fontFamily: 'Asap',
    fontSize: '12px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#ffffff',
    margin: '20px 20px 20px 20px',
    borderRadius: '5px',
    backgroundColor: `${navy}`,
    '&:hover': {
      backgroundColor: `${tiffany}!important`,
    },
  },
});