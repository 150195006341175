import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  IconButton,
  Icon,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';

import {
  navy,
  tiffany,
  hoverEffectTwo,
  dialogCloseButtonStyle,
  dialogTitleContainerStyle,
} from '../../css/style';

const ArchiveDialogStyles = theme => ({
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  titleContainer: {
    ...dialogTitleContainerStyle,
  },
  closeButton: {
    ...dialogCloseButtonStyle,
    color: theme.palette.grey[500],
  },
  icon: {
    fontSize: '37px',
    height: '50px',
    width: '50px',
    textAlign: 'center',
    margin: '20px 0',
    border: `solid 3px ${tiffany}`,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: `${tiffany}`,
  },
  dialogActions: {
    justifyContent: 'center',
    padding: '8px 25px',
  },
  dialogContent: {
    padding: '8px 25px',
  },
  button: {
    backgroundColor: `${navy}`,
    color: 'white',
    width: '100%',
    padding: '14px',
    ...hoverEffectTwo,
  },
  typographyName: {
    marginTop: '15px',
    fontWeight: 'bold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const ArchiveDialog = (props) => {
  const {
    handleOnClick, open, resource, resourceName, resourceId, promptText, theme,
  } = props;
  const stylesWithTheme = ArchiveDialogStyles(theme);

  return (
    <Dialog
      maxWidth="xs"
      PaperProps={{
        style: { padding: '24px 4px', minWidth: '310px' },
      }}
      open={open}
      onClose={() => handleOnClick('close')}
    >
      <DialogTitle sx={stylesWithTheme.dialogContent}>
        <div style={stylesWithTheme.titleContainer}>
          <Icon sx={stylesWithTheme.icon}>
            move_to_inbox
          </Icon>
          <Typography color="primary" sx={stylesWithTheme.title}>
            {`ARCHIVE ${resource.toUpperCase()}`}
          </Typography>
        </div>
        <IconButton
          aria-label="close"
          sx={stylesWithTheme.closeButton}
          onClick={() => handleOnClick('close')}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={stylesWithTheme.dialogContent}>
        <Typography gutterBottom align="center">
          {!promptText ? `Are you sure you want to archive the ${resource} below from the system?`
            : promptText}
        </Typography>
        <Typography gutterBottom align="center" sx={stylesWithTheme.typographyName}>
          {resourceName}
        </Typography>
      </DialogContent>
      <DialogActions sx={stylesWithTheme.dialogActions}>
        <Button
          sx={stylesWithTheme.button}
          onClick={() => handleOnClick('confirm', resourceId)}
          color="primary"
        >
          {!promptText ? `YES, ARCHIVE ${resource.toUpperCase()}` : 'YES'}
        </Button>
        {promptText
        && (
          <Button
            sx={stylesWithTheme.button}
            onClick={() => handleOnClick('no')}
            color="secondary"
          >
          {'NO'}
        </Button>
        )
        }
      </DialogActions>
    </Dialog>
  );
};

ArchiveDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  resource: PropTypes.string.isRequired,
  resourceName: PropTypes.string.isRequired,
  resourceId: PropTypes.number,
};

ArchiveDialog.defaultProps = {
  resourceId: null,
};

export default withStyles(ArchiveDialogStyles, { withTheme: true })(ArchiveDialog);
